import axios from "axios";

export const downloadFile = (item) => {
  let servInstance = process.env.REACT_APP_BASE_URL;

  axios({
    url: servInstance + item.file_path,
    method: item.method,
    responseType: "blob", // important
  })
    .then((response) => {
      if (response.status == 200) {
        var file_blob = new Blob([response.data]);
        if (file_blob instanceof Blob) {
          const url = window.URL.createObjectURL(file_blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${item.display_name}`);
          document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
    })
    .catch((err) => {});
};

export const generateErrorMessage = (err) => {
  let errMessage = "Something went wrong!";

  const errorArray = [];
  if (err.response.data.errors) {
    if (Array.isArray(err.response.data.errors)) {
      err.response.data.errors.forEach((item) => {
        errorArray.push(`${item.field} : ${item.message}`);
      });
      errMessage = String(errorArray);
    }
  } else {
    if (err?.response?.data?.error?.message) {
      errMessage = err?.response?.data?.error?.message;
    } else {
      errMessage = "Something went wrong!";
    }
  }
  return errMessage;
};

export const getServiceTotal = (chrg, gvt, gst, disc, ind, vf) => {
  let actual = chrg && chrg !== "" ? Number(chrg) : 0;
  let govt_fee = gvt && gvt !== "" ? Number(gvt) : 0;
  let gst_per = gst && gst !== "" ? Number(gst) : 0;
  let disc_per = disc && disc !== "" ? Number(disc) : 0;
  let vendor_fee = vf && vf !== "" ? Number(vf) : 0;

  let calculatedValue = govt_fee;

  if (actual || vendor_fee) {
    if (disc_per) {
      actual = (actual * (100 - disc_per)) / 100;
      vendor_fee = (vendor_fee * (100 - disc_per)) / 100;
    }
    calculatedValue =
      calculatedValue +
      actual +
      vendor_fee +
      ((actual + vendor_fee) * gst_per) / 100;
  }

  return calculatedValue.toFixed(2);
};

export const handleError = (err) => {

  const HTTP_NOT_FOUND = 404;
  const HTTP_403_FORBIDDEN = 403;
  if (err?.response?.status === HTTP_NOT_FOUND) {
    window.location.href = "/page-not-found";
  } else if (err?.response?.status === HTTP_403_FORBIDDEN) {
      return false;
  }else {
    return true;
  }
};
