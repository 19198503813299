// export const ROLES = {
//     admin: "ADMIN",
//     teamLead: "TL",
//     agent: "AGENT"
// };

export const SCOPES = {
  viewEmployee: "employee.view_employee",
  addEmployee: "employee.add_employee",
  changeEmployee: "employee.change_employee",
  deleteEmployee: "employee.delete_employee",
  manageEmployee: "employee.employee",
  changeEmployeePassword: "employee.change_employee_password",
  manageExport: "employee.manage_export",

  dashboard: "employee.employee_dashboard",

  settings: "employee.settings",
  manageOrg: "employee.manage_org",
  teamSettings: "employee.manage_team",
  manageRole: "group_extend.manage_role",
  manageServices: "service.manage_service",
  viewService: "service.view_service",
  taskSettings: "employee.task_settings",
  promotionSettings: "employee.task_settings",

  manageCoupon: "employee.task_settings",
  manageData: "employee.task_settings",
  manageForms: "employee.task_settings",
  manageLeads: "crm.lead",
  manageQuotes: "sales.quote",
  manageTasks: "employee.employee",
  manageApprovals: "employee.employee",
  managePaymentApprovals: "order.payment_approvals",
  exportPaymentApprovals: "order.export_payment_approvals",

  // contacts
  manageContacts: "customer.customer",
  viewContacts: "customer.view_customer",
  addContacts: "customer.add_customer",
  editContact: "customer.change_customer",
  deleteContact: "customer.delete_customer",
  exportContact: "customer.export_customers",
  // accounts
  manageAccounts: "customer.account",
  editAccounts: "customer.change_customercompany",
  viewAccounts: "customer.view_customercompany",
  deleteAccounts: "customer.delete_customercompany",
  addAccounts: "customer.add_customercompany",
  exportAccounts: "customer.export_accounts",
  //orders
  manageOrders: "order.order",
  viewOrder: "order.view_order",
  viewAllOrder: "order.view_team_order",
  viewTeamOrder: "order.view_all_order",
  addOrder: "order.add_order",
  editOrder: "order.change_order",
  deleteOrder: "order.delete_order",
  addOrderPayment: "order.add_payments",
  deleteOrderPayment: "order.delete_payments",
  cancelOrderPayment: "order.cancel_payments",
  resendOrderPaymentLink: "order.resend_payment_links",
  sendOrderPaymentLink: "order.send_payment_links",
  addOrderItems: "order.add_order_items",
  deleteOrderItems: "order.delete_order_items",
  exportOrders: "order.export_orders",
  //
  manageDeals: "crm.opportunity",

  manageInvoices: "sales.invoice",
  managePayments: "payment.payment",
  viewPayments: "payment.view_payment",
  manageFilings: "sales.payment",
};

// export const PERMISSIONS = {
//     [ROLES.agent]: [SCOPES.canView],
//     [ROLES.teamLead]: [SCOPES.canView, SCOPES.canEdit],
//     [ROLES.admin]: [
//         SCOPES.canView,
//         SCOPES.canEdit,
//         SCOPES.canCreate,
//         SCOPES.canDelete,
//         SCOPES.manageEmployee,
//         SCOPES.employee
//     ]
// };
