import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import apis from "../../../apiactions/api.actions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { constitutioncat } from "../apifunctions";

class ConstitutionCategoriesCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        ranking_order: "1",
        constitution_category_code: "",
        constitution_category_name: "",
        constitution_category_display_name: "",
        description: "",
        status: "",
      },
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loader: true });
    apis
      .createData(constitutioncat + "?", this.state.formData)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            loader: false,
          },
          () => {
            setTimeout(() => {
              this.props.history.push(
                "/data-management/constitution-categories"
              );
            }, 2500);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  handleFileChange = (e) => {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
  };
  handleClose = () => {
    this.props.history.push("/data-management/constitution-categories");
  };

  render() {
    const { masterData } = this.props;
    const { loader, formData } = this.state;
    const textareaStyle = {
      minHeight: "135px",
    };

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} className=" itemCenter">
                <div className="card-title">
                  <h4 className="">Add New Constitution Category</h4>
                </div>
              </Grid>
              <Grid align="right" item xs={6} sm={6}>
                <button
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </Grid>
            </Grid>
            <div className="card-content cardContentBlock">
              <div className="row">
                <div className="col-md-10 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Display Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="constitution_category_display_name"
                        value={formData.constitution_category_display_name}
                        onChange={this.handleChange}
                        class="form-control"
                        required
                        placeholder="Constitution Category Display Name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Status <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        required
                        onChange={this.handleChange}
                        value={formData.status}
                      >
                        <option value="" selected>
                          Status
                        </option>
                        {masterData &&
                          masterData.statuses &&
                          masterData.statuses.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Category Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="constitution_category_name"
                        value={formData.constitution_category_name}
                        onChange={this.handleChange}
                        class="form-control"
                        required
                        placeholder="Constitution Category Name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Category Code <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="constitution_category_code"
                        value={formData.constitution_category_code}
                        onChange={this.handleChange}
                        class="form-control"
                        required
                        placeholder="Constitution Category Code"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Description</label>
                      <textarea
                        name="description"
                        value={formData.description ? formData.description : ""}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Description"
                        rows="5"
                        style={textareaStyle}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-xs-12 upload-block upload-block01 ">
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label">Thumbnail Image </label>
                    <div className="upload-img">
                      <input
                        type="file"
                        onChange={this.handleFileChange}
                        className="file-upload"
                        id="img-upload"
                      />
                      <label for="img-upload">
                        <img alt="" id="output" />
                        <div className="upload-icon">
                          <img src="/app-assets/backend-images/icons/camera.svg" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ConstitutionCategoriesCreate;
