export const parseQueryParams = (params = {}) => {
  if (!Object.entries(params).length) return "";
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + (value && value !== '' ? `${key}=${value}&` : ''),
    "?"
  );
};
export const parseExpQueryParams = (params = {}) => {
  if (!Object.entries(params).length) return "";
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + `${key}=${value}&`,
    ""
  );
};

export const formatFormErrors = (params = {}) => {
  if (!Object.entries(params).length) return "";
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + `${key}=${value},`,
    ""
  );
};


export const EMAIL_REGEX = '^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$';
