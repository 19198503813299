import { Grid, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CommonLoaderComponent from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeletePopup from "../../../../../plugins/delete/newDeleteComponent";
import SnackbarComponent from "../../../../../plugins/snackbar-component";
import { delete_team_from_employee, fetchEmployeeTeams } from "../../../apifunctions";
import TeamDialogTheme from "../../employeedetailtheme";
import ManageEmployeeTeam from "./manage";

const EmployeeRole = (props) => {
  console.log(props);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});

  const [snackbarData, setSnackbarData] = useState({});

  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleCancel = (flag = false) => {
    setEdit(false);
    if (flag) {
      handleGetEmployeeTeam()
    }
  };
  const handleDeleteOpen = (e, data) => {
    setRowData(data);
    setOpenDelete(true);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };
  const handleGetEmployeeTeam = () => {
    fetchEmployeeTeams(props.employee_id)
      .then((res) => {
        if (res.data.employee_profile) {
          setFormData(res.data.employee_profile.team)
        }
        else {
          // setFormData(res.data.employee_profile.team)
        }
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });

      });
  }
  useEffect(()=> {
    handleGetEmployeeTeam();
  }, [props.employee_id])

  const handleDeleteConfirm = () => {
    delete_team_from_employee(props.mainData.id, rowData?.id)
      .then((res) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: res.data?.message,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        setTimeout(() => {
          handleGetEmployeeTeam();
        }, 500);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  return (
    <div>
      <CommonLoaderComponent open={loader} />
      <DeletePopup
        opendelete={openDelete}
        submitted={loader}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
      />
      {edit && (
        <ManageEmployeeTeam
          open={edit}
          employee_id={props.mainData.id}
          handleClose={handleCancel}
          refetch={handleGetEmployeeTeam}
        />
      )}
      <MuiThemeProvider theme={TeamDialogTheme}>
        {/* <form onSubmit={(e) => onSubmit(e)}> */}
        <Grid container spacing={2} className="customFields">
          <Grid item xs={12}>
            <div className=" p-0 m-0">
              <div className="fieldBox d-flex align-items-center justify-content-between">
                <div className="details-box">
                  <div className="detail-box border-r">
                    <div className="">
                      <p className="name">Team Info</p>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  {!edit && (
                    <button
                      className="btn btn-primary"
                      title="Edit Team Lead"
                      onClick={(e) => setEdit(true)}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={4}>
            <label className="control-label">
            Team Lead <span className="red">*</span>
          </label>
          </Grid> */}
          <Grid item xs={12}>
            <div className=" w-100">
              <DataTable
                data={formData?? []}
                columns={[
                  {
                    title: "Name",
                    type: "text",
                    field: "name",
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "datetime_created",
                  pagination: false,
                }}
                actions={[
                  {
                    className: "delete",
                    rowActionCLick: handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
        {/* </form> */}
      </MuiThemeProvider>
      <SnackbarComponent
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
    </div>
  );
};

export default EmployeeRole;
