import apis from "../apiactions/api.actions";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import permissionReducer from "../config/redux/permissionSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import persistReducer from "redux-persist/es/persistReducer";

function getmasterData() {
  apis
    .getDatas("core/master-data")
    .then((res) => res.data)
    .then((res) => {
      return res;
    });
}
export default getmasterData;

const reducers = combineReducers({
  permission: permissionReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.ENV !== "production",
  middleware: [thunk],
});
