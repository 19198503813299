import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const tasks = "employee/tasks/";
const task = "employee/task/";
const approvals =
  "employee/approval-requests/?approval_type__code=payment_approvals";
const export_approvals =
  "employee/approval-requests/export/?approval_type__code=payment_approvals";
const services = "service/";
const employee = "employee/";
const orders = "sales/orders/";
const alltasks = "employee/tasks/?view=all";
const accounts = "customer/companies/";
const contacts = "customer/";
const approval_types = "core/approval-types";
const approval_statuses = "core/approval-statuses";
const task_statuses = "core/task-statuses";
const employee_approval_request = "employee/approval-requests/";
const all_services = "service/?view=all";

export const searchtasks = (val) => apis.getDatas(tasks);
export const searchApprovals = (data) => apis.getDatas(approvals,data);
export const exportApprovals = (val) => apis.getDatas(export_approvals);
export const add_tasks = (data) => apis.createData(tasks, data);
export const update_tasks = (id, data) =>
  apis.updateData(`${tasks}${id}/`, data);
export const delete_tasks = (id) => apis.deleteData(`${tasks}${id}/`);
export const gettasksList = () => apis.getDatas(alltasks);
export const getServiceList = (data) =>
  apis.getDatas(`${services}${parseQueryParams(data)}`);
export const getEmployeeList = (data) =>
  apis.getDatas(`${employee}${parseQueryParams(data)}`);
export const fetchtaskDetails = (id) => apis.getDatas(tasks + `${id}/`);
export const fetchtaskMembers = (id) => apis.getDatas(task + `${id}/members`);
export const fetchtaskService = (id) => apis.getDatas(task + `${id}/services`);
export const addServiceTotask = (id, data) =>
  apis.createData(task + `${id}/add-service`, data);

export const addEmployeeTotask = (id, data) =>
  apis.createData(task + `${id}/add-member`, data);
export const settaskLead = (id, id2, data) =>
  apis.createData(task + `${id}/set-task-lead/${id2}`, data);
export const deleteEmployeeFromtask = (id, id2) =>
  apis.deleteData(task + `${id}/members/${id2}`);
export const deleteServiceFromtask = (id, id2) =>
  apis.deleteData(task + `${id}/services/${id2}`);
export const add_orders = (val) =>
  apis.createData(orders + "create-order", val);
export const update_orders = (id, val) => apis.updateData(orders + id, val);
export const add_items_to_orders = (id, val) =>
  apis.createData(orders + id + "/add-items", val);
export const assign_employee_to_order_item_task = (id, id1, id2, val) =>
  apis.createData(orders + id + "/items/" + id1 + "/task/" + id2, val);
export const assign_team_lead_to_order_item = (id, id1, val) =>
  apis.createData(orders + id + "/items/" + id1 + "/assign", val);
export const fetch_order_items = (id) => apis.getDatas(orders + id + "/items");
export const remove_order_items = (id, id2) =>
  apis.deleteData(orders + id + "/remove-item/" + id2);
export const fetch_order_item_tasks = (id, id2) =>
  apis.getDatas(orders + id + "/items/" + id2 + "/tasks");
export const fetch_order_item_task_approvals = (id, id2, id3) =>
  apis.getDatas(
    orders + id + "/items/" + id2 + "/tasks/" + id3 + "/approval-requests",
  );
export const add_order_item_task_approvals = (id, id2, id3, data) =>
  apis.createData(
    orders + id + "/items/" + id2 + "/tasks/" + id3 + "/approval-requests",
    data,
  );
export const get_order_details = (val) => apis.getDatas(orders + val);

export const getTeamLeads = (data) =>
  apis.getDatas(`employee/team/leads${parseQueryParams(data)}`);
export const getEmployees = (data) =>
  apis.getDatas(`employee/${parseQueryParams(data)}`);

export const getAccountList = (data) =>
  apis.getDatas(`${accounts}${parseQueryParams(data)}`);
export const getApprovalTypeList = (data) =>
  apis.getDatas(`${approval_types}${parseQueryParams(data)}`);
export const getTaskStatusList = (data) =>
  apis.getDatas(`${task_statuses}${parseQueryParams(data)}`);
export const getApprovalStatusList = (data) =>
  apis.getDatas(`${approval_statuses}${parseQueryParams(data)}`);
export const getOrderStatusList = (data) =>
  apis.getDatas(`core/order-statuses${parseQueryParams(data)}`);

export const getContactList = (data) =>
  apis.getDatas(`${contacts}${parseQueryParams(data)}`);
export const changeApprovalStatus = (id, data) =>
  apis.patchData(`${employee_approval_request}${id}/`, data);
export const changeTaskStatus = (id, data) =>
  apis.createData(`${tasks}${id}/change-status`, data);
export const get_order_payments = (id) =>
  apis.getDatas(orders + id + "/payments");
export const create_order_payments = (id, data) =>
  apis.createData(orders + id + "/payments", data);
export const remove_order_payment = (id, id2) =>
  apis.deleteData(orders + id + "/payments/" + id2);
export const getPaymentMethods = (data) =>
  apis.getDatas(`cart/payment-methods/`);

export const getAllServiceList = (data) =>
  apis.getDatas(`${all_services}${parseQueryParams(data)}`);
