import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MuiThemeProvider,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { useEffect, useState } from "react";

import CalendarComponent from "../../../../../plugins/calendar.comp";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { create_order_payments } from "../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

import "./style.css";
import { formatDate } from "../../../../../utils/dateUtils";
import { MdDownload } from "react-icons/md";

const ViewPayment = ({
  open = false,
  order_id,
  paymentType,
  data = {},
  handleClose = () => {},
  ...props
}) => {
  console.log(data);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({
    ...data,
    payment_type: paymentType,
  });

  const [serviceData, setServiceData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const fetchMethods = (e) => {
    let d = [];
    if (localStorage.getItem("masterData")) {
      let parsed = JSON.parse(localStorage.getItem("masterData"));
      d = parsed.payment_methods || [];
    }
    if (formData.payment_type === "link") {
      let razorpayPaymentMethod = d.find(
        (paymentMethod) => paymentMethod?.code === "razorpay"
      );
      if (razorpayPaymentMethod) {
        setFormData((formData) => ({
          ...formData,
          payment_method_name: razorpayPaymentMethod.name,
          payment_method: razorpayPaymentMethod.id,
        }));
      }
    }
    setServiceData(d);
  };
  console.log(serviceData);
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      payment_method_name: e.display_name,
      payment_method: e.id,
    }));
  };
  const handleDatePicker = (e) => {
    setFormData((f) => ({ ...f, [e.name]: e.value }));
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    if (name === "amount") {
      const regex = /^[0-9]*$/;
      if (regex.test(e.target.value)) {
        setFormData((f) => ({ ...f, [name]: value }));
      }
    } else if (name === "document") {
      setFormData((f) => ({ ...f, [name]: e.target.files[0] }));
    } else if (name === "is_advance") {
      setFormData((f) => ({ ...f, [name]: e.target.checked }));
    } else {
      setFormData((f) => ({ ...f, [name]: value }));
    }
  };
  useEffect(() => {
    fetchMethods();
  }, []);
  console.log(formData);
  const textareaStyle = {
    minHeight: "60px",
  };
  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleDownload = (r) => {
    if (r) {
      window.open(r, "_blank");
    }
  };

  const hasTransactions =
    formData.transactions && formData.transactions.length > 0;

  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields">
            <DialogTitle disableTypography>
              <h3>View Payment Data</h3>
              <IconButton aria-label="close" onClick={(e) => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-4 pb-2">
                  <InputLabel>Transaction Date</InputLabel>
                  <p>
                    {hasTransactions &&
                    formData.transactions[0]?.transaction_date
                      ? formatDate(formData.transactions[0]?.transaction_date)
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Transaction ID</InputLabel>
                  <p>
                    {hasTransactions
                      ? formData.transactions[0]?.transaction_id
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Payment Method</InputLabel>
                  <p>
                    {formData.payment_method?.name
                      ? formData.payment_method?.name
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Amount</InputLabel>
                  <p>{formData.amount ? formData.amount : "--"}</p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>External reference ID</InputLabel>
                  <p>
                    {hasTransactions &&
                    formData.transactions[0]?.external_reference_id
                      ? formData.transactions[0]?.external_reference_id
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Professional Fee</InputLabel>
                  <p>
                    {formData.professional_fee
                      ? formData.professional_fee
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Vendor Fee</InputLabel>
                  <p>{formData.vendor_fee ? formData.vendor_fee : "--"}</p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Govt Fee</InputLabel>
                  <p>{formData.govt_fee ? formData.govt_fee : "--"}</p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>GST Amount</InputLabel>
                  <p>{formData.gst ? formData.gst : "--"}</p>
                </div>
                <div className="col-4 pb-2">
                  <InputLabel>Extra Received</InputLabel>
                  <p>
                    {formData.extra_received ? formData.extra_received : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Receipt number</InputLabel>
                  <p>
                    {hasTransactions && formData.transactions[0]?.receipt_number
                      ? formData.transactions[0]?.receipt_number
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Advance</InputLabel>
                  <p>
                    {hasTransactions && formData.transactions[0]?.is_advance
                      ? "Yes"
                      : "No"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Created by</InputLabel>
                  <p>
                    {formData.created_by
                      ? `${formData.created_by?.first_name} ${formData.created_by?.last_name}`
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Document</InputLabel>
                  <p>
                    {hasTransactions &&
                    formData.transactions[0]?.payment_document?.name ? (
                      <div
                        className="d-flex align-content-center justify-content-between text-link cursor-pointer"
                        onClick={() =>
                          handleDownload(
                            formData.transactions[0]?.payment_document?.url
                          )
                        }
                      >
                        <div>
                          {formData.transactions[0]?.payment_document?.name}
                        </div>
                        <div style={{ paddingLeft: "2px" }}>
                          <MdDownload />
                        </div>
                      </div>
                    ) : (
                      "--"
                    )}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Payment Type</InputLabel>
                  <p>
                    {formData.payment_type_label
                      ? formData.payment_type_label
                      : "--"}
                  </p>
                </div>

                <div className="col-4 pb-2">
                  <InputLabel>Payment Status</InputLabel>
                  <p>
                    {formData.payment_status_label
                      ? formData.payment_status_label
                      : "--"}
                  </p>
                </div>

                <div className="col-8 pb-2">
                  <div className="textarea-height create-content p-0">
                    <InputLabel>Message</InputLabel>
                    <p>{formData.message ? formData.message : "--"}</p>
                  </div>
                </div>

                <div className="col-12 pb-2">
                  <div className="textarea-height create-content p-0">
                    <InputLabel>Internal Notes</InputLabel>
                    <p>
                      {formData.internal_notes ? formData.internal_notes : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </DialogContent>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ViewPayment;
