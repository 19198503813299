import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CommonLoader from "../../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../../plugins/snackbar-component";
import { add_order_item_task_approvals, getApprovalTypeList, getEmployees } from "../../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

const ManageOrderApprovalRequest = ({
  open = false,
  data = {},
  handleClose = () => {},
  orderId,
  itemId,
  taskId,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [approvalTypeData, setApprovalTypeData] = useState([]);

  const [serviceData, setServiceData] = useState([]);

  const history = useHistory();

  const fetchApprovalTypeBySearch = (e) => {
  
    getApprovalTypeList({ page: 1, ...(e ? { search: e } : {}) }).then(
      (res) => {
        setApprovalTypeData(res?.data || []);
      }
    );
  };
  const fetchEmployeeNySearch = (e) => {
 
    getEmployees({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setServiceData(res?.results || []);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    const payload = {
      title: formData?.title,
      comment: formData?.comment,
      assignee: formData?.assignee_id,
      approval_type: formData?.approval_type_id,
      ...(formData?.approval_type_code === "payment_approvals"
        ? { amount: formData?.amount || 0 }
        : {}),
    };
    let error = false;
    Object.keys(payload).forEach((item) => {
      if (item !== "comment" ) {
        if (payload[item].trim() === "") {
          error = true;
        }
      }
    });
    if (!error) {
      try {
        add_order_item_task_approvals(orderId, itemId, taskId, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);

            setTimeout(() => {
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please fill all reequired fields");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(e.target.value.trim()===""){
      value=""
    }

    if (name === "amount") {
      const regex = /^[0-9]*$/;
      if (regex.test(value)) {
        setFormData((f) => ({ ...f, [name]: value }));
      }
    } else {
      setFormData((f) => ({ ...f, [name]: value }));
    }
  };

  const handleApprovalTypeChange = (e, a, b) => {
    console.log(e);
    setFormData((f) => ({
      ...f,
      approval_type_name: e[b],
      approval_type_id: e[a],
      approval_type_code: e.code,
    }));
  };

  const handleServiceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      assignee_name: e["name"],
      assignee_id: e["id"],
    }));
  };
  useEffect(() => {
    fetchApprovalTypeBySearch();
    fetchEmployeeNySearch();
  }, []);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> Add Approval Request</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>
                  Title<span className="red">*</span>
                </InputLabel>
                <TextField
                  name="title"
                  id="id"
                  required
                  value={formData.title ? formData.title : ""}
                  placeholder="Title"
                  className="customFieldInput"
                  onChange={handleChange}
                />
              </div>
              <div>
                <InputLabel>Assignee<span className="red">*</span></InputLabel>
                <SelectBox
                  Data={serviceData}
                  id="id"
                  async="true"
                  required
                  value={formData.assignee_name ? formData.assignee_name : ""}
                  desc="name"
                  descId="id"
                  getData={fetchEmployeeNySearch}
                  placeholder="Select assignee"
                  className="form-control"
                  onChange={handleServiceChange}
                />
              </div>
              <div>
                <InputLabel>
                  Approval Type<span className="red">*</span>
                </InputLabel>
                <SelectBox
                  Data={approvalTypeData}
                  id="id"
                  async="true"
                  required
                  value={
                    formData.approval_type_name
                      ? formData.approval_type_name
                      : ""
                  }
                  desc="name"
                  descId="id"
                  getData={fetchApprovalTypeBySearch}
                  placeholder="Select approval type"
                  className="form-control"
                  onChange={handleApprovalTypeChange}
                />
              </div>
              {formData.approval_type_code === "payment_approvals" && (
                <div>
                  <InputLabel>
                    Amount<span className="red">*</span>
                  </InputLabel>
                  <TextField
                    name="amount"
                    id="id"
                    required
                    value={formData.amount ? formData.amount : ""}
                    placeholder="Amount"
                    className="customFieldInput"
                    onChange={handleChange}
                  />
                </div>
              )}
              <div>
                <InputLabel>
                  Remarks<span className="red">*</span>
                </InputLabel>
                <TextField
                  // autoFocus
                  variant="outlined"
                  margin="dense"
                  // required
                  name="comment"
                  value={formData.comment ? formData.comment : ""}
                  onChange={handleChange}
                  className="customFieldInput textareaField"
                  multiline
                  fullWidth
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageOrderApprovalRequest;
