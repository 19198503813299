import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  addParameterOption,
  getParameterList,
  getParameterOptionData,
  updateParameterOption,
} from "../apifunctions";

class ParameterOptionsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        parameter_name: "",
        min_value: "",
        max_value: "",
        option: "",
        admin_portal_name: "",
        display_name: "",
      },
      parameters_list: [],
      // parameter_type - 0: default, 1: Number Range, 2: Yes/No - Option
      parameter_type: 0,
      editflag: false,
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount = () => {
    if (this.props.match.params.id !== "new") {
      this.setState({ editflag: true });
      this.getParameterOptionData();
    } else {
      this.setState({ editflag: false });
    }
    this.getParams();
  };
  handleChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
    if (e.target.name === "parameter_name") {
      if (this.state.editflag) {
        this.setState({
          formData: {
            ...this.state.formData,
            [e.target.name]: e.target.value,
            min_value: "",
            max_value: "",
            option: "",
          },
        });
      }
      this.loadParamType(e.target.value);
    }
  };
  loadParamType = (parameter_id) => {
    let parameter_type = this.getParamType(parameter_id);
    if (parameter_type === "Number Range") {
      this.setState({
        parameter_type: 1,
      });
    } else if (parameter_type === "Yes/No - Option") {
      this.setState({
        parameter_type: 2,
      });
    }
  };
  getParamType = (parameter_name) => {
    let parameter_type = "";
    for (let i = 0; i < this.state.parameters_list.length; i++) {
      if (parameter_name == this.state.parameters_list[i].id) {
        parameter_type = this.state.parameters_list[i].parameter_type;
        break;
      }
    }

    return parameter_type;
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleDescriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.editflag) {
      addParameterOption(this.state.formData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.props.history.push("/data-management/parameter-options");
              }, 2500);
            }
          );
        })
        .catch((err) => {
          //sreeeee
          //
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } else {
      updateParameterOption(this.props.match.params.id, this.state.formData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.props.history.push("/data-management/parameter-options");
              }, 2500);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    }
  };
  handleFileChange = (e) => {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
  };
  handleClose = () => {
    this.props.history.push("/data-management/parameter-options");
  };

  async getParameterOptionData() {
    this.setState({ loader: true });
    try {
      getParameterOptionData(this.props.match.params.id)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: {
                parameter_name: res.parameter_name.id,
                min_value: res.min_value,
                max_value: res.max_value,
                option: res.option,
                admin_portal_name: res.admin_portal_name,
                display_name: res.display_name,
              },
              loader: false,
            },
            () => {
              setTimeout(() => {
                this.loadParamType(res.parameter_name.id);
              }, 200);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  async getParams() {
    try {
      getParameterList()
        .then((res) => res.data)
        .then((res) => {
          this.setState({ parameters_list: res.parameters });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  render() {
    //  const { masterData } = this.props;
    const { formData, editflag, parameters_list, parameter_type } = this.state;

    return (
      <div className="create-content">
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} className=" itemCenter">
                <div className="card-title">
                  <h4>
                    {editflag
                      ? "Edit Parameter Option"
                      : "Add New Parameter Option"}
                  </h4>
                </div>
              </Grid>
              <Grid align="right" item xs={6} sm={6}>
                <button
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {editflag ? "Update" : "Save"}
                </button>
              </Grid>
            </Grid>
            <div className="card-content cardContentBlock">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Parameter Name <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="parameter_name"
                        value={formData.parameter_name}
                        onChange={this.handleChange}
                        required //sreelekshmi
                      >
                        <option value="">Select Parameter</option>
                        {parameters_list.map(
                          ({ id, parameter_name }, index) => (
                            <option value={id}>{parameter_name}</option>
                          )
                        )}
                      </select>
                    </div>
                    {parameter_type === 1 ? (
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Minimum Value <span className="red">*</span>
                        </label>
                        <input
                          type="number"
                          name="min_value"
                          value={formData.min_value}
                          onChange={this.handleChange}
                          class="form-control"
                          placeholder="Enter Minimum Value"
                          required //sreelekshmi
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {parameter_type === 1 ? (
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Maximum Value <span className="red">*</span>
                        </label>
                        <input
                          type="number"
                          name="max_value"
                          value={formData.max_value}
                          onChange={this.handleChange}
                          class="form-control"
                          placeholder="Enter Maximum Value"
                          required //sreelekshmi
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {parameter_type === 2 ? (
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Parameter Options <span className="red">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="option"
                          value={formData.option}
                          onChange={this.handleChange}
                          required
                        >
                          <option value="">Select Parameter</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Admin Portal Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin_portal_name"
                        value={formData.admin_portal_name}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Enter Admin Portal Name"
                        required
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Display Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="display_name"
                        value={formData.display_name}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Enter Display Name"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ParameterOptionsCreate;
