import React, { Component, useState } from "react";
import { Grid, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SnackbarMessage from "../../../plugins/snackbar-component";

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      formSearch: {
        page: 1,
        search: "",
      },
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
  }

  async componentDidMount() {
    try {
    } catch (err) {}
  }

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  // setSmShow = (value) => {
  //   this.smShow = value;
  // };

  // setLgShow = (value) => {
  //   this.lgShow = value;
  // };

  render() {
    // this.setSmShow(false);
    // this.setLgShow(false);

    console.log("text");
    const { loader, totalCount, formSearch, pagesize, editMode, formData } =
      this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={3} sm={4} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={1} sm={1} className="filter-box">
              <Link className="filter-btn">
                <img src="/app-assets/backend-images/icons/filter-icon.svg" />
              </Link>
            </Grid>

            <Grid item align="right" xs={8} sm={7}>
              {/* <Link
                         className="btn btn-secondary" onClick={() => this.setSmShow(true)}
                         
                    >
                         + &nbsp; New Document
                   </Link>
                   <Link
                         className="btn btn-primary" onClick={() => this.setLgShow(true)}
                         
                    >
                         + &nbsp; New Folder
                   </Link> */}

              <a
                href="#upload_document"
                data-toggle="modal"
                className="btn btn-secondary"
              >
                + &nbsp; New Document
              </a>

              <a
                href="#create_folder"
                data-toggle="modal"
                className="btn btn-primary"
              >
                + &nbsp; New Folder
              </a>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  /*{
                            title: "SL",
                            type: "String",
                            field: "slNo",
                          },*/
                  {
                    title: "Name",
                    type: "text",
                    field: "name",
                  },
                  {
                    title: "Customer Name",
                    type: "text",
                    field: "customer_name",
                  },
                  { title: "Services", type: "text" },
                  { title: "Last modified on", type: "text" },
                  {
                    title: "Last modified By",
                    type: "text",
                  },
                  {
                    title: "File Type",
                    type: "text",
                  },
                  {
                    title: "Created on",
                    type: "text",
                  },
                  {
                    title: "Created By",
                    type: "text",
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "name",
                  pageNo: formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  //handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    //rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit ",
                  },
                  {
                    className: "delete",
                    //rowActionCLick: this.handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              />
            </Grid>
          </Grid>

          {/* modal small */}
          <div
            className="modal modal-mini  fade"
            id="create_folder"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    CREATE FOLDER
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="/app-assets/backend-images/icons/close-modal.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <fieldset>
                      <label className="control-label">Folder Name</label>
                      <input
                        type="text"
                        name="folder-name"
                        className="form-control"
                      />
                    </fieldset>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">
                    CREATE
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* modal large */}

          <div
            className="modal modal-large  fade"
            id="upload_document"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD DOCUMENT
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="/app-assets/backend-images/icons/close-modal.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="dropzone">
                    <div className="flex-centered">
                      <span>Browse file</span>
                      <p>OR</p>
                      <p>Drop your files here </p>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="modal-table">
                      <thead>
                        <tr>
                          <th>
                            File Name <span className="red">*</span>
                          </th>
                          <th>Customer </th>
                          <th>Services</th>
                          <th>Status</th>
                          <th>Uploading Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="fields">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="File Name"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="fields">
                              <select className="form-control">
                                <option>Select Customer</option>
                                <option>Select Customer</option>
                                <option>Select Customer</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="fields">
                              <select className="form-control">
                                <option>Select Services</option>
                                <option>Select Services</option>
                                <option>Select Services</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="fields">
                              <select className="form-control">
                                <option>Select Status</option>
                                <option>Select Status</option>
                                <option>Select Status</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="progress-block">
                              <div className="doc-filename">
                                <p className="sub">Uploading.....</p>
                                <div className="doc-progress">
                                  <span className="progress-scale progress-success">
                                    <span
                                      className="progress-percent"
                                      style={{ width: "20%" }}
                                    ></span>
                                  </span>
                                </div>
                                <p className="sub">20%</p>
                              </div>
                              <a className="delete-link" href="#">
                                <img src="/app-assets/backend-images/icons/delete-red.svg" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="fields">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="File Name"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="fields">
                              <select className="form-control">
                                <option>Select Customer</option>
                                <option>Select Customer</option>
                                <option>Select Customer</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="fields">
                              <select className="form-control">
                                <option>Select Services</option>
                                <option>Select Services</option>
                                <option>Select Services</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="fields">
                              <select className="form-control">
                                <option>Select Status</option>
                                <option>Select Status</option>
                                <option>Select Status</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="progress-block">
                              <div className="doc-filename">
                                <p className="sub">Failed</p>
                                <div className="doc-progress">
                                  <span className="progress-scale progress-failed">
                                    <span
                                      className="progress-percent"
                                      style={{ width: "100%" }}
                                    ></span>
                                  </span>
                                  <img src="/app-assets/backend-images/icons/refresh-icon.svg" />
                                </div>
                                <p className="sub">0%</p>
                              </div>
                              <a className="delete-link" href="#">
                                <img src="/app-assets/backend-images/icons/delete-red.svg" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* empty content */}

                  {/* <div className="content-empty">
                          <div className="fullwidth">
                            <img src="/app-assets/backend-images/icons/no-documents.png" />
                            <p>No files Selected</p>
                          </div>
                      </div>  */}
                </div>
              </div>
            </div>
          </div>

          {/* <Modal
                size="sm"
                show={this.smShow}
                onHide={() => this.setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-sm">
                    Small Modal
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>...</Modal.Body>
              </Modal>
              <Modal
                size="lg"
                show={this.lgShow}
                onHide={() => this.setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Large Modal
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>...</Modal.Body>
              </Modal>  */}
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
export default OrdersList;
