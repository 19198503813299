import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate from "../../../config/permissiongate";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { getServiceTotal } from "../../../utils/commonUtils";
import { numberFormat } from "../../../utils/numberFormat";
import { add_orders, getAccountList, getContacts, getEmployees, getLeadSourceList, getServiceList } from "../apifunctions";
import ManageAccount from "./addAccount";
import ManageContact from "./addContact";
import RoleDialogTheme from "./dialogTheme";

const ManageOrder = ({
  open = false,
  data = {},
  orderExist = false,
  handleClose = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newAccountDialog, setNewAccountDialog] = useState(false);
  const [newContactDialog, setNewContactDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data, order_date: new Date() });
  const [accountData, setAccountData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [CREData, setCREData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const history = useHistory();

  const fetchAccountBySearch = (e) => {
    getAccountList({
      page: 1,
      type: "dropdown",
      ...(e ? { search: e } : {}),
    }).then((res) => {
      setAccountData(res?.data?.results || []);
    });
  };
  const fetchContacteBySearch = (search_key, company) => {
    getContacts(company || formData?.account_id, {
      page: 1,

      ...(search_key ? { search: search_key } : {}),
    }).then((res) => {
      let d = res.data;
      d.forEach((item) => {
        item["name"] =
          item.customer_info.first_name + " " + item.customer_info.last_name;
      });
      setContactData(d || []);
    });
  };
  const fetchServiceBySearch = (e) => {
    getServiceList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setServiceData(res?.data?.results || []);
    });
  };

  const fetchCREBySearch = (e) => {
    getEmployees({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setCREData(res?.results || []);
    });
  };

  const fetchLeadSourceBySearch = (searchText) => {
    getLeadSourceList({ page: 1, search: searchText ?? "" }).then((res) => {
      setLeadSourceData(res?.data || []);
    });
  };
  const handleDatePicker = (e) => {
    setFormData((f) => ({ ...f, [e.name]: e.value }));
  };
  const filteredServiceData = () => {
    const filteredData = serviceData;
    return filteredData;
  };
  const filteredCREData = () => {
    const filteredEmployeeData = CREData;
    return filteredEmployeeData;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    let error = false;
    let errorMessage = "Please fill all required fields";
    let perm = [];
    if (selectedItems.length == 0) {
      error = true;
      errorMessage = "Please select atleast one service";
    }
    selectedItems?.forEach((item) => {
      perm.push({
        id: item.id,
        professional_fee: parseFloat(item.professional_fee),
        vendor_fee: item.vendor_fee == null ? 0 : parseFloat(item.vendor_fee),
        govt_fee: parseFloat(item.govt_fee),
        gst_percent: parseFloat(item.gst_percent),
        discount_percent: parseFloat(item.discount_percent),
      });
    });
    const payload = {
      account_id: formData?.account_id ?? "",
      contact_id: formData?.contact_id ?? "",
      external_id: formData?.external_id ?? "",
      order_comment: formData?.order_comment ?? "",
      internal_campaign_id: formData?.internal_campaign_id ?? "",
      spot_incentive: formData?.spot_incentive ?? 0,
      lead_source_id: formData?.lead_source_id || null,
      // total_amount: formData?.total_amount ?? 0,
      assigned_to: formData?.employee_id || null,
      order_date: formData.order_date
        ? moment(formData.order_date).format("YYYY-MM-DD")
        : "",

      items: perm,
    };
    Object.keys(payload).forEach((item) => {
      console.log(payload, item);
      if (
        item !== "internal_campaign_id" &&
        item !== "external_id" &&
        item !== "order_comment" &&
        item !== "spot_incentive" &&
        item !== "lead_source_id" &&
        item !== "assigned_to"
      )
        if (!payload[item] || payload[item] === "") {
          error = true;
        }
    });
    if (!error) {
      try {
        add_orders(payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);

            setTimeout(() => {
              setSubmitting(false);

              history.push("/orders-view/" + res?.data?.id);
              handleClose(true);
            }, 500);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");

      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  const handleTableChange = (e, ind) => {
    let sel = [...selectedItems];
    sel[ind][e.target.name] = e.target.value ? e.target.value : 0;

    setSelectedItems(sel);
  };
  const handleChange = (e) => {
    let value = e.target.value;

    if (value.trim() === "") {
      value = "";
    }
    setFormData((f) => ({ ...f, [e.target.name]: value }));
  };
  const handleServiceChange = (e, a, b) => {
    let sel = [...selectedItems];
    sel.push({ ...e, gst_percent: 18 });
    setFormData((f) => ({ ...f, service_name: null }));
    if (e["id"]) {
      setSelectedItems(sel);
    }
  };
  const fetchExistingLeadSource = (order_exist) => {
    if (order_exist) {
      getLeadSourceList({ page: 1, search: "Existing" }).then((response) => {
        const leadSource = (response?.data || []).pop();
        setFormData((f) => ({
          ...f,
          lead_source_id: leadSource?.id,
          lead_source_name: leadSource?.name,
        }));
      });
    }
  };
  const handleAccountChange = (selected_data, id, name) => {
    setFormData((f) => ({
      ...f,
      account_name: selected_data[name],
      account_id: selected_data[id],
      contact_name: null,
      contact_id: null,
    }));
    if (selected_data[id]) {
      fetchContacteBySearch(null, selected_data[id]);
      fetchExistingLeadSource(selected_data["order_count"]);
    } else {
      fetchAccountBySearch();
    }
  };
  const handleContactChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      contact_name: e["name"],
      contact_id: e["customer_id"],
    }));
  };
  const handleCREChange = (selected_data, id, name) => {
    setFormData((f) => ({
      ...f,
      assigned_to: selected_data[name],
      employee_id: selected_data[id],
    }));
    if (!selected_data[id]) {
      fetchCREBySearch();
    }
  };
  const handleSourceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      lead_source_name: e[b],
      lead_source_id: e[a],
    }));
    if (!e["id"]) {
      fetchLeadSourceBySearch();
    }
  };
  const removeItem = (ind) => {
    let sele = [];
    sele = selectedItems;
    sele.splice(ind, 1);
    setSelectedItems([...sele]);
  };
  useEffect(() => {
    fetchAccountBySearch();
    fetchServiceBySearch();
    fetchLeadSourceBySearch();
    fetchCREBySearch();
    fetchExistingLeadSource(orderExist);
    if (formData.account_id) {
      fetchContacteBySearch(null, formData.account_id);
    }
  }, []);
  const openNewAccount = () => {
    setNewAccountDialog(true);
  };
  const openNewContact = () => {
    setNewContactDialog(true);
  };
  const handleCloseChildDalogs = () => {
    setNewAccountDialog(false);
    setNewContactDialog(false);
  };
  const onAccountSuccess = (data) => {
    setNewAccountDialog(false);
    fetchAccountBySearch();
    setFormData((f) => ({
      ...f,
      account_name: data.business_name,
      account_id: data.id,
    }));
  };
  const onContactSuccess = (data) => {
    console.log(data);
    setNewContactDialog(false);
    fetchContacteBySearch(null, formData.account_id);
    setFormData((f) => ({
      ...f,

      contact_name:
        data.default_contact?.first_name +
        " " +
        data.default_contact?.last_name,
      contact_id: data?.id,
    }));
  };
  const getWholeTotal = () => {
    let total = 0;
    selectedItems.forEach((item, ind) => {
      total =
        total +
        Number(
          parseFloat(
            getServiceTotal(
              item.professional_fee,
              item.govt_fee,
              item.gst_percent,
              item.discount_percent,
              ind,
              item.vendor_fee
            )
          ).toFixed(0)
        );
    });
    return total.toFixed(2);
  };
  const getRounding = (value) => {
    if (!value) {
      return 0.0;
    }
    let rounded_total = parseFloat(value).toFixed(0);
    let rounding = rounded_total - value;
    return rounding;
  };
  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  console.log(formData);
  return (
    <div>
      <CommonLoader open={submitting} />
      {newAccountDialog && (
        <ManageAccount
          open={newAccountDialog}
          onSuccess={(data) => {
            onAccountSuccess(data);
          }}
          handleClose={handleCloseChildDalogs}
          {...props}
        />
      )}
      {newContactDialog && (
        <ManageContact
          open={newContactDialog}
          accountData={{ name: formData.account_name, id: formData.account_id }}
          onSuccess={(data) => {
            onContactSuccess(data);
          }}
          handleClose={handleCloseChildDalogs}
          {...props}
        />
      )}
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          maxWidth="lg"
          fullWidth
          // aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> Add Order</h3>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-6 pb-2">
                  <InputLabel>
                    Account<span className="red">*</span>
                    <PermissionsGate scopes={[SCOPES.addAccounts]}>
                      <span
                        title="Add Account"
                        onClick={() => {
                          openNewAccount();
                        }}
                        className="ml-1 cursor-pointer bg-success text-white "
                        style={{ padding: "3px 6px" }}
                      >
                        +
                      </span>
                    </PermissionsGate>
                  </InputLabel>
                  <SelectBox
                    Data={accountData}
                    id="id"
                    async="true"
                    required
                    value={formData.account_name ? formData.account_name : ""}
                    desc="business_name"
                    descId="id"
                    getData={fetchAccountBySearch}
                    placeholder="Account"
                    className="form-control"
                    onChange={handleAccountChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <InputLabel>
                    Contact<span className="red">*</span>{" "}
                    {formData.account_id && (
                      <PermissionsGate scopes={[SCOPES.addContacts]}>
                        <span
                          title="Add Contact"
                          onClick={() => {
                            openNewContact();
                          }}
                          className="ml-1 cursor-pointer bg-success text-white "
                          style={{ padding: "3px 6px" }}
                        >
                          +
                        </span>
                      </PermissionsGate>
                    )}
                  </InputLabel>
                  <SelectBox
                    Data={contactData}
                    id="id"
                    async="true"
                    required
                    disabled={!formData?.account_id}
                    value={formData.contact_name ? formData.contact_name : ""}
                    desc="name"
                    descId="customer_id"
                    // getData={fetchContacteBySearch}
                    getData={(e) =>
                      fetchContacteBySearch(e, formData.account_id)
                    }
                    placeholder="Contact"
                    className="form-control"
                    onChange={handleContactChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <div>
                    <InputLabel>
                      Order Date<span className="red">*</span>
                    </InputLabel>
                    <div className="calInput">
                      <CalendarComponent
                        required
                        selected={formData.order_date}
                        name="order_date"
                        onChange={handleDatePicker}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 pb-2">
                  <InputLabel>Internal Campaign</InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    margin="dense"
                    // required
                    name="internal_campaign_id"
                    value={
                      formData.internal_campaign_id
                        ? formData.internal_campaign_id
                        : ""
                    }
                    onChange={handleChange}
                    className="customFieldInput "
                    fullWidth
                    placeholder="Enter Internal Campaign"
                  />
                </div>
                <div className="col-6 pb-2">
                  <InputLabel>Spot Incentive</InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    margin="dense"
                    // required
                    name="spot_incentive"
                    value={
                      formData.spot_incentive ? formData.spot_incentive : ""
                    }
                    type="number"
                    onKeyPress={(e) => validateNumber(e, /[0-9.]/)}
                    onChange={handleChange}
                    className="customFieldInput "
                    fullWidth
                    placeholder="Enter Spot Incentive"
                  />
                </div>
                <div className="col-6 pb-2">
                  <InputLabel>Source Of Sale</InputLabel>
                  <SelectBox
                    Data={leadSourceData}
                    id="id"
                    async="true"
                    value={
                      formData.lead_source_name ? formData.lead_source_name : ""
                    }
                    desc="name"
                    descId="id"
                    getData={fetchLeadSourceBySearch}
                    placeholder="Source Of Sale"
                    className="form-control"
                    onChange={handleSourceChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <div>
                    <InputLabel>Assigned CRE</InputLabel>
                    <SelectBox
                      Data={filteredCREData()}
                      id="id"
                      async="true"
                      value={formData.assigned_to ? formData.assigned_to : ""}
                      desc="name"
                      descId="id"
                      getData={fetchCREBySearch}
                      placeholder="Select Agent"
                      className="form-control"
                      onChange={handleCREChange}
                    />
                  </div>
                </div>
                {/* <div className="col-6 pb-2">
                  <InputLabel>
                    Total Amount<span className="red">*</span>
                  </InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    margin="dense"
                    required
                    type="number"
                    name="total_amount"
                    value={formData.total_amount ? formData.total_amount : ""}
                    onChange={handleChange}
                    className="customFieldInput "
                    fullWidth
                    placeholder="Total Order Amount"
                  />
                </div> */}
                <div className="col-12 pb-2">
                  <InputLabel>Remarks</InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    // required
                    margin="dense"
                    name="order_comment"
                    value={formData.order_comment ? formData.order_comment : ""}
                    onChange={handleChange}
                    className="customFieldInput textareaField"
                    multiline
                    rowsMax={1}
                    rows={5}
                    fullWidth
                    placeholder="Enter Remarks"
                  />
                </div>
                <div className="col-6 pb-2">
                  <InputLabel>External id</InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    margin="dense"
                    // required
                    name="external_id"
                    value={formData.external_id ? formData.external_id : ""}
                    onChange={handleChange}
                    className="customFieldInput "
                    fullWidth
                    placeholder="Enter External id"
                  />
                </div>
                <div className="col-6 pb-2">
                  <div>
                    <InputLabel>Order Items</InputLabel>
                    <SelectBox
                      Data={filteredServiceData()}
                      id="id"
                      async="true"
                      value={formData.service_name ? formData.service_name : ""}
                      desc="service_name"
                      descId="id"
                      getData={fetchServiceBySearch}
                      placeholder="Select Item"
                      className="form-control"
                      onChange={handleServiceChange}
                    />
                  </div>
                </div>
                <div className="w-100">
                  <table className="table table-striped w-100">
                    <thead>
                      <tr>
                        <th>Order Item</th>
                        <th>
                          Professional fee <span className="red">*</span>
                        </th>
                        <th>Vendor fee</th>
                        <th>
                          Govt. fee <span className="red">*</span>
                        </th>
                        <th>
                          GST Percent <span className="red">*</span>
                        </th>
                        <th>
                          Discount Percent <span className="red">*</span>
                        </th>
                        <th>Rounding</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedItems.length > 0 ? (
                        <>
                          {selectedItems.map((item, ind) => {
                            return (
                              <tr>
                                <td>
                                  <div
                                    className="d-flex align-items-center "
                                    style={{ minWidth: 200 }}
                                  >
                                    <TextField
                                      // autoFocus
                                      variant="outlined"
                                      margin="dense"
                                      required
                                      inputProps={{ readOnly: true }}
                                      name="service_name"
                                      value={
                                        item.service_name
                                          ? item.service_name
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleTableChange(e, ind)
                                      }
                                      fullWidth
                                      placeholder="Order Item"
                                      className="customFieldInput "
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <TextField
                                      // autoFocus
                                      variant="outlined"
                                      margin="dense"
                                      required
                                      type="number"
                                      inputProps={{ min: 0 }}
                                      name="professional_fee"
                                      value={
                                        item.professional_fee
                                          ? item.professional_fee
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleTableChange(e, ind)
                                      }
                                      className="customFieldInput "
                                      fullWidth
                                      placeholder="Professional fee"
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className="d-flex align-items-center">
                                    <TextField
                                      // autoFocus
                                      variant="outlined"
                                      margin="dense"
                                      type="number"
                                      inputProps={{ min: 0 }}
                                      className="customFieldInput "
                                      name="vendor_fee"
                                      value={
                                        item.vendor_fee ? item.vendor_fee : ""
                                      }
                                      onChange={(e) =>
                                        handleTableChange(e, ind)
                                      }
                                      fullWidth
                                      placeholder="Vendor fee"
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <TextField
                                      // autoFocus
                                      variant="outlined"
                                      margin="dense"
                                      required
                                      type="number"
                                      inputProps={{ min: 0 }}
                                      name="govt_fee"
                                      value={item.govt_fee ? item.govt_fee : ""}
                                      onChange={(e) =>
                                        handleTableChange(e, ind)
                                      }
                                      className="customFieldInput "
                                      fullWidth
                                      placeholder="Govt. fee"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <TextField
                                      // autoFocus
                                      variant="outlined"
                                      margin="dense"
                                      required
                                      type="number"
                                      inputProps={{ min: 0, max: 100 }}
                                      name="gst_percent"
                                      value={
                                        item.gst_percent ? item.gst_percent : ""
                                      }
                                      onChange={(e) =>
                                        handleTableChange(e, ind)
                                      }
                                      className="customFieldInput "
                                      fullWidth
                                      placeholder="GST Percent"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <TextField
                                      // autoFocus
                                      variant="outlined"
                                      margin="dense"
                                      required
                                      type="number"
                                      inputProps={{ min: 0, max: 100 }}
                                      name="discount_percent"
                                      value={
                                        item.discount_percent
                                          ? item.discount_percent
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleTableChange(e, ind)
                                      }
                                      className="customFieldInput "
                                      fullWidth
                                      placeholder="Discount Percent"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="h-full w-full">
                                    <div
                                      className="d-flex align-items-center text-bold  justify-content-center"
                                      style={{ height: 30 }}
                                    >
                                      {numberFormat(
                                        getRounding(
                                          getServiceTotal(
                                            item.professional_fee,
                                            item.govt_fee,
                                            item.gst_percent,
                                            item.discount_percent,
                                            ind,
                                            item.vendor_fee
                                          )
                                        )
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="h-full w-full">
                                    <div
                                      className="d-flex align-items-center text-bold  justify-content-center"
                                      style={{ height: 30 }}
                                    >
                                      {numberFormat(
                                        parseFloat(
                                          getServiceTotal(
                                            item.professional_fee,
                                            item.govt_fee,
                                            item.gst_percent,
                                            item.discount_percent,
                                            ind,
                                            item.vendor_fee
                                          )
                                        ).toFixed(0)
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ height: 30 }}
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => removeItem(ind)}
                                    >
                                      <img
                                        src="/app-assets/backend-images/icons/delete-icon.svg"
                                        alt="Delete"
                                      />
                                    </IconButton>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <td colSpan={8}>
                            <div
                              className="flex justify-content-end"
                              style={{ float: "right" }}
                            >
                              <h4>
                                Total Amount :{" "}
                                <strong>{numberFormat(getWholeTotal())}</strong>
                              </h4>
                            </div>
                          </td>
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <div className="d-flex align-items-center justify-content-center">
                              No order items selected
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageOrder;
