import {
  Checkbox,
  FormControlLabel,
  MuiThemeProvider,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { create_order_payments } from "../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

import "./style.css";

const ManagePayment = ({
  open = false,
  order_id,
  data = {},
  handleClose = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });

  const [serviceData, setServiceData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const fetchMethods = (e) => {
    let d = [];
    if (localStorage.getItem("masterData")) {
      let parsed = JSON.parse(localStorage.getItem("masterData"));
      d = parsed.payment_methods || [];
    }
    setServiceData(d);
  };
  console.log(serviceData);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    let payload = new FormData();
    if (formData.document) {
      payload.append("document", formData.document);
    }
    if (formData.amount) {
      payload.append("amount", formData.amount);
    }

    payload.append("message", formData.message || "");

    if (formData.payment_method) {
      payload.append("payment_method", formData.payment_method);
    }

    payload.append("transaction_id", formData.transaction_id || "");
    payload.append("is_advance", formData.is_advance || false);
    payload.append("receipt_number", formData.receipt_number || "");
    payload.append(
      "external_reference_id",
      formData.external_reference_id || ""
    );

    try {
      create_order_payments(order_id, payload)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Created Successfully");
          setSnackbarOpen(true);
          setSubmitting(false);

          setTimeout(() => {
            handleClose(true);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setSnackbarVarient("error");
          setSnackbarMessage(err?.message || "Something went wrong");
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      payment_method_name: e.display_name,
      payment_method: e.id,
    }));
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "amount") {
      const regex = /^[0-9]*$/;
      if (regex.test(e.target.value)) {
        setFormData((f) => ({ ...f, [name]: value }));
      }
    } else if (name === "document") {
      setFormData((f) => ({ ...f, [name]: e.target.files[0] }));
    } else if (name === "is_advance") {
      setFormData((f) => ({ ...f, [name]: e.target.checked }));
    } else {
      setFormData((f) => ({ ...f, [name]: value }));
    }
  };
  useEffect(() => {
    fetchMethods();
  }, []);
  console.log(formData);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> Add Payment</h3>
              <IconButton aria-label="close" onClick={(e) => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>Amount</InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="amount"
                  value={formData.amount ? formData.amount : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="fieldInput"
                />
              </div>
              <div>
                <InputLabel>Payment Method</InputLabel>
                <SelectBox
                  Data={serviceData}
                  id="id"
                  async="true"
                  value={
                    formData.payment_method_name
                      ? formData.payment_method_name
                      : ""
                  }
                  desc="display_name"
                  descId="id"
                  // getData={fetchMethods}
                  placeholder="Payment Method"
                  className="form-control"
                  onChange={handleServiceChange}
                />
              </div>
              <div>
                <InputLabel>Transaction ID</InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="transaction_id"
                  value={formData.transaction_id ? formData.transaction_id : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="fieldInput"
                />
              </div>
              <div>
                <InputLabel>External reference ID</InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="external_reference_id"
                  value={
                    formData.external_reference_id
                      ? formData.external_reference_id
                      : ""
                  }
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="fieldInput"
                />
              </div>
              <div>
                <InputLabel>Receipt number</InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="receipt_number"
                  value={formData.receipt_number ? formData.receipt_number : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="fieldInput"
                />
              </div>
              <div>
                <InputLabel>Document</InputLabel>
                <input
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  // required
                  name="document"
                  onChange={handleChange}
                  type="file"
                  accept="pdf,.jpeg, .jpg
                  .png, .doc, .docx, .xls .xlsx"
                  fullWidth
                  // className="fieldFileInput"
                />
              </div>
              <div>
                <InputLabel>Message</InputLabel>
                <TextField
                  variant="outlined"
                  margin="dense"
                  name="message"
                  value={formData.message ? formData.message : ""}
                  onChange={handleChange}
                  fullWidth
                  multiline
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.is_advance}
                    onChange={handleChange}
                    name="is_advance"
                  />
                }
                label="Advance"
              />
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManagePayment;
