import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import apis from "../../../apiactions/api.actions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import { deleteService, getPackagelist, servicecat } from "../apifunctions";

class PackageManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servisedatas: [],
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,

      formSearch: {
        search: "",
        page: 1,
        service_category: "",
      },
      searchParam: "",
      loader: false,
      tableConfig: {},
      pagesize: 0,
      masterData: this.props.masterData,
      totalCount: 0,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      masterData: this.props.masterData,
    });
  };
  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    deleteService(this.state.mainId).then(() => {
      this.getData();
    });

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData(query) {
    this.setState({ loader: true });
    try {
      getPackagelist(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleEdit = (e, row) => {
    this.props.history.push(`/packages-services-package/details/${row.id}`);
  };

  handleClose = () => {};
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  handleSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          service_category: e.id,
          service_category_name: e.service_category_name,
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleDataChange = (value, reason=undefined) => {
  
      let tag = servicecat + "?view=all&&search=" + value;
      this.getserviceData(tag);
  
  };
  async getserviceData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            servisedatas: res.service_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleOpen = () => {
    this.props.history.push("/packages-services-package/details/new");
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  render() {
    const { totalCount, formSearch, loader, servisedatas } = this.state;
    const { masterData } = this.props;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={servisedatas}
                id="id"
                required
                async="true"
                placeholder="Service Category"
                value={formSearch.service_category_name}
                desc="service_category_name"
                getData={this.handleDataChange}
                className="form-control"
                onChange={this.handleSelectChange}
              />
            </Grid>
            <Grid align="right" item xs={6} sm={7}>
              <button
                onClick={(e) => this.handleOpen()}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </button>
            </Grid>

            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Category",
                    type: "text",
                    field: "Category",
                    render: (rowData) =>
                      rowData.service_category && (
                        <Link
                          className="text-primary cursor-pointer"
                          to={`/packages-services-package/details/${rowData.id}`}
                        >
                          {rowData.service_category.service_category_name}
                        </Link>
                      ),
                  },
                  {
                    title: "Category Display Name",
                    type: "text",
                    field: "Category",
                    render: (rowData) =>
                      rowData.service_category && (
                        <span>
                          {
                            rowData.service_category
                              .service_category_display_name
                          }
                        </span>
                      ),
                  },
                  {
                    title: "Service Name",
                    type: "text",
                    field: "service_name",
                  },
                  {
                    title: "Managed By",
                    type: "text",
                    field: "managed_by",
                  },
                  {
                    title: "Status",
                    field: "status",
                    render: (rowData) => (
                      <span
                        span
                        style={{
                          backgroundColor:
                            rowData.status.name === "Active"
                              ? "#78DEC0"
                              : "#DEDEDE",
                          padding: "0px 8px",
                        }}
                      >
                        {rowData.status.name}
                      </span>
                    ),
                    type: "status",
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "serviceName",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: this.state.pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit Package ",
                  },
                  {
                    className: "delete",
                    rowActionCLick: this.handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete Package ",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default PackageManagement;
