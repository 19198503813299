import api from "../apis/api";

const apis = {
  auth: async (url, reqData) => await api.post(url, reqData).then((res) => res),

  getDatas: async (url, queryParams) =>
    await api.get(url, queryParams).then((res) => res),

  createData: async (url, queryParams) =>
    await api.post(url, queryParams).then((res) => res),

  updateData: async (url, queryParams) =>
    await api.put(url, queryParams).then((res) => res),
  patchData: async (url, queryParams) =>
    await api.patch(url, queryParams).then((res) => res),

  deleteData: async (url, queryParams) =>
    await api.delete(url, queryParams).then((res) => res),
};
export default apis;
