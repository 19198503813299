import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  get_Compnay_Basic,
  get_Compnayaddressinfo,
  get_Compnaycompliance,
  get_Compnaycontact,
  get_CompnayPromotorinfo,
  get_CompnayRegistration,
  listAccountDuplicate,
} from "../../../apifunctions";

// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";
let serId = "";
class AddressInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      formData: {},
      basicInfo: {},
      contactInfo: {},
      coplianceInfo: {},
      reginfo: [],
      addressInfo: [],
      promotorInfo: [],
      duplicates: [],
    };
  }

  async componentDidMount() {
    if (this.props.com_Id) {
      await Promise.all([
        get_Compnay_Basic(this.props.com_Id).then(this.handleBasic),
        get_Compnaycontact(this.props.com_Id).then(this.handlecontact),
        get_CompnayRegistration(this.props.com_Id).then(this.handleReg),
        get_Compnayaddressinfo(this.props.com_Id).then(this.handleAddress),
        get_CompnayPromotorinfo(this.props.com_Id).then(this.handlePromo),
        get_Compnaycompliance(this.props.com_Id).then(this.handleCompli),
        listAccountDuplicate(this.props.com_Id).then(this.handleDupli),
      ]);
    }
  }
  handleBasic = (res) => {
    this.setState({
      basicInfo: res.data,
    });
  };
  handlecontact = (res) => {
    this.setState({
      contactInfo: res.data.contact_info[0],
    });
  };
  handleReg = (res) => {
    this.setState({
      reginfo: res.data.registration_information,
    });
  };
  handleAddress = (res) => {
    this.setState({
      addressInfo: res.data.company_address,
    });
  };
  handlePromo = (res) => {
    this.setState({
      promotorInfo: res.data.promoters,
    });
  };
  handleCompli = (res) => {
    this.setState({
      coplianceInfo: res.data.company_compliance_info,
    });
  };

  handleDupli = (res) => {
    this.setState({
      duplicates: res.data,
    });
  };

  render() {
    const {
      basicInfo,
      contactInfo,
      reginfo,
      addressInfo,
      promotorInfo,
      loader,
      duplicates,
      coplianceInfo,
    } = this.state;
    const { userDetails } = this.props;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form">
            <div className="card-content">
              <div className="row">
                {/*basic information*/}
                <div className="col-md-12 col-xs-12">
                  <div className="companylist-card">
                    <div className="list-header">
                      <h4 className="title">Basic Information</h4>
                    </div>
                    <div className="cardPadding">
                      <div className="company-list-items row">
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">Primary Customer ID :</span>
                            <span className="text">
                              {basicInfo.customer_code
                                ? basicInfo.customer_code
                                : ""}{" "}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Primary Customer Name :</span>
                            <span className="text">
                              {basicInfo.customer_name
                                ? basicInfo.customer_name
                                : ""}{" "}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Industry :</span>
                            <span className="text">
                              {basicInfo.industry
                                ? basicInfo.industry.business_industry_name
                                : ""}
                            </span>
                          </li>
                        </ul>
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">
                              {" "}
                              Constitution Category :
                            </span>
                            <span className="text">
                              {basicInfo.constitution
                                ? basicInfo.constitution
                                    .constitution_category_name
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Sector :</span>
                            <span className="text">
                              {basicInfo.sector
                                ? basicInfo.sector.business_sector_name
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Sub-Sector :</span>
                            <span className="text">
                              {basicInfo.sub_sector
                                ? basicInfo.sub_sector.business_industry_name
                                : ""}
                            </span>
                          </li>
                        </ul>
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">Lead Source :</span>
                            <span className="text">
                              {basicInfo.lead_source
                                ? basicInfo.lead_source.name
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="sub">PAN Number :</span>
                            <span className="text">
                              {basicInfo.pan_no ? basicInfo.pan_no : ""}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/*contact information*/}
                <div className="col-md-12 col-xs-12">
                  <div className="companylist-card">
                    <div className="list-header">
                      <h4 className="title">Communication Information </h4>
                    </div>
                    <div className="cardPadding">
                      <div className="company-list-items row">
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">Mobile 1 :</span>
                            <span className="text">{contactInfo.mobile}</span>
                          </li>
                          <li>
                            <span className="sub">Email ID 1 :</span>
                            <span className="text">{contactInfo.email}</span>
                          </li>
                        </ul>
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub"> Mobile 2 :</span>
                            <span className="text">{contactInfo.mobile_2}</span>
                          </li>
                          <li>
                            <span className="sub">Email ID 2 :</span>
                            <span className="text">{contactInfo.email_2}</span>
                          </li>
                        </ul>
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">Website :</span>
                            <span className="text">{contactInfo.website}</span>
                          </li>
                          {/* <li>
                            <span className="sub">Email ID 3 :</span>
                            <span className="text">info@dummy@gmail.com</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/*registration information*/}
                {/* <div className="col-md-12 col-xs-12">
                  <Accordion className="customAccordion mb-0">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="list-header">
                        <h4 className="titlestyle">
                          Registration Information{" "}
                        </h4>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="cardPadding w-100">
                        <div className="companylist-card">
                          <div className="company-list-items">
                            <div className="table-block">
                              <table className="table custom-table">
                                <thead>
                                  <tr>
                                    <th>SL</th>
                                    <th>Document Name</th>
                                    <th>Document Number</th>
                                    <th>Upload Date</th>
                                    <th>Uploaded By</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reginfo &&
                                    reginfo.length !== 0 &&
                                    reginfo.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.document_type.type_name}</td>
                                        <td>{item.number}</td>
                                        <td>12 Feb 2021</td>
                                        <td>John Wick Alexander</td>
                                        <td>
                                          <a href="#" className="link">
                                            Download
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div> */}
                {/*address information*/}
                <div className="col-md-12 col-xs-12">
                  <Accordion className="customAccordion mb-0">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="list-header">
                        <h4 className="titlestyle">
                          Address & KYC Information{" "}
                        </h4>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="col-md-12 col-xs-12 p-0">
                        {addressInfo &&
                          addressInfo.length !== 0 &&
                          addressInfo.map((add, index) => (
                            <div className="companylist-card" key={index}>
                              <div className="cardPadding">
                                <div className="company-list-items row">
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub">Name :</span>
                                      <span className="text">{add.name}</span>
                                    </li>
                                    <li>
                                      <span className="sub">Type :</span>
                                      <span className="text">
                                        {add.address_type?.name}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Phone :</span>
                                      <span className="text">
                                        {add.phone_number}
                                      </span>
                                    </li>
                                  </ul>
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub"> Address :</span>
                                      <span className="text">
                                        {add.address_1}, {add.address_2}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Pin :</span>
                                      <span className="text">
                                        {add.pin_code}
                                      </span>
                                    </li>
                                  </ul>
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub">Country :</span>
                                      <span className="text">
                                        {add.country}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">State :</span>
                                      <span className="text">{add.state}</span>
                                    </li>
                                    <li>
                                      <span className="sub">City :</span>
                                      <span className="text">{add.city}</span>
                                    </li>
                                  </ul>
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub">Description :</span>
                                      <span className="text">
                                        {add.description}{" "}
                                      </span>
                                    </li>
                                  </ul>
                                  {add.address_kyc &&
                                    add.address_kyc.length !== 0 && (
                                      <div className="col-md-12 col-xs-12">
                                        <div className="table-block">
                                          <table className="table custom-table">
                                            <thead>
                                              <tr>
                                                <th>SL</th>
                                                <th>Document Name</th>
                                                <th>Document Number</th>
                                                <th>Upload Date</th>
                                                <th>Uploaded By</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {add.address_kyc &&
                                                add.address_kyc.length !== 0 &&
                                                add.address_kyc.map(
                                                  (kyc, child) => (
                                                    <tr>
                                                      <td>{child + 1}</td>
                                                      <td>
                                                        {kyc.document_type
                                                          ? kyc.document_type
                                                              .type_name
                                                          : ""}
                                                      </td>
                                                      <td>{kyc.number}</td>
                                                      <td>12 Feb 2021</td>
                                                      <td>
                                                        John Wick Alexander
                                                      </td>
                                                      <td>
                                                        <a
                                                          href="#"
                                                          className="link"
                                                        >
                                                          Download
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                 <div className="col-md-9 col-xs-12 customer-profile-table">
                  <h4 className="title">Possible Duplicates</h4>
                  <div className="table-block">
                    <DataTable
                      data={this.state.duplicates}
                      columns={[
                          {
                            title: "Account ID",
                            type: "text",
                            field: "company_code",
                          },
                          {
                            title: "Business Name",
                            type: "text",
                            field: "business_name",
                          },
                          {
                            title: "Email",
                            type: "text",
                            field: "email",
                          },
                          {
                            title: "Mobile",
                            type: "text",
                            field: "mobile",
                          },
                          {
                            title: "Secondary Email",
                            type: "text",
                            field: "email_2",
                          },
                          {
                            title: "Secondary Mobile",
                            type: "text",
                            field: "mobile_2",
                          },
                          {
                            title: "Pan No",
                            type: "text",
                            field: "pan_no",
                          }
                        ]}
                      options={{
                      }}
                    />
                  </div>                
                </div>
               
                {/*promoter information*/}
                {/* <div className="col-md-12 col-xs-12">
                  <Accordion className="customAccordion mb-0">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="list-header">
                        <h4 className="titlestyle">Promoter Information </h4>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="col-md-12 col-xs-12 p-0">
                        {promotorInfo &&
                          promotorInfo.length !== 0 &&
                          promotorInfo.map((prom, index) => (
                            <div className="companylist-card">
                              <div className="cardPadding">
                                <div className="company-list-items row">
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub">Name :</span>
                                      <span className="text">
                                        {prom.customer_info
                                          ? prom.customer_info.first_name +
                                          ` ` +
                                          prom.customer_info.last_name
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Role :</span>
                                      <span className="text">
                                        {prom.role ? prom.role.name : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">
                                        Status in Company :
                                      </span>
                                      <span className="text">
                                        {prom.status ? prom.status.name : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Mobile :</span>
                                      <span className="text">
                                        {prom.customer_info
                                          ? prom.customer_info.mobile
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Email :</span>
                                      <span className="text">
                                        {prom.customer_info
                                          ? prom.customer_info.email
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">
                                        Date of Birth :
                                      </span>
                                      <span className="text">
                                        {prom.date_of_birth}
                                      </span>
                                    </li>
                                  </ul>
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub">
                                        {" "}
                                        Preferred Language :
                                      </span>
                                      <span className="text">
                                        {prom.preferred_language
                                          ? prom.preferred_language
                                            .language_name
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Country :</span>
                                      <span className="text">
                                        {prom.address
                                          ? prom.address[0]?.country
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub"> State :</span>
                                      <span className="text">
                                        {prom.address
                                          ? prom.address[0]?.state
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">City :</span>
                                      <span className="text">
                                        {prom.address
                                          ? prom.address[0]?.city
                                          : ""}
                                      </span>
                                    </li>
                                  </ul>
                                  <ul className="col-md-3 col-xs-12">
                                    <li>
                                      <span className="sub">Address :</span>
                                      <span className="text">
                                        {prom.address
                                          ? prom.address[0]?.address_1
                                          : ""}
                                        ,{" "}
                                        {prom.address
                                          ? prom.address[0]?.address_2
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="sub">Phone :</span>
                                      <span className="text">
                                        {prom.address
                                          ? prom.address[0]?.mobile
                                          : ""}
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="col-md-12 col-xs-12">
                                    <div className="table-block">
                                      <table className="table custom-table">
                                        <thead>
                                          <tr>
                                            <th>SL</th>
                                            <th>Document Name</th>
                                            <th>Document Number</th>
                                            <th>Upload Date</th>
                                            <th>Uploaded By</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {prom.kyc &&
                                            prom.kyc.length !== 0 &&
                                            prom.kyc.map(
                                              (promkyc, prochild) => (
                                                <tr>
                                                  <td>{prochild + 1}</td>
                                                  <td>
                                                    {promkyc.document_type
                                                      ? promkyc.document_type
                                                        .type_name
                                                      : ""}
                                                  </td>
                                                  <td>{promkyc.number}</td>
                                                  <td>12 Feb 2021</td>
                                                  <td>John Wick Alexander</td>
                                                  <td>
                                                    <a
                                                      href="#"
                                                      className="link"
                                                    >
                                                      Download
                                                    </a>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div> */}
                {/*compliances information*/}
                {/* <div className="col-md-12 col-xs-12">
                  <div className="companylist-card">
                    <div className="list-header">
                      <h4 className="title">Compliances Information</h4>
                    </div>
                    <div className="cardPadding">
                      <div className="company-list-items row">
                        <ul className="col-md-4 col-xs-12">
                          <li>
                            <span className="sub">Entity Variation Type :</span>
                            <span className="text">
                              {coplianceInfo.entity_type
                                ? coplianceInfo.entity_type
                                  .entity_variation_name
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Annual Turnover :</span>
                            <span className="text">
                              {coplianceInfo.turnover_expected
                                ? coplianceInfo.turnover_expected
                                  .admin_portal_name
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Paid-up Capital :</span>
                            <span className="text">
                              {" "}
                              {coplianceInfo.capital_employed
                                ? coplianceInfo.capital_employed
                                  .admin_portal_name
                                : ""}
                            </span>
                          </li>
                        </ul>
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">
                              {" "}
                              Constitution Category :
                            </span>
                            <span className="text">
                              {coplianceInfo.constitution
                                ? coplianceInfo.constitution
                                  .constitution_category_name
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Profession :</span>
                            <span className="text">
                              {coplianceInfo.profession ? "Yes" : "No"}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Business :</span>
                            <span className="text">
                              {coplianceInfo.business ? "Yes" : "No"}
                            </span>
                          </li>
                        </ul>
                        <ul className="col-md-3 col-xs-12">
                          <li>
                            <span className="sub">Composition Scheme :</span>
                            <span className="text">
                              {coplianceInfo.is_composition_scheme_opted
                                ? "Yes"
                                : "No"}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Updated Date & Time :</span>
                            <span className="text">
                              {coplianceInfo.incorporation_date}
                            </span>
                          </li>
                          <li>
                            <span className="sub">Updated By :</span>
                            <span className="text">John Wick</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default AddressInformation;
