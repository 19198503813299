import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BreadCrumb from "../../../layout/breadcrumb";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../plugins/customtab/customtab";
import { fetchTeamDetails } from "../apifunctions";
import ManageTeam from "../manage/addTeam";
import TeamInfo from "./info";
import TeamMembers from "./members";
import TeamServices from "./service";

// import { get_Company_Details } from "../../apifunctions";

let tabData = [
  {
    name: "Info",
    id: 1,
  },
  {
    name: "Members",
    id: 2,
  },
  {
    name: "Services",
    id: 3,
  },
];

const TeamDetails = (props) => {
  const param = useParams();
  const [currentTab, setCurrentTab] = useState(1);
  const [teamData, setTeamData] = useState({});
  const [edit,setEdit]=useState(props.history?.location?.state?.edit||false)
  const [loader, setLoader] = useState(false);
  const [openManage, setOpenManage] = useState(false);
  const handleTabView = (val) => {
    setCurrentTab(val);
  };
  const getTeamDetails = () => {
    setLoader(true);
    fetchTeamDetails(param.id)
      .then((res) => {
        console.log(res);
        let data = {
          ...res,
          lead_name: res.lead ? res.lead["name"] : null,
          lead_id: res.lead ? res.lead["id"] : null,
        };
        setTeamData(data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getTeamDetails();
  }, []);

  const handleClose = (flag = false) => {
    setOpenManage(false);

    if (flag) {
      getTeamDetails();
    }
  };
  return (
    <>
      <div className="app-content content">
        <CommonLoader open={loader} />
        {openManage && (
          <ManageTeam
            open={openManage}
            data={teamData}
            handleClose={handleClose}
          />
        )}
        <div className="content-wrapper">
          <BreadCrumb type={"teamDetails"} />
          <div className>
            <div className="content-body">
              <section id="configuration">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="customer-list-head customer-view">
                        <div className="details-box">
                          <div className="detail-box border-r">
                            {/* <div className="img-box">
                              <img
                                alt=""
                                src={
                                  custData.profile_image
                                    ? custData.profile_image.profile_thumbnail
                                    : ""
                                }
                              />
                            </div> */}
                            <div className="content">
                              <p className="name">
                                {/* display name */}
                                {teamData?.name ?? "--"}
                                {/* <span
                                  className="tag yellow"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {custData.tier}
                                </span> */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="actions">
                          {/* <a
                            className="btn btn-primary"
                            title="Edit Details"
                            onClick={(e) => handleEdit(teamData)}
                          >
                            <img src="/app-assets/backend-images/icons/edit-lg.png" />
                          </a> */}
                          {/* <a className="btn btn-secondary" href="#">
                            <img src="/app-assets/backend-images/icons/file-lg.png" />
                          </a> */}
                        </div>
                      </div>
                      <NavigationTab
                        tabData={tabData}
                        currentTab={currentTab}
                        handleTabView={handleTabView}
                      />
                      <div className="card-body">
                        {currentTab === 1 && !loader && (
                          <TeamInfo
                            id={param.id}
                            edit={edit}
                            setEdit={setEdit}
                            data={teamData}
                            handleRefresh={getTeamDetails}
                          />
                        )}
                        {currentTab === 2 && <TeamMembers id={param.id} />}
                        {currentTab === 3 && <TeamServices id={param.id} />}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetails;
