import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SCOPES } from "../../../../config/permission-maps";
import PermissionsGate from "../../../../config/permissiongate";
import CommonLoaderComponent from "../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../plugins/dataTable/dataTable";
import DeletePopup from "../../../../plugins/delete/newDeleteComponent";
import SearchField from "../../../../plugins/searchfield";
import { fetchTeamService, deleteServiceFromTeam } from "../../apifunctions";
import ManageTeamServices from "./manage";
import SnackbarMessage from "../../../../plugins/snackbar-component";

const TeamServices = ({ id }) => {
  const [formSearch, setFormSearch] = useState({
    search: "",
    // status: "",
    // employee_tier__tier: "",
    // cre_agent__id: "",
    // agent_name: "",
    // fromDate: "",
    // toDate: "",
    page: 1,
  });
  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});

  useEffect(() => {
    console.log("use effect teams");
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      fetchTeamService(id)
        .then((res) => {
          setTeamsList(res.results);
          setTotalCount(res.count);
          setLoader(false);
          setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      setSnackbarData({
        snackbarMessage: err.message,
        snackbarOpen: true,
        snackbarVariant: "error",
      });
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleDeleteOpen = (e, data) => {
    setRowData(data);

    setOpenDelete(true);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleDeleteConfirm = () => {
    setLoader(true);

    deleteServiceFromTeam(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Service removed from team",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  return (
    <div className="card-content collapse show">
      <CommonLoaderComponent open={loader} />
      <SnackbarMessage
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      {openManage && (
        <ManageTeamServices
          open={openManage}
          team_id={id}
          refetch={getData}
          data={rowData}
          handleClose={handleClose}
        />
      )}
      <DeletePopup
        opendelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
        submitted={loader}
      />
      <div className="">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={6} sm={2} className="table-search">
            <SearchField
              onChange={handleChange}
              value={formSearch.search}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </Grid>

          <Grid item className="table-search">
            <Button
              className="btn btn-primary"
              onClick={() => setOpenManage(true)}
            >
              + &nbsp; Add New
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[SCOPES.manageEmployee]}
            >
              <DataTable
                data={teamsList}
                columns={[
                  {
                    title: "Service Name",
                    type: "text",
                    field: "service_name",
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "name",
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "delete",
                    rowActionCLick: handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              />
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TeamServices;
