module.exports = {
  default: {
    title: "Default",
    menus: [],
  },
  dataManagement: {
    title: "DATA MANAGEMENT",
    menus: [
      {
        name: "Service Categories",
        path: "/data-management/service-categories",
      },
      {
        name: "Constitution Categories",
        path: "/data-management/constitution-categories",
      },
      { name: "Business Sector", path: "/data-management/business-sector" },
      // {
      //   name: "Business Industries",
      //   path: "/data-management/business-industries",
      // },
      {
        name: "Educational Qualifications",
        path: "/data-management/educational-qualifications",
      },
      { name: "Specializations", path: "/data-management/specializations" },
      { name: "Current Practices", path: "/data-management/current-practices" },
      { name: "Parameter Options", path: "/data-management/parameter-options" },
    ],
  },
  filingscompliance: {
    title: "Filings Compliance",
    menus: [
      { name: "Entity Type", path: "/filings-compliance/entity-type" },
      {
        name: "Government Filings",
        path: "/filings-compliance/government-filings",
      },
      {
        name: "Compliance Master",
        path: "/filings-compliance/compliance-master",
      },
      { name: "Compliance List", path: "/filings-compliance/compliance-list" },
      {
        name: "Compliance Calender",
        path: "/filings-compliance/compliance-calender",
      },
    ],
  },
  servicespackages: {
    title: "Services",
    menus: [
      {
        name: "Service Management",
        path: "/packages-services/service-management",
      },
      // {
      //   name: "Package Management",
      //   path: "/packages-services/package-management",
      // },
    ],
  },

  servicecomponent: {
    title: "Service Management",
    menus: [
      {
        id: 1,
        name: "Service Info",
        path: "/packages-services-management/service-info",
      },
      {
        id: 2,
        name: "Description",
        path: "/packages-services-management/description",
      },
      {
        id: 3,
        name: "Benefits",
        path: "/packages-services-management/benefits",
      },
      {
        id: 4,
        name: "Procedure",
        path: "/packages-services-management/procedure",
      },
      {
        id: 5,
        name: "Document Required",
        path: "/packages-services-management/document-required",
      },
      {
        id: 6,
        name: "Service CheckList",
        path: "/packages-services-management/service-checklist",
      },
      {
        id: 7,
        name: "Pre Requisits",
        path: "/packages-services-management/pre-requisits",
      },
      {
        id: 8,
        name: "Deliverable",
        path: "/packages-services-management/deliverable",
      },
      { id: 9, name: "FAQ", path: "/packages-services-management/faq" },
      {
        id: 10,
        name: "Pricing",
        path: "/packages-services-management/pricing",
      },
      { id: 11, name: "Media", path: "/packages-services-management/media" },
      {
        id: 12,
        name: "Quick Video",
        path: "/packages-services-management/quick-video",
      },
    ],
  },
  packagecomponent: {
    title: "Package Management",
    menus: [
      {
        id: 0,
        name: "Package Info",
        path: "/packages-management/package-info",
      },
      {
        id: 1,
        name: "List of Services",
        path: "/packages-management/service-info",
      },
      { id: 2, name: "Description", path: "/packages-management/description" },
      { id: 3, name: "Benefits", path: "/packages-management/benefits" },
      { id: 4, name: "Procedure", path: "/packages-management/procedure" },
      {
        id: 5,
        name: "Document Required",
        path: "/packages-management/document-required",
      },
      {
        id: 6,
        name: "Package Checklist",
        path: "/packages-management/package-checklist",
      },
      {
        id: 7,
        name: "Pre Requisits",
        path: "/packages-management/pre-requisits",
      },
      { id: 8, name: "Deliverable", path: "/packages-management/deliverable" },
      { id: 9, name: "FAQ", path: "/packages-management/faq" },
      { id: 10, name: "Pricing", path: "/packages-management/pricing" },
      { id: 11, name: "Media", path: "/packages-management/media" },
      { id: 12, name: "Quick Video", path: "/packages-management/quick-video" },
    ],
  },

  leadsenquiry: {
    title: "Leads & Enquires",
    menus: [
      { name: "Leads", path: "/leads-enquiry/leads" },
      { name: "Support", path: "/leads-enquiry/support" },
    ],
  },

  coupons: {
    title: "Coupons",
    menus: [{ name: "Coupons", path: "/coupons/coupons-list" }],
  },
  contacts: {
    title: "Contacts",
    menus: [{ name: "Contacts", path: "/contacts/contact-list" }],
  },
  contactlistcomponent: {
    title: "Contact List Component",
    menus: [
      { id: 1, name: "Basic Information" },
      { id: 2, name: "Official Maping" },
      { id: 3, name: "Address Information" },
      // { id: 4, name: "KYC Information" },
    ],
  },
  contactviewcomponent: {
    title: "Contact View Component",
    menus: [
      { id: 1, name: "Contact Profile" },
      { id: 2, name: "Accounts" },
      // { id: 3, name: "Orders" },
      // { id: 4, name: "Accounts" },
      // { id: 4, name: "Transactions" },
      // { id: 6, name: "Services" },
      // { id: 7, name: "Referrals" },
      // { id: 8, name: "Compliances" },
      // { id: 9, name: "Tickets" },
      // { id: 10, name: "Leads" },
      // { id: 11, name: "Related Business" },
    ],
  },
  accounts: {
    title: "Company",
    menus: [{ name: "Company", path: "/accounts/account-list" }],
  },
  accountListComponent: {
    title: "Company List Component",
    menus: [
      {
        id: 1,
        name: "Basic Information",
        path: "/accounts-list-create/basic-information",
      },
      {
        id: 2,
        name: "Official Mapping",
        path: "/accounts-list-create/official-maping",
      },
      {
        id: 3,
        name: "Contact Information",
        path: "/accounts-list-create/contact-information",
      },
      // {
      //   id: 4,
      //   name: "Registration Information",
      //   path: "/accounts-list-create/registration-information",
      // },
      {
        id: 5,
        name: "Address Information",
        path: "/accounts-list-create/address-information",
      },
      // {
      //   id: 6,
      //   name: "Promoter Information",
      //   path: "/accounts-list-create/promoter-information",
      // },
      // {
      //   id: 7,
      //   name: "Compliances Information",
      //   path: "/accounts-list-create/compliances-information",
      // },
    ],
  },
  accountViewComponent: {
    title: "Company View Component",
    menus: [
      {
        name: "Account Profile",
        path: "/accounts-list-view/company-profile",
      },
      {
        name: "Contacts",
        path: "/accounts-list-view/representative",
      },
      { name: "Compliances", path: "/accounts-list-view/compliances" },
    ],
  },
  roleViewComponent: {
    title: "Role View Component",
    menus: [
      {
        name: "Permissionss",
        path: "/accounts-list-view/company-profile",
      },
    ],
  },
};
