import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BreadCrumb from "../../../layout/breadcrumb";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../plugins/customtab/customtab";
import { get_order_details } from "../apifunctions";
import OrderInfo from "./info";
import OrderItems from "./order_items";
import Payment from "./payments";
import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate from "../../../config/permissiongate";
import { handleError } from "../../../utils/commonUtils";

let tabData = [
  {
    name: "Info",
    id: 1,
  },
  {
    name: "Progress",
    id: 2,
  },
  {
    name: "Payments",
    id: 3,
    permission: SCOPES.managePaymentApprovals,
  },
];

const OrderDetails = (props) => {
  const param = useParams();
  const [currentTab, setCurrentTab] = useState(1);
  const [orderData, setOrderData] = useState({});
  const [loader, setLoader] = useState(false);
  const [openManage, setOpenManage] = useState(false);
  const [hasPermission,setHasPermission]=useState(true)
  const handleTabView = (val) => {
    setCurrentTab(val);
    getOrderDetails();
  };
  const getOrderDetails = () => {
    setLoader(true);
    get_order_details(param.id)
      .then((res) => res.data)
      .then((res) => {
        console.log(res);
        let data = {
          ...res,
          account_name: res?.account?.business_name,
          contact_name: res?.contact?.name,
          contact_id: res?.contact?.id,
          status_name: res?.order_status?.name,
          status_id: res?.order_status?.id,
          account_id: res?.account?.id,
        };
        console.log(data);
        setOrderData(data);
        setLoader(false);
      })
      .catch((err) => {
        let ErrorStatus = handleError(err);
        setHasPermission(ErrorStatus);
        setLoader(false);
      });
  };
  useEffect(() => {
    getOrderDetails();
  }, []);

  const handleClose = (flag = false) => {
    setOpenManage(false);

    if (flag) {
      getOrderDetails();
    }
  };
  return (
    <>
      <CommonLoader open={loader} />

      <BreadCrumb type={"taskDetails"} />
      {hasPermission?
      <div className>
        <div className="content-body">
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="customer-list-head customer-view">
                    <div className="details-box">
                      <div className="detail-box border-r">
                        {/* <div className="img-box">
                              <img
                                alt=""
                                src={
                                  custData.profile_image
                                    ? custData.profile_image.profile_thumbnail
                                    : ""
                                }
                              />
                            </div> */}
                        <div className="content">
                          <p className="name">
                            {/* display name */}
                            {orderData?.order_number ?? "--"}
                            <span
                              className="tag yellow"
                              style={{ textTransform: "capitalize" }}
                            >
                              {orderData?.order_status?.name}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="actions"></div>
                  </div>
                  <NavigationTab
                    tabData={tabData}
                    currentTab={currentTab}
                    handleTabView={handleTabView}
                  />
                  <div className="card-body">
                    {currentTab === 1 && !loader && (
                      <OrderInfo
                        id={param.id}
                        data={orderData}
                        handleRefresh={getOrderDetails}
                      />
                    )}
                    {currentTab === 2 && !loader && (
                      <OrderItems id={param.id} />
                    )}
                    {currentTab === 3 && !loader && <Payment id={param.id} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        </div>: <p>Access Denied !!</p>}
    </>
  );
};

const OrderDetailsWithPermission = () => {
  return (
    <div className="app-content content">
      <div className="content-wrapper">
        <PermissionsGate
          scopes={[]}
          RenderError={() => <p>Access Denied !!</p>}
        >
          <OrderDetails />
        </PermissionsGate>
      </div>
    </div>
  );
};
export default OrderDetailsWithPermission;
