import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { SCOPES } from "../../config/permission-maps";
import PermissionsGate from "../../config/permissiongate";
import CommonLoader from "../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../plugins/dataTable/dataTable";
import DeleteComponent from "../../plugins/delete/newDeleteComponent";
import SearchField from "../../plugins/searchfield";
import SnackbarComponent from "../../plugins/snackbar-component";
import { delete_teams, searchTeams } from "./apifunctions";
import ManageTeam from "./manage/addTeam";

export default function TeamsListComponent(props) {
  const [formSearch, setFormSearch] = useState({
    search: "",
    // status: "",
    // employee_tier__tier: "",
    // cre_agent__id: "",
    // agent_name: "",
    // fromDate: "",
    // toDate: "",
    page: 1,
  });
  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});

  useEffect(() => {
    console.log("use effect teams");
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      searchTeams(formSearch)
        .then((res) => {
          setTeamsList(res.results);
          setTotalCount(res.count);
          setLoader(false);
          setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleView = (d, row) => {
    props.history.push(`${props.match.path}-view/` + row.id);
  };
  const handleEdit = (e, data) => {
    props.history.push({
      pathname: `${props.match.path}-view/` + data.id,
      state: { edit: true },
    });
  };
  const handleDeleteOpen = (e, data) => {
    setRowData(data);
    setOpenDelete(true);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };

  const handleDeleteConfirm = () => {
    delete_teams(rowData?.id)
      .then((res) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: res.data?.message,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  return (
    <div className="card-content collapse show">
      <CommonLoader open={loader} />
      {openManage && (
        <ManageTeam
          open={openManage}
          data={rowData}
          handleClose={handleClose}
        />
      )}
      <SnackbarComponent
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      <DeleteComponent
        opendelete={openDelete}
        submitted={loader}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
      />
      <div className="card-body card-dashboard">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={6} sm={2} className="table-search">
            <SearchField
              onChange={handleChange}
              value={formSearch.search}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </Grid>

          <Grid item className="table-search">
            <Button
              className="btn btn-primary"
              onClick={() => setOpenManage(true)}
            >
              + &nbsp; Add New
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[SCOPES.manageEmployee]}
            >
              <DataTable
                data={teamsList}
                columns={[
                  {
                    title: "Team Name",
                    type: "text",
                    field: "name",
                    render: (rowData) =>
                      rowData?.name && (
                        <Link
                          className="text-primary cursor-pointer"
                          to={`${props.match.path}-view/` + rowData.id}
                        >
                          {rowData?.name ?? "--"}
                        </Link>
                      ),
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "name",
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "view",
                    rowActionCLick: handleView.bind(this),
                    iconName: "/app-assets/backend-images/icons/view.png",
                    tooltip: "View In Detailed",
                  },
                  {
                    className: "edit",
                    rowActionCLick: handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit",
                  },
                  {
                    className: "delete",
                    rowActionCLick: handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              />
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
