import React, { Component } from "react";
import { BiCalendar, BiEnvelope, BiPhone } from "react-icons/bi";

import { SCOPES } from "../../../../config/permission-maps";
import PermissionsGate from "../../../../config/permissiongate";
import BreadCrumb from "../../../../layout/breadcrumb";
import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../../plugins/customtab/customtab";
import { handleError } from "../../../../utils/commonUtils";
import { formatDate } from "../../../../utils/dateUtils";
import ManageOrder from "../../../orders/manage";
import { get_Company_Details } from "../../apifunctions";
import AccountAgents from "../agents";
import BusinessProfile from "../contacts";
import AccountOrders from "../orders";
import AddressInformation from "./company-profile/company-profile";

import "./companyview-component.css";

let com_Id = "";
let tabData = [
  {
    name: "Account Profile",
    id: 1,
  },
  {
    name: "Contacts",
    id: 2,
  },
  {
    name: "Orders",
    id: 3,
  },
  {
    name: "Agents",
    id: 4,
  },
];
class CompanyListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      custData: {},
      userDetails: {},
      loader: false,
      currentTab: 0,
      selectedAccount: null,
      addSOFlag: false,
      params: "",
    };

    com_Id = this.props.match.params.id;
  }
  componentDidMount = () => {
    if (com_Id) {
      this.getData();
    }
  };
  getData = () => {
    this.setState({
      loader: true,
    });
    get_Company_Details(com_Id)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          userDetails: res,
          loader: false,
          currentTab: 1,
        });
      })
      .catch((err) => {
        handleError(err);
        this.setState({
          loader: false,
        });
      });
  };
  refetch = () => {
    this.setState({
      loader: true,
    });
    get_Company_Details(com_Id)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          userDetails: res,
          loader: false,
        });
      })
      .catch((err) => {
        handleError(err);
        this.setState({
          loader: false,
        });
      });
  };

  componentWillMount() {}
  componentDidUpdate(prevProps) {
    // this.loadjsagain(prevProps);
  }

  handleTabView = (value) => {
    this.setState({
      currentTab: value,
    });
  };
  handleEdit = (id) => {
    console.log(id);
    this.props.history.push(`/accounts-list-create/` + id);
  };

  handleSOAdd = (com_Id, com_Name, order_count) => {
    this.setState({ addSOFlag: true });
    this.setState({
      selectedAccount: { id: com_Id, name: com_Name, order_count: order_count },
    });
  };

  handleSOClose = (e, row) => {
    this.setState({ addSOFlag: false });
    this.setState({ selectedAccount: null });
  };

  render() {
    const { userDetails, loader, currentTab } = this.state;

    return (
      <>
        {this.state.addSOFlag && (
          <ManageOrder
            open={this.state.addSOFlag}
            handleClose={this.handleSOClose}
            data={{
              account_id: this.state.selectedAccount.id,
              account_name: this.state.selectedAccount.name,
            }}
            orderExist={this.state.selectedAccount.order_count}
          />
        )}
        <div className="app-content content">
          <CommonLoader open={loader} />
          <div className="content-wrapper">
            <BreadCrumb type={"accountViewComponent"} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="customer-list-head customer-view">
                          <div className="details-box">
                            <div className="detail-box border-r">
                              <div className="img-box">
                                {userDetails.profile_image ? (
                                  <img
                                    alt=""
                                    src={
                                      userDetails.profile_image
                                        .profile_thumbnail
                                    }
                                  />
                                ) : (
                                  // <span className="defaultItem">A</span>
                                  <img src="/app-assets/backend-images/default.png" />
                                )}
                              </div>
                              <div className="content">
                                <p className="name">
                                  {userDetails.business_name}
                                  <span className="tag yellow">Platinum</span>
                                </p>
                                <div className="list">
                                  <span className="icon">
                                    {/* <img src="/app-assets/backend-images/icons/phone.png" /> */}
                                    <BiPhone />
                                  </span>
                                  <span className="para">
                                    {userDetails.mobile
                                      ? userDetails.mobile
                                      : "N/A"}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="icon">
                                    {/* <img src="/app-assets/backend-images/icons/envelope.png" /> */}
                                    <BiEnvelope />
                                  </span>
                                  <span className="para">
                                    {" "}
                                    {userDetails.email
                                      ? userDetails.email
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="icon">
                                    {/* <img src="/app-assets/backend-images/icons/date.png" /> */}
                                    <BiCalendar />
                                  </span>
                                  <span className="para">
                                    {formatDate(userDetails.datetime_created)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="detail-box border-r">
                              <div className="content">
                                <p className="main">Account Details</p>
                                <div className="list">
                                  <span className="sub">Account Id :</span>
                                  <span className="text">
                                    {userDetails.company_code}
                                  </span>
                                </div>
                                {/* <div className="list">
                                  <span className="sub">Reg.Date :</span>
                                  <span className="text">
                                    {userDetails.incorporation_date}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                            {/* <div className="detail-box">
                              <div className="content">
                                <p className="main">Data Summary</p>
                                <div className="flex-list">
                                  <div className="view">
                                    <div className="list">
                                      <span className="sub">
                                        Number of Accounts :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                    <div className="list">
                                      <span className="sub">
                                        Active Orders :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <PermissionsGate scopes={[SCOPES.editAccounts]}>
                            <a
                              className="btn btn-primary text-white"
                              title="Add Service Order"
                              onClick={(e) =>
                                this.handleSOAdd(
                                  com_Id,
                                  userDetails.business_name,
                                  userDetails.order_count
                                )
                              }
                            >
                              + &nbsp; Add Order
                            </a>
                            <div className="actions">
                              <a
                                className="btn btn-primary"
                                title="Edit Details"
                                onClick={(e) => this.handleEdit(com_Id)}
                              >
                                <img src="/app-assets/backend-images/icons/edit-lg.png" />
                              </a>
                            </div>

                            {/* <a className="btn btn-secondary" href="#">
                              <img src="/app-assets/backend-images/icons/file-lg.png" />
                            </a> */}
                          </PermissionsGate>
                        </div>
                        <NavigationTab
                          tabData={tabData}
                          currentTab={currentTab}
                          handleTabView={this.handleTabView}
                        />
                        {currentTab === 1 && (
                          <AddressInformation
                            key={com_Id}
                            com_Id={com_Id}
                            userDetails={userDetails}
                          />
                        )}
                        {currentTab === 2 && (
                          <BusinessProfile
                            key={com_Id}
                            custId={com_Id}
                            userDetails={userDetails}
                            refetch={this.refetch}
                          />
                        )}
                        {currentTab === 3 && (
                          <AccountOrders
                            key={com_Id}
                            custId={com_Id}
                            userDetails={userDetails}
                            refetch={this.refetch}
                          />
                        )}
                        {currentTab === 4 && (
                          <AccountAgents
                            key={com_Id}
                            custId={com_Id}
                            userDetails={userDetails}
                            refetch={this.refetch}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyListComponent;
