import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

import CommonLoader from "../../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../../plugins/snackbar-component";
import { getEmployeeTeam, updateService } from "../../../../apifunctions";
import TeamDialogTheme from "./dialogTheme";

const ManageServiceTeam = ({
  open = false,
  data,
  maindata,
  handleClose = () => {},
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
const [isSubmitClicked,setIsSubmitClicked]=useState(false)
  const [formData, setFormData] = useState({});
  const [serviceData, setServiceData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const handleSubmit = (event, flag) => {
    event.preventDefault();
    setSubmitting(true);
    setIsSubmitClicked(true)
    let teamarray = [...selectedItems, ...data];
    let tm = [];
    teamarray.forEach((item) => {
      tm.push(item.id);
    });
    const payload = { ...maindata, teams: tm };
if(selectedItems.length>0){
    try {
      updateService(payload, maindata.id)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Added Successfully");
          setSnackbarOpen(true);
          setSubmitting(false);
          setFormData((f) => ({ name: null, id: null }));
          if (flag) {
            setTimeout(() => {
              handleClose(true);
            }, 1000);
          }
        })
        .catch((err) => {
          setSnackbarVarient("error");
          setSnackbarMessage(err?.response?.data?.error?.message);
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }}else{
      setSnackbarVarient("error");
      setSnackbarMessage("Please select atleast on member");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const fetServiceBySearch = (e) => {
    getEmployeeTeam({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      const result = res?.results || [];
      if(result) {
        const filteredTeamData = result.filter(
          (team) =>
            !data.some((dt) => team.id === dt.id) 
        );
        setServiceData(filteredTeamData);
      }else {
        setServiceData(result);
      }

    });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    let sel = [...selectedItems];
    const isDuplicate = selectedItems.some((item) => item.id === e['id']);

    if (!isDuplicate) {
      sel.push(e);
      setFormData((f) => ({ ...f, name: null }));

      if (e["id"]) {
      setSelectedItems(sel);
      }
    }
  };
  const removeItem = (item) => {
    let sele = [];
    sele = selectedItems.filter((se) => se.id !== item.id);
    setSelectedItems(sele);
  };
  useEffect(() => {
    fetServiceBySearch();
  }, []);
  console.log("hi");
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {"Add  Teams"}</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <label className="control-label">
                  Team Name 
                </label>

                <SelectBox
                  Data={serviceData}
                  id="id"
                  async="true"
                  // required
                  value={formData.name ? formData.name : ""}
                  desc="name"
                  descId="id"
                  getData={fetServiceBySearch}
                  placeholder=" Select team"
                  className="form-control"
                  onChange={handleServiceChange}
                />
                {selectedItems.length===0&&isSubmitClicked&&<div className="red py-1">
                  Select atleast one team member
                  </div>}
              </div>

              <div className="d-flex " style={{ flexWrap: "wrap" }}>
                {selectedItems.map((item, ind) => {
                  return (
                    <div
                      key={ind}
                      className="text-white mt-2 mr-2 px-1 py-0 bg-primary flex align-items-center justify-content-center"
                    >
                      {item.name}
                      <IconButton
                        aria-label="close"
                        onClick={() => removeItem(item)}
                      >
                        <CloseIcon className="text-white p-o" />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                onClick={(e) => handleSubmit(e, true)}
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save "}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageServiceTeam;
