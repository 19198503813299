import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MuiThemeProvider,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { useEffect, useState } from "react";

import CalendarComponent from "../../../../../plugins/calendar.comp";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { create_order_payments } from "../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

import "./style.css";

const ManagePayment = ({
  open = false,
  order_id,
  paymentType,
  data = {},
  handleClose = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({
    ...data,
    payment_type: paymentType,
  });

  const [serviceData, setServiceData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const fetchMethods = (e) => {
    let d = [];
    if (localStorage.getItem("masterData")) {
      let parsed = JSON.parse(localStorage.getItem("masterData"));
      d = parsed.payment_methods || [];
    }
    if (formData.payment_type === "link") {
      let razorpayPaymentMethod = d.find(
        (paymentMethod) => paymentMethod?.code === "razorpay"
      );
      if (razorpayPaymentMethod) {
        setFormData((formData) => ({
          ...formData,
          payment_method_name: razorpayPaymentMethod.name,
          payment_method: razorpayPaymentMethod.id,
        }));
      }
    }
    setServiceData(d);
  };
  console.log(serviceData);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    let payload = new FormData();
    Object.keys(formData)?.forEach((key) => {
      if (key === "transaction_date") {
        payload.append(
          key,
          formData[key] ? moment(formData[key]).format("YYYY-MM-DD") : ""
        );
      } else if (key === "is_advance" && formData[key] === undefined) {
        payload.append(key, false);
      } else {
        payload.append(key, formData[key]);
      }
    });

    try {
      create_order_payments(order_id, payload)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Created Successfully");
          setSnackbarOpen(true);
          setSubmitting(false);

          setTimeout(() => {
            handleClose(true);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setSnackbarVarient("error");
          setSnackbarMessage(
            err?.response?.data?.error.message
              ? String(err?.response?.data?.error.message)
              : "Something went wrong"
          );
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      payment_method_name: e.display_name,
      payment_method: e.id,
    }));
  };
  const handleDatePicker = (e) => {
    setFormData((f) => ({ ...f, [e.name]: e.value }));
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    if (name === "amount") {
      const regex = /^[0-9]*$/;
      if (regex.test(e.target.value)) {
        setFormData((f) => ({ ...f, [name]: value }));
      }
    } else if (name === "document") {
      setFormData((f) => ({ ...f, [name]: e.target.files[0] }));
    } else if (name === "is_advance") {
      setFormData((f) => ({ ...f, [name]: e.target.checked }));
    } else {
      setFormData((f) => ({ ...f, [name]: value }));
    }
  };
  useEffect(() => {
    fetchMethods();
  }, []);
  console.log(formData);
  const textareaStyle = {
    minHeight: "60px",
  };
  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          maxWidth="lg"
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3>
                {" "}
                {formData.payment_type === "manual"
                  ? "Record Payment Data"
                  : "Send payment link to user"}
              </h3>
              <IconButton aria-label="close" onClick={(e) => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="row">
                {formData.payment_type === "manual" && (
                  <>
                    <div className="col-6 pb-2">
                      <InputLabel>
                        Transaction Date<span className="red">*</span>
                      </InputLabel>
                      <div className="calInput">
                        <CalendarComponent
                          required
                          selected={formData.transaction_date}
                          name="transaction_date"
                          onChange={handleDatePicker}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>

                    <div className="col-6 pb-2">
                      <InputLabel>
                        Transaction ID<span className="red">*</span>
                      </InputLabel>
                      <TextField
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        required
                        name="transaction_id"
                        value={
                          formData.transaction_id ? formData.transaction_id : ""
                        }
                        onChange={handleChange}
                        type="text"
                        fullWidth
                        className="customFieldInput"
                        placeholder="Enter Transaction ID"
                      />
                    </div>
                  </>
                )}

                <div className="col-6 pb-2">
                  <InputLabel>
                    Amount<span className="red">*</span>
                  </InputLabel>
                  <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    required
                    name="amount"
                    value={formData.amount ? formData.amount : ""}
                    onChange={handleChange}
                    type="text"
                    fullWidth
                    className="customFieldInput"
                    placeholder="Enter Amount"
                  />
                </div>
                <div className="col-6 pb-2">
                  <InputLabel>
                    Payment Method<span className="red">*</span>
                  </InputLabel>
                  <SelectBox
                    Data={serviceData}
                    id="id"
                    disabled={formData.payment_type === "link"}
                    required
                    value={
                      formData.payment_method_name
                        ? formData.payment_method_name
                        : ""
                    }
                    desc="display_name"
                    descId="id"
                    placeholder="Payment Method"
                    className="form-control"
                    onChange={handleServiceChange}
                  />
                </div>

                {formData.payment_type === "manual" && (
                  <>
                    <div className="col-6 pb-2">
                      <InputLabel>
                        Receipt number<span className="red">*</span>
                      </InputLabel>
                      <TextField
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        required
                        name="receipt_number"
                        value={
                          formData.receipt_number ? formData.receipt_number : ""
                        }
                        onChange={handleChange}
                        type="text"
                        fullWidth
                        className="customFieldInput"
                        placeholder="Enter Receipt number"
                      />
                    </div>

                    <div className="col-6 pb-2">
                      <InputLabel>Document</InputLabel>
                      <input
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        // required
                        name="document"
                        onChange={handleChange}
                        type="file"
                        accept="pdf,.jpeg, .jpg
                  .png, .doc, .docx, .xls .xlsx"
                        fullWidth
                        // className="fieldFileInput"
                      />
                    </div>

                    <div className="col-6 pb-2">
                      <InputLabel>External reference ID</InputLabel>
                      <TextField
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        name="external_reference_id"
                        value={
                          formData.external_reference_id
                            ? formData.external_reference_id
                            : ""
                        }
                        onChange={handleChange}
                        type="text"
                        fullWidth
                        className="customFieldInput"
                        placeholder="Enter External reference ID"
                      />
                    </div>
                  </>
                )}
                <div className="col-12 pb-2">
                  <div className="textarea-height create-content p-0">
                    <InputLabel>Message</InputLabel>
                    <textarea
                      name="message"
                      value={formData.message ? formData.message : ""}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Message"
                      rows="2"
                      style={textareaStyle}
                    ></textarea>
                  </div>
                </div>

                {formData.payment_type === "link" && (
                  <div className="col-12 pb-2">
                    <div className="textarea-height create-content p-0">
                      <InputLabel>Internal Notes</InputLabel>
                      <textarea
                        name="internal_notes"
                        value={
                          formData.internal_notes ? formData.internal_notes : ""
                        }
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter Notes"
                        rows="2"
                        style={textareaStyle}
                      ></textarea>
                    </div>
                  </div>
                )}

                <div className="col-6 pb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_advance}
                        onChange={handleChange}
                        name="is_advance"
                      />
                    }
                    label="Advance Payment"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManagePayment;
