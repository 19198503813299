import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
const styles = {};
let serId = "";

const SelectStyle = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "5px 10px !important",

        border: "1px solid #f1f1f1 !important",
      },
    },
  },
});
class PricingCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      questions: [],
      options: [],
      formData: {
        ...props.formData,
        pricing_attributes: props.formData.pricing_attributes
          ? props.formData.pricing_attributes
          : [],
      },
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formData: {
        ...props.formData,
        pricing_attributes: props.formData.pricing_attributes
          ? props.formData.pricing_attributes
          : [],
      },
    });
  };
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidMount = () => {
    this.props.onRef(this);
  };
  handleChange = (e) => {
    const { value, max } = e.target;
    if (e.target.name === "gst") {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]:
            Number(max) >= Number(value) && Number(value) >= 0 ? value : "",
        },
      });
    } else {
      let values = e.target.value;
      if (e.target.value.trim() === "") {
        values = "";
      }

      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]: values,
        },
      });
    }
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleAddQuestion = () => {
    const { questions } = this.state;
    let data = { question: "", gst: "" };
    questions.push(data);
    this.setState({ questions });
  };
  handleQueChange = (ind) => (e) => {
    const { questions } = this.state;
    const { value, max } = e.target;

    if (e.target.name === "gst") {
      questions[ind][e.target.name] =
        Number(max) >= Number(value) && Number(value) >= 0 ? value : "";
      this.setState({ questions });
    } else {
      questions[ind][e.target.name] = value;
      this.setState({ questions });
    }
  };
  handleOptionClick = () => {
    const { options } = this.state;
    let data = { choice: "", price: "" };
    options.push(data);
    this.setState({ options });
  };
  handleOptionDel = (index) => {
    const { options } = this.state;
    options.splice(index, 1);
    this.setState({ options });
  };
  handleOptionChange = (ind) => (e) => {
    const { options } = this.state;
    options[ind][e.target.name] = e.target.value;
    this.setState({ options });
  };
  handleData = () => {
    const { formData, questions } = this.state;

    let data = {
      base_price: formData.base_price,
      gst: formData.gst,
      pricing_attributes: [...formData.pricing_attributes],
    };
    return data;
  };
  handleOptionDelete = (e, ind, chilind) => {
    const { formData } = this.state;
    if (
      formData.pricing_attributes.length === 1 &&
      formData.pricing_attributes[ind].options.length === 1
    ) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Pricing calculator should have atleast one question",
        snackbarVariant: "error",
      });
    } else {
      formData.pricing_attributes[ind].options.splice(chilind, 1);
      if (formData.pricing_attributes[ind].options.length === 0) {
        formData.pricing_attributes.splice(ind, 1);
      }

      this.setState({ formData }, () => {
        this.props.handleSubmit(e);
      });
    }
  };
  handleOptionEdit = (e, ind, chilind) => {
    const { formData } = this.state;
    formData.pricing_attributes[ind].options[chilind]["edit"] = true;
    this.setState({
      formData,
    });
  };
  handleTableOptionChange = (ind, chilind) => (e) => {
    const { formData } = this.state;
    formData.pricing_attributes[ind].options[chilind][e.target.name] =
      e.target.value;
    this.setState({
      formData,
    });
  };
  handleSave = (e, ind, chilind) => {
    const { formData } = this.state;
    formData.pricing_attributes[ind].options[chilind]["edit"] = false;
    this.props.handleSubmit(e);
  };
  handleSubmit = (e) => {
    const { formData, questions } = this.state;
    if (questions[0] && this.state.options.length !== 0) {
      let quest = {
        question: questions[0].question,
        gst: questions[0].gst,
        options: this.state.options,
      };
      formData.pricing_attributes.push(quest);
      this.props.handleSubmit(e);
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage:
          "Pricing calculator should have atleast one question and option",
        snackbarVariant: "error",
      });
    }
  };
  render() {
    const { formData, questions, options } = this.state;

    return (
      <div className="create-content">
        <div className="content-body">
          <div className="card-content margin-box">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="pricing-box">
                  <div className="row align-start">
                    <div className="col-md-6 col-xs-6">
                      <div className="sub-head">
                        <p>Price Calculator </p>
                      </div>
                      <p className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Quisque felis ligula, molestie at tellus ac, hendrerit
                        ullamcorper enim. Sed egestas tortor eros, ac ate ante
                        feugiat sed. Quisque sollicitudin orci eu nisi viverra,
                        a tincidunt augue efficitur.
                      </p>
                      <div className="row">
                        {questions.length === 0 && (
                          <div className="col-md-12 col-xs-12 text-left">
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={(e) => this.handleAddQuestion()}
                            >
                              Add Question
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6 border-l">
                      <div className="row align-center">
                        <div className="col-md-4 col-xs-12">
                          <label className="control-label sub-head">
                            Enter Price
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="base_price"
                            value={formData.base_price}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <label className="control-label sub-head">
                            GST Percentage
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="gst"
                            max="100"
                            value={formData.gst}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        {/* <div className="col-md-4 col-xs-4 text-left">
                            <button className="btn btn-primary" type="submit">
                              Update
                            </button>
                          </div> */}
                      </div>
                    </div>
                    {questions.map((ques, index) => (
                      <React.Fragment key={index}>
                        <div className="col-md-6 col-xs-12">
                          <label className="control-label">Questions</label>
                          <textarea
                            onChange={this.handleQueChange(index)}
                            value={ques.question}
                            required
                            name="question"
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                        <div className="col-md-6 col-xs-12">
                          <div className="row">
                            <div className="col-md-4 col-xs-12">
                              <label className="control-label">
                                GST Percentage
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="gst"
                                max="100"
                                value={ques.gst}
                                onChange={this.handleQueChange(index)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                    {options.map((opt, index) => (
                      <div key={index} className="col-md-6 col-xs-12 ">
                        <div className="row align-end">
                          <div className="col-md-7 col-xs-12">
                            <label className="control-label">
                              {`Option ${index + 1}`}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="choice"
                              value={opt.choice}
                              onChange={this.handleOptionChange(index)}
                              required
                            />
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              Pricing Variation
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              value={opt.price}
                              onChange={this.handleOptionChange(index)}
                              required
                            />
                          </div>
                          <div className="col-md-1 col-xs-2">
                            <a
                              href="#/"
                              className="delete"
                              onClick={(e) => this.handleOptionDel(index)}
                            >
                              <img src="/app-assets/backend-images/icons/delete.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-md-12 col-xs-12 text-left">
                      {questions.length !== 0 && (
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={this.handleOptionClick}
                        >
                          Add Options
                        </button>
                      )}
                    </div>
                    {/* </div> */}
                    {/* </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xs-12 text-right">
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => this.handleSubmit(e)}
            >
              Save
            </button>
          </div>
          <div class="card-top ">
            <h4 class="card-title"> Updated Questions</h4>
          </div>
          <MuiThemeProvider theme={SelectStyle}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sl</TableCell>
                  <TableCell>Questions</TableCell>

                  <TableCell>Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData &&
                  formData.pricing_attributes &&
                  formData.pricing_attributes.map((pare, index) => (
                    <TableRow key={index}>
                      <TableCell>{pare.index}</TableCell>
                      <TableCell>{pare.question}</TableCell>

                      <TableCell style={{ padding: "0px !important" }}>
                        {pare.options &&
                          pare.options.map((item, chilind) => (
                            <TableRow>
                              <TableCell width="10%">{item.code}</TableCell>
                              <TableCell width="40%">
                                {item.edit ? (
                                  <React.Fragment>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="choice"
                                      value={item.choice}
                                      onChange={this.handleTableOptionChange(
                                        index,
                                        chilind
                                      )}
                                      required
                                    />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>{item.choice}</React.Fragment>
                                )}
                              </TableCell>
                              <TableCell width="20%">
                                {item.edit ? (
                                  <React.Fragment>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="price"
                                      value={item.price}
                                      onChange={this.handleTableOptionChange(
                                        index,
                                        chilind
                                      )}
                                      required
                                    />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>{item.price}</React.Fragment>
                                )}
                              </TableCell>
                              <TableCell width="10%" align="center">
                                {item.edit ? (
                                  <a
                                    className="save"
                                    onClick={(e) =>
                                      this.handleSave(e, index, chilind)
                                    }
                                    href="#/"
                                  >
                                    <img
                                      alt=""
                                      src="/app-assets/backend-images/icons/tick.png"
                                    />
                                  </a>
                                ) : (
                                  <a
                                    href="#/"
                                    className="edit"
                                    onClick={(e) =>
                                      this.handleOptionEdit(e, index, chilind)
                                    }
                                  >
                                    <img src="/app-assets/backend-images/icons/edit-icon.svg" />
                                  </a>
                                )}
                              </TableCell>
                              <TableCell width="10%" align="center">
                                <a
                                  href="#/"
                                  className="delete"
                                  onClick={(e) =>
                                    this.handleOptionDelete(e, index, chilind)
                                  }
                                >
                                  <img src="/app-assets/backend-images/icons/delete-icon.svg" />
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </MuiThemeProvider>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default PricingCalculator;
