import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TreeItem from "@material-ui/lab/TreeItem";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Paper } from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createMuiTheme } from "@material-ui/core/styles";
import { getRolePermissions } from "../../apifunctions";

const theme = createMuiTheme({
  overrides: {
    MuiAccordionSummary: {
      root: {
        background: "#e4eef7!important",
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "8px 16px 16px!important",
      },
    },
    MuiIconButton: {
      root: {
        color: "#3e69d7",
      },
    },
  },
});

const RolePermission = (props, ref) => {
  const [permissions, setPermissions] = React.useState(props.rolepermission);

  const [upKey, setUpKey] = useState(0);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      let permionData = [];
      permissions.forEach((item) => {
        if (item.checked) {
          permionData.push(item.id);
        }
      });
      props.handleSubmit({ ...props.data, permissions: permionData });
    },
  }));

  const FunMenus = (renProps) => {
    const {
      item,
      id,

      data,
    } = renProps;
    if (item.parent === id) {
      let child = "";
      for (let i = 0; i < data.length; i++) {
        if (item.id === data[i].parent) {
          child = 1;
        }
      }
      return (
        <div style={{ display: "contents" }}>
          {child === 1 && (
            <TreeItem
              nodeId={item.id}
              label={
                <Grid container>
                  {" "}
                  <Grid container>
                    {" "}
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={item.checked}
                          indeterminate={item.intermediate && item.checked}
                          disabled={!props.edit}
                          onChange={(e) => handleCheckChange(e, item)}
                        />
                      }
                      label={item.title}
                    />
                  </Grid>
                </Grid>
              }
            >
              <Paper elevation={0}>{renProps.renderFuncs(item.id, 1)}</Paper>
            </TreeItem>
          )}
          {child === "" && (
            <TreeItem
              nodeId={item.id}
              //showTreeExpandNoChildren={true}
              label={
                // <TableRow key={func.SysFunctionID}>
                <Grid container>
                  <Grid container>
                    {" "}
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={item.checked}
                          indeterminate={item.intermediate && item.checked}
                          disabled={!props.edit}
                          onChange={(e) => handleCheckChange(e, item)}
                        />
                      }
                      label={item.title}
                    />
                  </Grid>
                </Grid>
              }
            ></TreeItem>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const handleCheckChange = (e, d, m, pi, ci) => {
    e.stopPropagation();

    let per = permissions;
    per.forEach((item) => {
      if (item.id === d.id) {
        item["checked"] = e.target.checked;

        item["intermediate"] = false;
      }
    });
    handleChildCheck(d.id, e.target.checked, per, d.parent);
  };

  const handleChildCheck = (childId, chkd, per, pare) => {
    per.forEach((item) => {
      if (item.parent === childId) {
        item["checked"] = chkd;

        handleChildCheck(item.id, chkd, per);
      }
    });
    handleParentCheck(pare, chkd, per);
    // setPermissions(per);
    // setUpKey(upKey + 1);
  };
  const handleParentCheck = (parentId, checked, per) => {
    let parentChecked = false;
    let intermediate = false;

    per.forEach((item) => {
      if (item.id === parentId) {
        console.log(item);
      }
      if (item.parent === parentId) {
        if (item.checked) {
          parentChecked = true;
        }
        if (!item.checked) {
          intermediate = true;
        }
        updateParent(parentId, parentChecked, per, intermediate);
      }
    });
  };
  const updateParent = (id, flag, per, intermediate) => {
    per.forEach((item) => {
      if (item.id === id) {
        item["checked"] = flag;
        item["intermediate"] = intermediate;
        handleParentCheck(item.parent, flag, per);
      }
    });
    setPermissions(per);
    setUpKey(upKey + 1);
  };
  const getAllPermissions = () => {
    getRolePermissions().then((res) => {
      res.forEach((parent) => {
        parent["checked"] = permissions?.some((itm) => itm.acl === parent.id);
      });
      console.log(res);
      setPermissions(res);
    });
  };
  useEffect(() => {
    getAllPermissions();
  }, []);
  const renderFuncs = (id, level) => {
    return permissions.map((item, key) => {
      if (item.parent === id) {
        return (
          <React.Fragment>
            <FunMenus
              item={item}
              level={level}
              id={id}
              key={key}
              data={permissions}
              renderFuncs={renderFuncs}
            />
          </React.Fragment>
        );
      }
    });
  };
  return (
    <div className="customTreeView">
      <MuiThemeProvider theme={theme}>
        {permissions.map((item, i) => (
          <div key={item.id}>
            {(item.parent === null || item.parent === "") && (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <TreeItem
                  nodeId={item.id}
                  showTreeExpandNoChildren={false}
                  label={
                    <Grid container>
                      {" "}
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={item.checked}
                            indeterminate={item.intermediate && item.checked}
                            disabled={!props.edit}
                            onChange={(e) => handleCheckChange(e, item)}
                          />
                        }
                        label={item.title}
                      />
                    </Grid>
                  }
                >
                  <Paper elevation={0}>{renderFuncs(item.id, 1)}</Paper>
                </TreeItem>
              </TreeView>
            )}
          </div>
        ))}
      </MuiThemeProvider>
    </div>
  );
};

export default forwardRef(RolePermission);
