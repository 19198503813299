import React, { useEffect, useState } from "react";

import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../plugins/dataTable/dataTable";

import SnackbarMessage from "../../../../plugins/snackbar-component";
import { getAgentList } from "../../apifunctions";

const AccountAgents = (props) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarVariant, setSnackbarVarient] = useState(null);

  // const pagesize = 15;
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(null);
    setSnackbarVarient(null);
  };
  const fetchData = () => {
    setLoader(true);
    getAgentList(props.custId)
      .then((res) => {
        let data = res.data;
        data.forEach((item) => {
          item["ex_name"] = item?.customer_relation_executive?.name;
          item["ex_id"] = item?.customer_relation_executive?.id;
          item["role_id"] = item?.agent_role?.id;
          item["role_name"] = item?.agent_role?.name;
        });
        setData(res.data);
      })
      .catch((err) => {
        setSnackbarMessage(err.message);
        setSnackbarVarient("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // const handlePagination = () => {};
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("hello,", loader, data);
  return (
    <div className="create-content">
      <CommonLoader open={loader} />
      <div className="content-body">
        {/* <form className="edit-form" onSubmit={this.handleSubmit}> */}
        <div className="card-content">
          <div className="row">
            <div className="titlehead col-md-12 col-xs-12 d-flex justify-content-between">
              <div> {/* <h4>Contacts</h4> */}</div>
            </div>
            <div className="col-md-12 col-xs-12 table-block">
              <DataTable
                data={data}
                columns={[
                  {
                    title: "Name",
                    type: "text",
                    field: "ex_name",
                  },
                  {
                    title: "Role",
                    type: "text",
                    field: "role_name",
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "order_number",
                  // pageNo: pageNo,
                  pagination: false,
                  // totalCount: totalCount,
                  // pageSize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,,
                  // handlepagination: handlePagination.bind(this),
                }}
                actions={
                  [
                    // {
                    //   className: "delete",
                    //   rowActionCLick: this.openMenu.bind(this),
                    //   iconName: "/app-assets/backend-images/icons/menu-dot.svg",
                    //   tooltip: "Menu",
                    // },
                  ]
                }
              />
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>

      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVariant}
      />
    </div>
  );
};

export default AccountAgents;
