import { Grid } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate, { hasPermission } from "../../../config/permissiongate";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  exportCustomerData,
  getEmployeeList,
  getMasterDataList,
  searhCustomerData,
  mergeContacts,
} from "../apifunctions";
import MergePopup from "./manage/mergePopup";

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      openmerge: false,
      formSearch: {
        search: "",
        cre_agent__user__id: "",
        preferred_language__id: "",
        fromDate: "",
        toDate: "",
        page: 1,
      },
      employee_list: [],
      language_list: [],
      loader: false,
      pagesize: 0,
      totalCount: 0,
      selectedRow: [],
      filterStatus: false,
      filterAppliedStatus: false,
      emptyStatus: false,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();

      this.loadEmployeeList();
      this.loadLanguageList();
    } catch (err) {}
  }

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
        selectedRow: 0,
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      const response = await searhCustomerData(this.state.formSearch);
      const resData = response.data;

      const isFormEmpty = this.isFormEmpty(this.state.formSearch);
      this.setState({
        datas: resData.results,
        totalCount: resData.count,
        pagesize: resData.page_size,
        loader: false,
        filterAppliedStatus: true,
        emptyStatus: !isFormEmpty,
      });
    } catch (err) {
      this.setState({
        snackbarMessage: err.message,
        snackbarOpen: true,
        snackbarVariant: "error",
        loader: false,
      });
      console.log("error", err);
    }
  }

  isFormEmpty(form) {
    const fieldsToCheck = [
      "preferred_language__id",
      "cre_agent__user__id",
      "startDate",
      "endDate",
      "search",
    ];
    return fieldsToCheck.every((field) => !form[field]);
  }

  handleView = (e, row) => {
    this.setState({ loader: true });
    this.props.history.push(`/contacts-list-view/${row.id}`);
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleLanguageSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          preferred_language__id: e.id,
          preferred_language_name: e.preferred_language_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleCreSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          cre_agent__user__id: e.id,
          cre_agent_user_name: e.cre_agent_user_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleLanguageDataChange = (value, reason = undefined) => {
    this.loadLanguageList(value);
  };

  handleCreDataChange = (value, reason = undefined) => {
    this.loadEmployeeList(value);
  };

  async loadEmployeeList() {
    try {
      const response = await getEmployeeList();
      const updatedData = response.map((item) => ({
        id: item.id,
        cre_agent_user_name: item.name,
      }));
      this.setState({ employee_list: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  async loadLanguageList() {
    try {
      const response = await getMasterDataList();
      const updatedData = response.data.languages.map((item) => ({
        id: item.id,
        preferred_language_name: item.language_name,
      }));
      this.setState({ language_list: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  handleDatePicker = (date) => {
    //
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          fromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          toDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
        filterStatus: true,
      },
      () => {
        if (this.state.formSearch.fromDate && this.state.formSearch.toDate) {
          this.handleSearch();
        }
      }
    );
  };

  clearFilter = () => {
    this.setState(
      {
        formSearch: {
          search: "",
          constitution__id: "",
          sector__id: "",
          industry__id: "",
          sub_sector__id: "",
          default_customer__id: "",
          lead_source__id: "",
          state: "",
          business_mapping_info__customer_relation_executive: "",
          page: 1,
        },
        filterStatus: false,
      },
      () => {
        this.getData();
      }
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleEdit = (e, row) => {
    this.props.history.push(`/contact-detailed/` + row.id);
  };
  handleExport = () => {
    exportCustomerData(this.state.formSearch)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  selectProps = (selected) => {
    this.setState({ selectedRow: selected });
  };
  handleMergeOpen = () => {
    console.log(this.state.selectedRow);
    this.setState({
      openmerge: true,
    });
  };

  handleMergeClose = () => {
    this.setState({
      openmerge: false,
    });
  };

  handleMerge = (parentContactId) => {
    if (this.state.selectedRow.length > 0) {
      let selectedContactIds = this.state.selectedRow
        .filter((contact) => contact.id !== parentContactId)
        .map((contact) => contact.id);
      let payload = {
        children_contacts: selectedContactIds,
      };
      mergeContacts(parentContactId, payload)
        .then((res) => {
          this.setState({
            snackbarMessage: res?.data,
            snackbarOpen: true,
            snackbarVariant: "success",
            loader: false,
            selectedRow: [],
          });
          this.handleMergeClose();
          this.getData();
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    }
  };
  render() {
    const { masterData, isAdmin } = this.props;
    const {
      loader,
      formSearch,
      employee_list,
      language_list,
      totalCount,
      pagesize,
      filterStatus,
      filterAppliedStatus,
      emptyStatus,
    } = this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.startDate}
                endDate={formSearch.endDate}
                name="date"
                onChange={this.handleDatePicker}
                placeholder="Created Date"
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={language_list}
                id="id"
                async="true"
                value={formSearch.preferred_language_name}
                desc="preferred_language_name"
                getData={this.handleLanguageDataChange}
                placeholder="Language Pref"
                className="form-control"
                onChange={this.handleLanguageSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={employee_list}
                id="id"
                async="true"
                value={formSearch.cre_agent_user_name}
                desc="cre_agent_user_name"
                getData={this.handleCreDataChange}
                placeholder="CRE / Agent"
                className="form-control"
                onChange={this.handleCreSelectChange}
              />
            </Grid>
            {/* add btn */}
            <Grid align="right" item xs={6} sm={4}>
              {filterStatus && filterAppliedStatus && emptyStatus && (
                <Link
                  className="btn btn-secondary"
                  onClick={(e) => this.clearFilter(e)}
                  to="#"
                >
                  Clear filters
                </Link>
              )}
              {totalCount > 0 && (
                <PermissionsGate scopes={[SCOPES.exportContacts]}>
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    onClick={(e) => this.handleExport(e)}
                  >
                    Export
                  </Link>
                </PermissionsGate>
              )}
              <PermissionsGate scopes={[SCOPES.addContacts]}>
                <Link to={"/contact-detailed/new"} className="btn btn-primary">
                  + &nbsp; Add New
                </Link>

                {this.state.selectedRow?.length > 1 && (
                  <>
                    &nbsp;&nbsp;
                    <Link
                      to="#"
                      className="btn btn-secondary"
                      onClick={(e) => this.handleMergeOpen()}
                    >
                      Merge
                    </Link>
                  </>
                )}
              </PermissionsGate>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                scopes={[SCOPES.viewContacts]}
                RenderError={<p>Access Denied</p>}
              >
                <DataTable
                  data={this.state.datas}
                  columns={[
                    // {
                    //   title: "SL",
                    //   type: "text",
                    //   field: "slNo",
                    // },
                    // {
                    //   title: "Created Date/ Time",
                    //   type: "text",
                    //   field: "datetime_created",
                    // },
                    {
                      title: "Customer ID",
                      type: "text",
                      field: "customer_number",
                      render: (rowData) =>
                        rowData?.customer_number && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={`/contacts-list-view/${rowData.id}`}
                          >
                            {rowData?.customer_number ?? "--"}
                          </Link>
                        ),
                    },
                    {
                      title: "Contact Name",
                      type: "text",
                      field: "name",
                      // render: (rowData) =>
                      //   rowData.default_contact && (
                      //     <span>{rowData.default_contact.username}</span>
                      //   ),
                    },
                    {
                      title: "Mobile",
                      type: "text",
                      field: "mobile",
                      render: (rowData) =>
                        rowData.default_contact && (
                          <span>{rowData.default_contact.mobile}</span>
                        ),
                    },
                    {
                      title: "Email",
                      type: "text",
                      field: "email",
                      render: (rowData) =>
                        rowData.default_contact && (
                          <span>{rowData.default_contact.email}</span>
                        ),
                    },
                    // {
                    //   title: "Primary Business",
                    //   field: "primaryBusiness",
                    //   align: "center",
                    // },
                    // {
                    //   title: "City",
                    //   field: "city",
                    //   align: "city",
                    // },
                    {
                      title: "Tier",
                      field: "tier",
                    },
                    {
                      title: "CRE/ Agent",
                      field: "creAgent",
                      render: (rowData) => (
                        <span>
                          {rowData.cre_agent ? rowData.cre_agent.name : ""}
                        </span>
                      ),
                    },
                    {
                      title: "Status",
                      field: "status",
                      render: (rowData) =>
                        rowData.status.code && (
                          <span
                            span
                            style={{
                              backgroundColor:
                                rowData.status.code === "active"
                                  ? "#299956"
                                  : "#DEDEDE",
                              padding: "2px 8px",
                              borderRadius: "6px",
                              color:
                                rowData.status.code === "active"
                                  ? "#fff"
                                  : "#313131",
                              fontSize: "11px",
                            }}
                          >
                            {rowData.status.name}
                          </span>
                        ),
                      type: "status",
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "datetime_created",
                    pageNo: this.state.formSearch.page,
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                    selection: true,
                  }}
                  selectProps={this.selectProps}
                  actions={[
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.viewContacts],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "view",
                            rowActionCLick: this.handleView.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/view.png",
                            tooltip: "View Details",
                          },
                        ]
                      : []),
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.editAccounts],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "edit",
                            rowActionCLick: this.handleEdit.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/edit-icon.svg",
                            tooltip: "Edit",
                          },
                        ]
                      : []),
                    // {
                    //   className: "view",
                    //   rowActionCLick: this.handleView.bind(this),
                    //   iconName:
                    //     "/app-assets/backend-images/icons/delete-icon.svg",
                    //   tooltip: "Delete Customer",
                    // },
                  ]}
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        {this.state.openmerge && (
          <MergePopup
            openMergePopup={this.state.openmerge}
            handleMergePopupClose={this.handleMergeClose}
            mergePopup={(parentContactId) => this.handleMerge(parentContactId)}
            contactList={this.state.selectedRow}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
  };
};
export default connect(mapStateToProps)(CustomerList);
