import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  deleteCustomerKYC,
  getCustomerKYC,
  saveCustomerKYC,
} from "../../../apifunctions";

class CustomerKYCInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      mainId: "",
      snackbarMessage: "",
      kyctypes: [],
      formKycData: [],
    };
  }
  componentDidMount = () => {
    if (this.props.custId) {
      this.getData();
    }
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  async getData() {
    let kyctypes = this.props.masterData.customer_kyc_types;
    let data = [];
    this.setState({ loader: true });
    try {
      getCustomerKYC(this.props.custId)
        .then((res) => res.data)
        .then((res) => {
          data = res.kyc_attachments;
          if (data.length === 0) {
            data.push({ customer: this.props.custId });
          } else {
            data.forEach((kyc) => {
              kyctypes.forEach((types) => {
                if (kyc.document_type && kyc.document_type.id === types.id) {
                  types["checked"] = true;
                }
              });
            });
          }
          this.setState(
            {
              kyctypes: kyctypes,
              formKycData: [],
              loader: false,
            },
            () => {
              this.setState({ formKycData: data });
            }
          );
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleKycUpload = (event, index) => {
    event.preventDefault();
    const { formKycData } = this.state;
    formKycData[index]["submited"] = true;
    this.setState({ formKycData });
    const form = new FormData();
    form.append("attachment1", formKycData[index].attachment2);
    form.append("attachment2", formKycData[index].attachment2);
    form.append("number", formKycData[index].number);
    form.append("document_type", formKycData[index].document_type);
    form.append("document_name", formKycData[index].document_name);
    form.append("attachment1_name", formKycData[index].attachment1_name);
    form.append("attachment2_name", formKycData[index].attachment2_name);
    form.append("customer", this.props.custId);
    saveCustomerKYC(this.props.custId, form)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  handleFile = (e, id, index) => {
    const { formKycData } = this.state;
    formKycData[index][e.target.name] = e.target.files[0];
    formKycData[index][e.target.name + id] = e.target.files[0].name;
    this.setState({
      formKycData,
    });
  };
  handleKycChange = (e, index) => {
    const { formKycData } = this.state;
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    formKycData[index][e.target.name] = value;
    this.setState({
      formKycData,
    });
  };
  handleAddnewKyc = (e, id, id2) => {
    const { formKycData } = this.state;
    formKycData.push({ customer: id2, addnew: true });
    this.setState({ formKycData });
  };
  handleChildCancel = (ind) => {
    const { formKycData } = this.state;
    formKycData.splice(ind, 1);

    this.setState({ formKycData });
  };
  handleOthers = (index) => {
    let findOthers = this.state.kyctypes.find(
      (el) =>
        el.code === "others" &&
        el.id === this.state.formKycData[index].document_type
    );
    return findOthers;
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleKycDelete = (regId) => {
    this.setState({
      opendelete: true,
      mainId: regId,
    });
  };
  handleDelete = () =>
    deleteCustomerKYC(this.props.custId, this.state.mainId).then((res) => {
      this.getData();
    });
  render() {
    const { formKycData, loader, kyctypes } = this.state;
    const { masterData } = this.props;
    let totalKyc = kyctypes.filter((el) => !el.checked);

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <div className="card-content">
            {formKycData &&
              formKycData.length !== 0 &&
              formKycData.map((item, index) => (
                <form
                  className="edit-form"
                  autoComplete="off"
                  onSubmit={(e) => this.handleKycUpload(e, index)}
                >
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        KYC Type <span className="red">*</span>
                      </label>
                      {item.id ? (
                        <input
                          type="text"
                          required
                          name="number"
                          value={item.document_type.type_name}
                          class="form-control"
                          onChange={(e) => this.handleKycChange(e, index)}
                          disabled={item.id ? true : false}
                          placeholder=""
                        />
                      ) : (
                        <select
                          onChange={(e) => this.handleKycChange(e, index)}
                          className="form-control"
                          name="document_type"
                          disabled={item.id ? true : false}
                          value={item.document_type}
                          required
                        >
                          <option value="">Select</option>
                          {kyctypes &&
                            kyctypes.map((item) => (
                              <React.Fragment>
                                {!item.checked && (
                                  <option value={item.id}>
                                    {" "}
                                    {item.type_name}
                                  </option>
                                )}
                              </React.Fragment>
                            ))}
                        </select>
                      )}
                    </div>
                    {((this.handleOthers(index) &&
                      this.handleOthers(index).code === "others") ||
                      (item.document_type &&
                        item.document_type.code === "others")) && (
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Name <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          name="document_name"
                          value={item.document_name}
                          class="form-control"
                          onChange={(e) => this.handleKycChange(e, index)}
                          disabled={item.id ? true : false}
                          placeholder=""
                        />
                      </div>
                    )}
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Number <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="number"
                        value={item.number}
                        class="form-control"
                        onChange={(e) => this.handleKycChange(e, index)}
                        disabled={item.id ? true : false}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Front Page <span className="red">*</span>
                      </label>
                      <div className="upload-field">
                        <label for={`upload-img${index}`}>
                          <input
                            id={`upload-img${index}`}
                            required
                            type="file"
                            onChange={(e) => this.handleFile(e, "_name", index)}
                            // value={item.attachment1_name}
                            disabled={item.id ? true : false}
                            accept="image/png, image/jpeg"
                            className="form-control"
                            name="attachment1"
                          />
                          {item.attachment1_name}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Back Page <span className="red">*</span>
                      </label>
                      <div className="upload-field">
                        <label for={`backpage${index}`}>
                          <input
                            id={`backpage${index}`}
                            required
                            type="file"
                            disabled={item.id ? true : false}
                            onChange={(e) => this.handleFile(e, "_name", index)}
                            // value={item.attachment2_name}
                            accept="image/png, image/jpeg"
                            className="form-control"
                            name="attachment2"
                          />
                          {item.attachment2_name}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12 col-xs-12  text-right">
                      {formKycData.length === index + 1 && (
                        <a
                          href="#/"
                          className="btn btn-secondary"
                          onClick={(e) => this.props.handleTabView(3)}
                        >
                          Back
                        </a>
                      )}
                      {item.id &&
                        formKycData.length === index + 1 &&
                        totalKyc &&
                        totalKyc.length !== 0 && (
                          <button
                            className="btn btn-secondary"
                            type="button"
                            //   onSubmit={this.handleSubmit}
                            onClick={(e) => this.handleAddnewKyc(e)}
                          >
                            + &nbsp;&nbsp; ADD NEW KYC
                          </button>
                        )}
                      {!item.id && item.addnew && (
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={(e) => this.handleChildCancel(index)}
                        >
                          CANCEL
                        </button>
                      )}
                      {item.id ? (
                        <button
                          className="btn btn-primary"
                          onClick={(e) => this.handleKycDelete(item.id)}
                          type="button"
                        >
                          Delete
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={item.submited}
                        >
                          Save
                        </button>
                      )}
                      {formKycData.length === index + 1 && (
                        <a
                          href="#/"
                          className="btn btn-secondary"
                          onClick={(e) => this.props.handleTabView(5)}
                        >
                          Next
                        </a>
                      )}
                    </div>
                  </div>
                </form>
              ))}
          </div>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CustomerKYCInformation;
