import apis from "../../apiactions/api.actions";

const roles = "user/groups/";
const allRoles = "user/groups/?view=all";
const permissions = "core/permissions";

export const searchRoles = (val) =>
  apis.getDatas(
    roles +
      "?search=" +
      val.search +
      // + '&view=all'
      // +
      // "&status__id=" +
      // val.status +
      // "&fromDate=" +
      // val.fromDate +
      // "&toDate=" +
      // val.toDate +
      // "&cre_agent__id=" +
      // val.cre_agent__id +
      // "&employee_tier__tier=" +
      // val.employee_tier__tier +
      "&page=" +
      val.page
  );
export const add_roles = (data) => apis.createData(roles, data);
export const update_roles = (id, data) =>
  apis.updateData(`${roles}${id}/`, data);
export const delete_roles = (id) => apis.deleteData(`${roles}${id}/`);
export const getRolesList = () => apis.getDatas(allRoles);
export const getRoleDetailsById = (id) => apis.getDatas(`${roles}${id}/`);
export const getRolePermissions = (id) => apis.getDatas(permissions);
