// import { ServInstance } from "../config";
import axios from "axios";

const servInstance = process.env.REACT_APP_BASE_URL;

const api = {
  get: (URL, queryParams = {}) =>
    axios.get(servInstance + URL, queryParams).then((res) => res.data),
  post: (URL, reqData = {}) =>
    axios.post(servInstance + URL, reqData).then((res) => res.data),

  put: (URL, reqData = {}) =>
    axios.put(servInstance + URL, reqData).then((res) => res.data),

  patch: (URL, reqData = {}) =>
    axios.patch(servInstance + URL, reqData).then((res) => res.data),

  delete: (URL, reqData = {}) =>
    axios.delete(servInstance + URL, reqData).then((res) => res.data),
};
export default api;
