import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../config/protectedroute";
import routes from "../config/routes";
import Header from "./header";
import Sidemenu from "./sidemenu";
function Layout({ masterData, login, logout }) {
  // const history = useHistory();
  // useEffect(() => {
  //   window.addEventListener("popstate", () => {
  //     history.go(0);
  //   });
  // }, []);
  return (
    <div className="App">
      <body
        className={`vertical-layout vertical-menu 2-columns   menu-expanded fixed-navbar
        `}
        data-open="click"
        data-menu="vertical-menu"
        data-col="2-columns"
      >
        <Header logout={logout} />
        <Sidemenu />
        <Switch>
          {routes.map((route, index) => {
            if (route.restricted) {
              return (
                <ProtectedRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  logout={logout}
                  masterData={masterData}
                  component={route.component}
                  route={route}
                />
              );
            }
          })}
        </Switch>
      </body>
    </div>
  );
}

export default Layout;
