import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";

import PermissionsGate from "../../../../config/permissiongate";
import CommonLoaderComponent from "../../../../plugins/commonLoader/commonLoader.component";
import DeletePopup from "../../../../plugins/delete/newDeleteComponent";
import SearchField from "../../../../plugins/searchfield";
import SnackbarMessage from "../../../../plugins/snackbar-component";
import {
  assign_team_lead_to_order_item,
  fetch_order_items,
  getTeamLeads,
  remove_order_items,
} from "../../apifunctions";
import OrderItemTasks from "./componennts/orderitemtasks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const OrderItems = ({ id }) => {
  const classes = useStyles();
  const [formSearch, setFormSearch] = useState({
    search: "",
    // status: "",
    // employee_tier__tier: "",
    // cre_agent__id: "",
    // agent_name: "",
    // fromDate: "",
    // toDate: "",
    page: 1,
  });
  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [teamLeads, setTeamLeads] = useState([]);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      fetch_order_items(id)
        .then((res) => {
          let dt = res.data;
          dt.forEach((itm) => {
            itm["employee_name"] = itm?.assigned_to?.name;
            itm["employee_id"] = itm?.assigned_to?.id;
          });
          setTeamsList(dt);
          // setTotalCount(res.count);
          setLoader(false);
          // setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleDeleteOpen = (e, data) => {
    setRowData(data);

    setOpenDelete(true);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleDeleteConfirm = () => {
    setLoader(true);
    console.log(rowData);
    remove_order_items(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Service removed from team",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const fetchEmployeeNySearch = (e, row) => {
    if (e) {
      teamsList.forEach((itm) => {
        if (itm.id === row.id) {
          itm["employee_name"] = e;
        }
      });
      setTeamsList(teamsList);
      setUpdateKey(updateKey + 1);
    }
    getTeamLeads({
      page: 1,
      ...(e
        ? {
            search: e,
          }
        : {}),
    }).then((res) => {
      setTeamLeads(res?.results || []);
    });
  };
  const handleServiceChange = (e, row) => {
    let tm = teamsList;
    tm.forEach((itm) => {
      if (itm.id === row.id) {
        itm["employee_name"] = e["name"];
        itm["employee_id"] = e["id"];
      }
    });
    setTeamsList(tm);
    setUpdateKey(updateKey + 1);

    setAssigneeToItem(e["id"], row);
  };
  const setAssigneeToItem = (id, row) => {
    console.log(row);
    assign_team_lead_to_order_item(row.order?.id, row.id, {
      employee_id: id || null,
    })
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Team Lead added successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  return (
    <div className="card-content collapse show">
      <CommonLoaderComponent open={loader} />
      <SnackbarMessage
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />

      <DeletePopup
        opendelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
        submitted={loader}
      />
      <div className="">
        <Grid container spacing={1} justify="start">
          <Grid item xs={6} sm={2} className="table-search">
            {/* <SearchField
              onChange={handleChange}
              value={formSearch.search}
              handleSearch={handleSearch}
              handleClear={handleClear}
            /> */}
          </Grid>

          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[]}
            >
              {/* <DataTable
                data={teamsList}
                columns={[
                  {
                    title: "Service Name",
                    type: "text",
                    field: "service_name",
                  },
                ]}
                options={{
                  sort:false,
                  sortvalue: "name",
                  pagination: true,
                  totalCount: totalCount,
                      pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "delete",
                    rowActionCLick: handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              /> */}
              <div className="orderAccordion">
                {teamsList.map((item, ind) => (
                  <div key={ind}>
                    <Accordion className="customAccordion mb-0" defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="start"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Typography className={classes.heading}>
                            <span className="order-item-value">
                                {item.service_name}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className="pt-2">
                            <table className=" w-100">
                              <tbody>
                                <tr>
                                  <td className="px-0 py-0 active">
                                    <span className="order-item-value">
                                      Total Amount :
                                    </span>
                                    <span className="order-item-total">
                                      {" " + item.total_price || " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Professional fee :
                                    <span className="order-item-value">
                                      {" " + item.professional_fee || " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Govt. fee :
                                    <span className="order-item-value">
                                      {" " + item.govt_fee || " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    GST Percent :
                                    <span className="order-item-value">
                                      {" " + item.gst_percent || " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Discount Percent :
                                    <span className="order-item-value">
                                      {" " + item.discount_percent || " 0.00 "}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <OrderItemTasks orderId={id} itemId={item.id} />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderItems;
