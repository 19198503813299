import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const teams = "employee/teams/";
const team = "employee/team/";
const services = "service/";
const employee = "employee/";

const allTeams = "employee/teams/?view=all";

export const searchTeams = (val) =>
  apis.getDatas(
    teams +
      "?search=" +
      val.search +
      // + '&view=all'
      // +
      // "&status__id=" +
      // val.status +
      // "&fromDate=" +
      // val.fromDate +
      // "&toDate=" +
      // val.toDate +
      // "&cre_agent__id=" +
      // val.cre_agent__id +
      // "&employee_tier__tier=" +
      // val.employee_tier__tier +
      "&page=" +
      val.page
  );
export const add_teams = (data) => apis.createData(teams, data);
export const update_teams = (id, data) =>
  apis.updateData(`${teams}${id}/`, data);
export const delete_teams = (id) => apis.deleteData(`${teams}${id}/`);
export const getTeamsList = () => apis.getDatas(allTeams);
export const getServiceList = (data) =>
  apis.getDatas(`${services}${parseQueryParams(data)}`);
export const getEmployeeList = (data) =>
  apis.getDatas(`${employee}${parseQueryParams(data)}`);
export const fetchTeamDetails = (id) => apis.getDatas(teams + `${id}/`);
export const fetchTeamMembers = (id) => apis.getDatas(team + `${id}/members`);
export const fetchTeamService = (id) => apis.getDatas(team + `${id}/services`);
export const addServiceToTeam = (id, data) =>
  apis.createData(team + `${id}/add-service`, data);

export const addEmployeeToTeam = (id, data) =>
  apis.createData(team + `${id}/add-member`, data);
export const setTeamLead = (id, id2, data) =>
  apis.createData(team + `${id}/set-team-lead/${id2}`, data);
export const deleteEmployeeFromTeam = (id, id2) =>
  apis.deleteData(team + `${id}/members/${id2}`);
export const deleteServiceFromTeam = (id, id2) =>
  apis.deleteData(team + `${id}/services/${id2}`);
