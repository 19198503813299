import { Grid } from "@material-ui/core";
// import Managetask from "./manage/addtask";
import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";

import PermissionsGate from "../../config/permissiongate";
import CommonLoader from "../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../plugins/dataTable/dataTable";
import DeleteComponent from "../../plugins/delete/newDeleteComponent";
import SearchField from "../../plugins/searchfield";
import SnackbarComponent from "../../plugins/snackbar-component";
import { delete_tasks, searchApprovals } from "./apifunctions";
import ManageApprovalRequestStatus from "./details/order_items/componennts/manageApprovalRequestStatus";

export default function TasksListComponent(props) {
  const [formSearch, setFormSearch] = useState({
    search: "",
    // status: "",
    // employee_tier__tier: "",
    // cre_agent__id: "",
    // agent_name: "",
    // fromDate: "",
    // toDate: "",
    page: 1,
  });
  const [tasksList, settasksList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);

  const [activeItem, setActiveItem] = useState({});
  const [snackbarData, setSnackbarData] = useState({});
  const history = useHistory();
  useEffect(() => {
    console.log("use effect tasks");
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      searchApprovals(formSearch)
        .then((res) => {
          let d = res.results;
          d.forEach((item) => {
            item["assignee_name"] = item?.assignee?.name;
            item["assignee_id"] = item?.assignee?.id;
            item["status_name"] = item?.status?.name;
            item["status_id"] = item?.status?.id;
            item["assignee_is_admin"] = item?.assignee?.is_admin;
          });
          settasksList(d);
          setTotalCount(res.count);
          setLoader(false);
          setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleView = (id) => {
    if (id) {
      history.push("/approval-details/" + id);
    }
  };

  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };

  const handleDeleteConfirm = () => {
    delete_tasks(rowData?.id)
      .then((res) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: res.data?.message,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleManageStatus = (data) => {
    setActiveItem({
      id: data?.id,
      status_id: data?.status?.id,
      status_name: data?.status?.name,
    });
    setOpenManage(true);
  };
  return (
    <>
      {openManage && (
        <ManageApprovalRequestStatus
          data={activeItem}
          open={openManage}
          handleClose={handleClose}
        />
      )}
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        {/* {openManage && (
        <Managetask
          open={openManage}
          data={rowData}
          handleClose={handleClose}
        />
      )} */}
        <SnackbarComponent
          snackbarOpen={snackbarData?.snackbarOpen}
          snackbarMessage={snackbarData?.snackbarMessage}
          onSnackbarClose={onSnackbarClose}
          variant={snackbarData?.snackbarVariant}
        />
        <DeleteComponent
          opendelete={openDelete}
          submitted={loader}
          handleDeleteClose={handleDeleteClose}
          delete={handleDeleteConfirm}
        />
        <div className="card-body card-dashboard">
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={handleChange}
                value={formSearch.search}
                handleSearch={handleSearch}
                handleClear={handleClear}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                // errorProps={{ disabled: true }}
                RenderError={() => <p>Access Denied !!</p>}
                scopes={[]}
              >
                <DataTable
                  data={tasksList}
                  columns={[
                    {
                      title: "Order ID",
                      type: "text",

                      render: (rowData) =>
                        rowData?.task?.item?.order?.id && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={"/approval-details/" + rowData?.task?.item?.order?.id}
                          >
                            {rowData?.task?.item?.order?.order_number ?? "--"}
                          </Link>
                        ),
                    },
                    {
                      title: "Title",
                      type: "text",
                      field: "title",
                    },
                    {
                      title: "Approval Type",
                      type: "text",

                      render: (rowData) => (
                        <span>{rowData?.approval_type?.name ?? "--"}</span>
                      ),
                    },
                    {
                      title: "Status",
                      type: "text",
                      field: "status_name",
                      render: (rowData) => (
                        <>
                          {rowData.status.code === "requested" ? (
                            <div
                              className={`d-flex align-items-center justify-content-between text-primary cursor-pointer`}
                              onClick={() => {
                                handleManageStatus(rowData);
                              }}
                            >
                              <div>
                                {rowData?.status?.name ?? "Select status"}
                              </div>
                              <div>
                                <AiOutlineCaretDown />
                              </div>
                            </div>
                          ) : (
                            <div className="disabled">
                              {rowData?.status?.name}
                            </div>
                          )}
                        </>
                      ),
                    },
                    {
                      title: "Request Date",
                      type: "date",
                      field: "request_date",
                    },
                    {
                      title: "Remarks",
                      type: "text",
                      field: "remark",
                    },
                    {
                      title: "Approval / Rejected Date",
                      type: "date",
                      field: "approved_date",
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "name",
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: handlepagination.bind(this),
                  }}
                  actions={
                    [
                      //   {
                      //     className: "view",
                      //     rowActionCLick: handleView.bind(this),
                      //     iconName: "/app-assets/backend-images/icons/view.png",
                      //     tooltip: "View In Detailed",
                      //   },
                      //   {
                      //     className: "edit",
                      //     rowActionCLick: handleEdit.bind(this),
                      //     iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                      //     tooltip: "Edit",
                      //   },
                      //   {
                      //     className: "delete",
                      //     rowActionCLick: handleDeleteOpen.bind(this),
                      //     iconName:
                      //       "/app-assets/backend-images/icons/delete-icon.svg",
                      //     tooltip: "Delete",
                      //   },
                    ]
                  }
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
