import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const services = "service/";
const all_services = "service/?view=all";
const service_category = "core/service-categories?view=all";
const orders = "sales/orders/";
const accounts = "customer/companies/";
const contacts = "customer/";
const customer = "customer/";
const orderservices = "sales/orders/order-services/";
const teams = "employee/teams/";

export const searchOrders = (val) =>
  apis.getDatas(orders + `${parseQueryParams(val)}`);
export const searchOrderService = (val) =>
  apis.getDatas(orderservices + `${parseQueryParams(val)}`);
export const exportOrderService = (val) =>
  apis.getDatas(orderservices + `export/${parseQueryParams(val)}`);
export const downloadOrdersExport = (val) => {
  return orders + "export" + parseQueryParams(val);
};
export const exportOrders = (val) =>
  apis.getDatas(orders + "export" + parseQueryParams(val));
export const add_orders = (val) =>
  apis.createData(orders + "create-order", val);
export const update_orders = (id, val) => apis.updateData(orders + id, val);
export const add_items_to_orders = (id, val) =>
  apis.createData(orders + id + "/add-items", val);
export const update_order_item = (orderId, orderItemId, val) =>
  apis.updateData(orders + orderId + "/items/" + orderItemId, val);
export const assign_employee_to_order_item_task = (id, id1, id2, val) =>
  apis.createData(orders + id + "/items/" + id1 + "/task/" + id2, val);
export const assign_team_lead_to_order_item = (id, id1, val) =>
  apis.createData(orders + id + "/items/" + id1 + "/assign", val);
export const assign_team_to_order_item = (id, id1, val) =>
  apis.createData(orders + id + "/items/" + id1 + "/assign-team", val);
export const fetch_order_items = (id) => apis.getDatas(orders + id + "/items");
export const remove_order_items = (id, id2) =>
  apis.deleteData(orders + id + "/remove-item/" + id2);
export const getContacts = (id, data) =>
  apis.getDatas(`${contacts}account/${id}/contacts/${parseQueryParams(data)}`);
export const fetch_order_item_tasks = (id, id2) =>
  apis.getDatas(orders + id + "/items/" + id2 + "/tasks");
export const get_order_details = (val) => apis.getDatas(orders + val);
export const get_order_payments = (id) =>
  apis.getDatas(orders + id + "/payments");
export const get_order_payments_export = (id) =>
  apis.getDatas(orders + id + "/payments/export");
export const create_order_payments = (id, data) =>
  apis.createData(orders + id + "/payments", data);
export const remove_order_payment = (id, id2) =>
  apis.deleteData(orders + id + "/payments/" + id2);
export const cancel_order_payment_link = (id, id2) =>
  apis.patchData(orders + id + "/payments/" + id2 + "/cancel");
export const resend_order_payment_link = (id, id2) =>
  apis.createData(orders + id + "/payments/" + id2 + "/remainder");
export const getPaymentMethods = (data) =>
  apis.getDatas(`cart/payment-methods/`);
export const getServiceList = (data) =>
  apis.getDatas(`${services}${parseQueryParams(data)}`);
export const getAllServiceList = (data) =>
  apis.getDatas(`${all_services}${parseQueryParams(data)}`);

export const getTeamLeads = (data) =>
  apis.getDatas(`employee/team/leads${parseQueryParams(data)}`);
export const getEmployees = (data) =>
  apis.getDatas(`employee/${parseQueryParams(data)}`);
export const getAllEmployees = (data) =>
  apis.getDatas(`employee/?view=all${parseQueryParams(data)}`);

export const getAccountList = (data) =>
  apis.getDatas(`${accounts}${parseQueryParams(data)}`);
export const getOrderStatusList = (data) =>
  apis.getDatas(
    `core/order-statuses?view=all&type=order${parseQueryParams(data)}`
  );

export const getContactList = (data) =>
  apis.getDatas(`${contacts}${parseQueryParams(data)}`);

export const getServiceCategoryList = (data) =>
  apis.getDatas(`${service_category}`);
export const getOrderServiceStatusList = (data) =>
  apis.getDatas(`core/order-service-status${parseQueryParams(data)}`);

export const change_service_status = (
  order_id,
  order_item_id,
  order_service_status_id
) =>
  apis.createData(
    `${orders}${order_id}/items/${order_item_id}/change-service-status`,
    order_service_status_id
  );
export const getLeadSourceList = (data) =>
  apis.getDatas(`core/lead-source${parseQueryParams(data)}`);

export const deleteServiceOrderRow = (id) => apis.deleteData(orders + id);

export const add_target_close_date = (order_id, order_item_id, date) =>
  apis.createData(
    `${orders}${order_id}/items/${order_item_id}/target-close-date`,
    date
  );
export const getAccountDuplicate = (data) =>
  apis.getDatas(
    `${customer}possible-account-duplicates/${parseQueryParams(data)}`
  );

export const getContactDuplicate = (data) =>
  apis.getDatas(
    `${customer}possible-contact-duplicates/${parseQueryParams(data)}`
  );
export const removeAssignedEmployee = (orderItemId, taskId) =>
  apis.updateData(
    orders + "items/" + orderItemId + "/task/" + taskId + "/unassign"
  );
export const unAssignTeamLead = (orderId, orderItemId) =>
  apis.updateData(orders + orderId + "/items/" + orderItemId + "/unassign");

export const unAssignTeamFromItem = (orderId, orderItemId) =>
  apis.updateData(
    orders + orderId + "/items/" + orderItemId + "/unassign-team"
  );
export const update_service_remark = (order_id, order_item_id, remark) =>
  apis.createData(
    `${orders}${order_id}/items/${order_item_id}/update-remarks`,
    remark
  );

export const getTeams = (data) =>
  apis.getDatas(`${teams}${parseQueryParams(data)}`);
