const { default: Login } = require("../components/login/login");

const {
  default: ForgotPassword,
} = require("../components/login/forgot-password");
const { default: EnterOTP } = require("../components/login/enter-otp");
const {
  default: ResetPassword,
} = require("../components/login/reset-password");

const { default: Dashboard } = require("../components/dashboard/dashboard");
const {
  default: DataManagement,
} = require("../components/data-management/data-management");

const {
  default: ServiceCategories,
} = require("../components/data-management/service-category/service-categories");
const {
  default: ServiceCategoriesCreate,
} = require("../components/data-management/service-category/add-service-categories");
const {
  default: ServiceCategoriesEdit,
} = require("../components/data-management/service-category/edit-service-categories");

const {
  default: ConstitutionCategories,
} = require("../components/data-management/constitution-category/constitution-categories");
const {
  default: ConstitutionCategoriesCreate,
} = require("../components/data-management/constitution-category/add-constitution-categories");
const {
  default: ConstitutionCategoriesEdit,
} = require("../components/data-management/constitution-category/edit-constitution-categories");

const {
  default: BusinessSector,
} = require("../components/data-management/business-sector/business-sector");

const {
  default: BusinessIndustries,
} = require("../components/data-management/business-industries/business-industries");
const {
  default: BusinessIndustriesCreate,
} = require("../components/data-management/business-industries/add-business-industries");
const {
  default: BusinessIndustriesEdit,
} = require("../components/data-management/business-industries/edit-business-industries");

const {
  default: EducationalQualifications,
} = require("../components/data-management/educational-qualifications/educational-qualifications");
const {
  default: EducationalQualificationsCreate,
} = require("../components/data-management/educational-qualifications/add-educational-qualifications");
const {
  default: EducationalQualificationsEdit,
} = require("../components/data-management/educational-qualifications/edit-educational-qualifications");

const {
  default: Specialization,
} = require("../components/data-management/specialization/specialization");
const {
  default: SpecializationCreate,
} = require("../components/data-management/specialization/add-specialization");
const {
  default: SpecializationEdit,
} = require("../components/data-management/specialization/edit-specialization");

const {
  default: CurrentPractices,
} = require("../components/data-management/current-practices/current-practices");
const {
  default: CurrentPracticesCreate,
} = require("../components/data-management/current-practices/add-current-practices");
const {
  default: CurrentPracticesEdit,
} = require("../components/data-management/current-practices/edit-current-practices");

const {
  default: ParameterOptions,
} = require("../components/data-management/parameter-options/parameter-options");
const {
  default: ParameterOptionsCreate,
} = require("../components/data-management/parameter-options/add-parameter-options");

const {
  default: FilingsCompliance,
} = require("../components/filings-compliance/filings-compliance");
const {
  default: EntityType,
} = require("../components/filings-compliance/entity-type/entity-type");
const {
  default: EntityTypeCreate,
} = require("../components/filings-compliance/entity-type/add-entity-type");
const {
  default: GovernmentFilings,
} = require("../components/filings-compliance/government-filings/government-filings");
const {
  default: GovernmentFilingsCreate,
} = require("../components/filings-compliance/government-filings/add-government-filings");

const {
  default: ComplianceMaster,
} = require("../components/filings-compliance/compliance-master/compliance-master");
const {
  default: ComplianceMasterCreate,
} = require("../components/filings-compliance/compliance-master/add-compliance-master");

const {
  default: ComplianceList,
} = require("../components/filings-compliance/compliance-list/compliance-list");
const {
  default: ComplianceListEdit,
} = require("../components/filings-compliance/compliance-list/edit-compliance-list");

const {
  default: ServicesPackages,
} = require("../components/services-packages/services-packages");

const {
  default: ServiceManagement,
} = require("../components/services-packages/service-management/service-management");

const {
  default: PackageManagement,
} = require("../components/services-packages/package_management/package_management");

const {
  default: ServiceComponent,
} = require("../components/services-packages/service-management/servicemanagement_Component/serviceComponent");
const {
  default: PackageComponent,
} = require("../components/services-packages/package_management/packagemanagement_Component/packageComponent");

const {
  default: LeadsEnquiry,
} = require("../components/leads-enquiry/leads-enquiry");

const { default: Leads } = require("../components/leads-enquiry/leads/leads");

const {
  default: Support,
} = require("../components/leads-enquiry/support/support");

const {
  default: SupportView,
} = require("../components/leads-enquiry/support/view-support");

const { default: Coupons } = require("../components/coupons/coupons");

const {
  default: CouponsList,
} = require("../components/coupons/components/coupons-list");

const {
  default: CouponsCreate,
} = require("../components/coupons/components/coupons-create");
const {
  default: couponsEdit,
} = require("../components/coupons/components/coupons-create");

const {
  default: CouponsView,
} = require("../components/coupons/components/coupons-view");

const { default: Contacts } = require("../components/customers/customers");

const {
  default: CustomerList,
} = require("../components/customers/customer-list/customer-list");

const {
  default: CustomerListComponent,
} = require("../components/customers/customer-list/customer-manageDetailed/customer_manage_detailed");

const {
  default: BasicInformation,
} = require("../components/customers/customer-list/customer-manageDetailed/basic-information/basic-information");

const {
  default: OfficialMaping,
} = require("../components/customers/customer-list/customer-manageDetailed/official-maping/official-maping");

const {
  default: AddressInformation,
} = require("../components/customers/customer-list/customer-manageDetailed/address-information/address-information");

const {
  default: KYCInformation,
} = require("../components/customers/customer-list/customer-manageDetailed/kyc-information/kyc-information");

const {
  default: CustomerViewComponent,
} = require("../components/customers/customer-list/customer-detailedview/customer-detailedview");

// const {
//   default: CustomerOrders,
// } = require("../components/customers/customer-list/customerview_Component/customer-orders/customer-orders");

// const {
//   default: BusinessProfile,
// } = require("../components/customers/customer-list/customerview_Component/business-profile/business-profile");

// const {
//   default: CustomerProfile,
// } = require("../components/customers/customer-list/customerview_Component/customer-profile/customer-profile");

// const {
//   default: CustomerCompliances,
// } = require("../components/customers/customer-list/customerview_Component/compliances/compliances");

const { default: Company } = require("../components/company/account");
const {
  default: CompanyList,
} = require("../components/company/company-list/company-list");

const {
  default: CompanyListComponent,
} = require("../components/company/company-list/companylist_Component/companylist_Component");

const {
  default: CompanyBasicInformation,
} = require("../components/company/company-list/companylist_Component/basic-information/basic-information");

const {
  default: CompanyOfficialMaping,
} = require("../components/company/company-list/companylist_Component/official-maping/official-maping");

const {
  default: CompanyContactInformation,
} = require("../components/company/company-list/companylist_Component/contact-information/contact-information");

const {
  default: CompanyRegistrationInformation,
} = require("../components/company/company-list/companylist_Component/registration-information/registration-information");

const {
  default: CompanyAddressInformation,
} = require("../components/company/company-list/companylist_Component/address-information/address-information");

const {
  default: CompanyPromoterInformation,
} = require("../components/company/company-list/companylist_Component/promoter-information/promoter-information");

const {
  default: CompanyCompliancesInformation,
} = require("../components/company/company-list/companylist_Component/compliances-information/compliances-information");

const {
  default: CompanyViewComponent,
} = require("../components/company/company-list/companyview_Component/companyview_Component");

const { default: ErrorComponent } = require("../errors/404.component");

const { default: CheckList } = require("../components/checklist/checklist");

const {
  default: CheckListView,
} = require("../components/checklist/checklistComponent/checklistView");
///////////////////////////////
const { default: Orders } = require("../components/orders/orders");

const {
  default: OrdersList,
} = require("../components/orders/ordersComponent/orders-list");
const {
  default: ServicesList,
} = require("../components/orders/ordersComponent/services-list");
const { default: Exports } = require("../components/exports/exports");
const { default: MyTasks } = require("../components/my-tasks/tasks");
const { default: MyApprovals } = require("../components/my-approvals/tasks");
const {
  default: MyPaymentApprovals,
} = require("../components/my-payment-approvals/tasks");

const { default: Employee } = require("../components/employee/employee");
const {
  default: EmployeeList,
} = require("../components/employee/employee-list/employee-list");

const {
  default: UserFileManagement,
} = require("../components/user-file-management/file-management");

const {
  default: FoldersList,
} = require("../components/user-file-management/folder-list/folder-list");

const { default: Teams } = require("../components/teams/teams");
const { default: TeamsList } = require("../components/teams/teamsList");
const { default: Roles } = require("../components/groups/groups");
const { default: RolesList } = require("../components/groups/groupsList");
const { default: RoleDetails } = require("../components/groups/details");
const { default: TeamDetails } = require("../components/teams/details");
const { default: EmployeeDetails } = require("../components/employee/details");
const { default: OrderDetails } = require("../components/orders/details");
const { default: TaskDetails } = require("../components/my-tasks/details");
const { default: PageNotFound } = require("../pages/PageNotFound");
const {
  default: ApprovalDetails,
} = require("../components/my-approvals/details");
const {
  default: PaymentApprovalDetails,
} = require("../components/my-payment-approvals/details");
const { default: Profile } = require("../components/profile");
const { default: Payments } = require("../components/payments/payments");
const {
  default: PaymentsList,
} = require("../components/payments/paymentsComponent/payment-list");

/////////////////////////////////

const routes = [
  {
    name: "Default",
    path: "/",
    exact: true,
    restricted: false,
    component: Login,
    type: "login",
  },

  {
    name: "Login",
    path: "/login",
    exact: true,
    restricted: false,
    component: Login,
    type: "login",
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    restricted: false,
    component: ForgotPassword,
    type: "forgotpassword",
  },
  {
    name: "Enter OTP",
    path: "/enter-otp",
    restricted: false,
    component: EnterOTP,
    type: "enterotp",
  },
  {
    name: "Reset Password",
    path: "/reset-password",
    restricted: false,
    component: ResetPassword,
    type: "resetpassword",
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    restricted: true,
    component: Dashboard,
    type: "dashboard",
  },
  {
    name: "Profile",
    path: "/profile",
    restricted: true,
    component: Profile,
    type: "profile",
  },
  {
    name: "Error",
    path: "/error",
    restricted: true,
    component: Error,
    type: "error",
  },
  {
    name: "Data Management",
    restricted: true,
    path: "/data-management",
    component: DataManagement,
    routes: [
      {
        name: "Service Categories",
        path: "/service-categories",
        restricted: true,
        component: ServiceCategories,
        type: "servicecategories",
      },
      {
        name: "Add Service Categories",
        path: "/service-categories-create",
        restricted: true,
        component: ServiceCategoriesCreate,
        type: "servicecategoriescreate",
      },
      {
        name: "Edit Service Categories",
        path: "/service-categories-edit/:id",
        restricted: true,
        component: ServiceCategoriesEdit,
        type: "servicecategoriesedit",
      },
      {
        name: "Constitution Categories",
        path: "/constitution-categories",
        restricted: true,
        component: ConstitutionCategories,
        type: "constitutioncategories",
      },
      {
        name: "Add Constitution Categories",
        path: "/constitution-categories-create",
        restricted: true,
        component: ConstitutionCategoriesCreate,
        type: "constitutioncategoriescreate",
      },
      {
        name: "Edit Constitution Categories",
        path: "/constitution-categories-edit/:id",
        restricted: true,
        component: ConstitutionCategoriesEdit,
        type: "constitutioncategoriesedit",
      },

      {
        name: "Business Sector",
        path: "/business-sector",
        restricted: true,
        component: BusinessSector,
        type: "businesssector",
      },

      {
        name: "Business Industries",
        path: "/business-industries",
        restricted: true,
        component: BusinessIndustries,
        type: "businessindustries",
      },
      {
        name: "Add Business Industries",
        path: "/business-industries-create/:id",
        restricted: true,
        component: BusinessIndustriesCreate,
        type: "businessindustriescreate",
      },
      {
        name: "Edit Business Industries",
        path: "/business-industries-edit",
        restricted: true,
        component: BusinessIndustriesEdit,
        type: "businessindustriesedit",
      },

      {
        name: "Educational Qualifications",
        path: "/educational-qualifications",
        restricted: true,
        component: EducationalQualifications,
        type: "educationalqualifications",
      },
      {
        name: "Add Educational Qualifications",
        path: "/educational-qualifications-create",
        restricted: true,
        component: EducationalQualificationsCreate,
        type: "educationalqualificationscreate",
      },
      {
        name: "Edit Educational Qualifications",
        path: "/educational-qualifications-edit/:id",
        restricted: true,
        component: EducationalQualificationsEdit,
        type: "educationalqualificationsedit",
      },

      {
        name: "Specialization",
        path: "/specializations",
        restricted: true,
        component: Specialization,
        type: "specialization",
      },
      {
        name: "Add Specialization",
        path: "/specializations-create",
        restricted: true,
        component: SpecializationCreate,
        type: "specializationcreate",
      },
      {
        name: "Edit Specialization",
        path: "/specializations-edit/:id",
        restricted: true,
        component: SpecializationEdit,
        type: "specializationedit",
      },

      {
        name: "Current Practices",
        path: "/current-practices",
        restricted: true,
        component: CurrentPractices,
        type: "currentpractices",
      },
      {
        name: "Add Current Practices",
        path: "/current-practices-create",
        restricted: true,
        component: CurrentPracticesCreate,
        type: "currentpracticescreate",
      },
      {
        name: "Edit Current Practices",
        path: "/current-practices-edit/:id",
        restricted: true,
        component: CurrentPracticesEdit,
        type: "currentpracticesedit",
      },
      {
        name: "Parameter Options",
        path: "/parameter-options",
        restricted: true,
        component: ParameterOptions,
        type: "parameteroptions",
      },
      {
        name: "Add Parameter Options",
        path: "/parameter-options-create/:id",
        restricted: true,
        component: ParameterOptionsCreate,
        type: "parameteroptionscreate",
      },
      {
        name: "Edit Parameter Options",
        path: "/parameter-options-edit/:id",
        restricted: true,
        component: ParameterOptionsCreate,
        type: "parameteroptionsedit",
      },
    ],
    type: "dataManagement",
  },
  {
    name: "FilingsCompliance",
    path: "/filings-compliance",
    restricted: true,
    component: FilingsCompliance,
    routes: [
      {
        name: "Entity Type",
        path: "/entity-type",
        restricted: true,
        component: EntityType,
        type: "entitytype",
      },
      {
        name: "Add Entity Type",
        path: "/entity-type-create/:id",
        restricted: true,
        component: EntityTypeCreate,
        type: "entitytypecreate",
      },
      {
        name: "Edit Entity Type",
        path: "/entity-type-edit/:id",
        restricted: true,
        component: EntityTypeCreate,
        type: "entitytypeedit",
      },
      {
        name: "Government Filings",
        path: "/government-filings",
        restricted: true,
        component: GovernmentFilings,
        type: "governmentfilings",
      },
      {
        name: "Add Government Filings",
        path: "/government-filings-create/:id",
        restricted: true,
        component: GovernmentFilingsCreate,
        type: "governmentfilingscreate",
      },
      {
        name: "Edit Government Filings",
        path: "/government-filings-edit/:id",
        restricted: true,
        component: GovernmentFilingsCreate,
        type: "governmentfilingsedit",
      },
      {
        name: "Compliance Master",
        path: "/compliance-master",
        restricted: true,
        component: ComplianceMaster,
        type: "compliancemaster",
      },
      {
        name: "Add Compliance Master",
        path: "/compliance-master-create/:id",
        restricted: true,
        component: ComplianceMasterCreate,
        type: "compliancemastercreate",
      },
      {
        name: "Edit Compliance Master",
        path: "/compliance-master-edit/:id",
        restricted: true,
        component: ComplianceMasterCreate,
        type: "compliancemasteredit",
      },
      {
        name: "Compliance List",
        path: "/compliance-list",
        restricted: true,
        component: ComplianceList,
        type: "compliancelist",
      },
      {
        name: "All Compliances",
        path: "/compliance-list-edit/:id",
        restricted: true,
        component: ComplianceListEdit,
        type: "compliancelistedit",
      },
    ],
    type: "filingscompliance",
  },
  {
    name: "Services",
    path: "/packages-services",
    restricted: true,
    component: ServicesPackages,
    routes: [
      {
        name: "Service Management",
        path: "/service-management",
        restricted: true,
        component: ServiceManagement,
        type: "servicemanagement",
      },
      // {
      //   name: "Package Management",
      //   path: "/package-management",
      //   restricted: true,
      //   component: PackageManagement,
      //   type: "packagemanagement",
      // },
    ],

    type: "servicespackages",
  },
  {
    name: "Service Component",
    path: "/packages-services-service/details/:id",
    restricted: true,
    component: ServiceComponent,
    type: "servicecomponent",
  },
  {
    name: "Package Component",
    path: "/packages-services-package/details/:id",
    restricted: true,
    component: PackageComponent,
    type: "packagecomponent",
  },
  {
    name: "Leads Enquiry",
    path: "/leads-enquiry",
    restricted: true,
    component: LeadsEnquiry,
    routes: [
      {
        name: "Leads",
        path: "/leads",
        restricted: true,
        component: Leads,
        type: "leads",
      },
      {
        name: "Support",
        path: "/support",
        restricted: true,
        component: Support,
        type: "support",
      },
      {
        name: "Support",
        path: "/support-view/:id",
        restricted: true,
        component: SupportView,
        type: "supportview",
      },
    ],
    type: "leadsenquiry",
  },
  {
    name: "Coupons",
    path: "/coupons",
    restricted: true,
    component: Coupons,
    routes: [
      {
        name: "Coupons",
        path: "/coupons-list",
        restricted: true,
        component: CouponsList,
        type: "couponslist",
      },
      {
        name: "Coupon Create",
        path: "/coupons-list/:id",
        restricted: true,
        component: CouponsCreate,
        type: "couponscreate",
      },
      {
        name: "Coupon Edit",
        path: "/coupons-list-edit/:id",
        restricted: true,
        component: couponsEdit,
        type: "couponsedit",
      },
      {
        name: "Coupons View",
        path: "/coupons-list-view/:id",
        restricted: true,
        component: CouponsView,
        type: "couponsview",
      },
    ],
    type: "coupons",
  },
  {
    name: "Employees",
    path: "/employees",
    restricted: true,
    component: Employee,
    routes: [
      {
        name: "Employee List",
        path: "/employee-list",
        restricted: true,
        component: EmployeeList,
        type: "employeelist",
      },
    ],
    type: "employees",
  },
  {
    name: "Contacts",
    path: "/contacts",
    restricted: true,
    component: Contacts,
    routes: [
      {
        name: "Customer List",
        path: "/contact-list",
        restricted: true,
        component: CustomerList,
        type: "contactlist",
      },
    ],
    type: "contacts",
  },
  {
    name: "Contacts List Compoenent",
    path: "/contact-detailed/new",
    restricted: true,
    component: CustomerListComponent,
    routes: [
      {
        name: "Address Information",
        path: "/address-information",
        restricted: true,
        component: AddressInformation,
        type: "addressinformation",
      },
    ],
    type: "contactlistcomponent",
  },
  {
    name: "Contacts List Compoenent",
    path: "/contact-detailed/:id",
    restricted: true,
    component: CustomerListComponent,
    type: "contactlistcomponent",
  },
  {
    name: "Contacts View Compoenent",
    path: "/contacts-list-view/:id",
    restricted: true,
    component: CustomerViewComponent,

    type: "contactviewcomponent",
  },
  {
    name: "Companies",
    path: "/accounts",
    restricted: true,
    component: Company,
    routes: [
      {
        name: "Company List",
        path: "/account-list",
        restricted: true,
        component: CompanyList,
        type: "accountlist",
      },
    ],
    type: "accounts",
  },
  {
    name: "Company List Component",
    path: "/accounts-list-create/:id",
    restricted: true,
    component: CompanyListComponent,
    routes: [],
    type: "accountListComponent",
  },
  {
    name: "Company View Component",
    path: "/accounts-list-view/:id",
    restricted: true,
    component: CompanyViewComponent,

    type: "accountViewComponent",
  },
  {
    name: "CheckList",
    path: "/checklist",
    restricted: true,
    component: CheckList,
    routes: [
      {
        name: "CheckList View",
        path: "/checklistView",
        restricted: true,
        component: CheckListView,
        type: "checklistview",
      },
    ],
    type: "checklist",
  },
  ///////////////////////

  {
    name: "Orders",
    path: "/orders",
    restricted: true,
    component: Orders,
    routes: [
      {
        name: "By Orders",
        path: "/by-orders-list",
        restricted: true,
        component: OrdersList,
        type: "orderslist",
      },
      {
        name: "By Services",
        path: "/by-services-list",
        restricted: true,
        component: ServicesList,
        type: "serviceslist",
      },
    ],
    type: "orders",
  },
  {
    name: "Payments and Refunds",
    path: "/payment-refund",
    restricted: true,
    component: Payments,
    routes: [
      {
        name: "Payments",
        path: "/payments",
        restricted: true,
        component: PaymentsList,
        type: "paymentslist",
      },
    ],
    type: "payments",
  },
  {
    name: "Export",
    path: "/employee/exports",
    restricted: true,
    component: Exports,
    routes: [],
    type: "exports",
  },
  {
    name: "My Tasks",
    path: "/my-tasks",
    restricted: true,
    component: MyTasks,
    routes: [],
    type: "tasks",
  },
  {
    name: "My Approvals",
    path: "/my-apprrovals",
    restricted: true,
    component: MyApprovals,
    routes: [],
    type: "approvals",
  },
  {
    name: "Payment Approvals",
    path: "/payment-apprrovals",
    restricted: true,
    component: MyPaymentApprovals,
    routes: [],
    type: "paymentApprovals",
  },
  {
    name: "User File Management",
    path: "/user-file-management",
    restricted: true,
    component: UserFileManagement,
    routes: [
      {
        name: "Folders List",
        path: "/folders-list",
        restricted: true,
        component: FoldersList,
        type: "folderslist",
      },
    ],
    type: "userfilemanagement",
  },

  {
    name: "Teams",
    path: "/teams",
    restricted: true,
    component: Teams,
    routes: [],
    type: "teams",
  },
  {
    name: "Order Details",
    path: "/orders-view/:id",
    restricted: true,
    component: OrderDetails,
    type: "orderDetails",
  },
  {
    name: "Task Details",
    path: "/task-details/:id",
    restricted: true,
    component: TaskDetails,
    type: "taskDetails",
  },
  {
    name: "Approval Details",
    path: "/approval-details/:id",
    restricted: true,
    component: ApprovalDetails,
    type: "approvalDetails",
  },
  {
    name: "Payment Approval Details",
    path: "/payment-approval-details/:id",
    restricted: true,
    component: PaymentApprovalDetails,
    type: "approvalDetailsPayment",
  },
  {
    name: "Teams View",
    path: "/teams-view/:id",
    restricted: true,
    component: TeamDetails,
    routes: [],
    type: "teams",
  },
  {
    name: "Employee View",
    path: "/employees-list-view/:id",
    restricted: true,
    component: EmployeeDetails,
    routes: [],
    type: "employee",
  },
  {
    name: "Roles",
    path: "/roles",
    restricted: true,
    component: Roles,
    routes: [],
    type: "roles",
  },
  {
    name: "Role Details",
    path: "/roles-view/:id",
    restricted: true,
    component: RoleDetails,
    type: "roleViewComponent",
  },
  {
    name: "PageNotFound",
    path: "/page-not-found",
    restricted: true,
    component: PageNotFound,
  },

  /////////////////////
];

module.exports = routes;
