import { Grid } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { Link } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate from "../../../config/permissiongate";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { formatDate } from "../../../utils/dateUtils";
import { numberFormat } from "../../../utils/numberFormat";
import {
  exportOrderService,
  getAllEmployees,
  getOrderServiceStatusList,
  getServiceCategoryList,
  getTeamLeads,
  searchOrderService,
  getTeams,
} from "../apifunctions";
import ManageOrder from "../manage";

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      addNew: false,
      formSearch: {
        page: 1,
        search: "",
        order__account__business_mapping_info__customer_relation_executive: "",
        order__assigned_to_id: "",
        service__service_category_id: "",
        assigned_to_id: "",
        order_service_status_id: "",
        order_value: "",
        order_collection: "",
        assigned_team_id: "",
      },
      accountCreData: [],
      orderCreData: [],
      servicecategoryData: [],
      teamLeaderData: [],
      orderValueData: [
        { id: 1, name: "1000-2000" },
        { id: 2, name: "2000-5000" },
        { id: 3, name: "5000-10000" },
        { id: 4, name: "10000-25000" },
        { id: 5, name: "25000-50000" },
        { id: 6, name: "50000-100000" },
      ],
      orderCollectionData: [
        { id: 1, name: "1000-2000" },
        { id: 2, name: "2000-5000" },
        { id: 3, name: "5000-10000" },
        { id: 4, name: "10000-25000" },
        { id: 5, name: "25000-50000" },
        { id: 6, name: "50000-100000" },
      ],
      serviceStatusData: [],
      teamData: [],
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
      filterStatus: false,
      filterAppliedStatus: false,
      emptyStatus:false,
      tabType: "orderDetailsByService",
    };
  }

  async componentDidMount() {
    try {
      this.getData();
    } catch (err) {}
  }

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      },
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      const response = await searchOrderService(this.state.formSearch);
      const resData = response.data;

      const isFormEmpty = this.isFormEmpty(this.state.formSearch);
      this.setState({
          datas: resData.results,
          totalCount: resData.count,
          pagesize: resData.page_size,
          loader: false,
          filterAppliedStatus: true,
          emptyStatus: !isFormEmpty
      });

        }
        catch(err) {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
          console.log("error", err);
        };
        
  }

  isFormEmpty(form) {
    const fieldsToCheck = [
        'order__account__business_mapping_info__customer_relation_executive',
        'order__assigned_to_id',
        'service__service_category_id',
        // 'assigned_to_id',
        'assigned_team_id',
        'order_service_status_id',
        'order_value',
        'order_collection',
        'order_start_ate',
        'order_end_date',
        'allocated_start_date',
        'allocated_end_date',
        'target_start_date',
        'target_end_date',
        'search'
    ];
    return fieldsToCheck.every(field => !form[field]);
}

  handleOrderDateDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_start_ate: date[0],
          order_end_date: date[1],
          order_from_date: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          order_to_date: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
        filterStatus: true,
      },
      () => {
        if (
          this.state.formSearch.order_from_date &&
          this.state.formSearch.order_to_date
        ) {
          this.handleSearch();
        }
      },
    );
  };

  handleAllocatedDateDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          allocated_start_date: date[0],
          allocated_end_date: date[1],
          allocated_from_date: date[0]
            ? moment(date[0]).format("DD-MM-YYYY")
            : "",
          allocated_to_date: date[1]
            ? moment(date[1]).format("DD-MM-YYYY")
            : "",
        },
        filterStatus: true,
      },
      () => {
        if (
          this.state.formSearch.allocated_from_date &&
          this.state.formSearch.allocated_to_date
        ) {
          this.handleSearch();
        }
      },
    );
  };

  handleTargetDateDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          target_start_date: date[0],
          target_end_date: date[1],
          target_from_date: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          target_to_date: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
        filterStatus: true,
      },
      () => {
        if (
          this.state.formSearch.target_from_date &&
          this.state.formSearch.target_to_date
        ) {
          this.handleSearch();
        }
      },
    );
  };

  handleAccountCreDataChange = (value, reason = undefined) => {
    this.getAccountCre(value);
  };

  handleOrderValue = (e) => {
    const temp = this.state.orderValueData?.find(
      (item) => item.id == e.target.value,
    );

    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_value: temp?.name ?? 0,
          order_value_id: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleOrderCollection = (e) => {
    const temp = this.state.orderCollectionData?.find(
      (item) => item.id == e.target.value,
    );

    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_collection: temp?.name ?? 0,
          order_collection_id: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleOrderCreDataChange = (value, reason = undefined) => {
    this.getOrderCre(value);
  };

  handleServiceCategoryDataChange = (value, reason = undefined) => {
    this.getServiceCategory(value);
  };

  handleTeamLeaderDataChange = (value, reason = undefined) => {
    this.getTeamLeader(value);
  };

  handleServiceStatusDataChange = (value, reason = undefined) => {
    this.getServiceStatus(value);
  };

  handleTeamDataChange = (value, reason = undefined) => {
    this.getTeam(value);
  };

  handleAccountCreSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order__account__business_mapping_info__customer_relation_executive:
            e.id,
          account_cre_name: e.account_cre_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleOrderCreSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order__assigned_to_id: e.id,
          order_cre_name: e.order_cre_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleServiceCategorySelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          service__service_category_id: e.id,
          service_category_name: e.service_category_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleTeamLeaderSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          assigned_to_id: e.id,
          team_leader_name: e.team_leader_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleServiceStatusSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_service_status_id: e.id,
          service_status_name: e.service_status_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleTeamSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          assigned_team_id: e.id,
          team_name: e.name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      },
    );
  };
  handleView = (id) => {
    this.props.history.push("/orders-view/" + id);
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,

          page: 1,
        },
      },
      () => {
        this.getData();
      },
    );
  };

  clearFilter = () => {
    this.setState(
      {
        formSearch: {
          page: 1,
          search: "",
          order__account__business_mapping_info__customer_relation_executive:
            "",
          order__assigned_to_id: "",
          service__service_category_id: "",
          // assigned_to_id: "",
          assigned_team_id:"",
          order_service_status_id: "",
        },
        filterStatus: false,
      },
      () => {
        this.getData();
      },
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      },
    );
  };

  async getAccountCre(value) {
    try {
      const response = await getAllEmployees(value);
      const updatedData = response.map((item) => ({
        ...item,
        account_cre_name: item.name,
      }));
      this.setState({ accountCreData: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  async getOrderCre(value) {
    try {
      const response = await getAllEmployees(value);
      const updatedData = response.map((item) => ({
        ...item,
        order_cre_name: item.name,
      }));
      this.setState({ orderCreData: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  async getServiceCategory(value) {
    try {
      const response = await getServiceCategoryList(value);
      const updatedData = response.data.service_categories.map((item) => ({
        ...item,
        service_category_name: item.service_category_display_name,
      }));
      this.setState({ servicecategoryData: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  async getTeamLeader(value) {
    try {
      const response = await getTeamLeads(value);
      const updatedData = response.results.map((item) => ({
        ...item,
        team_leader_name: item.name,
      }));
      this.setState({ teamLeaderData: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  async getServiceStatus(value) {
    try {
      const response = await getOrderServiceStatusList(value);
      const updatedData = response.data.map((item) => ({
        ...item,
        service_status_name: item.name,
      }));
      this.setState({ serviceStatusData: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  async getTeam(value) {
    try {
      const response = await getTeams({ type: "dropdown_with_leads_only" });
      const updatedData = response.results.map((team) => ({
        id: team.id,
        name: team.lead ? team.name + " (" + team.lead + ") " : team.name,
      }));
      updatedData.unshift({ id: "unallocated", name: "Unalocated" });
      this.setState({ teamData: updatedData });
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message.error;
      if (errorMessage) {
        this.setState({
          snackbarMessage: errorMessage,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    }
  }

  handleExport = () => {
    exportOrderService(this.state.formSearch)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  render() {
    const {
      loader,
      totalCount,
      formSearch,
      pagesize,
      editMode,
      formData,
      filterStatus,
      accountCreData,
      orderCreData,
      servicecategoryData,
      teamLeaderData,
      serviceStatusData,
      orderCollectionData,
      orderValueData,
      filterAppliedStatus,
      emptyStatus,
      teamData,
    } = this.state;
    const { masterData } = this.props;
    
    return (
      <div className="card-content collapse show">
        {this.state.addNew && (
          <ManageOrder
            open={this.state.addNew}
            handleClose={this.handleCloseAdd}
            masterData={masterData}
            {...this.props}
          />
        )}
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.order_start_ate}
                endDate={formSearch.order_end_date}
                name="date"
                onChange={this.handleOrderDateDatePicker}
                placeholder="Order Date"
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={accountCreData}
                id="id"
                async="true"
                value={formSearch.account_cre_name}
                desc="account_cre_name"
                getData={this.handleAccountCreDataChange}
                placeholder="Account CRE"
                className="form-control"
                onChange={this.handleAccountCreSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={orderCreData}
                id="id"
                async="true"
                value={formSearch.order_cre_name}
                desc="order_cre_name"
                getData={this.handleOrderCreDataChange}
                placeholder="Order CRE"
                className="form-control"
                onChange={this.handleOrderCreSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={servicecategoryData}
                id="id"
                async="true"
                value={formSearch.service_category_name}
                desc="service_category_name"
                getData={this.handleServiceCategoryDataChange}
                placeholder="Service Category"
                className="form-control"
                onChange={this.handleServiceCategorySelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="order_value"
                onChange={this.handleOrderValue}
                value={formSearch?.order_value_id ?? ""}
              >
                <option value="" selected>
                  Order Value
                </option>
                {orderValueData &&
                  orderValueData?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="order_collection"
                onChange={this.handleOrderCollection}
                value={formSearch?.order_collection_id ?? ""}
              >
                <option value="" selected>
                  Order Collection
                </option>
                {orderCollectionData &&
                  orderCollectionData?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </Grid>
            {/* <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={teamLeaderData}
                id="id"
                async="true"
                value={formSearch.team_leader_name}
                desc="team_leader_name"
                getData={this.handleTeamLeaderDataChange}
                placeholder="Team Leader "
                className="form-control"
                onChange={this.handleTeamLeaderSelectChange}
              />
            </Grid> */}
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.allocated_start_date}
                endDate={formSearch.allocated_end_date}
                name="date"
                onChange={this.handleAllocatedDateDatePicker}
                placeholder="Allocated Date"
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.target_start_date}
                endDate={formSearch.target_end_date}
                name="date"
                onChange={this.handleTargetDateDatePicker}
                placeholder="Target Date"
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={serviceStatusData}
                id="id"
                async="true"
                value={formSearch.service_status_name}
                desc="service_status_name"
                getData={this.handleServiceStatusDataChange}
                placeholder="Service Status"
                className="form-control"
                onChange={this.handleServiceStatusSelectChange}
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={teamData}
                id="id"
                async="true"
                value={formSearch.team_name}
                desc="name"
                getData={this.handleTeamDataChange}
                placeholder="Team"
                className="form-control"
                onChange={this.handleTeamSelectChange}
              />
            </Grid>
            <Grid item align="right" xs={6} sm={2} className="table-search">
              {filterStatus && filterAppliedStatus && emptyStatus && (
                <Link
                  className="btn btn-secondary"
                  onClick={(e) => this.clearFilter(e)}
                  to="#"
                >
                  Clear filters
                </Link>
              )}

              {totalCount > 0 && (
                <PermissionsGate scopes={[SCOPES.exportOrders]}>
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    onClick={(e) => this.handleExport(e)}
                  >
                    Export
                  </Link>
                </PermissionsGate>
              )}
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                scopes={[
                  SCOPES.viewOrder,
                  SCOPES.viewAllOrder,
                  SCOPES.viewTeamOrder,
                ]}
                RenderError={<p>Access Denied</p>}
              >
                <DataTable
                  data={this.state.datas}
                  columns={[
                    /*{
                              title: "SL",
                              type: "String",
                              field: "slNo",
                            },*/
                    {
                      title: "Order ID",
                      type: "text",
                      field: "order_number",
                      render: (rowData) =>
                        rowData.order?.order_number && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={{
                              pathname: `/orders-view/${rowData?.order?.id}`,
                              state: { tabType: this.state.tabType },
                            }}
                          >
                            {rowData.order?.order_number ?? "--"}
                          </Link>
                        ),
                    },
                    {
                      title: "Order Date",
                      type: "date",
                      render: (rowData) => (
                        <span>{formatDate(rowData.order?.order_date)}</span>
                      ),
                    },
                    {
                      title: "Account Name",
                      type: "text",
                      render: (rowData) => (
                        <span>
                          {rowData?.order?.account?.business_name ?? "--"}
                        </span>
                      ),
                    },
                    {
                      title: "Contact Name",
                      type: "text",
                      render: (rowData) => (
                        <span>{rowData?.order?.contact?.name ?? "--"}</span>
                      ),
                    },
                    {
                      title: "Account CRE",
                      type: "text",
                      render: (rowData) => (
                        <span>
                          {rowData?.order?.account?.cre_agent ?? "--"}
                        </span>
                      ),
                    },
                    {
                      title: "Order CRE",
                      type: "text",
                      render: (rowData) => (
                        <span>{rowData?.order?.order_cre ?? "--"}</span>
                      ),
                    },
                    {
                      title: "Service Name",
                      type: "text",
                      width: 250,
                      field: "service_name",
                    },
                    {
                      title: "Service Value",
                      type: "amount",
                      field: "total_price",
                      render: (rowData) => (
                        <span>{numberFormat(rowData.total_price) ?? "--"}</span>
                      ),
                    },
                    {
                      title: "Order Value",
                      type: "amount",
                      field: "total_price",
                      render: (rowData) => (
                        <span>
                          {rowData?.order?.grand_total
                            ? numberFormat(rowData?.order?.grand_total)
                            : "--"}
                        </span>
                      ),
                    },
                    {
                      title: "Service Status",
                      type: "text",
                      width: 275,
                      render: (rowData) => (
                        <span>
                          {rowData.order_service_status?.name ?? "--"}
                        </span>
                      ),
                    },
                    {
                      title: "Team Leader",
                      type: "text",
                      field: "assigned_team_lead",
                      render: (rowData) => (
                        <span>{rowData?.assigned_team_lead ?? "--"}</span>
                      ),
                    },
                    {
                      title: "Allocated Date",
                      type: "date",
                      field: "assigned_date",
                      render: (rowData) => (
                        <span>{formatDate(rowData.assigned_date)}</span>
                      ),
                    },
                    {
                      title: "Target Date",
                      type: "date",
                      field: "target_close_date",
                      render: (rowData) => (
                        <span>{formatDate(rowData.target_close_date)}</span>
                      ),
                    },
                    {
                      title: "Order Collection",
                      type: "amount",
                      field: "total_price",
                      render: (rowData) => (
                        <span>
                          {rowData?.order?.paid_amount
                            ? numberFormat(rowData?.order?.paid_amount)
                            : "--"}
                        </span>
                      ),
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "order_date",
                    pageNo: formSearch.page,
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                  }}
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
export default OrdersList;
