import { Grid } from "@material-ui/core";
import { EditorState } from "draft-js";
import moment from "moment";
import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import htmlToDraft from 'html-to-draftjs';
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { addCoupon, getCouponDetails } from "../apifunctions";

class CouponsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        coupon_name: "",
        coupon_code: "",
        start_date: "",
        end_date: "",
        maximum_usage_limit: 0,
        status: "",
        minimum_sale_value_for_discount: 0.0,
        discount_type: "",
        coupon_description: EditorState.createEmpty(),
        terms_and_conditions: EditorState.createEmpty(),
        percentage_discount: 0.0,
        max_discount_amount: 0,
        coupon_category: "",
      },
      editflag: false,
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    if (this.props.match.params.id !== "new") {
      this.setState({ editflag: true });
      this.getCouponDetails();
    } else {
      this.setState({ editflag: false });
    }
  };

  async getCouponDetails() {
    this.setState({ loader: true });
    try {
      getCouponDetails(this.props.match.params.id)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          this.setState({
            formData: {
              coupon_name: res.coupon_name,
              coupon_code: res.coupon_code,
              start_date: res.start_date,
              end_date: res.end_date,
              maximum_usage_limit: res.maximum_usage_limit,
              status: res.status,
              minimum_sale_value_for_discount:
                res.minimum_sale_value_for_discount,
              discount_type: res.discount_type,
              coupon_description: res.coupon_description,
              terms_and_conditions: res.terms_and_conditions,
              percentage_discount: res.percentage_discount
                ? res.percentage_discount
                : 0.0,
              max_discount_amount: res.max_discount_amount
                ? res.max_discount_amount
                : 0,
              coupon_category: res.coupon_category,
            },
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loader: true });
    console.log(this.state.formData);
    addCoupon(this.state.formData)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            loader: false,
          },
          () => {
            setTimeout(() => {
              this.props.history.push("/coupons/coupons-list");
            }, 2500);
          }
        );
      })
      .catch((err) => {
        var error_message = this.props.loadError(
          err?.response?.data?.error?.message
        );
        this.setState({
          snackbarMessage: error_message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleDatePicker = (e) => {
    if (e.value) {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.name]: e.value ? moment(e.value).format("YYYY-MM-DD HH:mm") : "",
        },
      });
    }
  };
  onEditorCoupondescription = (editorState) => {
    this.setState({
      formData: {
        ...this.state.formData,
        coupon_description: editorState,
      },
    });
  };
  onEditorTermsconditions = (editorState) => {
    this.setState({
      formData: {
        ...this.state.formData,
        terms_and_conditions: editorState,
      },
    });
  };

  handleClose = () => {
    this.props.history.push("/coupons/coupons-list");
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { masterData } = this.props;
    const { formData, loader, editflag, editorState } = this.state;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <div className="card-title">
                  <h4>
                    {editflag ? "Edit Coupon Details" : "Add New Coupons"}
                  </h4>
                </div>
              </Grid>
              <Grid align="right" item xs={6} sm={6}>
                <button
                  style={{ marginRight: 6 }}
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {editflag ? "Update" : "Save"}
                </button>
              </Grid>
            </Grid>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Coupon Category <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="coupon_category"
                        onChange={this.handleChange}
                        value={formData.coupon_category.id}
                        required
                      >
                        <option value="" selected>
                          Select Category
                        </option>
                        {masterData &&
                          masterData.coupon_categories &&
                          masterData.coupon_categories.map(
                            ({ id, coupon_category_display_name }) => (
                              <option key={id} value={id}>
                                {coupon_category_display_name}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Coupon Title <span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="coupon_name"
                        class="form-control"
                        placeholder="Coupon Name"
                        onChange={this.handleChange}
                        value={formData.coupon_name}
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Coupon Code <span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="coupon_code"
                        class="form-control"
                        placeholder="#COUPONCODE"
                        onChange={this.handleChange}
                        value={formData.coupon_code}
                      />
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Start Date <span className="red">*</span>
                      </label>
                      <CalendarComponent
                        label="date"
                        required
                        onChange={this.handleDatePicker}
                        name="start_date"
                        dateTime={true}
                        showTimeSelect
                        minDate={new Date()}
                        maxDate={new Date(formData.end_date)}
                        selected={formData.start_date}
                        class="form-control"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        End Date <span className="red">*</span>
                      </label>
                      <CalendarComponent
                        label="date"
                        required
                        onChange={this.handleDatePicker}
                        name="end_date"
                        dateTime={true}
                        showTimeSelect
                        minDate={
                          formData.start_date
                            ? new Date(formData.start_date)
                            : new Date()
                        }
                        selected={formData.end_date}
                        class="form-control"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Maximum Usage Limit <span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="maximum_usage_limit"
                        class="form-control"
                        placeholder="10"
                        onChange={this.handleChange}
                        value={formData.maximum_usage_limit}
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Status <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        required
                        onChange={this.handleChange}
                        value={formData.status}
                      >
                        <option value="" selected>
                          Select Status
                        </option>
                        {masterData &&
                          masterData.statuses &&
                          masterData.statuses.map(({ id, code, name }) => (
                            <option key={id} value={code}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Minimum Sale Value for Discount{" "}
                        <span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="minimum_sale_value_for_discount"
                        class="form-control"
                        placeholder="100.00"
                        onChange={this.handleChange}
                        value={formData.minimum_sale_value_for_discount}
                      />
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Discount Type <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="discount_type"
                        required
                        onChange={this.handleChange}
                        value={formData.discount_type}
                      >
                        <option value="" selected>
                          Select Discount Type
                        </option>
                        {masterData &&
                          masterData.discount_types &&
                          masterData.discount_types.map(({ name, code }) => (
                            <option key={code} value={code}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Discount %<span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="percentage_discount"
                        class="form-control"
                        placeholder="10.00"
                        onChange={this.handleChange}
                        value={formData.percentage_discount}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Coupon Description
                      </label>
                      {/* <textarea
                        type="text"
                        name="coupon_description"
                        class="form-control"
                        placeholder=""
                        rows="5"
                        onChange={this.handleChange}
                      >
                        {formData.coupon_description
                          ? formData.coupon_description
                          : ""}
                      </textarea> */}

                      <Editor
                        editorState={formData.coupon_description}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorCoupondescription}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Terms & Conditions
                      </label>
                      {/* <textarea
                        type="text"
                        name="terms_and_conditions"
                        class="form-control"
                        placeholder=""
                        rows="5"
                        onChange={this.handleChange}
                      >
                        {formData.terms_and_conditions
                          ? formData.terms_and_conditions
                          : ""}
                      </textarea> */}

                      <Editor
                        // name="terms_and_conditions"
                        editorState={formData.terms_and_conditions}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorTermsconditions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CouponsCreate;
