import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrumb from "../../../layout/breadcrumb";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../plugins/customtab/customtab";
import { Menu, MenuItem, IconButton, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { HiEllipsisVertical } from "react-icons/hi2";
import {
  getEmployeeDetails,
  setAdminStatus,
  setOperationsHeadStatus,
  setEmployeeStatus,
  getEmployeePermissions,
} from "../apifunctions";
// import ManageTeam from "../manage/addTeam";
import TeamInfo from "./info";
import WorkProfile from "./workprofile";
import ChangePassword from "../changePassword";
import SnackbarComponent from "../../../plugins/snackbar-component";
import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate from "../../../config/permissiongate";
import { setIsAdmin, setIsImpersonating, setPermission } from "../../../config/redux/permissionSlice";
import { connect } from "react-redux";
import { setImpersonateToken } from "../../../config";

// import TeamMembers from "./members";
// import TeamServices from "./service";
// import { get_Company_Details } from "../../apifunctions";


let tabData = [
  {
    name: "Basic Info",
    id: 1,
  },
  {
    name: "Work Profile",
    id: 2,
  },
  // {
  //   name: "Roles",
  //   id: 3,
  // },
];

const TeamDetails = (props) => {
  const history = useHistory();
  const param = useParams();
  const isAdmin = useSelector((state) => state.permission.isAdmin);
  // const isImpersonating = useSelector((state) => state.isImpersonating ? true : false);
  const [currentTab, setCurrentTab] = useState(1);
  const [teamData, setTeamData] = useState({});
  const [loader, setLoader] = useState(false);
  const [updateKey, setUpdateKey] = useState(1);
  const [anchorE1, setAnchorE1] = useState(null);
  const [openManage, setOpenManage] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
const [edit,setEdit]=useState(props.history?.location?.state?.edit||false)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleTabView = (val) => {
    setCurrentTab(val);
  };
  const fetchEmployeePermissions = () => {
    setLoader(true);
    getEmployeePermissions(param.id)
      .then((res) => res.data)
      .then((res) => {

        setLoader(false);
        const permissions = res?.role?.permissions;
        console.log('permissions', permissions);
        props.dispatch(
          setPermission(
            permissions?.map((permission) => {
              return permission.acl.replace("::", ".");
            })
          )
        );
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarVarient("error");
        setSnackbarMessage(
          err?.response?.data?.error?.message || "Something went wrong"
        );
        setSnackbarOpen(true);
      });
  };
  const getTeamDetails = () => {
    setLoader(true);
    getEmployeeDetails(param.id)
      .then((res) => res.data)
      .then((res) => res.employee)
      .then((res) => {
        console.log(res);
        let data = {
          ...res,
          // lead_name: res.lead ? res.lead["name"] : null,
          // lead_id: res.lead ? res.lead["id"] : null,
        };
        setTeamData(data);
        setLoader(false);
        setAnchorE1(null);
        // setUpdateKey(updateKey + 1);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarVarient("error");
        setSnackbarMessage(
          err?.response?.data?.error?.message || "Something went wrong"
        );
        setSnackbarOpen(true);
      });
  };
  useEffect(() => {
    getTeamDetails();
  }, []);
  const handleChangePassword = () => {
    setOpenManage(true);
  };
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setAnchorE1(null);

    if (flag) {
      getTeamDetails();
    }
  };

  const handleImpersonate = (e) => {
    console.log("handle impersonate");
    fetchEmployeePermissions();
    props.dispatch(setIsImpersonating(param.id));
    props.dispatch(setIsAdmin(false));
    setImpersonateToken(param.id);
    history.push("/dashboard");
  };

  const handleAdminChange = (flag) => {
    Swal.fire({
      title: "Are you sure?",
      text: flag
        ? "Do you want to make this user as admin?"
        : "Do you want to remove this user from admin?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setAdminStatus(param.id, { status: flag }).then(() => {
          setSnackbarVarient("success");
          setSnackbarMessage("Updated Successfully");
          setSnackbarOpen(true);
          getTeamDetails();
        });
      }
    });
  };
  const handleHeadChange = (flag) => {
    Swal.fire({
      title: "Are you sure?",
      text: flag
        ? "Do you want to make this user as operations head?"
        : "Do you want to remove this user from operations head?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setOperationsHeadStatus(param.id, { is_operations_head: flag })
          .then(() => {
            getTeamDetails();
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(
              err?.response?.data?.error?.message || "Something went wrong"
            );
            setSnackbarOpen(true);
          });
      }
    });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  const handleStatusChange = (flag) => {
    Swal.fire({
      title: "Are you sure?",
      text: flag
        ? "Do you want to activate this user?"
        : "Do you want to deactivate this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setEmployeeStatus(param.id, { is_active: flag })
          .then(() => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            getTeamDetails();
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(
              err?.response?.data?.error?.message || "Something went wrong"
            );
            setSnackbarOpen(true);
          });
      }
    });
  };
  return (
    <>
      <div className="app-content content">
        <CommonLoader open={loader} />
        {openManage && (
          <ChangePassword
            open={openManage}
            handleClose={handleClose}
            id={param.id}
            data={teamData}
            handleRefresh={getTeamDetails}
          />
        )}
        <SnackbarComponent
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          onSnackbarClose={onSnackbarClose}
          variant={snackbarVarient}
        />

        <div className="content-wrapper">
          <BreadCrumb type={"employeeeDetail"} />
          <PermissionsGate
            // errorProps={{ disabled: true }}
            RenderError={() => <p>Access Denied !!</p>}
            scopes={[SCOPES.manageEmployee]}
          >
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="customer-list-head customer-view">
                          <div className="details-box">
                            <div className="detail-box border-r">
                              <div className="content">
                                <p className="name">
                                  {teamData?.name ?? "--"}
                                  {teamData?.status ? (
                                    <span className="tag green">Active</span>
                                  ) : (
                                    <span className="tag red">Inactive</span>
                                  )}
                                  {teamData?.is_admin ? (
                                    <span className="tag yellow">Admin</span>
                                  ) : teamData?.is_operations_head ? (
                                    <span className="tag yellow">
                                      Operations Head
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="actions">
                            <IconButton
                              onClick={(e) => setAnchorE1(e.currentTarget)}
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              className="btn btn-rounded"
                            >
                              {" "}
                              <HiEllipsisVertical />
                            </IconButton>

                            <Menu
                              anchorEl={anchorE1}
                              open={Boolean(anchorE1)}
                              onClose={() => setAnchorE1(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              className="customPopper"
                            >
                              {isAdmin && (
                                <MenuItem
                                  key="export-csv"
                                  onClick={(e) =>
                                    handleAdminChange(
                                      teamData.is_admin ? false : true
                                    )
                                  }
                                >
                                  {teamData.is_admin
                                    ? "Remove from admin"
                                    : "Make as admin"}
                                </MenuItem>
                              )}
                              {isAdmin && (
                                <>
                                  <MenuItem
                                    key="export-csv"
                                    onClick={(e) =>
                                      handleHeadChange(
                                        teamData.is_operations_head ? false : true
                                      )
                                    }
                                  >
                                    {teamData.is_operations_head
                                      ? "Remove from operations head"
                                      : "Make operations head"}
                                  </MenuItem>
                                  {!teamData?.is_admin && (
                                    <MenuItem
                                    key="export-csv"
                                    onClick={(e) => handleImpersonate()}
                                  >
                                    Impersonate
                                  </MenuItem>
                                  )}

                                </>
                              )}
                              <MenuItem
                                key="export-csv"
                                onClick={(e) =>
                                  handleStatusChange(
                                    teamData.status ? false : true
                                  )
                                }
                              >
                                {teamData.status
                                  ? "Deactivate Employee"
                                  : "Activate Employee"}
                              </MenuItem>
                              {isAdmin && (
                                <MenuItem
                                  key="export-csv"
                                  onClick={(e) => handleChangePassword()}
                                >
                                  Change Password
                                </MenuItem>
                              )}
                            </Menu>
                          </div>
                        </div>
                        <NavigationTab
                          tabData={tabData}
                          currentTab={currentTab}
                          handleTabView={handleTabView}
                        />
                        <div className="card-body">
                          {currentTab === 1 && !loader && (
                            <TeamInfo
                              id={param.id}
                              edit={edit}
                              setEdit={setEdit}
                              data={teamData}
                              handleRefresh={getTeamDetails}
                            />
                          )}
                          {currentTab === 2 && !loader && (
                            <WorkProfile
                              id={param.id}
                              data={teamData}
                              handleRefresh={getTeamDetails}
                            />
                          )}
                          {/* {currentTab === 2 && <TeamMembers id={param.id} />}
                        {currentTab === 3 && <TeamServices id={param.id} />} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </PermissionsGate>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  isAdmin: state.isAdmin,
  isImpersonating: state.isImpersonating,
  allPermissions: state.allPermissions,
});
export default connect(mapStateToProps)(TeamDetails);

// export default TeamDetails;
