import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { MdCheck, MdDownload } from "react-icons/md";
import { useSelector } from "react-redux";

import { SCOPES } from "../../../../config/permission-maps";
import PermissionsGate from "../../../../config/permissiongate";
import CommonLoaderComponent from "../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../plugins/dataTable/dataTable";
import DeletePopup from "../../../../plugins/delete/newDeleteComponent";
import SearchField from "../../../../plugins/searchfield";
import SnackbarMessage from "../../../../plugins/snackbar-component";
import { assign_team_lead_to_order_item, get_order_payments, getTeamLeads, remove_order_payment } from "../../apifunctions";
import OrderItemTasks from "./componennts/orderitemtasks";
import ManagePayment from "./manage";
import { formatDate } from "../../../../utils/dateUtils.js"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Payment = ({ id }) => {
  const classes = useStyles();
  const [formSearch, setFormSearch] = useState({
    search: "",

    page: 1,
  });
  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.permission.userData);
  const [teamLeads, setTeamLeads] = useState([]);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      get_order_payments(id)
        .then((res) => {
          let dt = res.data;
          dt.forEach((itm) => {
            itm["employee_name"] = itm?.assigned_to?.name;
            itm["employee_id"] = itm?.assigned_to?.id;
          });
          setTeamsList(dt);
          // setTotalCount(res.count);
          setLoader(false);
          // setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err?.message ?? "Could not add payment",
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleDeleteOpen = (e, data) => {
    setRowData(data);

    setOpenDelete(true);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleDeleteConfirm = () => {
    setLoader(true);
    console.log(rowData);
    remove_order_payment(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Payment deleted.",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const fetchEmployeeNySearch = (e, row) => {
    if (e) {
      teamsList.forEach((itm) => {
        if (itm.id === row.id) {
          itm["employee_name"] = e;
        }
      });
      setTeamsList(teamsList);
      setUpdateKey(updateKey + 1);
    }
    getTeamLeads({
      page: 1,
      ...(e
        ? {
            search: e,
          }
        : {}),
    }).then((res) => {
      setTeamLeads(res?.results || []);
    });
  };
  const handleServiceChange = (e, row) => {
    let tm = teamsList;
    tm.forEach((itm) => {
      if (itm.id === row.id) {
        itm["employee_name"] = e["name"];
        itm["employee_id"] = e["id"];
      }
    });
    setTeamsList(tm);
    setUpdateKey(updateKey + 1);

    setAssigneeToItem(e["id"], row);
  };
  const setAssigneeToItem = (id, row) => {
    console.log(row);
    assign_team_lead_to_order_item(row.order?.id, row.id, {
      employee_id: id || null,
    })
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Team Lead added successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  const handleDownload = (r) => {
    if (r) {
      window.open(r, "_blank");
    }
  };
  return (
    <div className="card-content collapse show">
      <CommonLoaderComponent open={loader} />
      <SnackbarMessage
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      {openManage && (
        <ManagePayment
          open={openManage}
          order_id={id}
          data={rowData}
          handleClose={handleClose}
        />
      )}
      <DeletePopup
        opendelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
        submitted={loader}
      />
      <div className="">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={6} sm={2} className="table-search">
            {/* <SearchField
              onChange={handleChange}
              value={formSearch.search}
              handleSearch={handleSearch}
              handleClear={handleClear}
            /> */}
          </Grid>

          {/* <Grid item className="table-search">
            <Button
              className="btn btn-primary"
              onClick={() => setOpenManage(true)}
            >
              + &nbsp; Add New
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[SCOPES.view_order]}
            >
              {/* <div className="orderAccordion">
                {teamsList.map((item, ind) => (
                  <div key={ind}>
                    <div className="customAccordion mb-0">
                      <div
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          className="h-100"
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            items
                            xs={12}
                            className="bg-primary text-white p-1"
                          >
                            <Grid
                              container
                              spacing={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={10}>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <div>Amount</div>
                                    <div className=" text-success ">{item.amount}</div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div>Payment Method</div>
                                    <div className=" text-success ">
                                      {item.payment_method?.display_name}
                                    </div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div>Status</div>

                                    <div className=" text-success ">
                                      {item.status}
                                    </div>

                                  </Grid>
                                  <Grid item xs={3}>
                                    <div>Created by</div>
                                    <div className=" text-success ">
                                      {item.created_by?.first_name}{" "}
                                      {item.created_by?.last_name}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2} className="px-2">
                                <Grid
                                  container
                                  justify="flex-end"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <div
                                    className="btn btn-danger"
                                    onClick={(e) => handleDeleteOpen(e, item)}
                                  >
                                    Delete
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <div>
                        <OrderItemTasks data={item.transactions || []} />
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <DataTable
                data={teamsList}
                columns={[
                  {
                    title: "Transaction Date",
                    type: "date",

                    render: (ro) => (
                      <div className=" ">
                        {formatDate(ro.transactions[0]?.datetime_updated)}
                      </div>
                    ),
                  },
                  {
                    title: "Transaction ID",
                    type: "text",

                    render: (ro) => (
                      <div className=" ">
                        {ro.transactions[0]?.transaction_id}
                      </div>
                    ),
                  },
                  {
                    title: "Payment method",
                    type: "text",
                    width: "100px",
                    render: (ro) => (
                      <div className="  ">
                        {ro.payment_method?.display_name}
                      </div>
                    ),
                  },

                  {
                    title: "Amount",
                    type: "amount",
                    field: "amount",
                  },

                  // {
                  //   title: "Status",
                  //   type: "text",
                  //   field: "status",
                  // },

                  {
                    title: "External reference ID",
                    type: "text",
                    render: (ro) => (
                      <div className=" ">
                        {ro.transactions[0]?.external_reference_id}
                      </div>
                    ),
                  },
                  {
                    title: "Professional Fee",
                    type: "amount",
                    field: "professional_fee",
                    // render: (ro) => (
                    //   <div className=" ">
                    //     {ro.professional_fee??""}
                    //   </div>
                    // ),
                  },
                  {
                    title: "Govt Fee",
                    type: "amount",
                    field:"govt_fee",
                    // render: (ro) => (
                    //   <div className=" ">
                    //     {ro.govt_fee??""}
                    //   </div>
                    // ),
                  },
                  {
                    title: "GST",
                    type: "amount",
                    field:"gst",
                    // render: (ro) => (
                    //   <div className=" ">
                    //     {ro.gst??""}
                    //   </div>
                    // ),
                  },
                  {
                    title: "Receipt Number",
                    type: "text",
                    render: (ro) => (
                      <div className=" ">
                        {ro.transactions[0]?.receipt_number}
                      </div>
                    ),
                  },

                  {
                    title: "Advance",
                    align: "center",
                    render: (ro) => (
                      <>{ro.transactions[0]?.is_advance && <MdCheck />}</>
                    ),
                  },

                  {
                    title: "Created by",
                    type: "text",

                    render: (ro) => (
                      <div className=" ">
                        {ro.created_by?.first_name} {ro.created_by?.last_name}
                      </div>
                    ),
                  },
                  {
                    title: "Message",
                    type: "text",
                    maxWidth: "300px",
                    render: (ro) => (
                      <div className=" " title={ro.message}>
                        <p
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {ro.message}
                        </p>
                      </div>
                    ),
                  },
                  {
                    title: "Document",

                    render: (ro) => (
                      <>
                        {ro.transactions[0]?.payment_document?.name && (
                          <div
                            className="d-flex align-items-center justify-content-between text-link cursor-pointer"
                            onClick={() =>
                              handleDownload(
                                ro?.transactions[0]?.payment_document?.url
                              )
                            }
                          >
                            <div className="">
                              {ro.transactions[0]?.payment_document?.name}
                            </div>
                            <div style={{ paddingLeft: "2px" }}>
                              <MdDownload />
                            </div>
                          </div>
                        )}
                      </>
                    ),
                  },
                ]}
                options={{
                  sort: false,

                  pagination: false,
                }}
                actions={
                  [
                    // {
                    //   className: "edit",
                    //   //rowActionCLick: this.handleEdit.bind(this),
                    //   iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    //   tooltip: "Edit ",
                    // },
                    // {
                    //   className: "delete",
                    //   rowActionCLick: handleDeleteOpen.bind(this),
                    //   iconName:
                    //     "/app-assets/backend-images/icons/delete-icon.svg",
                    //   tooltip: "Delete",
                    // },
                  ]
                }
              />
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Payment;
