import { Grid } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate, { hasPermission } from "../../../config/permissiongate";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import ManageOrder from "../../orders/manage";
import {
  exportCompany,
  getbusIndusData,
  getbusinsector,
  getConstCat,
  getCountryState,
  getEmployeeListSearch,
  getleadsource,
  getSubIndusData,
  mergeAccounts,
  seachCompanyData,
  searhCustomerData,
} from "../apifunctions";
import MergePopup from "./manage/mergePopup";

class CompanyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      openmerge: false,
      addSOFlag: false,
      selectedAccount: null,
      formSearch: {
        search: "",
        constitution__id: "",
        sector__id: "",
        industry__id: "",
        sub_sector__id: "",
        default_customer__id: "",
        lead_source__id: "",
        state: "",
        business_mapping_info__customer_relation_executive: "",
        page: 1,
      },
      employee_list: [],
      customData: [],
      constData: [],
      loader: false,
      pagesize: 0,
      busData: [],
      industryData: [],
      subSectorData: [],
      leadSourceData: [],
      stateData: [],
      creData: [],
      totalCount: 0,
      selectedRow: [],
      filterStatus: false,
      filterAppliedStatus: false,
      emptyStatus: false,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
        selectedRow: 0,
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      const response = await seachCompanyData(this.state.formSearch);
      const resData = response.data;

      const isFormEmpty = this.isFormEmpty(this.state.formSearch);
      this.setState({
        datas: resData.results,
        totalCount: resData.count,
        pagesize: resData.page_size,
        loader: false,
        filterAppliedStatus: true,
        emptyStatus: !isFormEmpty,
      });
    } catch (err) {
      this.setState({
        snackbarMessage: err.message,
        snackbarOpen: true,
        snackbarVariant: "error",
        loader: false,
      });
      console.log("error", err);
    }
  }

  isFormEmpty(form) {
    const fieldsToCheck = [
      "constitution__id",
      "sector__id",
      "sub_sector__id",
      "lead_source__id",
      "state",
      "business_mapping_info__customer_relation_executive",
      "industry__id",
      "default_customer__id",
      "startDate",
      "endDate",
      "search",
    ];
    return fieldsToCheck.every((field) => !form[field]);
  }
  handleView = (e, row) => {
    this.props.history.push(`/accounts-list-view/${row.id}`);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleDataChange = (value, reason = undefined) => {
    this.getconstData(value);
  };

  handleSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          constitution__id: e.id,
          constitution_category_name: e.constitution_category_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          fromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          toDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
        filterStatus: true,
      },
      () => {
        if (this.state.formSearch.fromDate && this.state.formSearch.toDate) {
          this.handleSearch();
        }
      }
    );
  };

  handleBusDataChange = (value, reason = undefined) => {
    this.getbus(value);
  };

  handleBusSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          sector__id: e.id,
          business_sector_name: e.business_sector_name,
          industry__id: "",
          business_industry_name: "",
          sub_sector__id: "",
          sub_industry_name: "",
        },
        industryData: [],
        subSectorData: [],
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleIndustryDataChange = (value, reason = undefined) => {
    this.getindustry(value);
  };

  handleSubIndustryDataChange = (value, reason = undefined) => {
    this.getsubindustry(value);
  };

  handleLeadSourceDataChange = (value, reason = undefined) => {
    this.getleadsource(value);
  };

  handleStateDataChange = (value, reason = undefined) => {
    this.getState(value);
  };

  handleCreDataChange = (value, reason = undefined) => {
    this.getCre(value);
  };

  handleIndustrySelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          industry__id: e.id,
          business_industry_name: e.business_industry_name,
          sub_sector__id: "",
          sub_industry_name: "",
        },
        subSectorData: [],
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleSubIndustrySelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          sub_sector__id: e.id,
          sub_industry_name: e.sub_industry_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleLeadSourceSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          lead_source__id: e.id,
          lead_source_name: e.lead_source_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleStateSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          state: e.state_name,
          state_name: e.state_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleCreSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          business_mapping_info__customer_relation_executive: e.id,
          cre_name: e.cre_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  getconstData = (value) => {
    getConstCat(value ?? "")
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          constData: res.constitution_categories,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getbus = (value) => {
    getbusinsector(value ?? "")
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          busData: res,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getindustry = (value) => {
    getbusIndusData(value ?? "", this.state.formSearch.sector__id)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          industryData: res.results,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getsubindustry = (value) => {
    getSubIndusData(value ?? "", this.state.formSearch.industry__id)
      .then((res) => res.data)
      .then((res) => {
        const updatedData = res.results.map((item) => ({
          ...item,
          sub_industry_name: item.business_industry_name,
        }));
        this.setState({ subSectorData: updatedData });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getleadsource = (value) => {
    getleadsource(value ?? "")
      .then((res) => res.data)
      .then((res) => {
        const updatedData = res.map((item) => ({
          ...item,
          lead_source_name: item.name,
        }));
        this.setState({ leadSourceData: updatedData });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getState = (value) => {
    getCountryState(value ?? "")
      .then((res) => res.data)
      .then((res) => {
        const updatedData = res.state.map((item) => ({
          ...item,
          state_name: item.name,
        }));
        this.setState({ stateData: updatedData });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getCre = (value) => {
    getEmployeeListSearch(value ?? "")
      .then((res) => {
        const updatedData = res?.map((item) => ({
          ...item,
          cre_name: item.name,
        }));
        this.setState({ creData: updatedData });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  getCusts = (value) => {
    searhCustomerData(value ?? "")
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          customData: res.customers,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  handleCustDataChange = (value, reason = undefined) => {
    this.getCusts(value);
  };

  handleCustSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          default_customer__id: e.id,
          name: e.name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  async getCusts(value) {
    try {
      searhCustomerData(value)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            customData: res.customers,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  clearFilter = () => {
    this.setState(
      {
        formSearch: {
          search: "",
          constitution__id: "",
          sector__id: "",
          industry__id: "",
          sub_sector__id: "",
          default_customer__id: "",
          lead_source__id: "",
          state: "",
          business_mapping_info__customer_relation_executive: "",
          page: 1,
        },
        filterStatus: false,
      },
      () => {
        this.getData();
      }
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };

  handleExport = () => {
    exportCompany(this.state.formSearch)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  handleSOAdd = (e, row) => {
    this.setState({ addSOFlag: true });
    this.setState({
      selectedAccount: {
        id: row.id,
        name: row.business_name,
        order_count: row.order_count,
      },
    });
  };
  handleSOClose = (e, row) => {
    this.setState({ addSOFlag: false });
    this.setState({ selectedAccount: null });
  };
  handleEdit = (e, row) => {
    this.props.history.push(`/accounts-list-create/${row.id}`);
  };
  selectProps = (selected) => {
    this.setState({ selectedRow: selected });
  };

  handleMergeOpen = () => {
    console.log(this.state.selectedRow);
    this.setState({
      openmerge: true,
    });
  };

  handleMergeClose = () => {
    this.setState({
      openmerge: false,
    });
  };

  handleMerge = (parentAccountId) => {
    if (this.state.selectedRow.length > 0) {
      let selectedAccountIds = this.state.selectedRow
        .filter((account) => account.id !== parentAccountId)
        .map((account) => account.id);
      let payload = {
        children_accounts: selectedAccountIds,
      };
      mergeAccounts(parentAccountId, payload)
        .then((res) => {
          this.setState({
            snackbarMessage: res?.data,
            snackbarOpen: true,
            snackbarVariant: "success",
            loader: false,
            selectedRow: [],
          });
          this.handleMergeClose();
          this.getData();
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    }
  };

  render() {
    const { isAdmin } = this.props;
    const {
      loader,
      formSearch,
      employee_list,
      busData,
      industryData,
      subSectorData,
      leadSourceData,
      stateData,
      creData,
      customData,
      totalCount,
      pagesize,
      constData,
      filterStatus,
      filterAppliedStatus,
      emptyStatus,
    } = this.state;
    return (
      <div className="card-content collapse show">
        {this.state.addSOFlag && (
          <ManageOrder
            open={this.state.addSOFlag}
            handleClose={this.handleSOClose}
            data={{
              account_id: this.state.selectedAccount.id,
              account_name: this.state.selectedAccount.name,
            }}
            orderExist={this.state.selectedAccount.order_count}
          />
        )}
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={2} className="table-search">
                  <SearchField
                    onChange={this.handleChange}
                    value={formSearch.search}
                    handleSearch={this.handleSearch}
                    handleClear={this.handleClear}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <CalendarComponent
                    selectsRange={true}
                    startDate={formSearch.startDate}
                    endDate={formSearch.endDate}
                    name="date"
                    onChange={this.handleDatePicker}
                    placeholder="Created Date"
                  />
                </Grid>

                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={constData}
                    id="id"
                    async="true"
                    value={formSearch.constitution_category_name}
                    desc="constitution_category_name"
                    getData={this.handleDataChange}
                    placeholder="Category"
                    className="form-control"
                    onChange={this.handleSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={busData}
                    id="id"
                    async="true"
                    value={formSearch.business_sector_name}
                    desc="business_sector_name"
                    getData={this.handleBusDataChange}
                    placeholder="Sector"
                    className="form-control"
                    onChange={this.handleBusSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={industryData}
                    id="id"
                    async="true"
                    value={formSearch.business_industry_name}
                    desc="business_industry_name"
                    getData={this.handleIndustryDataChange}
                    placeholder="Industry"
                    className="form-control"
                    onChange={this.handleIndustrySelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={subSectorData}
                    id="id"
                    async="true"
                    value={formSearch.sub_industry_name}
                    desc="sub_industry_name"
                    getData={this.handleSubIndustryDataChange}
                    placeholder="Sub-sector"
                    className="form-control"
                    onChange={this.handleSubIndustrySelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={leadSourceData}
                    id="id"
                    async="true"
                    value={formSearch.lead_source_name}
                    desc="lead_source_name"
                    getData={this.handleLeadSourceDataChange}
                    placeholder="Lead Source"
                    className="form-control"
                    onChange={this.handleLeadSourceSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={stateData}
                    id="id"
                    async="true"
                    value={formSearch.state_name}
                    desc="state_name"
                    getData={this.handleStateDataChange}
                    placeholder="State"
                    className="form-control"
                    onChange={this.handleStateSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={creData}
                    id="id"
                    async="true"
                    value={formSearch.cre_name}
                    desc="cre_name"
                    getData={this.handleCreDataChange}
                    placeholder="CRE"
                    className="form-control"
                    onChange={this.handleCreSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={customData}
                    id="id"
                    async="true"
                    value={formSearch.name}
                    desc="name"
                    getData={this.handleCustDataChange}
                    placeholder="Primary Contact"
                    className="form-control"
                    onChange={this.handleCustSelectChange}
                  />
                </Grid>
                <Grid align="right" item xs={12} sm={4}>
                  {filterStatus && filterAppliedStatus && emptyStatus && (
                    <Link
                      className="btn btn-secondary"
                      onClick={(e) => this.clearFilter(e)}
                      to="#"
                    >
                      Clear filters
                    </Link>
                  )}
                  {totalCount > 0 && (
                    <PermissionsGate scopes={[SCOPES.exportAccounts]}>
                      <Link
                        to="#"
                        className="btn btn-secondary"
                        onClick={(e) => this.handleExport(e)}
                      >
                        Export
                      </Link>
                    </PermissionsGate>
                  )}

                  <PermissionsGate scopes={[SCOPES.addAccounts]}>
                    <Link
                      to={"/accounts-list-create/new"}
                      className="btn btn-primary"
                    >
                      + &nbsp; Add New
                    </Link>

                    {this.state.selectedRow?.length > 1 && (
                      <>
                        &nbsp;&nbsp;
                        <Link
                          to="#"
                          className="btn btn-secondary"
                          onClick={(e) => this.handleMergeOpen()}
                        >
                          Merge
                        </Link>
                      </>
                    )}
                  </PermissionsGate>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                scopes={[SCOPES.viewAccounts]}
                RenderError={<p>Access Denied</p>}
              >
                <DataTable
                  data={this.state.datas}
                  columns={[
                    {
                      title: "Account ID",
                      type: "text",
                      field: "company_code",
                      render: (rowData) =>
                        rowData?.company_code && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={`/accounts-list-view/${rowData.id}`}
                          >
                            {rowData?.company_code ?? "--"}
                          </Link>
                        ),
                    },

                    {
                      title: "Business Name",
                      type: "text",
                      field: "business_name",
                    },
                    {
                      title: "Category",
                      type: "text",
                      field: "constitution_category_name",
                      render: (rowData) =>
                        rowData.constitution && (
                          <span>
                            {rowData.constitution.constitution_category_name}
                          </span>
                        ),
                    },
                    {
                      title: "Contact",
                      type: "text",
                      field: "customer_name",
                    },
                    {
                      title: "Sector",
                      type: "text",
                      field: "constitution_category_name",
                      render: (rowData) =>
                        rowData.sector && (
                          <span>{rowData.sector.business_sector_name}</span>
                        ),
                    },
                    {
                      title: "Account CRE",
                      type: "text",
                      field: "primary_agent",
                      render: (rowData) => <span>{rowData.account_cre}</span>,
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "company_code",
                    pageNo: this.state.formSearch.page,
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                    selection: true,
                  }}
                  selectProps={this.selectProps}
                  actions={[
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.viewAccounts],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "view",
                            rowActionCLick: this.handleView.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/view.png",
                            tooltip: "View Details",
                          },
                        ]
                      : []),
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.editAccounts],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "view",
                            rowActionCLick: this.handleEdit.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/edit-icon.svg",
                            tooltip: "Edit Details",
                          },
                          {
                            className: "view",
                            rowActionCLick: this.handleSOAdd.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/add.png",
                            tooltip: "Add Service Order",
                          },
                        ]
                      : []),
                  ]}
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        {this.state.openmerge && (
          <MergePopup
            openMergePopup={this.state.openmerge}
            handleMergePopupClose={this.handleMergeClose}
            mergePopup={(parentAccountId) => this.handleMerge(parentAccountId)}
            accountList={this.state.selectedRow}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
  };
};
export default connect(mapStateToProps)(CompanyList);
