import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  get_Compnaycontact,
  update_Compnaycontact,
  getAccountDuplicate
} from "../../../apifunctions";
import { debounce } from "@material-ui/core";
// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";
let serId = "";
class CompanyContactInformation extends Component {
  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.email2ref = React.createRef();
    this.mobilerefRef = React.createRef();
    this.mobile22ref = React.createRef();
    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      duplicates: {},
      formData: {},
    };
  }
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };
  async getData() {
    this.setState({ loader: true });
    try {
      get_Compnaycontact(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: res.contact_info[0],
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  getAccountDuplicateData = debounce((fieldName, value) => {
    getAccountDuplicate({ [fieldName]: value, company_id: this.props.com_Id ? this.props.com_Id : '' })
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          duplicates: {
            [fieldName]: res ?? [],
          },
        });
      });
  }, 1000);
  handleChange = (e) => {
    const companyContactFieldInfo = {
    email: { ref: this.emailRef, errorMessage: "Enter a valid email" },
    email_2: { ref: this.email2ref, errorMessage: "Enter a valid email" },
    mobile: { ref: this.mobilerefRef, errorMessage: "Enter a valid 10 digit mobile number starts with +91" },
    mobile_2: { ref: this.mobile22ref, errorMessage: "Enter a valid 10 digit mobile number starts with +91" },
    };
    const { name, value } = e.target;
    const trimmedValue = value.trim()

    const setValidity = (ref, errorMessage, isMismatch) => {
        ref.current.setCustomValidity(isMismatch ? errorMessage : "");
    };

    if (companyContactFieldInfo[name]) {
        const { ref, errorMessage } = companyContactFieldInfo[name];
        const isMismatch = ref.current.validity.patternMismatch;
        setValidity(ref, errorMessage, isMismatch);
    }

   this.setState({
    formData: {
        ...this.state.formData,
        [name]: trimmedValue,
    },
}, () => {
    const valueToPass = (name === "mobile" || name === "mobile_2") ? encodeURIComponent(trimmedValue) : trimmedValue;
    this.getAccountDuplicateData(name, valueToPass);
});
};
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.formData.email_2 === this.state.formData.email) {
      this.setState({
        snackbarMessage: "Primary and Secondary email should not be same",
        snackbarOpen: true,
        snackbarVariant: "error",
        loader: false,
      });
    } else {
      if (this.state.formData.mobile_2 === this.state.formData.mobile) {
        this.setState({
          snackbarMessage:
            "Primary and Secondary phone number should not be same",
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      } else {
        this.setState({ submited: true });
        // if (this.state.formData.id) {
        update_Compnaycontact(this.props.com_Id, this.state.formData)
          .then((res) => {
            this.setState(
              {
                snackbarMessage: "Updated Successfully",
                snackbarOpen: true,
                submited: false,
                snackbarVariant: "success",
              },
              () => {}
            );
          })
          .catch((err) => {
            this.setState({
              snackbarMessage: err?.response?.data?.error?.message,
              snackbarOpen: true,
              submited: false,
              snackbarVariant: "error",
            });
          });
      }
    }
    // } else {
    //   createService_Benefits(serId, this.state.formData)
    //     .then((res) => res.data)
    //     .then((res) => {
    //       this.setState(
    //         {
    //           formData: res.service_benefits,
    //           snackbarMessage: "Created Successfully",
    //           snackbarOpen: true,
    //           snackbarVariant: "success",
    //         },
    //         () => {}
    //       );
    //     })
    //     .catch((err) => {
    //       this.setState({
    //         snackbarMessage: err?.response?.data?.error?.message,
    //         snackbarOpen: true,
    //         snackbarVariant: "error",
    //       });
    //     });
    // }
  };
  validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  blurChange = (fieldName) => {
    this.setState({
      duplicates: {...this.state.duplicates, [fieldName]: []}
    })
  }
  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Mobile 1 <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    ref={this.mobilerefRef}
                    pattern="\+91\d{10}$"
                    minLength={13}
                    maxLength={13}
                    onKeyPress={(e) => this.validateNumber(e, /[+0-9]|\./)}
                    required
                    value={formData.mobile}
                    name="mobile"
                    placeholder="+910000000000"
                    class="form-control"
                    onChange={this.handleChange}
                    onBlur={() => this.blurChange('mobile')}
                  />
                  {this.state.duplicates.mobile?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {this.state.duplicates.mobile?.map(
                        (duplicate, index) => (
                          <li key={index}>
                            {duplicate.mobile}{" "}
                                - {duplicate.business_name}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Email ID 01 <span className="red">*</span>
                  </label>
                  <input
                    type="email"
                    pattern="^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$"
                    required
                    name="email"
                    ref={this.emailRef}
                    value={formData.email}
                    class="form-control"
                    onChange={this.handleChange}
                    onBlur={() => this.blurChange('email')}
                    placeholder="Enter Email ID 1"
                  />
                  {this.state.duplicates.email?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {this.state.duplicates.email?.map(
                        (duplicate, index) => (
                          <li key={index}>
                            {duplicate.email}{" "}
                                - {duplicate.business_name}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">Website</label>
                  <input
                    type="url"
            
                    name="website"
                    value={formData.website}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder="https://www.google.com"
                  />
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">Mobile 2</label>
                  <input
                    name="mobile_2"
                    value={formData.mobile_2}
                    class="form-control"
                    onChange={this.handleChange}
                    onBlur={() => this.blurChange('mobile_2')}
                    ref={this.mobile22ref}
                    pattern="\+91\d{10}$"
                    minLength={13}
                    maxLength={13}
                    onKeyPress={(e) => this.validateNumber(e, /[+0-9]|\./)}
                    type="text"
                    placeholder="+910000000000"
                  />
                  {this.state.duplicates.mobile_2?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {this.state.duplicates.mobile_2?.map(
                        (duplicate, index) => (
                          <li key={index}>
                            {duplicate.mobile_2}{" "}
                                - {duplicate.business_name}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">Email ID 2</label>
                  <input
                    type="email"
                    pattern="^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$"
                    value={formData.email_2}
                    ref={this.email2ref}
                    name="email_2"
                    class="form-control"
                    onChange={this.handleChange}
                    onBlur={() => this.blurChange('email_2')}
                    placeholder="Enter Email ID 2"
                  />
                  {this.state.duplicates.email_2?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {this.state.duplicates.email_2?.map(
                        (duplicate, index) => (
                          <li key={index}>
                            {duplicate.email_2}{" "}
                                - {duplicate.business_name}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>

                <div className="col-md-12 col-xs-12 text-right">
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(2)}
                  >
                    Back
                  </a>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                    onSubmit={this.handleSubmit}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(5)}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CompanyContactInformation;
