import { InputLabel, MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { add_account_to_customer, getAccounts } from "../../../apifunctions";
import TeamDialogTheme from "./dialogTheme";

const ManageAccount = ({
  open = false,

  custId,
  handleClose = () => {},
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [serviceData, setServiceData] = useState([]);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = { account_id: formData?.id };

    try {
      add_account_to_customer(custId, payload)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Added Successfully");
          setSnackbarOpen(true);

          setTimeout(() => {
            handleClose(true);
            setSubmitting(false);
          }, 1000);
        })
        .catch((err) => {
          setSnackbarVarient("error");
          setSnackbarMessage(err?.response?.data?.error?.message);
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }
  };
  const fetServiceBySearch = (e) => {
   
    getAccounts({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      console.log(res);
      setServiceData(res?.data?.results || []);
    });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    console.log(e, a, b);
    setFormData((f) => ({
      ...f,
      name: e["business_name"],
      id: e["id"],
    }));
  };
  useEffect(() => {
    fetServiceBySearch();
  }, []);
  console.log("hi");
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {"Add  Account"}</h3>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>
                  Account<span className="red">*</span>
                </InputLabel>
                <SelectBox
                  Data={serviceData}
                  id="id"
                  async="true"
                  required
                  value={formData.name ? formData.name : ""}
                  desc="business_name"
                  descId="id"
                  getData={fetServiceBySearch}
                  placeholder="Account"
                  className="form-control"
                  onChange={handleServiceChange}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                disabled={submitting}
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageAccount;
