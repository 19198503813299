import { Grid } from "@material-ui/core";
import moment from "moment";
// import Managetask from "./manage/addtask";
import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";

import { SCOPES } from "../../config/permission-maps";
import PermissionsGate from "../../config/permissiongate";
import CalendarComponent from "../../plugins/calendar.comp";
import CommonLoader from "../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../plugins/dataTable/dataTable";
import DeleteComponent from "../../plugins/delete/newDeleteComponent";
import SearchField from "../../plugins/searchfield";
import SelectBox from "../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../plugins/snackbar-component";
import { getAllServiceList, getApprovalStatusList, getApprovalTypeList, getEmployees, getTaskStatusList } from "./apifunctions";
import { delete_tasks, exportApprovals, searchApprovals } from "./apifunctions";
import ManageApprovalRequestStatus from "./details/order_items/componennts/manageApprovalRequestStatus";

export default function TasksListComponent(props) {

  const [tasksList, settasksList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);

  const [activeItem, setActiveItem] = useState({});
  const [snackbarData, setSnackbarData] = useState({});

  const [formData, setFormData] = useState({
    search: "",
    page: 1,
    approvalTypeId:"",
    amount:"",
    serviceId:"",
    taskId:"",
    requestedById:"",
    statusId:"",
    approvalfromDate: null,
    approvaltoDate:null,
    requestedfromDate:null,
    requestedtoDate:null
  });

  const amountData = [
    {id:1,name:'1000-2000'},
    {id:2,name:'2000-5000'},
    {id:3,name:'5000-10000'},
    {id:4,name:'10000-25000'},
    {id:5,name:'25000-50000'},
    {id:6,name:'50000-100000'},
  ];
  const [approvalTypeData, setApprovalTypeData] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const [taskNameData, setTaskNameData] = useState([]);
  const [requestedByData, setRequestedByData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const history = useHistory();
  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  useEffect(() => {
    getData();
    return () => {};
  }, [formData]);
  const getData = () => {
    setLoader(true);
    try {
      searchApprovals(formData)
        .then((res) => {
          let d = res.results;
          d.forEach((item) => {
            item["assignee_name"] = item?.assignee?.name;
            item["assignee_id"] = item?.assignee?.id;
            item["status_name"] = item?.status?.name;
            item["status_id"] = item?.status?.id;
            item["assignee_is_admin"] = item?.assignee?.is_admin;
          });
          settasksList(d);
          setTotalCount(res.count);
          setLoader(false);
          setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleExport = () => {
    exportApprovals(formData)
      .then((res) => res.data)
      .then((res) => {
        setSnackbarData({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };

  const handleView = (id) => {
    if (id) {
      history.push("/approval-details/" + id);
    }
  };

  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };

  const handleDeleteConfirm = () => {
    delete_tasks(rowData?.id)
      .then((res) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: res.data?.message,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        getData();
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleManageStatus = (data) => {
    setActiveItem({
      id: data?.id,
      status_id: data?.status?.id,
      status_name: data?.status?.name,
    });
    setOpenManage(true);
  };

  const handleDatePicker = (date) => {
    setFormData({...formData, 
        requestedStartDate: date[0],
        requestedEndDate: date[1],
        requestedfromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
        requestedtoDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
       })
  };

  const handleApprovalChange = (e) => {
    setFormData({...formData, 
      approvalTypeName:e.name,
      approvalTypeId:e.id
    })
  };

  const handleAmountChange = (e) => {
    const temp = amountData?.find((item) => item.id == e.target.value );
    setFormData({...formData, amount:temp.name, amountId:e.target.value });
  };

  const handleServiceChange = (e) => {
    setFormData({...formData, 
       serviceName:e.service_name,
       serviceId:e.id,
       });
    };

  const handletaskChange = (e) => {
    setFormData({...formData, 
      taskId:e.id,
      taskName:e.name
    });
  };

  const handleRequestedChange = (e) => {
    setFormData({...formData, 
      requestedByName:e.name,
      requestedById:e.id 
    });
  };

  const handleStatusChange = (e) => {
    setFormData({...formData, 
      statusName:e.name,
      statusId:e.id,
    });
  };

  const handleApprovalDateChange = (date) => {
    setFormData({...formData, 
        approvalStartDate: date[0],
        approvalEndDate: date[1],
        approvalfromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
        approvaltoDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
       });
    };

  const getApprovalData = (e) => {
    getApprovalTypeList(e)
        .then((res) => {
          setApprovalTypeData(res.data)
        });
  };

  const getServiceData = (e) => {
    getAllServiceList(e)
        .then((res) => {
          setServiceName(res.data.services)
        })
  };

  const getTaskData = (e) => {
    getTaskStatusList(e)
    .then((res) => {
      setTaskNameData(res.data)
    })
  };

  const getRequestedData = (e) => {
    getEmployees(e)
    .then((res) => {
      setRequestedByData(res?.results)
    })
  };

  const getStatusData = (e) => {
    getApprovalStatusList(e)
    .then((res) => {
      setStatusData(res.data)
    })
  };

  return (
    <>
      {openManage && (
        <ManageApprovalRequestStatus
          data={activeItem}
          open={openManage}
          handleClose={handleClose}
        />
      )}
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        {/* {openManage && (
        <Managetask
          open={openManage}
          data={rowData}
          handleClose={handleClose}
        />
      )} */}
        <SnackbarComponent
          snackbarOpen={snackbarData?.snackbarOpen}
          snackbarMessage={snackbarData?.snackbarMessage}
          onSnackbarClose={onSnackbarClose}
          variant={snackbarData?.snackbarVariant}
        />
        <DeleteComponent
          opendelete={openDelete}
          submitted={loader}
          handleDeleteClose={handleDeleteClose}
          delete={handleDeleteConfirm}
        />
        <div className="card-body card-dashboard">
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={2} className="table-search">
                <SearchField
                onChange={handleChange}
                value={formData.search}
                handleSearch={handleSearch}
                handleClear={handleClear}
              />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <CalendarComponent
                    selectsRange={true}
                    startDate={formData?.requestedStartDate}
                    endDate={formData?.requestedEndDate}
                    name="date"
                    onChange={handleDatePicker}
                    placeholder="Requested Date"
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={requestedByData}
                    id="id"
                    async="true"
                    value={formData?.requestedByName}
                    desc="name"
                    getData={getRequestedData}
                    placeholder="Requested By"
                    className="form-control"
                    onChange={handleRequestedChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={approvalTypeData}
                    id="id"
                    async="true"
                    value={formData?.approvalTypeName}
                    desc="name"
                    getData={getApprovalData}
                    placeholder="Approval Type"
                    className="form-control"
                    onChange={handleApprovalChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                   <select
                className="form-control"
                name="amount"
                onChange={handleAmountChange}
                value={ formData?.amountId ?? "" }
              >
                <option value="" selected>
                Amount
                </option>
                {amountData &&
                  amountData?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={serviceName}
                    id="id"
                    async="true"
                    value={formData?.serviceName}
                    desc="service_name"
                    getData={getServiceData}
                    placeholder="Service Name"
                    className="form-control"
                    onChange={handleServiceChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={taskNameData}
                    id="id"
                    async="true"
                    value={formData?.taskName}
                    desc="name"
                    getData={getTaskData}
                    placeholder="Task Name"
                    className="form-control"
                    onChange={handletaskChange}
                  />
                </Grid>
               
                <Grid item xs={6} sm={2} className="table-search">
                  <SelectBox
                    Data={statusData}
                    id="id"
                    async="true"
                    value={formData?.statusName ?? ""}
                    desc="name"
                    getData={getStatusData}
                    placeholder="Status"
                    className="form-control"
                    onChange={handleStatusChange}
                  />
                </Grid>
                <Grid item xs={6} sm={2} className="table-search">
                <CalendarComponent
                    selectsRange={true}
                    startDate={formData?.approvalStartDate}
                    endDate={formData?.approvalEndDate}
                    name="date"
                    onChange={handleApprovalDateChange}
                    placeholder="Approval / Rejected Date"
                  />
                </Grid>
                <Grid item align="right" xs={12} sm={6} >
              {totalCount > 0 && (
                <PermissionsGate scopes={[SCOPES.exportPaymentApprovals]}>
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    onClick={(e) => handleExport()}
                  >
                    Export
                  </Link>
                </PermissionsGate>
              )}
            </Grid>

              </Grid>
            </Grid>
           

            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                // errorProps={{ disabled: true }}
                RenderError={() => <p>Access Denied !!</p>}
                scopes={[SCOPES.managePaymentApprovals]}
              >
                <DataTable
                  data={tasksList}
                  columns={[
                    {
                      title: "Order ID",
                      type: "text",

                      render: (rowData) =>
                        rowData?.task?.item?.order?.id && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={
                              "/approval-details/" +
                              rowData?.task?.item?.order?.id
                            }
                          >
                            {rowData?.task?.item?.order?.order_number ?? "--"}
                          </Link>
                        ),
                    },
                    {
                      title: "Title",
                      type: "text",
                      field: "title",
                    },
                    {
                      title: "Amount",
                      type: "amount",
                      field: "amount",
                      // render: (rowData) => (
                      //   <span>{rowData?.amount ?? "--"}</span>
                      // ),
                    },
                    {
                      title: "Status",
                      type: "text",
                      field: "status_name",
                      render: (rowData) => (
                        <>
                          {rowData.status.code === "requested" ? (
                            <div
                              className={`d-flex align-items-center justify-content-between text-primary cursor-pointer`}
                              onClick={() => {
                                handleManageStatus(rowData);
                              }}
                            >
                              <div>
                                {rowData?.status?.name ?? "Select status"}
                              </div>
                              <div>
                                <AiOutlineCaretDown />
                              </div>
                            </div>
                          ) : (
                            <div className="disabled">
                              {rowData?.status?.name}
                            </div>
                          )}
                        </>
                      ),
                    },
                    {
                      title: "Request Date",
                      type: "date",
                      field: "request_date",
                    },
                    {
                      title: "Remarks",
                      type: "text",
                      field: "remark",
                    },
                    {
                      title: "Approval / Rejected Date",
                      type: "date",
                      field: "approved_date",
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "name",
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: handlepagination.bind(this),
                  }}
                  actions={
                    [
                      //   {
                      //     className: "view",
                      //     rowActionCLick: handleView.bind(this),
                      //     iconName: "/app-assets/backend-images/icons/view.png",
                      //     tooltip: "View In Detailed",
                      //   },
                      //   {
                      //     className: "edit",
                      //     rowActionCLick: handleEdit.bind(this),
                      //     iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                      //     tooltip: "Edit",
                      //   },
                      //   {
                      //     className: "delete",
                      //     rowActionCLick: handleDeleteOpen.bind(this),
                      //     iconName:
                      //       "/app-assets/backend-images/icons/delete-icon.svg",
                      //     tooltip: "Delete",
                      //   },
                    ]
                  }
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
