const styles = (theme) => ({
  background: {
    background: theme.palette.primary.light,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default styles;
