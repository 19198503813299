// import { Grid, InputLabel, TextField } from "../../apifunctions";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SelectBox from "../../../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../../../plugins/snackbar-component";
import BasicInfo from "./components/basicInfo";
import EmployeeRole from "./components/roleInfo";
import TeamInfo from "./components/teamInfo";

const EmployeeInfo = (props) => {
  const [formData, setFormData] = useState(props.data);
  const [employeeData, setEmployeeData] = useState({});
  const [detailEdit, setDetailEdit] = useState(false);
  const [leadEdit, setLeadEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const getEmployeesList = (e) => {
  //   getEmployeeList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
  //     setEmployeeData(res?.results || []);
  //   });
  // };
  const getEmployeChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      lead_name: e["name"],
      lead_id: e["id"],
    }));
  };
  const handleChange = (e) => {
    setFormData((f) => ({ ...f, [e.target.name]: e.target.value }));
  };
  const handleManageLeadUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    // setTeamLead(props.id, formData.lead, { employee_id: formData.lead }).then(
    //   () => {
    //     setLeadEdit(false);
    //     setLoading(false);
    //     props.handleRefresh();
    //   }
    // );
  };

  const handleCancel = () => {
    setLeadEdit(false);
    setDetailEdit(false);
    props.handleRefresh();
  };
  // useEffect(() => {
  //   getEmployeesList();
  // }, []);
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  return (
    <div>
      <BasicInfo
        formData={formData?.user || {}}
        edit={props.edit}
        setEdit={props.setEdit}
        mainData={formData}
        refetch={props.handleRefresh}
      />
      <TeamInfo
        formData={formData?.employee_profile?.team || []}
        mainData={formData}
        refetch={props.handleRefresh}
        employee_id={props.id}
      />
      <EmployeeRole
        formData={formData?.group || {}}
        mainData={formData}
        refetch={props.handleRefresh}
      />

      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default EmployeeInfo;
