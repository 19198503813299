import React, { Component } from "react";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
let serId = "";

class FixedPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: this.props.formData,
    };
  }

  handleChange = (e) => {
    const { value, max } = e.target;
    if (e.target.name === "gst_percent") {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]:
            Number(max) >= Number(value) && Number(value) >= 0 ? value : "",
        },
      });
    } else {
      let values = e.target.value;
      if (e.target.value.trim() === "") {
        values = "";
      }

      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]: values,
        },
      });
    }
  };
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  componentWillReceiveProps = () => {
    this.setState({ formData: this.props.formData });
  };
  componentDidMount = () => {
    this.props.onRef(this);
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleData = () => {
    return this.state.formData;
  };
  render() {
    const { formData } = this.state;
    const { masterData } = this.props;
    return (
      <div>
        <div className="col-md-12 col-xs-12">
          <div className="pricing-box">
            <div className="row">
              <div className="col-md-4 col-xs-6">
                <div className="sub-head">
                  <p>Fixed Price</p>
                </div>
                <p className="text">
                  The Default Pricing for this service
                </p>
              </div>
              <div className="col-md-3 col-xs-6 border-l">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label sub-head">
                      Professional Fee
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="professional_fee"
                      value={formData.professional_fee}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label sub-head">
                      Government Fee
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="govt_fee"
                      value={formData.govt_fee}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label sub-head">
                      Discount Percent
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="discount_percent"
                      value={formData.discount_percent}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label sub-head">
                      GST Percentage
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="gst_percent"
                      max="100"
                      value={formData.gst_percent}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-xs-12 text-left">
                    <button className="btn btn-primary" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-xs-6">
                <div className="sub-head col-md-12 col-xs-12">
                  <p>Pricing History</p>
                </div>
                <div className="col-md-12 col-xs-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>01</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>02</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>03</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>04</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>05</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>06</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default FixedPricing;
