import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import styles from "./delete.style";
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const DeletePopup = ({ classes, submitted, opendelete, ...props }) => {
  const handleClose = () => {
    props.handleDeleteClose();
  };

  const handleDelete = () => {
    props.delete();
  };
  return (
    <div>
      <Dialog
        open={opendelete}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="customDialog deleteDialog"
      >
        <DialogTitle disableTypography className={classes.title}>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <h4>Delete</h4>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <p>Are you sure you want to delete?</p>
        </DialogContent>
        <DialogActions className={classes.actionsButton}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            className="btn btn-secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDelete()}
            autoFocus
            disabled={submitted}
            className="btn btn-danger"
            style={{
              backgroundColor: "#f00 !important",
              color: "#fff !important",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(DeletePopup);
