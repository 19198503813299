import { InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";

import styles from "./mergePopup.style";
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const MergePopup = ({
  classes,
  submitted,
  openMergePopup,
  contactList,
  ...props
}) => {
  const [parentId, setParentId] = useState();
  const [parentName, setParentName] = useState("");
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setParentId(undefined);
    setParentName("");
    setErrors({});
    props.handleMergePopupClose();
  };

  const handlemergePopup = () => {
    if (!parentId) {
      setErrors({
        id: "Parent contact is required",
      });
      return;
    }
    setErrors({});
    props.mergePopup(parentId);
  };

  function handleContactChange(e, a, b) {
    setParentId(e[a] ?? undefined);
    setParentName(e[b] ?? "");
  }
  return (
    <div>
      <Dialog
        open={openMergePopup}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="customDialog deleteDialog"
      >
        <DialogTitle disableTypography className={classes.title}>
          <h4>Merge Contacts</h4>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div>
            <p>
              Select a contact to choose as parent
              <span className="red">*</span>
            </p>
            <SelectBox
              Data={contactList}
              id="id"
              required
              value={parentName}
              desc="name"
              descId="id"
              placeholder="Parent Contact"
              className="form-control"
              onChange={handleContactChange}
            />
            {errors?.id && (
              <span className="red" style={{ fontSize: "14px" }}>
                {errors?.id}
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.actionsButton}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            className="btn btn-secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handlemergePopup()}
            autoFocus
            disabled={submitted}
            className="btn btn-primary"
            style={{
              backgroundColor: "#f00 !important",
              color: "#fff !important",
            }}
          >
            Merge
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(MergePopup);
