import moment from "moment";
import React, { Component } from "react";

import CalendarComponent from "../../../../../plugins/calendar.comp";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { add_CompanyPromoKyc, delete_CompanyPromoKyc, get_CompnayPromotorinfo, getEducationalList, getSpecializList, save_CompnayPromotorinfo, update_CompnayPromotorinfo } from "../../../apifunctions";
import AddressKyc from "./promotorKyc.comp";

let serId = "";
let key = 0;
class CompanyPromoterInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      mainId: {},
      specData: [],
      snackbarVariant: "",
      eduData: [],
      loader: false,
      snackbarMessage: "",
      formData: [],
    };
  }
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };
  async getData() {
    let data = [];

    let kyctypes = [];

    this.setState({ loader: true });
    try {
      get_CompnayPromotorinfo(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          data = res.promoters;
          if (data && data.length !== 0) {
            data.forEach((item) => {
              item["kyctypes"] = [
                ...JSON.parse(localStorage.getItem("masterData"))
                  .promoter_kyc_types,
              ];
              item["address_1"] = item.address[0]
                ? item.address[0].address_1
                : "";
              item["status"] = item.status ? item.status.id : "";
              item["role"] = item.role ? item.role.id : "";
              item["preferred_language"] = item.preferred_language
                ? item.preferred_language.language_name
                : "";
              item["address_2"] = item.address[0]
                ? item.address[0].address_2
                : "";
              item["country"] = item.address[0] ? item.address[0].country : "";
              item["state"] = item.address[0] ? item.address[0].state : "";
              item["pincode"] = item.address[0] ? item.address[0].pin_code : "";
              item["city"] = item.address[0] ? item.address[0].city : "";
              item["email"] = item.customer_info
                ? item.customer_info.email
                : "";
              item["mobile"] = item.customer_info
                ? item.customer_info.mobile
                : "";
              item["name"] = item.customer_info
                ? item.customer_info.username
                : "";
              item["specialization_name"] =
                item.qualification[0] && item.qualification[0].specialization
                  ? item.qualification[0].specialization.specialization_name
                  : "";
              item["specialization"] =
                item.qualification[0] && item.qualification[0].specialization
                  ? item.qualification[0].specialization.id
                  : "";
              item["qualification_name"] =
                item.qualification[0] &&
                item.qualification[0].educational_qualification
                  ? item.qualification[0].educational_qualification
                      .qualification_name
                  : "";
              item["qualification"] =
                item.qualification[0] &&
                item.qualification[0].educational_qualification
                  ? item.qualification[0].educational_qualification.id
                  : "";

              item["customer_info"] = "";
              item["address"] = "";

              if (!item.kyc || item.kyc.length === 0) {
                item["kyc"] = [{ promoter: item.id }];
              } else {
                item.kyc.forEach((kyc) => {
                  item.kyctypes.forEach((types) => {
                    if (
                      kyc.document_type &&
                      kyc.document_type.id === types.id
                    ) {
                      types["checked"] = true;
                    }
                  });
                });
              }
            });
          } else {
            data = [{ company: this.props.com_Id, country: "India" }];
          }

          this.setState(
            {
              formData: [],
              loader: false,
            },
            () => {
              this.setState({ formData: data });
              key = key + 1;
            }
          );
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleDatePicker = (e, index) => {
    if (e.value) {
      const { formData } = this.state;
      formData[index] = {
        ...formData[index],
        [e.name]: e.value ? moment(e.value).format("YYYY-MM-DD") : "",
      };
      this.setState({
        formData,
      });
    }
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleUpdate = (e, ind) => {
    e.preventDefault();
    const { formData } = this.state;
    formData[ind]["submited"] = true;
    this.setState({ formData });
    let data = {};
    data = this.state.formData[ind];
    update_CompnayPromotorinfo(this.props.com_Id, data.id, data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        formData[ind]["submited"] = false;
        this.setState({
          formData,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleSubmit = (event, ind) => {
    event.preventDefault();
    const { formData } = this.state;
    formData[ind]["submited"] = true;
    this.setState({ formData });
    let data = {};

    data = formData[ind];

    save_CompnayPromotorinfo(this.props.com_Id, data)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        formData[ind]["submited"] = false;
        this.setState({
          formData,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleChange = (e, index) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    const { formData } = this.state;
    formData[index] = { ...formData[index], [e.target.name]: value };
    this.setState({
      formData,
    });
  };

  handleAddNew = () => {
    const { formData } = this.state;
    formData.push({ company: this.props.com_Id, country: "India" });
    this.setState({ formData });
  };
  handleCancel = (index) => {
    const { formData } = this.state;

    formData.splice(index, 1);
    this.setState({ formData });
  };
  handleEdit = (index) => {
    const { formData } = this.state;
    formData[index]["editble"] = true;
    this.setState({ formData });
  };
  handleDisableEdit = (index) => {
    const { formData } = this.state;
    formData[index]["editble"] = false;
    this.setState({ formData });
  };
  handleKycUpload = (e, id, data) => {
    e.preventDefault();

    const form = new FormData();
    form.append("frontpage", data.frontpage);
    form.append("backpage", data.backpage);
    form.append("number", data.number);
    form.append("document_type", data.document_type);
    form.append("frontpage_name", data.frontpage_name);
    form.append("document_name", data.document_name);
    form.append("backpage_name", data.backpage_name);
    form.append("promoter", id);
    add_CompanyPromoKyc(this.props.com_Id, id, form)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleAddnewKyc = (e, id, id2) => {
    const { formData } = this.state;
    formData[id].kyc.push({ address: id2, addnew: true });
    this.setState({ formData });
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleKycDelete = (proId, kycId) => {
    this.setState({
      opendelete: true,
      mainId: { proId: proId, kycId: kycId },
    });
  };
  handleDelete = () =>
    delete_CompanyPromoKyc(
      this.props.com_Id,
      this.state.mainId.proId,
      this.state.mainId.kycId
    ).then((res) => {
      this.getData();
    });

  
  handleChildCancel = (ind, child) => {
    const { formData } = this.state;
    formData[ind].kyc.splice(child, 1);

    this.setState({ formData });
  };
  handleSelectChange = (e, id, name, index) => {
    const { formData } = this.state;
    formData[index] = { ...formData[index], [id]: e.id, [name]: e[name] };
    this.setState({
      formData,
    });
  };

  handleDataChange = (value, index, reason) => {
  
      this.getEdData(value);
  
  };
  getEdData = (value) => {
    getEducationalList(value)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          eduData: res.qualifications,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handlespecialData = (value, index) => {
 
      this.getspData(value);
    
  };
  getspData = (value) => {
    getSpecializList(value)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          specData: res.specialization,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  render() {
    const { formData, loader, eduData, specData } = this.state;
    const { masterData, coun_state } = this.props;
    let addnew = "";
    if (formData && formData.length !== 0) {
      addnew = formData.length;
    }

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <div className="card-content">
            {formData &&
              formData.map((item, index) => (
                <div key={index}>
                  <form
                    className="edit-form"
                    autoComplete="off"
                    onSubmit={(e) =>
                      item.editble
                        ? this.handleUpdate(e, index)
                        : this.handleSubmit(e, index)
                    }
                  >
                    <div className="row">
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Role <span className="red">*</span>
                        </label>
                        <select
                          onChange={(e) => this.handleChange(e, index)}
                          className="form-control"
                          name="role"
                          value={item.role}
                          disabled={item.id && !item.editble}
                          required
                          id="role"
                        >
                          <option value="">Select</option>
                          {masterData &&
                            masterData.promoter_roles.map((role) => (
                              <option value={role.id}>{role.name}</option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Status In Company <span className="red">*</span>
                        </label>
                        <select
                          name="status"
                          disabled={item.id && !item.editble}
                          required
                          id="status"
                          value={item.status}
                          onChange={(e) => this.handleChange(e, index)}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          {masterData &&
                            masterData.promoter_status.map((role) => (
                              <option value={role.id}>{role.name}</option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Name <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          disabled={item.id && !item.editble}
                          id="name"
                          name="name"
                          value={item.name}
                          onChange={(e) => this.handleChange(e, index)}
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Mobile <span className="red">*</span>
                        </label>
                        <input
                          type="tel"
                          required
                          minlength="13"
                          maxlength="13"
                          pattern="/(+)\d{12}/"
                          id="mobile"
                          placeholder="+91 0000 000 000"
                          name="mobile"
                          disabled={item.id && !item.editble}
                          value={item.mobile}
                          class="form-control"
                          onChange={(e) => this.handleChange(e, index)}
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Email 1<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          id="email"
                          name="email"
                          disabled={item.id && !item.editble}
                          class="form-control"
                          value={item.email}
                          pattern="^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$"
                          onChange={(e) => this.handleChange(e, index)}
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Date of Birth <span className="red">*</span>
                        </label>
                        <CalendarComponent
                          label="Date of Birth"
                          required
                          id="date_of_birth"
                          disabled={item.id && !item.editble}
                          onChange={(e) => this.handleDatePicker(e, index)}
                          name="date_of_birth"
                          selected={item.date_of_birth}
                          class="form-control"
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Country <span className="red">*</span>
                        </label>
                        <select
                          required
                          onChange={(e) => this.handleChange(e, index)}
                          className="form-control"
                          value={item.country}
                          id="country"
                          disabled={item.id && !item.editble}
                          name="country"
                        >
                          <option value="country">India</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          State <span className="red">*</span>
                        </label>
                        <select
                          type="text"
                          disabled={item.id && !item.editble}
                          required
                          value={item.state}
                          id="state"
                          name="state"
                          class="form-control"
                          onChange={(e) => this.handleChange(e, index)}
                        >
                          <option value="">select</option>
                          {coun_state &&
                            coun_state.map((state) => (
                              <option value={state.name}>{state.name}</option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          City <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          name="city"
                          id="city"
                          disabled={item.id && !item.editble}
                          value={item.city}
                          class="form-control"
                          onChange={(e) => this.handleChange(e, index)}
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Pincode <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          id="pincode"
                          required
                          name="pincode"
                          disabled={item.id && !item.editble}
                          class="form-control"
                          value={item.pincode}
                          onChange={(e) => this.handleChange(e, index)}
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Preferred Language <span className="red">*</span>
                        </label>
                        <select
                          id="preferred_language"
                          required
                          onChange={(e) => this.handleChange(e, index)}
                          value={item.preferred_language}
                          name="preferred_language"
                          className="form-control"
                          disabled={item.id && !item.editble}
                        >
                          <option value="">Select</option>
                          {masterData &&
                            masterData.languages.map((lang) => (
                              <option value={lang.language_name}>
                                {lang.language_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Education Qualification <span className="red">*</span>
                        </label>
                        <SelectBox
                          Data={eduData}
                          async={true}
                          id="id"
                          value={item.qualification_name}
                          disabled={item.id && !item.editble}
                          desc="qualification_name"
                          getData={(val, reason=undefined) => this.handleDataChange(val, index, reason)}
                          placeholder="Category"
                          className="form-control"
                          descId="educational_qualification"
                          onChange={(e, id, name) =>
                            this.handleSelectChange(e, id, name, index)
                          }
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Specialization <span className="red">*</span>
                        </label>
                        <SelectBox
                          Data={specData}
                          async={true}
                          id="id"
                          value={item.specialization_name}
                          desc="specialization_name"
                          getData={(val) => this.handlespecialData(val, index)}
                          placeholder="Category"
                          className="form-control"
                          disabled={item.id && !item.editble}
                          descId="specialization"
                          onChange={(e, id, name) =>
                            this.handleSelectChange(e, id, name, index)
                          }
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Address Line 1 <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          id="address_1"
                          onChange={(e) => this.handleChange(e, index)}
                          name="address_1"
                          value={item.address_1}
                          class="form-control"
                          disabled={item.id && !item.editble}
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <label className="control-label">
                          Address Line 2 <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          disabled={item.id && !item.editble}
                          id="address_2"
                          name="address_2"
                          value={item.address_2}
                          class="form-control"
                          onChange={(e) => this.handleChange(e, index)}
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-12 col-xs-12  text-right">
                        {addnew === index + 1 && item.id && !item.editble && (
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={(e) => this.handleAddNew()}
                          >
                            + &nbsp;&nbsp; ADD NEW PROMOTER
                          </button>
                        )}

                        {item.id && !item.editble && (
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) => this.handleEdit(index)}
                          >
                            Edit
                          </button>
                        )}
                        {item.id && item.editble && (
                          <React.Fragment>
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={(e) => this.handleDisableEdit(index)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={item.submited}
                            >
                              Update
                            </button>
                          </React.Fragment>
                        )}
                        {!item.id && (
                          <React.Fragment>
                            {formData.length > 1 && (
                              <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={(e) => this.handleCancel(index)}
                              >
                                Cancel
                              </button>
                            )}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={item.submited}
                            >
                              Save
                            </button>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </form>

                  {item.id && (
                    <React.Fragment>
                      {item.kyc &&
                        item.kyc.map((kyc, chilInd) => (
                          <AddressKyc
                            data={kyc}
                            key={item.id + chilInd}
                            chilInd={chilInd}
                            index={index}
                            kycLen={item.kyc.length}
                            typeCode={
                              kyc.document_type ? kyc.document_type.code : ""
                            }
                            handleAddnewKyc={this.handleAddnewKyc}
                            kyctypes={item.kyctypes}
                            handleChildCancel={this.handleChildCancel}
                            item={item}
                            handleKycDelete={this.handleKycDelete}
                            handleKycUpload={this.handleKycUpload}
                          />
                        ))}
                    </React.Fragment>
                  )}
                  <div className="row m-top">
                    <div className="linedivider"></div>
                  </div>
                </div>
              ))}
            <div className="row edit-form">
              <div className="col-md-12 col-xs-12 text-right">
                <a
                  href="#/"
                  className="btn btn-secondary"
                  onClick={(e) => this.props.handleTabView(5)}
                >
                  Back
                </a>

                <a
                  href="#/"
                  className="btn btn-secondary"
                  onClick={(e) => this.props.handleTabView(7)}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CompanyPromoterInformation;
