import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { Component } from "react";

import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SearchField from "../../../../../plugins/searchfield";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  addToService,
  deleteServiceCheckLists,
  getCheckList,
  getServiceCheckLists,
  updateServiceCheckLists,
} from "../../../apifunctions";

let addCheckListToService = [],
  updateObj = {},
  delData = {};

class ServiceCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      formSearch: {
        page: 1,
        search: "",
      },
      deleteCheckList: [],
      datas: [],
      selectedData: [],
      loader: false,
      serviceCheckLists: [],
      opendelete: false,
      serId: this.props.ser_Id,
    };
  }

  componentWillReceiveProps = () => {
    this.setState({ serId: this.props.ser_Id });
  };

  async componentDidMount() {
    try {
      if (this.props.ser_Id) {
        await this.getData(this.props.ser_Id);
      }
    } catch (err) {}
  }

  async getData(serId) {
    this.setState({ loader: true });
    try {
      getCheckList(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          const checklists = res.results;

          this.handlechecklist(checklists, serId);
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }

  handlechecklist = (checklist, serId) => {
    getServiceCheckLists(this.state.formSearch, serId)
      .then((res) => res.data)
      .then((res) => {
        const serviceCheckLists = res.service_checklists;

        if (serviceCheckLists.length !== 0) {
          let filteredCheckLists = checklist.filter(
            (val) =>
              !serviceCheckLists.some((el) => el.check_list.id === val.id)
          );

          this.setState({
            deleteCheckList: [],
            datas: filteredCheckLists,
            serviceCheckLists: serviceCheckLists,
            loader: false,
          });
        } else {
          this.setState({
            datas: checklist,
            serviceCheckLists: [],
            deleteCheckList: [],
            loader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          datas: checklist,
          deleteCheckList: [],
          serviceCheckLists: [],
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };

  handleAdd = (checkListData) => {
    this.setState({ selectedData: checkListData });
  };

  handleAddToService = (e) => {
    e.preventDefault();
    if (this.state.selectedData.length !== 0) {
      addCheckListToService = [];
      this.state.selectedData.forEach((item, index) => {
        addCheckListToService.push({
          sort_order:
            this.state.serviceCheckLists.length > 0
              ? this.state.serviceCheckLists.length + index
              : index,
          customer_actioned: true,
          employee_actioned: false,
          payment_prompt: true,
          show_for_customer: true,
          check_list: item.id,
        });
      });

      addToService(addCheckListToService, this.state.serId)
        .then((res) => {
          this.getData(this.state.serId);

          this.setState({
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            selectedData: [],
            snackbarVariant: "success",
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } else {
      this.setState({
        snackbarMessage: "Please select atleast one item to add ",
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };

  handleServiceCheckBox = (e, row, index) => {
    let checked = e.target.checked;
    let name = e.target.name;
    this.state.serviceCheckLists.forEach((item, ind) => {
      if (ind === index) {
        if (name === "customer_actioned") {
          item[name] = checked;
          item["employee_actioned"] = !checked;
        } else if (name === "employee_actioned") {
          item[name] = checked;
          item["customer_actioned"] = !checked;
        }
        item["edit"] = true;
        item[name] = checked;
      }
    });
    this.setState(
      {
        serviceCheckLists: this.state.serviceCheckLists,
      },
      () => {
        this.updateServiceCheckBox(row.id, this.state.serviceCheckLists);
      }
    );
  };

  updateServiceCheckBox = (rowId, newData) => {
    console.log(newData);
    let update_object = {};

    newData
      .filter((item) => item.id === rowId)
      .map((item, ind) => {
        if (item.edit) {
          delete item.edit;
        }
        update_object = {
          sort_order: item.sort_order,
          customer_actioned: item.customer_actioned,
          employee_actioned: item.employee_actioned,

          payment_prompt: item.payment_prompt,
          show_for_customer: item.show_for_customer,
          check_list: item.check_list.id,
        };
      });

    updateServiceCheckLists(this.state.serId, rowId, update_object)
      .then((res) => {
        this.setState({
          snackbarMessage: "Updated Successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage:
            err?.response?.data?.error?.message?.error ||
            "Something went wrong",
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  handleDeleteRow = (data) => {
    this.setState({ deleteCheckList: data });
  };

  handleDeleteFromService = () => {
    if (this.state.deleteCheckList.length !== 0) {
      this.handleDeleteOpen();
    } else {
      this.setState({
        snackbarMessage: "Please select an item to delete ",
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };

  handleDeleteOpen = () => {
    this.setState({
      opendelete: true,
    });
    this.state.deleteCheckList.forEach((obj, ind) => {
      delData["service_checklist_" + ind] = obj.id;
    });
  };

  handleDeleteClose = () => {
    delData = {};
    this.state.serviceCheckLists.forEach((item, ind) => {
      if (item.checked) {
        item["checked"] = false;
      }
    });
    this.setState({
      opendelete: false,
      serviceCheckLists: this.state.serviceCheckLists,
      deleteCheckList: [],
    });
  };

  handleDelete = () =>
    deleteServiceCheckLists(this.state.serId, delData).then(() => {
      this.getData(this.state.serId);
    });

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData(this.state.serId);
      }
    );
  };
  onDragEnd = (data, index, source_index) => {
    console.log("drag end data", data);
    console.log("drag end index", index);
    data[index].sort_order = index;
    this.updateServiceCheckBox(data[index].id, data);
  };
  render() {
    const { loader, formSearch, deleteCheckList, selectedData } = this.state;
    return (
      <div className="card-content create-content collapse show">
        <div className="edit-form ">
          <div className="card-content">
            <div className="row">
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-xs-12 ">
                    <div className="card-title">
                      <h4>CheckLists</h4>
                    </div>
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <SearchField
                      onChange={this.handleChange}
                      value={formSearch.search}
                      handleSearch={this.handleSearch}
                      handleClear={this.handleClear}
                    />
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <DataTable
                      data={this.state.datas}
                      columns={[
                        {
                          title: "CheckList",
                          type: "text",
                          field: "name",
                        },
                      ]}
                      selectProps={this.handleAdd.bind(this)}
                      options={{
                        //sort: true,
                        sortvalue: "",
                        selection: true,
                        //pageNo: formSearch.page,
                        //pagination: true,
                        //totalCount: totalCount,
                        //    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                        //handlepagination: this.handlepagination.bind(this),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-xs-12">
                <div className="flexBtnItem">
                  <div className="item">
                    <button
                      className="actionButton"
                      type="button"
                      onClick={this.handleAddToService}
                    >
                      <AddIcon />
                    </button>
                  </div>
                  <div className="item">
                    <button
                      className="remove actionButton"
                      type="button"
                      onClick={this.handleDeleteFromService}
                    >
                      <RemoveIcon />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-xs-12 card-title">
                    <h4>Added Service CheckLists</h4>
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <DataTable
                      data={this.state.serviceCheckLists}
                      columns={[
                        {
                          title: "Name",
                          type: "text",
                          align: "center",
                          render: (row) => <span>{row.check_list.name}</span>,
                        },
                        {
                          title: "Show for Customer",
                          type: "text",
                          render: (row, index) => (
                            <span>
                              <Checkbox
                                disableRipple="true"
                                checked={row.show_for_customer}
                                name="show_for_customer"
                                onClick={(e) =>
                                  this.handleServiceCheckBox(e, row, index)
                                }
                              />
                            </span>
                          ),
                        },
                        {
                          title: "Customer Action",
                          type: "text",
                          render: (row, index) => (
                            <span>
                              <Checkbox
                                checked={row.customer_actioned}
                                name="customer_actioned"
                                onClick={(e) =>
                                  this.handleServiceCheckBox(e, row, index)
                                }
                              />
                            </span>
                          ),
                        },
                        {
                          title: "Employee Action",
                          type: "text",
                          render: (row, index) => (
                            <span>
                              <Checkbox
                                checked={row.employee_actioned}
                                name="employee_actioned"
                                onClick={(e) =>
                                  this.handleServiceCheckBox(e, row, index)
                                }
                              />
                            </span>
                          ),
                        },
                      ]}
                      selectProps={this.handleDeleteRow.bind(this)}
                      onDragEnd={this.onDragEnd}
                      options={{
                        sort: false,
                        sortvalue: "",
                        selection: true,
                        draggable: true,

                        //pageNo: formSearch.page,
                        //pagination: true,
                        //totalCount: totalCount,
                        //    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                        //handlepagination: this.handlepagination.bind(this),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
export default ServiceCheckList;
