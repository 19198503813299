import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./sub-menu.css";
import PermissionsGate from "../../config/permissiongate";

class NavigationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleTabView, tabData, currentTab } = this.props;

    return (
      <div className="card-header">
        <ul>
          {tabData.map((item) => (
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <></>}
              scopes={item.permission ? [item.permission] : []}
            >
              <li
                key={item.id}
                className={item.id === currentTab ? " active" : ""}
                onClick={(e) => handleTabView(item.id)}
              >
                <a>{item.name}</a>
              </li>
            </PermissionsGate>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(NavigationTab);
