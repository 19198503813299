import React from "react";
import BreadCrumb from "../layout/breadcrumb";
import NavigationSubMenu from "../layout/sub-menu";
import PermissionsGate from "../config/permissiongate";
import ComponentRouter from "./componentRouter";

function ContentWrapperComponent(props) {
  const { component: Component } = props;
  return (
    <div className="app-content content">
      <div className="content-wrapper">
        <PermissionsGate
          RenderError={() => <p>Route Access Denied !!</p>}
          scopes={props.scopes}
        >
          <BreadCrumb type={props.type} />
          <div className>
            <div className="content-body">
              <section id="configuration">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <NavigationSubMenu type={props.type} />

                      {Component ? (
                        <Component
                          {...props}
                          routes={props.routes}
                          type={props.type}
                        />
                      ) : (
                        <ComponentRouter
                          {...props}
                          routes={props.routes}
                          type={props.type}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </PermissionsGate>
      </div>
    </div>
  );
}

export default ContentWrapperComponent;
