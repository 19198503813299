import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  createService_video,
  delService_video,
  getService_video,
} from "../../../apifunctions";

class QuickVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      loader: false,
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: {},
      serId: this.props.ser_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({ serId: this.props.ser_Id });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  componentDidMount = () => {
    if (this.props.ser_Id) {
      this.getproData(this.props.ser_Id);
    }
  };
  async getproData(serId) {
    this.setState({ loader: true });
    try {
      getService_video(serId)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.service_videos,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            loader: false,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    createService_video(this.props.ser_Id, this.state.formData)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            formData: {},
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            this.getproData(this.props.ser_Id);
          }
        );
      })
      .catch((err) => {
        this.setState({
          submited: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    delService_video(this.props.ser_Id, this.state.mainId).then(() => {
      this.getproData(this.props.ser_Id);
    });
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, loader, submited } = this.state;
    const { masterData } = this.props;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Add Service Video</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Video Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="video_name"
                        value={formData.video_name ? formData.video_name : ""}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Video Language</label>
                      <select
                        className="form-control"
                        required
                        value={
                          formData.video_language ? formData.video_language : ""
                        }
                        onChange={this.handleChange}
                        name="video_language"
                      >
                        <option>Select Language</option>
                        {masterData.languages &&
                          masterData.languages.map((item) => (
                            <option value={item.language_name}>
                              {item.language_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Video URL</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={formData.vide_url ? formData.vide_url : ""}
                        onChange={this.handleChange}
                        name="vide_url"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12 text-left">
                      <button
                        className="btn btn-primary"
                        disabled={submited}
                        type="submit"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Uploaded Videos</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <DataTable
                        data={this.state.datas}
                        columns={[
                          // {
                          //   title: "Sl",
                          //   type: "text",
                          //   field: "sl_no",
                          // },
                          {
                            title: "Video Name",
                            type: "text",
                            field: "video_name",
                          },
                          {
                            title: "Video Language",
                            type: "text",
                            field: "video_language",
                          },
                          {
                            title: "Video URL",
                            type: "text",
                            field: "vide_url",
                          },
                        ]}
                        options={{
                          sort: false,
                          sortvalue: "step",
                          // pageNo: this.state.formSearch.page,
                          // pagination: true,
                          // totalCount: totalCount,
                          pagesize: this.state.pagesize,
                          // handlepagination: this.handlepagination.bind(this),
                        }}
                        actions={[
                          {
                            className: "delete",
                            rowActionCLick: this.handleDeleteOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/delete-icon.svg",
                            tooltip: "Delete",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(11)}
              >
                Back
              </a>
              {/* <button className="btn btn-primary" type="submit">
                save
              </button> */}
            </div>
          </form>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default QuickVideo;
