import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import { deleteParameterOptionsRow, getParameterList, parameter_options, searchParameterOptionsData } from "../apifunctions";

class ParameterOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        parameter_name__id: "",
        page: 1,
      },
      parameterList: [],
      // searchParam: "",
      // tableConfig: {},
      pagesize: 0,
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
      //await this.handlepagination()

      this.loadParameterList();
    } catch (err) {}
  }

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    deleteParameterOptionsRow(this.state.mainId).then(() => {
      this.getData();
    });
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };

  handlepagination(val) {
    let query = "";
    if (val) {
      query = parameter_options + val;
    } else {
      query = parameter_options;
    }
    this.getData(query);
  }
  // this.setState(
  //   {
  //     formSearch: {
  //       ...this.state.formSearch,
  //       page: val,
  //     },
  //   },
  //   () => {
  //     this.getData();
  //   }
  // );
  /////////////////////////////////////////////
  /*async getData(query) {

  //
  this.setState({ loader: true });
  try {
    apis.getDatas(query)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          datas: res.results,
          totalCount: res.count,
          pagesize: res.page_size,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  } catch (err) {}
}*/
  //////////////////////////////////////////////////////
  async getData() {
    this.setState({ loader: true });
    try {
      searchParameterOptionsData(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          //this.handleSearch();
          this.getData();
        }
      }
    );
  };
  ////////////////////////////////////////
  handleClear = (name) => {
    //
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  async loadParameterList() {
    try {
      getParameterList()
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            parameterList: res.parameters,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }

  render() {
    const { loader, parameterList, formSearch, totalCount } = this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            {/* <div className="position-relative">
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="search"
                  value={formSearch.search}
                  className="form-control"
                  placeholder="Search"
                />
                <button
                  onClick={(e) => this.getData()}
                  className="btn-transparent"
                >
                  <img src="/app-assets/backend-images/icons/search-icon.svg" />
                </button>
              </div> */}

            <Grid item xs={6} sm={2}>
              <select
                className="form-control"
                name="parameter_name__id"
                value={
                  formSearch.parameter_name__id
                    ? formSearch.parameter_name__id
                    : ""
                }
                onChange={this.handleChange}
              >
                <option value="" selected>
                  Parameter Name
                </option>
                {parameterList &&
                  parameterList.map(({ id, parameter_name }, index) => (
                    <option value={id}>{parameter_name}</option>
                  ))}
              </select>
            </Grid>
            <Grid align="right" item xs={6} sm={8}>
              <Link
                to={`${this.props.match.path}-create/new`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Display Name",
                    type: "text",
                    field: "display_name",
                    render: (rowData) =>
                      rowData?.display_name && (
                        <Link
                          className="text-primary cursor-pointer"
                          to={`${this.props.match.path}-edit/` + rowData.id}
                        >
                          {rowData?.display_name ?? "--"}
                        </Link>
                      ),
                  },
                  {
                    title: "Portal Name",
                    type: "text",
                    field: "admin_portal_name",
                  },
                  {
                    title: "Parameter Name",
                    type: "text",
                    field: "parameter_name",
                    render: (rowData) => (
                      <span>
                        {rowData.parameter_name
                          ? rowData.parameter_name.parameter_name
                          : ""}
                      </span>
                    ),
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "admin_portal_name",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: this.state.pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit",
                  },
                  {
                    className: "delete",
                    rowActionCLick: this.handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default ParameterOptions;
