import { Grid, InputLabel, TextField } from "@material-ui/core";
import React, { useState } from "react";

const OrderInfo = ({ data, id, handleRefresh }) => {
  const [formData, setFormData] = useState({ ...data });

  return (
    <div>
      {/* <form onSubmit={manageTeamDetails}> */}
      <Grid container spacing={2} className="pb-3 customFields">
        <Grid item xs={12}>
          <div className=" p-0 m-0">
            <div className="fieldBox d-flex align-items-center justify-content-between">
              <div className="details-box">
                <div className="detail-box border-r">
                  <div className="">
                    <p className="name">Basic Info</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <InputLabel>Account</InputLabel>
          <TextField
            required
            disabled
            value={formData.account_name ? formData.account_name : ""}
            fullWidth
            placeholder="Account"
            className="customFieldInput"
          />
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <InputLabel>Contact</InputLabel>
          <TextField
            disabled
            required
            value={formData.contact_name ? formData.contact_name : ""}
            fullWidth
            placeholder="Contact"
            className="customFieldInput"
          />
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <InputLabel>Status</InputLabel>
          <TextField
            disabled
            required
            value={formData.status_name ? formData.status_name : ""}
            fullWidth
            placeholder="Status"
            className="customFieldInput"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Grand Total</InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            required
            disabled
            name="grand_total"
            className="customFieldInput"
            value={formData.grand_total ? formData.grand_total : ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>External id</InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            required
            name="external_id"
            value={formData.external_id ? formData.external_id : ""}
            disabled
            className="customFieldInput "
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Remarks</InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            required
            disabled
            name="order_comment"
            value={formData.order_comment ? formData.order_comment : ""}
            className="customFieldInput textareaField"
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderInfo;
