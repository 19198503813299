import { Button, Grid } from "@material-ui/core";
// import Managetask from "./manage/addtask";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils.js";

import PermissionsGate from "../../config/permissiongate";
import CommonLoader from "../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../plugins/dataTable/dataTable";
import SearchField from "../../plugins/searchfield";
import SnackbarComponent from "../../plugins/snackbar-component";
import { searchExport,copy_export } from "./apifunctions";

export default function ExportListComponent(props) {
	const [formSearch, setFormSearch] = useState({
		search: "",
		page: 1,
	});
	const [exportsList, setexportsList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [pagesize, setPageSize] = useState(0);
	const [rowData, setRowData] = useState({});
	const [totalCount, setTotalCount] = useState(0);
	const [snackbarData, setSnackbarData] = useState({});
	const history = useHistory();
	useEffect(() => {
		getData();

		return () => {};
	}, []);
	const handleClose = (flag = false) => {
		setRowData({});
		if (flag) {
			getData();
		}
	};
	const getData = () => {
		setLoader(true);

		try {
			searchExport(formSearch)
				.then((res) => {
					let d = res.results;
					d.forEach((item) => {
						item["assignee_name"] = item?.assignee?.name;
						item["assignee_id"] = item?.assignee?.id;
						item["status_name"] = item?.status?.name;
						item["status_id"] = item?.status?.id;
						item["assignee_is_admin"] = item?.assignee?.is_admin;
					});
					setexportsList(d);
					setTotalCount(res.count);
					setLoader(false);
					setPageSize(res.page_size);
				})
				.catch((err) => {
					setLoader(false);
					setSnackbarData({
						snackbarMessage: err.message,
						snackbarOpen: true,
						snackbarVariant: "error",
					});
				});
		} catch (err) {
			setLoader(false);
		}
	};

	const handleChange = () => {};

	const handleSearch = () => {};

	const handleClear = () => {};

	const handlepagination = () => {};

	const downloadFile = (file) => {
		if (file) {
			let link = document.createElement("a");
			link.href = file;
			link.setAttribute("download", "account.xlsx");
			link.click();
		}
	};
	const onSnackbarClose = () => {
		setSnackbarData({});
	};

  const copyExport = async (exportId) => {
		copy_export(exportId)
		.then(() => {
			setLoader(false);
			setSnackbarData({
				snackbarMessage:"Retrying export",
				snackbarOpen: true,
				snackbarVariant: "success",
			});
			getData();
		})
		.catch((err) => {
			setLoader(false);
			setSnackbarData({
				snackbarMessage: err.message,
				snackbarOpen: true,
				snackbarVariant: "error",
			});
		});
  };
	const columnsWithoutAction = [
		{
			title: "Request Date",
			type: "date",
			field: "datetime_created",
		},
		{
			title: "Requested By",
			type: "text",
			render: (rowData) =>
				rowData?.requested_by?.name,
		},
		{
			title: "Report Type",
			type: "text",
			field: "model",
		},
		{
			title: "Report Status",
			type: "text",
			field: "upload_status",
		},
		// {
		// 	title: "Report",
		// 	type: "text",
		// 	render: (rowData) =>
		// 		rowData?.document && (
		// 			<Link
		// 				className="text-primary cursor-pointer"
		// 				to="#"
		// 				onClick={() =>
		// 					downloadFile(
		// 						rowData?.document,
		// 					)
		// 				}
		// 			>
		// 				Download
		// 			</Link>
		// 		),
		// },
	];

	const hasErrors = exportsList.some((rowData) => rowData.upload_status === "Error");

	const columns =  [
				...columnsWithoutAction,
				{
					title: "Action",
					type: "text",
					render: (rowData) =>
					(rowData?.document ? (
							<Link
								className="text-primary cursor-pointer"
								to="#"
								onClick={() =>
									downloadFile(
										rowData?.document,
									)
								}
							>
								Download
							</Link>
					) : (
						(!rowData?.document && rowData.upload_status === "Error") ? (
							<Link
								className="text-primary cursor-pointer"
								to="#"
								onClick={() => copyExport(rowData.id)}
							>
								Retry
							</Link>
						) : (
							<span> -- </span>
						))),
				},
			];


	return (
		<div className="card-content collapse show">
			<CommonLoader open={loader} />
			<SnackbarComponent
				snackbarOpen={snackbarData?.snackbarOpen}
				snackbarMessage={snackbarData?.snackbarMessage}
				onSnackbarClose={onSnackbarClose}
				variant={snackbarData?.snackbarVariant}
			/>
			<div className="card-body card-dashboard">
				<Grid container spacing={1} justify="space-between">
					<Grid item xs={6} sm={2} className="table-search">
						<SearchField
							onChange={handleChange}
							value={formSearch.search}
							handleSearch={handleSearch}
							handleClear={handleClear}
						/>
					</Grid>
					<Grid item xs={12} sm={12} className="table-block">
						<PermissionsGate
							// errorProps={{ disabled: true }}
							RenderError={() => <p>Access Denied !!</p>}
							scopes={[]}
						>
							<DataTable
								data={exportsList}
								columns={columns}
								options={{
									sort: false,
									sortvalue: "name",
									pagination: true,
									totalCount: totalCount,
									pagesize:
										process.env.REACT_APP_DEFAULT_PAGE_SIZE,
									handlepagination:
										handlepagination.bind(this),
								}}
								actions={[]}
							/>
						</PermissionsGate>
					</Grid>
				</Grid>
			</div>

		</div>

	);
}
