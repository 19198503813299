import React, { Component } from "react";
import { SCOPES } from "../../config/permission-maps";
import ContentWrapperComponent from "../../layout/contentWrapper";
import "./employee.css";
class Employee extends Component {
  constructor(props) {
    super(props);
    const currentRoute = this.props.routes.find(
      (route) =>
        this.props.match.path + route.path === this.props.location.pathname
    );
    if (this.props.match.path === this.props.location.pathname) {
      this.props.history.push(`${this.props.match.path}/employee-list`);
    }
    this.state = {
      type: currentRoute ? currentRoute["type"] : null,
    };
  }
  componentDidMount = () => {
    // this.getMasterData();
  };

  componentWillMount() {
    this.rerouteToAdminPage();
    // this.loadjsagain(null);
  }
  componentDidUpdate(prevProps) {
    this.rerouteToAdminPage();
    // this.loadjsagain(prevProps);
  }

  rerouteToAdminPage() {
    const currentRoute = this.props.routes.find(
      (route) =>
        this.props.match.path + route.path === this.props.location.pathname
    );
    //Setting BreadCrumb type also in Here from currentRoute
    if (currentRoute && currentRoute["type"] !== this.state.type) {
      this.setState({ type: currentRoute["type"] });
    }
    if (this.props.location.pathname === "/employee")
      this.props.history.push(`${this.props.match.path}/employee-list`);
  }

  render() {
    return (
      <>
        <ContentWrapperComponent
          scopes={[SCOPES.manageEmployee]}
          type={this.state.type}
          routes={this.props.routes}
          {...this.props}
        />
      </>
    );
  }
}

export default Employee;
