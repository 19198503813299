import { MuiThemeProvider, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { getServiceTotal } from "../../../../../utils/commonUtils";
import { numberFormat } from "../../../../../utils/numberFormat";
import {
  add_items_to_orders,
  getServiceList,
  update_order_item,
} from "../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

const EditOrderItem = ({
  open = false,
  order_id,
  data = {},
  handleClose = () => {},
  refreshOrderData = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const history = useHistory();
  const [selected, setSelected] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitClicked(true);
    setSubmitting(true);
    let payload = {
      professional_fee: parseFloat(formData.professional_fee),
      vendor_fee:
        formData.vendor_fee == null ? 0 : parseFloat(formData.vendor_fee),
      govt_fee: parseFloat(formData.govt_fee),
      gst_percent: parseFloat(formData.gst_percent),
      discount_percent: parseFloat(formData.discount_percent),
    };
    try {
      if (formData?.id) {
        update_order_item(order_id, formData.id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);

            setTimeout(() => {
              handleClose(true);
            }, 1000);
            if (refreshOrderData) {
              refreshOrderData();
            }
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      }
    } catch (err) {
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleTableChange = (e, ind) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value ? e.target.value : 0,
    }));
  };

  const getRounding = (value) => {
    if (!value) {
      return 0.0;
    }
    let rounded_total = parseFloat(value).toFixed(0);
    let rounding = rounded_total - value;
    return rounding;
  };
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          maxWidth="lg"
          // aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <DialogTitle disableTypography>
            <h3> Edit Order Item</h3>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit}>
            <DialogContent>
              <div className="w-100">
                <div>
                  <div className="pb-2 w-50">
                    <p>
                      Service: <b>{formData.service_name}</b>
                    </p>
                  </div>
                  <div className="w-100">
                    <div className=" w-100">
                      <table className="table table-striped w-100">
                        <thead>
                          <tr>
                            <th>Order Item</th>
                            <th>
                              Professional fee <span className="red">*</span>
                            </th>
                            <th>Vendor fee</th>
                            <th>
                              Govt. fee <span className="red">*</span>
                            </th>
                            <th>
                              GST Percent <span className="red">*</span>
                            </th>
                            <th>
                              Discount Percent <span className="red">*</span>
                            </th>
                            <th>Rounding</th>
                            <th>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <div
                                  className="d-flex align-items-center "
                                  style={{ minWidth: 200 }}
                                >
                                  <TextField
                                    // autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    inputProps={{ readOnly: true }}
                                    name="service_name"
                                    value={
                                      formData.service_name
                                        ? formData.service_name
                                        : ""
                                    }
                                    className="customFieldInput "
                                    onChange={handleTableChange}
                                    fullWidth
                                    placeholder="Order Item"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <TextField
                                  // autoFocus
                                  variant="outlined"
                                  margin="dense"
                                  required
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  className="customFieldInput "
                                  name="professional_fee"
                                  value={
                                    formData.professional_fee
                                      ? parseInt(formData.professional_fee)
                                      : ""
                                  }
                                  onChange={handleTableChange}
                                  fullWidth
                                  placeholder="Professional fee"
                                />
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                <TextField
                                  // autoFocus
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  className="customFieldInput "
                                  name="vendor_fee"
                                  value={
                                    formData.vendor_fee
                                      ? parseInt(formData.vendor_fee)
                                      : ""
                                  }
                                  onChange={handleTableChange}
                                  fullWidth
                                  placeholder="Vendor fee"
                                />
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                <TextField
                                  // autoFocus
                                  variant="outlined"
                                  margin="dense"
                                  required
                                  className="customFieldInput "
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  name="govt_fee"
                                  value={
                                    formData.govt_fee
                                      ? parseInt(formData.govt_fee)
                                      : ""
                                  }
                                  onChange={handleTableChange}
                                  fullWidth
                                  placeholder="Govt. fee"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <TextField
                                  // autoFocus
                                  variant="outlined"
                                  margin="dense"
                                  required
                                  className="customFieldInput "
                                  type="number"
                                  inputProps={{ min: 0, max: 100 }}
                                  name="gst_percent"
                                  value={
                                    formData.gst_percent
                                      ? parseInt(formData.gst_percent)
                                      : ""
                                  }
                                  onChange={handleTableChange}
                                  fullWidth
                                  placeholder="GST Percent"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <TextField
                                  // autoFocus
                                  variant="outlined"
                                  margin="dense"
                                  className="customFieldInput "
                                  required
                                  type="number"
                                  inputProps={{ min: 0, max: 100 }}
                                  name="discount_percent"
                                  value={
                                    formData.discount_percent
                                      ? parseInt(formData.discount_percent)
                                      : ""
                                  }
                                  onChange={handleTableChange}
                                  fullWidth
                                  placeholder="Discount Percent"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="h-full w-full">
                                <div
                                  className="d-flex align-items-center text-bold  justify-content-center"
                                  style={{ height: 30 }}
                                >
                                  {numberFormat(
                                    getRounding(
                                      getServiceTotal(
                                        formData.professional_fee,
                                        formData.govt_fee,
                                        formData.gst_percent,
                                        formData.discount_percent,
                                        0,
                                        formData.vendor_fee
                                      )
                                    )
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="h-full w-full">
                                <div
                                  className="d-flex align-items-center text-bold  justify-content-center"
                                  style={{ height: 30 }}
                                >
                                  {numberFormat(
                                    parseFloat(
                                      getServiceTotal(
                                        formData.professional_fee,
                                        formData.govt_fee,
                                        formData.gst_percent,
                                        formData.discount_percent,
                                        0,
                                        formData.vendor_fee
                                      )
                                    ).toFixed(0)
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default EditOrderItem;
