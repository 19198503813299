import { Grid } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TreeView } from "@material-ui/lab";
import React, { Component } from "react";
import apis from "../../../apiactions/api.actions";
import { StyledTreeItem } from "../../../plugins/commonexports";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { businesCat, businsector } from "../apifunctions";
import ManageBusinesCategory from "./components/managebuscat.component";
import ManageBusinessSector from "./components/managebussector.component";

function FunMenus(props) {
  const { node, levelid } = props;
  let level = levelid + 1;
  return (
    <React.Fragment>
      {node.map((nodes, childindex) => (
        <StyledTreeItem
          onLabelClick={(e) => props.handleCatLabel(e, nodes.id, level)}
          key={nodes.id}
          nodeId={nodes.id}
          onIconClick={(e) => props.handleNodeSelect(e, nodes.id, false)}
          label={nodes.business_industry_name}
        >
          {nodes.children && nodes.children.length !== 0 && (
            <React.Fragment>
              {props.renderTree(nodes.children, level)}
            </React.Fragment>
          )}
        </StyledTreeItem>
      ))}
    </React.Fragment>
  );
}
let key = 0;
let addHeader = "Add Category";

class BusinessSector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectData: [],
      openSector: true,
      expanded: [],
      sectedit: false,
      header: "New Sector",
      sectorForm: {},
      catForm: {},
      currentParent: "",
      opencateg: false,
      level: 0,
      editCat: false,
      formData: {},
      snackbarMessage: "",
      snackbarOpen: false,

      snackbarVariant: "",
    };

    this.handleCatLabel = this.handleCatLabel.bind(this);
  }
  componentDidMount = () => {
    this.getData();
  };
  getData = (data = undefined) => {
    apis
      .getDatas(businsector)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          sectData: res,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleLabel = (e, id, level) => {
    e.preventDefault();
    apis
      .getDatas(businsector + "/" + id)
      .then((res) => res)
      .then((res) => {
        this.setState({
          sectorForm: res,
          openSector: true,
          level: level,
          header: "Edit Sector",
          editCat: false,
          sectedit: true,
          opencateg: false,
        });
        key = key + 1;
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  renderTree = (node, levelid, index) => {
    return (
      <FunMenus
        handleCatLabel={this.handleCatLabel}
        renderTree={this.renderTree}
        node={node}
        handleNodeSelect={this.handleNodeSelect}
        levelid={levelid}
      />
    );
  };
  handleCatLabel = (e, id, level, index) => {
    e.preventDefault();
    apis
      .getDatas(businesCat + "/" + id)
      .then((res) => res)
      .then((res) => {
        this.setState({
          catForm: res,
          openSector: false,
          header: "Edit Category",
          level: level,
          opencateg: true,
          editCat: true,
          sectedit: false,
        });
        key = key + 1;
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSubmit = (data) =>
    this.state.sectedit
      ? apis
          .updateData(businsector + "/" + data.id, data)
          .then((res) => res.data)
      : apis.createData(businsector, data).then((res) => res.data);
  handleCatSubmit = (data) =>
    this.state.editCat
      ? apis
          .updateData(businesCat + "/" + data.id, data)
          .then((res) => res.data)
      : apis.createData(businesCat, data).then((res) => res.data);
  handleClose = () => {
    this.setState({
      openSector: false,
    });
  };
  handleopenSector = () => {
    this.setState({
      header: "New Sector",
      sectorForm: {},
      sectedit: false,
      editCat: false,
      opencateg: false,
      openSector: true,
    });
    key = key + 1;
  };
  handleCategory = () => {
    const { sectData } = this.state;

    let data = {};
    if (this.state.level === 1) {
      data = {
        business_sector_name: this.state.sectorForm.business_sector_name,
        business_sector: this.state.sectorForm.id,
      };
    } else if (this.state.level === 2) {
      sectData.forEach((item) => {
        if (item.id === this.state.catForm.business_sector) {
          data = {
            business_sector_name: item.business_sector_name,
            business_sector: item.id,
            parent: this.state.catForm.id,
            parent_name: this.state.catForm.business_industry_name,
          };
        }
      });
    }
    this.setState({
      header: "Add Category",
      catForm: data,
      level: this.state.level === 2 ? 3 : this.state.level,
      opencateg: true,
      editCat: false,
      sectedit: false,
      openSector: false,
    });
    key = key + 1;
  };
  handleNodeSelect = (e, nodes) => {
    console.log(nodes);
    const { expanded, sectData } = this.state;

    let index = expanded.indexOf(nodes);
    if (index !== -1) {
      expanded.splice(index, 1);
    } else {
      this.state.expanded.push(nodes);
    }

    this.setState({
      currentParent: nodes,
      expanded,
    });
  };
  handleSectRefresh = (res, addnew) => {
    if (addnew) {
      this.setState(
        {
          header: "Edit Sector",
          sectedit: true,
          sectorForm: res,

          editCat: false,
          opencateg: false,
          openSector: true,
        },
        () => {
          this.getData();
        }
      );
      key = key + 1;
    } else {
      this.setState(
        {
          sectorForm: res,
        },
        () => {
          this.getData();
        }
      );
    }
  };
  handleCatRefresh = (e, res, addnew) => {
    if (addnew) {
      if (this.state.level === 1) {
        this.handleNodeSelect(e, res.business_sector);
        this.handleLabel(e, res.business_sector, 1);
      } else if (this.state.level === 3) {
        this.handleNodeSelect(e, res.parent);
        this.handleCatLabel(e, res.parent, 2);
      }
      this.setState(
        {
          opencateg: false,
        },
        () => {
          this.getData();
        }
      );
    } else {
      this.setState(
        {
          catForm: res,
        },
        () => {
          this.getData();
        }
      );
    }
  };
  render() {
    const {
      sectData,
      opencateg,
      level,
      expanded,
      openSector,
      sectedit,
      editCat,
    } = this.state;
    if (level === 2 && editCat) {
      addHeader = "Add Sub Category";
    } else {
      addHeader = "Add Category";
    }
    console.log(this.state.header);
    return (
      <div className="create-content">
        <div className="content-body">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4} className="edit-leftsection">
              <div className="card-top">
                <h4 className="card-title">Business Sectors</h4>{" "}
                {/* Sector List changed sreelekshmi*/}
                <a
                  href="#/"
                  onClick={this.handleopenSector}
                  className="btn btn-primary"
                >
                  + &nbsp; Add Sector
                </a>
              </div>
              <div
                className="bsector-height customTreeView smTreeView"
                style={{ padding: "12px 16px" }}
              >
                {sectData.map((item, index) => (
                  <TreeView
                    key={index}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={["root"]}
                    defaultExpandIcon={<ChevronRightIcon />}
                    disableSelection
                    onNodeSelect={this.handleNodeSelect}
                    expanded={expanded}
                  >
                    <StyledTreeItem
                      onLabelClick={(e) => this.handleLabel(e, item.id, 1)}
                      key={item.id}
                      onIconClick={(e) =>
                        this.handleNodeSelect(e, item.id, true)
                      }
                      label={item.business_sector_name}
                      nodeId={item.id}
                    >
                      {item.business_industries &&
                        item.business_industries.length !== 0 && (
                          <React.Fragment>
                            {" "}
                            {this.renderTree(item.business_industries, 1)}
                          </React.Fragment>
                        )}
                    </StyledTreeItem>
                  </TreeView>
                ))}
              </div>
            </Grid>
            <Grid item xs={6} sm={8} className="edit-rightsection">
              <div className="card-top">
                <h4 className="card-title">{this.state.header}</h4>
                {(sectedit || editCat) && level !== 3 && (
                  <a
                    href="#/"
                    onClick={(e) => this.handleCategory()}
                    className="btn btn-secondary"
                  >
                    + &nbsp; {addHeader}
                  </a>
                )}
              </div>
              {openSector && !opencateg && (
                <ManageBusinessSector
                  key={this.state.sectorForm.id + key}
                  submit={this.handleSubmit}
                  sectedit={this.state.sectedit}
                  sectorForm={this.state.sectorForm}
                  handleRefresh={this.handleSectRefresh}
                />
              )}
              {opencateg && (
                <ManageBusinesCategory
                  key={key}
                  submit={this.handleCatSubmit}
                  level={this.state.level}
                  editCat={this.state.editCat}
                  handleRefresh={this.handleCatRefresh}
                  catForm={this.state.catForm}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default BusinessSector;
