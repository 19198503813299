import React, { Component } from "react";

import "./header.css";
import WebSocketComponent from "../components/websocket/WebsocketComponent";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { setIsAdmin, setIsImpersonating } from "../config/redux/permissionSlice";
import { setImpersonateToken } from "../config";
import {withRouter} from 'react-router-dom';


class Header extends Component {
  constructor(props) {
    super(props);
  }

  handleImpersonate() {
    this.props.dispatch(setIsImpersonating(null));
    this.props.dispatch(setIsAdmin(true));
    setImpersonateToken(null);
    this.props.history.push(`/dashboard`);
  };

  render() {
    const { isAdmin,  isImpersonating } = this.props;
    return (
      <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow">
        <WebSocketComponent />
        <div className="navbar-wrapper">
          <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mobile-menu d-md-none mr-auto">
                <a
                  className="nav-link nav-menu-main menu-toggle hidden-xs"
                  href="#"
                >
                  <i className="ft-menu font-large-1" />
                </a>
              </li>
              <li className="nav-item w-100">
                <a className="navbar-brand" href="/">
                  <img
                    className="brand-logo-biz"
                    style={{ height: 50 }}
                    alt="Bizpole logo"
                    src="/app-assets/logos/logo.png"
                  />
                  {/* <h3 className="brand-text">
                    <span></span>
                    <span>Bizpole</span>
                  </h3> */}
                </a>
              </li>
              <li className="nav-item d-md-none">
                <a
                  className="nav-link open-navbar-container"
                  data-toggle="collapse"
                  data-target="#navbar-mobile"
                >
                  <i className="la la-ellipsis-v" />
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav mr-auto float-left">
                {/* <li className="nav-item d-none d-md-block">
                    <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                      <img src="/app-assets/backend-images/icons/menu.svg" alt="menu-icon" />
                    </a>
                  </li> */}
              </ul>
              <ul className="nav navbar-nav float-right">
                {/* <li className="nav-item search-form">
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <button className="btn-transparent">
                        <img src="/app-assets/backend-images/icons/search-icon.svg" />
                      </button>
                    </div>
                  </form>
                </li> */}
                {isImpersonating && (
                    <li className="nav-item search-form">
                      <Button
                        className="btn"
                        onClick={() => this.handleImpersonate()}
                      >
                        Stop Impersonation
                      </Button>
                    </li>
                  )
                }
                <li className="dropdown dropdown-user nav-item">
                  {/* <a
                    className="dropdown-toggle nav-link dropdown-user-link dropdown-notification-link"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <span className="notification notification-online">
                      <img
                        src="/app-assets/backend-images/icons/notification.svg"
                        alt="notification"
                      />
                      <i />
                    </span>
                  </a> */}
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="/profile">
                      <i className="ft-user" /> My Profile
                    </a>
                    {/* <a className="dropdown-item" href="#">
                      <i className="ft-mail" /> My Inbox
                    </a> */}
                  </div>
                </li>
                <li className="dropdown dropdown-user nav-item">
                  <a
                    className="dropdown-toggle nav-link dropdown-user-link"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <span className="avatar ">
                      <img src="/app-assets/images/profile.jpg" alt="avatar" />
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="/profile">
                      <i className="ft-user" /> My Profile
                    </a>
                    {/* <a className="dropdown-item" href="#">
                      <i className="ft-mail" /> My Inbox
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="ft-check-square" /> Task
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="ft-message-square" /> Chats
                    </a> */}
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" onClick={this.props.logout}>
                      <i className="ft-power" /> Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
    isImpersonating: state.permission.isImpersonating,
  };
};

export default connect(mapStateToProps)(withRouter(Header));

// export default Header;
