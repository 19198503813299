import React, { useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import CloseIcon from "@material-ui/icons/Close";
import RoleDialogTheme from "./dialogTheme";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { add_roles, update_roles } from "../apifunctions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";

const ManageRole = ({ open = false, data = {}, handleClose = () => {} }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });
  const generateErrorMessage = (err) => {
    let errMessage = "Something went wrong!";

    const errorArray = [];
    if (err.response.data.errors) {
      if (Array.isArray(err.response.data.errors)) {
        err.response.data.errors.forEach((item) => {
          errorArray.push(`${item.message}`);
        });

        // });
        errMessage = String(errorArray);
        // } else {
        //   errMessage = "Something went wrong!";
      }
    } else {
      if (err?.response?.data?.error?.message) {
        errMessage = err?.response?.data?.error?.message;
      } else {
        errMessage = "Something went wrong!";
      }
    }
    return errMessage;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    let perm = [];
    formData?.permission?.forEach((item) => {
      perm.push(item.acl);
    });
    const payload = {
      name: formData?.name,
      ...(formData?.permissions ? { permissions: perm } : {}),
      ...(formData?.id ? { id: formData?.id } : {}),
    };
    if (formData?.id) {
      try {
        update_roles(formData?.id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setTimeout(() => {
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(generateErrorMessage(err));
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      try {
        add_roles(payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setTimeout(() => {
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(generateErrorMessage(err));
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (value.trim() === "") {
      value = "";
    }
    setFormData((f) => ({ ...f, [e.target.name]: value }));
  };
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {formData?.id ? "Edit Role" : "Add Role"}</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>Role Name<span className="red">*</span></InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="name"
                  value={formData.name ? formData.name : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter Role Name"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {formData?.id ? "UPDATE" : "SAVE"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageRole;
