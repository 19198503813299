import { MuiThemeProvider, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { getServiceTotal } from "../../../../../utils/commonUtils";
import { numberFormat } from "../../../../../utils/numberFormat";
import { add_items_to_orders, getServiceList } from "../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

const ManageOrder = ({
  open = false,
  order_id,
  data = {},
  handleClose = () => {},
  refreshOrderData = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const history = useHistory();
  const [selected, setSelected] = useState(null);

  const fetchServiceBySearch = (e) => {
    getServiceList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setServiceData(res?.data?.results || []);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitClicked(true);
    setSubmitting(true);
    let perm = [];
    selectedItems?.forEach((item) => {
      perm.push({
        id: item.id,
        professional_fee: parseFloat(item.professional_fee),
        vendor_fee: item.vendor_fee == null ? 0 : parseFloat(item.vendor_fee),
        govt_fee: parseFloat(item.govt_fee),
        gst_percent: parseFloat(item.gst_percent),
        discount_percent: parseFloat(item.discount_percent),
      });
    });
    const payload = {
      items: perm,
    };
    console.log(perm.length);
    if (perm.length > 0) {
      try {
        add_items_to_orders(order_id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);

            setTimeout(() => {
              handleClose(true);
            }, 1000);
            if (refreshOrderData) {
              refreshOrderData();
            }
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Select atleast one order item");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    let sel = [...selectedItems];
    sel.push({ ...e, gst_percent: 18 });
    setFormData((f) => ({ ...f, service_name: null }));
    if (e["id"]) {
      setSelectedItems(sel);
    }
    setSelected(e.name);
    // setSelectedItems("");
  };
  const handleTableChange = (e, ind) => {
    let sel = [...selectedItems];
    sel[ind][e.target.name] = e.target.value ? e.target.value : 0;
    setSelectedItems(sel);
  };
  const filteredServiceData = () => {
    const filteredData = serviceData;
    return filteredData;
  };
  const removeItem = (ind) => {
    let sele = [];
    sele = selectedItems;
    sele.splice(ind, 1);
    setSelectedItems([...sele]);
  };
  useEffect(() => {
    fetchServiceBySearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getWholeTotal = () => {
    let total = 0;
    selectedItems.forEach((item, ind) => {
      total =
        total +
        Number(
          parseFloat(
            getServiceTotal(
              item.professional_fee,
              item.govt_fee,
              item.gst_percent,
              item.discount_percent,
              ind,
              item.vendor_fee
            )
          ).toFixed(0)
        );
    });

    return total.toFixed(2);
  };
  const getRounding = (value) => {
    if (!value) {
      return 0.0;
    }
    let rounded_total = parseFloat(value).toFixed(0);
    let rounding = rounded_total - value;
    return rounding;
  };
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          maxWidth="lg"
          // aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <DialogTitle disableTypography>
            <h3> Add Order Item</h3>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit}>
            <DialogContent>
              <div className="w-100">
                <div>
                  <div className="pb-2 w-50">
                    <SelectBox
                      Data={filteredServiceData()}
                      id="id"
                      async="true"
                      selectedValue={selected ?? ""}
                      value={formData.service_name ? formData.service_name : ""}
                      desc="service_name"
                      // required
                      descId="id"
                      getData={fetchServiceBySearch}
                      placeholder="Select Order Item"
                      className="form-control"
                      onChange={handleServiceChange}
                    />
                  </div>
                  {selectedItems.length === 0 && isSubmitClicked && (
                    <div className="red py-1">
                      Select atleast one order item
                    </div>
                  )}
                  <div className="w-100">
                    <div className=" w-100">
                      <table className="table table-striped w-100">
                        <thead>
                          <tr>
                            <th>Order Item</th>
                            <th>
                              Professional fee <span className="red">*</span>
                            </th>
                            <th>Vendor fee</th>
                            <th>
                              Govt. fee <span className="red">*</span>
                            </th>
                            <th>
                              GST Percent <span className="red">*</span>
                            </th>
                            <th>
                              Discount Percent <span className="red">*</span>
                            </th>
                            <th>Rounding</th>
                            <th>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedItems.length > 0 ? (
                            <>
                              {selectedItems.map((item, ind) => {
                                return (
                                  <tr>
                                    <td>
                                      <div>
                                        <div
                                          className="d-flex align-items-center "
                                          style={{ minWidth: 200 }}
                                        >
                                          <TextField
                                            // autoFocus
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            inputProps={{ readOnly: true }}
                                            name="service_name"
                                            value={
                                              item.service_name
                                                ? item.service_name
                                                : ""
                                            }
                                            className="customFieldInput "
                                            onChange={(e) =>
                                              handleTableChange(e, ind)
                                            }
                                            fullWidth
                                            placeholder="Order Item"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <TextField
                                          // autoFocus
                                          variant="outlined"
                                          margin="dense"
                                          required
                                          type="number"
                                          inputProps={{ min: 0 }}
                                          className="customFieldInput "
                                          name="professional_fee"
                                          value={
                                            item.professional_fee
                                              ? item.professional_fee
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleTableChange(e, ind)
                                          }
                                          fullWidth
                                          placeholder="Professional fee"
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div className="d-flex align-items-center">
                                        <TextField
                                          // autoFocus
                                          variant="outlined"
                                          margin="dense"
                                          type="number"
                                          inputProps={{ min: 0 }}
                                          className="customFieldInput "
                                          name="vendor_fee"
                                          value={
                                            item.vendor_fee
                                              ? item.vendor_fee
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleTableChange(e, ind)
                                          }
                                          fullWidth
                                          placeholder="Vendor fee"
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div className="d-flex align-items-center">
                                        <TextField
                                          // autoFocus
                                          variant="outlined"
                                          margin="dense"
                                          required
                                          className="customFieldInput "
                                          type="number"
                                          inputProps={{ min: 0 }}
                                          name="govt_fee"
                                          value={
                                            item.govt_fee ? item.govt_fee : ""
                                          }
                                          onChange={(e) =>
                                            handleTableChange(e, ind)
                                          }
                                          fullWidth
                                          placeholder="Govt. fee"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <TextField
                                          // autoFocus
                                          variant="outlined"
                                          margin="dense"
                                          required
                                          className="customFieldInput "
                                          type="number"
                                          inputProps={{ min: 0, max: 100 }}
                                          name="gst_percent"
                                          value={
                                            item.gst_percent
                                              ? item.gst_percent
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleTableChange(e, ind)
                                          }
                                          fullWidth
                                          placeholder="GST Percent"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <TextField
                                          // autoFocus
                                          variant="outlined"
                                          margin="dense"
                                          className="customFieldInput "
                                          required
                                          type="number"
                                          inputProps={{ min: 0, max: 100 }}
                                          name="discount_percent"
                                          value={
                                            item.discount_percent
                                              ? item.discount_percent
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleTableChange(e, ind)
                                          }
                                          fullWidth
                                          placeholder="Discount Percent"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="h-full w-full">
                                        <div
                                          className="d-flex align-items-center text-bold  justify-content-center"
                                          style={{ height: 30 }}
                                        >
                                          {numberFormat(
                                            getRounding(
                                              getServiceTotal(
                                                item.professional_fee,
                                                item.govt_fee,
                                                item.gst_percent,
                                                item.discount_percent,
                                                ind,
                                                item.vendor_fee
                                              )
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="h-full w-full">
                                        <div
                                          className="d-flex align-items-center text-bold  justify-content-center"
                                          style={{ height: 30 }}
                                        >
                                          {numberFormat(
                                            parseFloat(
                                              getServiceTotal(
                                                item.professional_fee,
                                                item.govt_fee,
                                                item.gst_percent,
                                                item.discount_percent,
                                                ind,
                                                item.vendor_fee
                                              )
                                            ).toFixed(0)
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <IconButton
                                          aria-label="close"
                                          onClick={() => removeItem(ind)}
                                        >
                                          <img
                                            src="/app-assets/backend-images/icons/delete-icon.svg"
                                            alt="Delete"
                                          />
                                        </IconButton>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              <td colSpan={8}>
                                <div
                                  className="flex justify-content-end"
                                  style={{ float: "right" }}
                                >
                                  <h4>
                                    Total Amount :{" "}
                                    {/* <strong>{getWholeTotal()}</strong> */}
                                    <strong>
                                      {numberFormat(getWholeTotal())}
                                    </strong>
                                  </h4>
                                </div>
                              </td>
                            </>
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <div className="d-flex align-items-center justify-content-center">
                                  No order items selected
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageOrder;
