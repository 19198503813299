
export const numberFormat = (number)=>{
    if (number){
        return Number(number).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
    }
    else {
        return "0.00"
    }
}
