import React from "react";
import ContentWrapperComponent from "../../layout/contentWrapper";
import ExportListComponent from "./exportListComponent";

export default function ExportComponent(props) {
  const currentRoute = props.routes?.find(
    (route) => props.match.path === props.location.pathname,
  );

  const state = {
    type: currentRoute ? currentRoute["type"] : null,
  };
  return (
    <ContentWrapperComponent
      type={state.type}
      routes={props.routes}
      {...props}
      component={ExportListComponent}
    />
  );
}
