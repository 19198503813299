import moment from "moment";
import React, { Component } from "react";

import CalendarComponent from "../../../../../plugins/calendar.comp";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { get_Compnaycompliance, getConstCat, update_Compnaycompliance } from "../../../apifunctions";

class CompanyCompliancesInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      constData: [],
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      formData: {},
      annualData: [],
      paidup: [],
    };
  }
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };
  async getData() {
    this.setState({ loader: true });
    try {
      get_Compnaycompliance(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: {
              ...res.company_compliance_info,
              constitution_category_name: res.company_compliance_info
                .constitution
                ? res.company_compliance_info.constitution
                    .constitution_category_display_name
                : "",
              constitution: res.company_compliance_info.constitution
                ? res.company_compliance_info.constitution.id
                : "",
              turnover_expected: res.company_compliance_info.turnover_expected
                ? res.company_compliance_info.turnover_expected.id
                : "",
              capital_employed: res.company_compliance_info.capital_employed
                ? res.company_compliance_info.capital_employed.id
                : null,
              incorporation_date: res.company_compliance_info.incorporation_date
                ? moment(res.company_compliance_info.incorporation_date).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : "",
            },

            loader: false,
          });
          if (
            res.company_compliance_info.constitution &&
            res.company_compliance_info.constitution.category_parameters
          ) {
            this.handledropData(
              res.company_compliance_info.constitution.category_parameters
            );
          }
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleCheckChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.checked,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    update_Compnaycompliance(this.props.com_Id, this.state.formData)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          submited: false,
          snackbarVariant: "error",
        });
      });
  };
  handleDataChange = (value, reason=undefined) => {
  
      this.getConstData(value);
    
  };
  getConstData = (value) => {
    getConstCat(value)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          constData: res.constitution_categories,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleSelectChange = (e, id, name) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [id]: e.id,
          [name]: e[name],
          turnover_expected: "",
        },
        annualData: [],
        paidup: [],
      },
      () => {
        if (e.category_parameters && e.category_parameters.length !== 0) {
          this.handledropData(e.category_parameters);
        }
      }
    );
  };
  handledropData = (data) => {
    const { annualData, paidup } = this.state;
    let catparams = [];
    catparams = data;
    catparams.forEach((ele) => {
      if (
        ele.parameter_option.parameter_name[0].parameter_name ===
        "Annual Turnover"
      ) {
        annualData.push({
          id: ele.parameter_option.id,
          name: ele.parameter_option.admin_portal_name,
        });
      } else if (
        ele.parameter_option.parameter_name[0].parameter_name ===
        "Paid-up Capital"
      ) {
        paidup.push({
          id: ele.parameter_option.id,
          name: ele.parameter_option.admin_portal_name,
        });
      }
    });
    this.setState({ annualData, paidup });
  };
  handleDatePicker = (e, index) => {
    if (e.value) {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.name]: e.value ? moment(e.value).format("YYYY-MM-DD HH:mm") : "",
        },
      });
    }
  };
  render() {
    const { formData, loader, constData, annualData, submited, paidup } =
      this.state;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Constitution Category <span className="red">*</span>
                  </label>
                  <SelectBox
                    Data={constData}
                    id="id"
                    async="true"
                    value={formData.constitution_category_name}
                    desc="constitution_category_name"
                    getData={this.handleDataChange}
                    placeholder="Category"
                    className="form-control"
                    descId="constitution"
                    onChange={this.handleSelectChange}
                  />
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Annual Turnover <span className="red">*</span>
                  </label>

                  <select
                    required
                    name="turnover_expected"
                    value={formData.turnover_expected}
                    onChange={this.handleChange}
                    disabled={annualData.length === 0 ? true : false}
                    class="form-control"
                  >
                    <option value={""}>select</option>
                    {annualData.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">Paid-up Capital</label>
                  <select
                    disabled={paidup.length === 0 ? true : false}
                    name="capital_employed"
                    value={formData.capital_employed}
                    onChange={this.handleChange}
                    class="form-control"
                  >
                    <option value={""}>Select</option>
                    {paidup.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Incorporation Date <span className="red">*</span>
                  </label>
                  <CalendarComponent
                    label="Incorporation Date"
                    required
                    dateTime={true}
                    showTimeSelect
                    id="incorporation_date"
                    onChange={(e) => this.handleDatePicker(e)}
                    name="incorporation_date"
                    selected={formData.incorporation_date}
                    class="form-control"
                  />
                </div>
                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.profession}
                    value={formData.profession}
                    type="checkbox"
                    class="form-check"
                    id="profession"
                    name="profession"
                    onChange={this.handleCheckChange}
                  />
                  <label for="profession">Profession</label>
                  {/* </div> */}
                </div>
                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.business}
                    value={formData.business}
                    type="checkbox"
                    id="business"
                    class="form-check"
                    name="business"
                    onChange={this.handleCheckChange}
                  />
                  <label for="business">Business</label>
                  {/* </div> */}
                </div>

                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.is_composition_scheme_opted}
                    value={formData.is_composition_scheme_opted}
                    type="checkbox"
                    id="is_composition_scheme_opted"
                    class="form-check"
                    name="is_composition_scheme_opted"
                    onChange={this.handleCheckChange}
                  />
                  <label for="is_composition_scheme_opted">
                    Composition Scheme
                  </label>{" "}
                  {/* </div> */}
                </div>
                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.is_gst_reg}
                    value={formData.is_gst_reg}
                    type="checkbox"
                    id="is_gst_reg"
                    class="form-check"
                    className=""
                    name="is_gst_reg"
                    onChange={this.handleCheckChange}
                  />
                  <label for="is_gst_reg">GST Registered</label> {/* </div> */}
                </div>
                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.is_esi_reg}
                    value={formData.is_esi_reg}
                    type="checkbox"
                    id="is_esi_reg"
                    class="form-check"
                    name="is_esi_reg"
                    onChange={this.handleCheckChange}
                  />
                  <label for="is_esi_reg">ESI Registered</label> {/* </div> */}
                </div>
                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.is_pf_reg}
                    value={formData.is_pf_reg}
                    type="checkbox"
                    id="is_pf_reg"
                    class="form-check"
                    name="is_pf_reg"
                    onChange={this.handleCheckChange}
                  />
                  <label for="is_pf_reg">PF Registered</label> {/* </div> */}
                </div>
                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  {/* <div class="form-check"> */}
                  <input
                    checked={formData.is_ie_reg}
                    value={formData.is_ie_reg}
                    type="checkbox"
                    id="is_ie_reg"
                    class="form-check"
                    name="is_ie_reg"
                    onChange={this.handleCheckChange}
                  />
                  <label for="is_ie_reg">IE Code Registered</label>{" "}
                  {/* </div> */}
                </div>

                <div className="col-md-3 col-xs-12 checkbox-block form-check">
                  <input
                    checked={formData.is_fssai_reg}
                    value={formData.is_fssai_reg}
                    type="checkbox"
                    id="is_fssai_reg"
                    class="form-check"
                    name="is_fssai_reg"
                    onChange={this.handleCheckChange}
                  />
                  <label for="is_fssai_reg">FSSAI Registered</label>
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(6)}
                  >
                    Back
                  </a>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CompanyCompliancesInformation;
