import { Grid } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { MdCheck, MdDownload } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate, { hasPermission } from "../../../config/permissiongate";
import CalendarComponent from "../../../plugins/calendar.comp";
import CancelComponent from "../../../plugins/cancelPopup/cancelComponent";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/newDeleteComponent";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { formatDate } from "../../../utils/dateUtils";
import ResendLinkComponent from "../../orders/details/payments/componennts/resendLinkPopup/resendLinkComponent";
import ViewPayment from "../../orders/details/payments/manage/view";
import {
  cancel_payment_link,
  exportPayments,
  getAccountList,
  getAllEmployees,
  getPaymentStatusList,
  getPaymentTypesList,
  remove_payment,
  resend_payment_link,
  searchPayments,
} from "../apifunctions";

class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      mainData: {},
      addNew: false,
      opendelete: false,
      opencancel: false,
      openview: false,
      openresendlink: false,
      formSearch: {
        page: 1,
        search: "",
        fromDate: "",
        toDate: "",
        customer: "",
        payment_method__id: "",
        status: "",
        payment_type: "",
        order_account_name: "",
        order__account__id: "",
        payment_transactions__is_advance: "",
        created_by_id: "",
      },
      order_status_list: [],
      service_list: [],
      service_category_list: [],
      payment_method_list: [],
      payment_status_list: [],
      payment_type_list: [],
      account_list: [],
      cre_list: [],
      is_advance_list: [
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ],
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
      filterStatus: false,
      filterAppliedStatus: false,
      emptyStatus:false,
      tabType: "orderDetails",
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
      this.loadCreList();
      this.loadPaymentMethods();
      this.loadPaymentStatusList();
      this.loadPaymentTypeList();
      this.loadAccounts();
      this.getAllEmployees();
    } catch (err) {}
  }

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      },
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {

    const response = await searchPayments(this.state.formSearch);
        const resData = response.data;
  
        const isFormEmpty = this.isFormEmpty(this.state.formSearch);
        this.setState({
            datas: resData.results,
            totalCount: resData.count,
            pagesize: resData.page_size,
            loader: false,
            filterAppliedStatus: true,
            emptyStatus: !isFormEmpty
        });
  
          }
          catch(err) {
            this.setState({
              snackbarMessage: err.message,
              snackbarOpen: true,
              snackbarVariant: 'error',
              loader: false,
            });
            console.log("error", err);
          };

  }

  isFormEmpty(form) {
    const fieldsToCheck = [
        'status',
        'payment_method__id',
        'payment_type',
        'order__account__id',
        'payment_transactions__is_advance',
        'created_by_id',
        'startDate',
        'endDate',
        'search'
    ];
    return fieldsToCheck.every(field => !form[field]);
}

  loadPaymentMethods = () => {
    let d = [];
    if (localStorage.getItem("masterData")) {
      let parsed = JSON.parse(localStorage.getItem("masterData"));
      d = parsed.payment_methods || [];
    }
    this.setState({
      payment_method_list: d,
    });
  };

  loadAccounts = (e) => {
    getAccountList({
      page: 1,
      type: "dropdown",
      ...(e ? { search: e } : {}),
    }).then((res) => {
      this.setState({
        account_list: res?.data?.results || [],
      });
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      },
    );
  };

  handlePaymentStatusSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          status: e.id,
          status_name: e.status_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handlePaymentStatusDataChange = (value, reason = undefined) => {
    this.loadPaymentStatusList(value);
  };

  handlePaymentMethodSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          payment_method__id: e.id,
          payment_method_name: e.name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handlePaymentMethodDataChange = (value, reason = undefined) => {
    this.loadPaymentMethods(value);
  };

  handlePaymentTypeSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          payment_type: e.id,
          payment_type_name: e.name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handlePaymentTypeDataChange = (value, reason = undefined) => {
    this.loadPaymentTypeList();
  };

  handlePaymentAdvanceSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          payment_transactions__is_advance: e.id,
          advance_name: e.name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleAccountChange = (selected_data, id, name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_account_name: selected_data[name],
          order__account__id: selected_data[id],
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleCreatedBySelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          created_by_id: e.id,
          created_by_name: e.name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleCreatedByDataChange = (value, reason = undefined) => {
    this.loadCreList();
  };

  handleView = (id) => {
    this.props.history.push("/orders-view/" + id);
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };

  handleDelete = () =>
    remove_payment(this.state.mainId)
      .then(() => {
        this.setState(
          {
            loader: false,
            snackbarMessage: "Payment deleted",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.handleDeleteClose();
            this.getData();
          },
        );
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });

  handleCancel = () =>
    cancel_payment_link(this.state.mainId)
      .then(() => {
        this.setState(
          {
            loader: false,
            snackbarMessage: "Payment link cancelled",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.handleCancelClose();
            this.getData();
          },
        );
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });

  handleResendLink = () =>
    resend_payment_link(this.state.mainData?.order?.id, this.state.mainId)
      .then(() => {
        this.setState(
          {
            loader: false,
            snackbarMessage: "Payment link resent successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.handleResendLinkClose();
            this.getData();
          },
        );
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });

  handleViewOpen = (e, reqData) => {
    console.log(reqData);
    this.setState({
      openview: true,
      mainData: reqData,
    });
  };

  handleViewClose = () => {
    this.setState({
      openview: false,
    });
  };

  handleCancelOpen = (e, reqData) => {
    this.setState({
      opencancel: true,
      mainId: reqData.id,
    });
  };

  handleCancelClose = () => {
    this.setState({
      opencancel: false,
    });
  };

  handleResendLinkOpen = (e, reqData) => {
    this.setState({
      openresendlink: true,
      mainId: reqData.id,
      mainData: reqData,
    });
  };

  handleResendLinkClose = () => {
    this.setState({
      openresendlink: false,
    });
  };

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,

          page: 1,
        },
      },
      () => {
        this.getData();
      },
    );
  };
  handleAdd = () => {
    this.setState({ addNew: true });
  };
  handleExport = () => {
    exportPayments(this.state.formSearch)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  handleCloseAdd = () => {
    this.setState({ addNew: false });
  };
  handleDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          fromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          toDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
        filterStatus: true,
      },
      () => {
        if (this.state.formSearch.fromDate && this.state.formSearch.toDate) {
          this.handleSearch();
        }
      },
    );
  };
  async loadPaymentStatusList() {
    try {
      getPaymentStatusList()
        .then((res) => {
          const updatedData = res.data.map((item) => ({
            id: item.id,
            status_name: item.name,
          }));
          this.setState({
            payment_status_list: updatedData,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  async loadPaymentTypeList() {
    try {
      getPaymentTypesList()
        .then((res) => {
          this.setState({
            payment_type_list: res.data,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  async loadCreList() {
    try {
      getAllEmployees()
        .then((res) => {
          this.setState({
            cre_list: res,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      },
    );
  };
  clearFilter = () => {
    this.setState(
      {
        formSearch: {
          page: 1,
          search: "",
          fromDate: "",
          toDate: "",
          customer: "",
          payment_method__id: "",
          status: "",
          payment_type: "",
          order_account_name: "",
          order__account__id: "",
          payment_transactions__is_advance: "",
          created_by_id: "",
        },
        filterStatus: false,
      },
      () => {
        this.getData();
      },
    );
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleDownload = (r) => {
    if (r) {
      window.open(r, "_blank");
    }
  };

  render() {
    const {
      loader,
      totalCount,
      formSearch,
      pagesize,
      editMode,
      formData,
      payment_method_list,
      cre_list,
      payment_status_list,
      payment_type_list,
      account_list,
      is_advance_list,
      filterStatus,
      filterAppliedStatus,
      emptyStatus
    } = this.state;
    const { masterData } = this.props;
    const { isAdmin } = this.props;

    return (
      <div className="card-content collapse show">
        {/* {this.state.addNew && (
          <ManageOrder
            open={this.state.addNew}
            handleClose={this.handleCloseAdd}
            masterData={masterData}
            {...this.props}
          />
        )} */}
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.startDate}
                endDate={formSearch.endDate}
                name="date"
                onChange={this.handleDatePicker}
                placeholder="Transaction Date"
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={payment_status_list}
                id="id"
                async="true"
                value={formSearch.status_name}
                desc="status_name"
                getData={this.handlePaymentStatusDataChange}
                placeholder="Payment Status"
                className="form-control"
                onChange={this.handlePaymentStatusSelectChange}
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={payment_method_list}
                id="id"
                async="true"
                value={formSearch.payment_method_name}
                desc="name"
                getData={this.handlePaymentMethodDataChange}
                placeholder="Payment Method"
                className="form-control"
                onChange={this.handlePaymentMethodSelectChange}
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={payment_type_list}
                id="id"
                async="true"
                value={formSearch.payment_type_name}
                desc="name"
                getData={this.handlePaymentTypeDataChange}
                placeholder="Payment Type"
                className="form-control"
                onChange={this.handlePaymentTypeSelectChange}
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={account_list}
                id="id"
                async="true"
                required
                value={
                  formSearch.order_account_name
                    ? formSearch.order_account_name
                    : ""
                }
                desc="business_name"
                descId="id"
                getData={this.loadAccounts}
                placeholder="Account"
                className="form-control"
                onChange={this.handleAccountChange}
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={is_advance_list}
                id="id"
                async="true"
                value={formSearch.advance_name}
                desc="name"
                placeholder="Advance Payment"
                className="form-control"
                onChange={this.handlePaymentAdvanceSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={cre_list}
                id="id"
                async="true"
                value={formSearch.created_by_name}
                desc="name"
                getData={this.handleCreatedByDataChange}
                placeholder="Created By"
                className="form-control"
                onChange={this.handleCreatedBySelectChange}
              />
            </Grid>
            <Grid item align="right" xs={12} sm={8}>
              {filterStatus && filterAppliedStatus && emptyStatus && (
                <Link
                  className="btn btn-secondary"
                  onClick={(e) => this.clearFilter(e)}
                  to="#"
                >
                  Clear filters
                </Link>
              )}
              {totalCount > 0 && (
                <Link
                  to="#"
                  className="btn btn-secondary"
                  onClick={(e) => this.handleExport(e)}
                >
                  Export
                </Link>
              )}
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                scopes={[
                  SCOPES.viewOrder,
                  SCOPES.viewAllOrder,
                  SCOPES.viewTeamOrder,
                  SCOPES.managePayments,
                ]}
                RenderError={<p>Access Denied</p>}
              >
                <DataTable
                  data={this.state.datas}
                  columns={[
                    /*{
                              title: "SL",
                              type: "String",
                              field: "slNo",
                            },*/
                    {
                      title: "Transaction Date",
                      type: "text",

                      render: (ro) => (
                        <div className=" ">
                          {formatDate(ro.transactions[0]?.transaction_date)}
                        </div>
                      ),
                    },
                    {
                      title: "Transaction ID",
                      type: "text",

                      render: (ro) => (
                        <div className=" ">
                          {ro.transactions[0]?.transaction_id}
                        </div>
                      ),
                    },
                    {
                      title: "Order ID",
                      type: "text",

                      render: (ro) => (
                        <div className=" ">
                          {ro.order?.id ? (
                            <Link
                              className="text-primary cursor-pointer"
                              to={`/orders-view/${ro.order?.id}`}
                            >
                              {ro.order?.order_number ?? "--"}
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                    },
                    {
                      title: "Payment method",
                      type: "text",
                      width: "100px",
                      render: (ro) => (
                        <div className="  ">
                          {ro.payment_method?.display_name}
                        </div>
                      ),
                    },

                    {
                      title: "Amount",
                      type: "amount",
                      field: "amount",
                    },

                    {
                      title: "External reference ID",
                      type: "text",
                      render: (ro) => (
                        <div className=" ">
                          {ro.transactions[0]?.external_reference_id}
                        </div>
                      ),
                    },
                    {
                      title: "Professional Fee",
                      type: "amount",
                      field: "professional_fee",
                    },
                    {
                      title: "Vendor Fee",
                      type: "amount",
                      field: "vendor_fee",
                    },
                    {
                      title: "Govt Fee",
                      type: "amount",
                      field: "govt_fee",
                    },
                    {
                      title: "GST Amount",
                      type: "amount",
                      field: "gst",
                    },
                    {
                      title: "Extra Received",
                      type: "amount",
                      field: "extra_received",
                    },
                    {
                      title: "Receipt Number",
                      type: "text",
                      render: (ro) => (
                        <div className=" ">
                          {ro.transactions[0]?.receipt_number}
                        </div>
                      ),
                    },

                    {
                      title: "Advance",
                      align: "center",
                      render: (ro) => (
                        <>{ro.transactions[0]?.is_advance && <MdCheck />}</>
                      ),
                    },

                    {
                      title: "Created by",
                      type: "text",

                      render: (ro) => (
                        <div className=" ">
                          {ro.created_by?.first_name} {ro.created_by?.last_name}
                        </div>
                      ),
                    },
                    {
                      title: "Document",

                      render: (ro) => (
                        <>
                          {ro.transactions[0]?.payment_document?.name && (
                            <div
                              className="d-flex align-content-center justify-content-between text-link cursor-pointer"
                              onClick={() =>
                                this.handleDownload(
                                  ro?.transactions[0]?.payment_document?.url,
                                )
                              }
                            >
                              <div>
                                {ro.transactions[0]?.payment_document?.name}
                              </div>
                              <div style={{ paddingLeft: "2px" }}>
                                <MdDownload />
                              </div>
                            </div>
                          )}
                        </>
                      ),
                    },

                    {
                      title: "Payment Type",
                      type: "text",
                      field: "payment_type_label",
                    },

                    {
                      title: "Payment Status",
                      type: "text",
                      field: "payment_status_label",
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "order_date",
                    pageNo: formSearch.page,
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                  }}
                  actions={[
                    {
                      className: "view",
                      rowActionCLick: this.handleViewOpen.bind(this),
                      iconName: "/app-assets/backend-images/icons/view.png",
                      tooltip: "View",
                    },
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.deleteOrderPayment],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "delete",
                            rowActionCLick: this.handleDeleteOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/delete-icon.svg",
                            tooltip: "Delete",
                          },
                        ]
                      : []),
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.cancelOrderPayment],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "view",
                            rowActionCLick: this.handleCancelOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/cancel-icon.svg",
                            tooltip: "Cancel",
                            show: (row) =>
                              row.payment_type === "link" &&
                              row.status === "link_created",
                          },
                        ]
                      : []),
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.resendOrderPaymentLink],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "view",
                            rowActionCLick:
                              this.handleResendLinkOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/refresh-icon-black.svg",
                            tooltip: "Resend Payment Link",
                            show: (row) =>
                              row.payment_type === "link" &&
                              row.status === "link_created",
                          },
                        ]
                      : []),
                  ]}
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <CancelComponent
          opencancel={this.state.opencancel}
          handleCancelClose={this.handleCancelClose}
          cancel={this.handleCancel}
        />
        <ResendLinkComponent
          openResendLink={this.state.openresendlink}
          handleResendLinkClose={this.handleResendLinkClose}
          resendLink={this.handleResendLink}
        />
        {this.state.openview && (
          <ViewPayment
            open={this.state.openview}
            data={this.state.mainData}
            handleClose={this.handleViewClose}
          />
        )}
      </div>
    );
  }
}
// export default PaymentsList;
const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
  };
};
export default connect(mapStateToProps)(PaymentsList);
