import React, { Component } from "react";
import { Link } from "react-router-dom";

import apis from "../../../../../apiactions/api.actions";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { createPackage, getPackage, servicecat, updatePackage } from "../../../apifunctions";
import Sectionelements from "./sectionelements";

let key = 1;
let masterData = [];
class PackageInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      datas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      sections: [],
      snackbarMessage: "",
      sectionData: {
        name: "",
        show_in_app: false,
        show_in_web: false,
      },
      sectionConut: 0,
      section: [],
      set_selected: false,
      elementList: [],
      pack_Id: this.props.pack_Id,
    };
    masterData = JSON.parse(localStorage.getItem("masterData")).service_tabs;

    this.handleChange = this.handleChange.bind(this);
    this.getservData = this.getservData.bind(this);
  }
  componentWillReceiveProps = () => {
    this.setState({ pack_Id: this.props.pack_Id });
  };
  setElements = () => {
    masterData = JSON.parse(localStorage.getItem("masterData")).service_tabs;
    if (masterData && masterData.length !== 0) {
      masterData.forEach((item) => {
        item["checkadd"] = false;
        item["is_selected"] = false;
      });
    }
  };
  componentDidMount = () => {
    if (this.props.pack_Id) {
      console.log("in cdm=======package-----", this.props.pack_Id);
      this.getservData(this.props.pack_Id);
    }
  };
  getservData(pack_Id) {
    this.setElements();
    this.setState({ loader: true });
    try {
      getPackage(pack_Id)
        .then((res) => res.data)
        .then((res) => {
          this.dataFilter(res.sections);
          setTimeout(() => {
            this.setState({
              formData: {
                service_category_name: res.service_category
                  ? res.service_category.service_category_name
                  : "",
                managed_by: res.managed_by,
                service_name: res.service_name,
                status: res.status.id,
                id: res.id,
                service_category: res.service_category
                  ? res.service_category.id
                  : "",
                description: res.description,
              },
            });
          }, 500);
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            loader: false,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  dataFilter = (res) => {
    let secData = res;
    let equip = [...masterData];
    if (secData && secData.length !== 0) {
      secData.forEach((item) => {
        item["readOnly"] = true;
        if (item.elements && item.elements.length !== 0) {
          item["added"] = true;
          item.elements.forEach((ele) => {
            ele["readOnly"] = true;
            ele["ids"] = ele.id;
            ele["tab"] = ele.key.tab;
            equip.forEach((elemen) => {
              if (ele.key.id === elemen.id) {
                let index = equip.indexOf(elemen);
                equip.splice(index, 1);
              }
            });
          });
        }
      });
      this.setState(
        { elementList: equip, sections: secData, loader: false },
        () => {
          key = key + 1;
        }
      );
    } else {
      this.setState(
        { elementList: masterData, sections: secData, loader: false },
        () => {
          key = key + 1;
        }
      );
    }
  };
  handleDataChange = (value, reason=undefined) => {
 
      let tag = servicecat + "?view=all&&search=" + value;
      this.getData(tag);
  
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSelectChange = (e, event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        service_category: e.id,
        service_category_name: e.service_category_name,
      },
    });
  };
  async getData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.service_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    if (this.state.formData.id) {
      updatePackage(this.state.formData, this.state.formData.id)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {
              this.getservData(this.state.formData.id);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            submited: false,
          });
        });
    } else {
      createPackage(this.state.formData)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: res.package_data,
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {
              this.props.handleParent(res.package_data.id);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "error",
          });
        });
    }
  };

  onSnackbarClose = (e) => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, datas, loader, sections, pack_Id, submited } = this.state;
    const { masterData } = this.props;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Package Category <span className="red">*</span>
                      </label>
                      <SelectBox
                        Data={datas}
                        id="id"
                        required
                        async="true"
                        placeholder="Service Category"
                        value={formData.service_category_name}
                        desc="service_category_name"
                        getData={this.handleDataChange}
                        className="form-control"
                        onChange={this.handleSelectChange}
                      />
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Package Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="service_name"
                        onChange={this.handleChange}
                        class="form-control"
                        value={formData.service_name}
                        placeholder="package name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Manged By <span className="red">*</span>
                      </label>
                      <select
                        name="managed_by"
                        onChange={this.handleChange}
                        required
                        value={formData.managed_by}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        {masterData.service_managed_by &&
                          masterData.service_managed_by.map((filt, ind) => (
                            <option key={filt} value={filt}>
                              {filt}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    {" "}
                    Status <span className="red">*</span>
                  </label>
                  <select
                    className="form-control"
                    onChange={this.handleChange}
                    required
                    name="status"
                    value={formData.status}
                  >
                    <option value="">Select</option>
                    {masterData.statuses &&
                      masterData.statuses.map((filt, ind) => (
                        <option key={filt.id} value={filt.id}>
                          {filt.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    {pack_Id && (
                      <div className="col-md-12 col-xs-12 sub-title">
                        <h4>Control Data Visibility in App & Web</h4>
                        <p>All Elements</p>
                      </div>
                    )}
                    <div className="col-md-12 col-xs-12">
                      <div className="row">
                        <Sectionelements
                          key={pack_Id ? pack_Id + key : key}
                          pack_Id={pack_Id}
                          elementList={this.state.elementList}
                          sections={sections}
                          handleRefresh={this.getservData}
                        />
                        <div className="col-md-12 col-xs-12 text-right">
                          <button
                            style={{ marginRight: 6 }}
                            onClick={(e) => this.props.handleCancel()}
                            className="btn btn-secondary"
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            disabled={submited}
                            type="submit"
                          >
                            {pack_Id ? "Update" : "Save"}
                          </button>
                          {pack_Id && (
                            <Link
                              className="btn btn-secondary"
                              onClick={(e) => this.props.handleTabView(1)}
                            >
                              Next
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default PackageInfo;
