import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrumb from "../../../layout/breadcrumb";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../plugins/customtab/customtab";
import { getRoleDetailsById, update_roles } from "../apifunctions";
import ManageRole from "../manage/addRole";
import RoleDetails from "./details";
import RolePermission from "./permission";
import SnackbarComponent from "../../../plugins/snackbar-component";
// import { get_Company_Details } from "../../apifunctions";

let tabData = [
  {
    name: "Details",
    id: 1,
  },
  {
    name: "Permissions",
    id: 2,
  },
];

const GroupDetails = (props) => {
  const param = useParams();
  const all_permissions = useSelector(
    (state) => state.permission.allPermissions
  );
  const [currentTab, setCurrentTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [edit, setEdit] = useState(
    props.history?.location?.state?.edit || false
  );
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [roleDate, setTeamData] = useState({});
  const [formData, setFormData] = useState({});

  const [rolepermission, setRolePermissions] = useState([]);
  const [openManage, setOpenManage] = useState(false);
  const detailRef = useRef(null);
  const permissionRef = useRef(null);
  const handleTabView = (val) => {
    setEdit(false);
    setCurrentTab(val);
  };
  const getTeamDetails = () => {
    setLoader(true);
    let all_per = all_permissions;
    getRoleDetailsById(param.id)
      .then((res) => {
        setTeamData(res);

        console.log(all_per);
        setRolePermissions(res?.permissions);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getTeamDetails();
  }, []);

  const handleClose = (flag = false) => {
    setOpenManage(false);

    if (flag) {
      getTeamDetails();
    }
  };
  const handleSubmit = (data) => {
    setSubmitting(true);

    try {
      update_roles(param?.id, data)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Updated Successfully");
          setSnackbarOpen(true);
          setSubmitting(false);
          setEdit(false);
          getTeamDetails();
        })
        .catch((err) => {
          setSnackbarVarient("error");
          setSnackbarMessage(
            err?.response?.data?.error?.message || "Something went wrong"
          );
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }
  };
  const handleManageUpdate = () => {
    if (currentTab === 1) {
      detailRef.current?.handleSave();
    } else if (currentTab === 2) {
      permissionRef.current?.handleSave();
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(null);
    setSnackbarVarient(null);
  };
  return (
    <>
      <div className="app-content content">
        <CommonLoader open={loader} />

        <SnackbarComponent
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          onSnackbarClose={onSnackbarClose}
          variant={snackbarVarient}
        />
        <div className="content-wrapper">
          <BreadCrumb type={"rolesDetails"} />
          <div className>
            <div className="content-body">
              <section id="configuration">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="customer-list-head customer-view">
                        <div className="details-box">
                          <div className="detail-box border-r">
                            {/* <div className="img-box">
                              <img
                                alt=""
                                src={
                                  custData.profile_image
                                    ? custData.profile_image.profile_thumbnail
                                    : ""
                                }
                              />
                            </div> */}
                            <div className="content">
                              <p className="name">
                                {/* display name */}
                                {roleDate?.name ?? "--"}
                                {/* <span
                                  className="tag yellow"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {custData.tier}
                                </span> */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="actions normalBtn">
                          {!edit ? (
                            <button
                              className="btn btn-primary"
                              title="Edit Details"
                              type="button"
                              onClick={(e) => setEdit(true)}
                            >
                              {/* <img src="/app-assets/backend-images/icons/edit-lg.png" /> */}
                              Change
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={(e) => {
                                  setEdit(false);
                                  getTeamDetails();
                                }}
                              >
                                {/* <img src="/app-assets/backend-images/icons/close.png" /> */}
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleManageUpdate()}
                              >
                                {/* <img src="/app-assets/backend-images/icons/save.png" /> */}
                                Update
                              </button>
                            </>
                          )}
                        </div>
                      </div>

                      <NavigationTab
                        tabData={tabData}
                        currentTab={currentTab}
                        handleTabView={handleTabView}
                      />
                      <div className="card-body">
                        <>
                          {currentTab === 1 && !loader && (
                            <RoleDetails
                              ref={detailRef}
                              data={roleDate}
                              edit={edit}
                              handleSubmit={handleSubmit}
                              handleRefresh={getTeamDetails}
                              rolepermission={rolepermission}
                            />
                          )}
                          {currentTab === 2 && !loader && (
                            <RolePermission
                              data={roleDate}
                              ref={permissionRef}
                              edit={edit}
                              handleSubmit={handleSubmit}
                              handleRefresh={getTeamDetails}
                              rolepermission={rolepermission}
                            />
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupDetails;
