import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useRef, useState } from "react";

import CommonLoader from "../../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../../plugins/snackbar-component";
import { addTeamToEmployee, getEmployeeTeam } from "../../../../apifunctions";
import TeamDialogTheme from "./dialogTheme";

const ManageEmployeeTeam = ({
  open = false,
  refetch = () => {},
  employee_id,
  handleClose = () => {},
}) => {
  const submitRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [actionType, setActionType] = useState("close");
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [serviceData, setServiceData] = useState([]);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = { team_id: formData?.id };

    try {
      addTeamToEmployee(employee_id, payload)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Added Successfully");
          setSnackbarOpen(true);
          setSubmitting(false);
          setFormData((f) => ({ name: null, id: null }));
          if (actionType === "close") {
            setTimeout(() => {
              handleClose(true);
            }, 500);
          }
          refetch();
        })
        .catch((err) => {
          setSnackbarVarient("error");
          setSnackbarMessage(err?.response?.data?.error?.message);
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }
  };
  const fetServiceBySearch = (e) => {
 
    getEmployeeTeam({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      console.log(res);
      setServiceData(res?.results || []);
    });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    console.log(e, a, b);
    setFormData((f) => ({
      ...f,
      name: e["name"],
      id: e["id"],
    }));
  };
  useEffect(() => {
    fetServiceBySearch();
  }, []);
  console.log("hi");
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {"Add  Team"}</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
              <label className="control-label">
              Team member<span className="red">*</span>
              </label>

              <SelectBox
                Data={serviceData}
                id="id"
                async="true"
                required
                value={formData.name ? formData.name : ""}
                desc="name"
                descId="id"
                getData={fetServiceBySearch}
                placeholder="Select Team"
                className="form-control"
                onChange={handleServiceChange}
              />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  setActionType("save");
                  setTimeout(() => {
                    submitRef.current.click();
                  }, 100);
                }}
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  setActionType("close");
                  setTimeout(() => {
                    submitRef.current.click();
                  }, 100);
                }}
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save & Close"}
              </Button>
              <div className="d-none">
                <button ref={submitRef} type="submit">
                  submit
                </button>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageEmployeeTeam;
