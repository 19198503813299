import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import apis from "../../../apiactions/api.actions";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  constitutioncat,
  constitution_params,
  parameter_options,
} from "../apifunctions";
import { FaSave } from "react-icons/fa";

class ConstitutionCategoriesCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarVariant: "",
      formData: {},
      constitution_parameters_list: [],
      mainId: "",
      opendelete: false,
      parameters_options: [],
    };
  }

  componentDidMount = () => {
    this.getData();
    this.getParameterOptions();
  };

  async getData() {
    try {
      apis
        .getDatas(constitutioncat + "/" + this.props.match.params.id)
        .then((res) => {
          this.setState({
            formData: res,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    apis
      .updateData(
        constitutioncat + "/" + this.props.match.params.id,
        this.state.formData
      )
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            setTimeout(() => {
              this.props.history.push(
                "/data-management/constitution-categories"
              );
            }, 2500);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleClose = () => {
    this.props.history.push("/data-management/constitution-categories");
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleChangeParam = (idx) => (e) => {
    const { constitution_parameters_list } = this.state;
    if (e.target.value) {
      let formsvalue = {
        [e.target.name]: e.target.value,
        constitution_category: this.state.formData.id,
      };

      constitution_parameters_list[idx] = formsvalue;
      this.setState({
        constitution_parameters_list,
      });
    }
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDelete = () =>
    apis.deleteData(constitution_params + "/" + this.state.mainId).then(() => {
      this.getData();
    });

  handleRemoveSpecificParam = (idx) => () => {
    let parameter_options_id = this.state.formData.category_parameters[idx].id;
    this.setState({
      opendelete: true,
      mainId: parameter_options_id,
    });
  };
  handleAddConstitutionParam = (idx) => () => {
    if (this.state.constitution_parameters_list[idx]?.parameter_name) {
      try {
        this.addConstitutionParams();
      } catch (err) {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      }
    } else {
      this.setState({
        snackbarMessage: "Please select a parameter",
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };
  handleAddParam = () => {
    const item = {
      parameter_option: [],
      constitution_category: this.state.formData.id,
      parameter_name: "",
    };
    let category_parameters = this.state.formData.category_parameters;
    category_parameters.push(item);
    this.setState({
      formData: {
        ...this.state.formData,
        category_parameters: category_parameters,
      },
    });
  };

  async addConstitutionParams() {
    let constitution_parameters_list_length =
      this.state.constitution_parameters_list.length - 1;

    try {
      apis
        .createData(
          constitution_params + "?",
          this.state.constitution_parameters_list[
            constitution_parameters_list_length
          ]
        )
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Parameter Added Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              this.getData();
              setTimeout(() => {
                this.props.history.push(
                  "/data-management/constitution-categories-edit/" +
                    res.data.constitution_parameter_data.constitution_category
                );
              }, 2500);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      this.setState({
        snackbarMessage: err.message,
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  }

  async getParameterOptions() {
    try {
      apis
        .getDatas(parameter_options + "?view=all")
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            parameters_options: res.parameter_options,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      this.setState({
        snackbarMessage: err.message,
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  }
  render() {
    const { masterData } = this.props;
    const { formData, parameters_options, constitution_parameters_list } =
      this.state;
    const textareaStyle = {
      minHeight: "135px",
    };

    return (
      <div className="create-content">
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} className=" itemCenter">
                <div className="card-title">
                  <h4>Edit Constitution Category</h4>
                </div>
              </Grid>
              <Grid align="right" item xs={6} sm={6}>
                <button
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                  type="button"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </Grid>
            </Grid>
            <div className="card-content cardContentBlock">
              <div className="row">
                <div className="col-md-10 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Display Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="constitution_category_display_name"
                        value={formData.constitution_category_display_name}
                        onChange={this.handleChange}
                        class="form-control"
                        required
                        placeholder="Constitution Category Display Name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Status <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        required
                        onChange={this.handleChange}
                        value={formData.status}
                      >
                        <option value="" selected>
                          Status
                        </option>
                        {masterData &&
                          masterData.statuses &&
                          masterData.statuses.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Category Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="constitution_category_name"
                        value={formData.constitution_category_name}
                        onChange={this.handleChange}
                        class="form-control"
                        required
                        placeholder="Constitution Category Name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Category Code <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="constitution_category_code"
                        value={formData.constitution_category_code}
                        onChange={this.handleChange}
                        class="form-control"
                        required
                        placeholder="Constitution Category Code"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Description</label>
                      <textarea
                        name="description"
                        class="form-control"
                        placeholder="Description"
                        value={formData.description}
                        onChange={this.handleChange}
                        rows="5"
                        style={textareaStyle}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-xs-12 upload-block upload-block01 ">
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label">Thumbnail Image </label>
                    <div className="upload-img">
                      <input
                        type="file"
                        onChange={this.handleFileChange}
                        className="file-upload"
                        id="img-upload"
                      />
                      <label for="img-upload">
                        <img alt="" id="output" />
                        <div className="upload-icon">
                          <img src="/app-assets/backend-images/icons/camera.svg" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="linedivider m-0"></div>
                <div className="col-md-12 col-xs-12">
                  <a
                    className="btn btn-secondary m-0"
                    onClick={this.handleAddParam}
                  >
                    + Add New Parameter
                  </a>
                </div>

                <div className="col-md-12 col-xs-12 row">
                  {formData.category_parameters &&
                    formData.category_parameters.map((item, idx, arr) => (
                      <div className="col-md-4 col-xs-12 new-parameter">
                        <div className="position-relative">
                          <label className="control-label">
                            Parameter Options
                          </label>

                          <select
                            className="form-control"
                            name="parameter_name"
                            required
                            value={
                              constitution_parameters_list[idx] &&
                              constitution_parameters_list[idx].parameter_name
                                ? constitution_parameters_list[idx]
                                    .parameter_name
                                : item.parameter_option
                                ? item.parameter_option.id
                                : ""
                            }
                            disabled={
                              item.parameter_option.parameter_name
                                ? "disabled"
                                : ""
                            }
                            onChange={this.handleChangeParam(idx)}
                          >
                            <option value="">Select Parameter</option>
                            {parameters_options.map((param, index) => (
                              <option value={param.id}>
                                {param.admin_portal_name}
                              </option>
                            ))}
                          </select>
                          {!item.parameter_option.parameter_name ? (
                            <a
                              className="save-icon"
                              onClick={this.handleAddConstitutionParam(idx)}
                            >
                              {/* <img src="/app-assets/backend-images/icons/save.png" /> */}
                              <FaSave />
                            </a>
                          ) : (
                            <a
                              className="del-icon"
                              onClick={this.handleRemoveSpecificParam(idx)}
                            >
                              <img src="/app-assets/backend-images/icons/delete.png" />
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default ConstitutionCategoriesCreate;
