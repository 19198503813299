import React, { useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import CloseIcon from "@material-ui/icons/Close";
import RoleDialogTheme from "./dialogTheme";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { add_employee, update__employee } from "../apifunctions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import { EMAIL_REGEX } from "../../../utils/formValidate";

const ManageEmployee = ({
  open = false,
  data = {},
  handleClose = () => {},
  refetch = () => {},
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });
  const emailRef = React.useRef(null);
  const mobileRef = React.useRef(null);
  const generateErrorMessage = (err) => {
    let errMessage = "Something went wrong!";

    const errorArray = [];
    if (err.response.data.errors) {
      if (Array.isArray(err.response.data.errors)) {
        err.response.data.errors.forEach((item) => {
          console.log(item);
          if (item.field === "message") {
            errorArray.push(item.message);
          }
        });

        // });
        errMessage = String(errorArray);
        // } else {
        //   errMessage = "Something went wrong!";
      }
    } else {
      if (err?.response?.data?.error?.message) {
        errMessage = err?.response?.data?.error?.message;
      } else {
        errMessage = "Something went wrong!";
      }
    }
    return errMessage;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    const payload = {
      user: formData,
    };
    payload.user.username = payload.user.email;
    if (formData?.id) {
      try {
        update__employee(formData?.id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            handleClose(true);
            refetch();
            // setTimeout(() => {

            //   //
            // }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setSnackbarVarient("error");
            setSnackbarVarient("error");
            setSnackbarMessage(generateErrorMessage(err));
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      try {
        add_employee(payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            // handleClose(true);
            refetch();
            setTimeout(() => {
              handleClose(true);
              // refetch();
            }, 500);
          })
          .catch((err) => {
            console.log(err.response);
            setSnackbarVarient("error");
            setSnackbarMessage(generateErrorMessage(err));
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    if (e.target.name === "email") {
      const { validity } = emailRef.current;
      console.log(validity);
      if (validity.patternMismatch) {
        emailRef.current.setCustomValidity("Enter a valid email");
      } else {
        emailRef.current.setCustomValidity("");
      }
    }
    if (e.target.name === "mobile") {
      const { validity } = mobileRef.current;
      console.log(validity);
      if (validity.patternMismatch) {
        mobileRef.current.setCustomValidity(
          "Enter a valid 10 digit mobile number starts with +91"
        );
      } else {
        mobileRef.current.setCustomValidity("");
      }
    }
    // if (e.target.name === "mobile") {
    //   value = value.replace(/[^\d]/g, "");
    // }
    setFormData((f) => ({ ...f, [e.target.name]: value }));
  };
  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  console.log(formData);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {formData?.id ? "Edit Employee" : "Add Employee"}</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>
                  First Name<span className="red">*</span>
                </InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="first_name"
                  value={formData.first_name ? formData.first_name : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter First Name"
                />
              </div>
              <div>
                <InputLabel>
                  Last Name<span className="red">*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  name="last_name"
                  value={formData.last_name ? formData.last_name : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter Last Name"
                />
              </div>
              <div>
                <InputLabel>
                  Email<span className="red">*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  inputRef={emailRef}
                  name="email"
                  inputProps={{ pattern: EMAIL_REGEX }}
                  disabled={formData?.id}
                  value={formData.email ? formData.email : ""}
                  onChange={handleChange}
                  type="email"
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter Email"
                />
              </div>
              {/* <div>
                <InputLabel>Username</InputLabel>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  name="username"
                  value={formData.username ? formData.username : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="fieldInput"
                />
              </div> */}

              <div>
                <InputLabel>
                  Mobile<span className="red">*</span>
                </InputLabel>

                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  name="mobile"
                  inputRef={mobileRef}
                  value={formData.mobile ? formData.mobile : ""}
                  onChange={handleChange}
                  onKeyPress={(e) => validateNumber(e, /[+0-9]|\./)}
                  inputProps={{
                    minLength: 13,
                    maxLength: 13,
                    pattern: "^[+][9][1][0-9]{10}$",
                  }}
                  type="text"
                  // disabled={formData?.id}
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter Mobile"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {formData?.id ? "Update" : "Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageEmployee;
