import { debounce } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";

import CalendarComponent from "../../../../../plugins/calendar.comp";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  getContactDuplicate,
  getCustomerBasic,
  saveCustomerBasic,
  updateCustomerBasic,
} from "../../../apifunctions";

// let serId = "";

class BasicInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      duplicates: {},
      // customer_basic_info: {},
      formData: {
        // country: "India",
        // state: "Kerala",
        // city: "Alappuzha",
        preferred_language: "English",
      },
      country_list: "",
      state_list: "",
      custId: props.custId,
    };
    this.emailRef = React.createRef();
    this.email2ref = React.createRef();
    this.mobilerefRef = React.createRef();
    this.mobile22ref = React.createRef();
  }
  componentWillReceiveProps = () => {
    this.setState({
      custId: this.props.custId,
    });
  };
  componentDidMount = () => {
    // this.loadLocation();
    // if (this.state.custId) {
    //   this.props.loadFlag(this.state.custId);
    // }
    if (this.state.custId) {
      this.getData(this.state.custId);
    }
  };
  // loadLocation() {
  //   try {
  //     getLocationList()
  //       .then((res) => res.data)
  //       .then((res) => {
  //         this.setState({
  //           country_list: res.countries,
  //           state_list: res.state,
  //         });
  //       })
  //       .catch((err) => {});
  //   } catch (err) {}
  // }
  async getData(custId) {
    this.setState({ loader: true });
    // try {
    getCustomerBasic(custId)
      .then((res) => {
        // console.log(res);
        this.setState({
          // customer_basic_info: res,
          formData: {
            ...this.state.formData,
            id: res.id,
            first_name: res.default_contact
              ? res.default_contact.first_name
              : "",
            last_name: res.default_contact ? res.default_contact.last_name : "",
            email: res.default_contact ? res.default_contact.email : "",
            email_2: res.default_contact ? res.default_contact.email_2 : "",
            mobile: res.default_contact ? res.default_contact.mobile : "",
            mobile_2: res.default_contact ? res.default_contact.mobile_2 : "",
            date_of_birth: res.date_of_birth
              ? moment(res.date_of_birth).toDate()
              : "",
            preferred_language: res.preferred_language
              ? res.preferred_language.language_name
              : "",
          },
          loader: false,
        });
      })
      .catch((err) => {
        var error_message = this.props.loadError(
          err?.response?.data?.error?.message
        );
        this.setState({
          snackbarMessage: error_message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
    // } catch (err) {}
  }

  getContactDuplicateData = debounce((fieldName, value) => {
    getContactDuplicate({
      [fieldName]: value,
      contact_id: this.state.custId ? this.state.custId : "",
    })
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          duplicates: {
            [fieldName]: res ?? [],
          },
        });
      });
  }, 1000);

  handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();

    this.updateFormData(name, trimmedValue);
    this.validateField(name, trimmedValue);
    this.checkForDuplicates(name, trimmedValue);
  };

  updateFormData = (name, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };

  validateField = (name, value) => {
    const fieldInfo = this.getFieldInfo(name);

    if (!fieldInfo) return;

    const { ref, errorMessage } = fieldInfo;
    const isMismatch = ref.current.validity.patternMismatch;
    this.setValidity(ref, errorMessage, isMismatch);
  };

  getFieldInfo = (name) => {
    const companyContactFieldInfo = {
      email: { ref: this.emailRef, errorMessage: "Enter a valid email" },
      email_2: { ref: this.email2ref, errorMessage: "Enter a valid email" },
      mobile: {
        ref: this.mobilerefRef,
        errorMessage: "Enter a valid 10 digit mobile number starts with +91",
      },
      mobile_2: {
        ref: this.mobile22ref,
        errorMessage: "Enter a valid 10 digit mobile number starts with +91",
      },
    };

    return companyContactFieldInfo[name];
  };

  setValidity = (ref, errorMessage, isMismatch) => {
    ref.current.setCustomValidity(isMismatch ? errorMessage : "");
  };

  checkForDuplicates = (name, value) => {
    const valueToPass = this.getEncodedValue(name, value);
    this.getContactDuplicateData(name, valueToPass);
  };

  getEncodedValue = (name, value) => {
    return ["mobile", "mobile_2"].includes(name)
      ? encodeURIComponent(value)
      : value;
  };

  modifyJson(obj) {
    Object.keys(obj).forEach((key) => obj[key] == "" && delete obj[key]);
    return obj;
  }
  handleDatePicker = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.name]: e.value,
      },
    });
    // setTimeout(() => {
    //   console.log(this.state.formData.dob, e.name);
    // }, 500);
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  refrainData = (formData) => {
    Object.entries(formData).map(([attr, value]) => {
      if (value === "" || value === null) delete formData[attr];
    });
    this.setState({
      formData: formData,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.formData.email_2 === this.state.formData.email) {
      this.setState({
        snackbarMessage: "Primary and Secondary email should not be same",
        snackbarOpen: true,
        snackbarVariant: "error",
        loader: false,
      });
    } else {
      if (this.state.formData.mobile_2 === this.state.formData.mobile) {
        this.setState({
          snackbarMessage:
            "Primary and Secondary phone number should not be same",
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      } else {
        this.setState({ loader: true });
        if (this.state.custId) {
          this.refrainData(this.state.formData);
          console.log(this.state.formData, "this.state.formData");
          updateCustomerBasic(this.state.custId, {
            ...this.state.formData,
            date_of_birth: this.state.formData.date_of_birth
              ? moment(this.state.formData.date_of_birth).format("YYYY-MM-DD")
              : "",
            preferred_language:
              this.state.formData.preferred_language &&
              this.state.formData.preferred_language.length > 0
                ? this.state.formData.preferred_language
                : null,
            last_name:
              this.state.formData.last_name &&
              this.state.formData.last_name.length > 0
                ? this.state.formData.last_name
                : "",
          })
            .then((res) => {
              this.setState({
                snackbarMessage: "Updated Successfully",
                snackbarOpen: true,
                snackbarVariant: "success",
                loader: false,
              });
            })
            .catch((err) => {
              var error_message = this.props.loadError(
                err?.response?.data?.error?.message
              );
              this.setState({
                snackbarMessage: error_message,
                snackbarOpen: true,
                snackbarVariant: "error",
                loader: false,
              });
            });
        } else {
          console.log(this.state.formData, "this.state.formData");
          saveCustomerBasic({
            ...this.state.formData,
            date_of_birth: this.state.formData.date_of_birth
              ? moment(this.state.formData.date_of_birth).format("YYYY-MM-DD")
              : "",
            preferred_language:
              this.state.formData.preferred_language &&
              this.state.formData.preferred_language.length > 0
                ? this.state.formData.preferred_language
                : null,
            last_name:
              this.state.formData.last_name &&
              this.state.formData.last_name.length > 0
                ? this.state.formData.last_name
                : "",
          })
            .then((res) => res.data)
            .then((res) => {
              this.setState(
                {
                  formData: { ...this.state.formData, id: res.id },
                  snackbarMessage: "Created Successfully",
                  snackbarOpen: true,
                  snackbarVariant: "success",
                  loader: false,
                },

                () => {
                  setTimeout(() => {
                    this.props.updateTab(this.state.formData.id);
                  }, 500);
                }
              );
            })
            .catch((err) => {
              console.log("err", err);
              var error_message = this.props.loadError(
                err?.response?.data?.error?.message
              );
              this.setState({
                snackbarMessage: error_message,
                snackbarOpen: true,
                snackbarVariant: "error",
                loader: false,
              });
            });
        }
      }
    }
  };
  validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  blurChange = (fieldName) => {
    this.setState({
      duplicates: { ...this.state.duplicates, [fieldName]: [] },
    });
  };
  render() {
    const { masterData, editflag } = this.props;
    const {
      formData,
      // customer_basic_info,
      loader,
      custId,
      country_list,
      state_list,
    } = this.state;
    // const { user } = formData.default_contact
    //   ? formData.default_contact
    //   : "";
    // var user = formData.default_contact;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form
            className="edit-form"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            {/* <Grid container spacing={1}>
              {/* <Grid item xs={6} sm={6}>
                <h4 className="card-title">
                  {custId ? "" : "New "}Contact Details
                </h4>
              </Grid> 
               <Grid align="right" item xs={12} sm={12}>
                <button
                  style={{ marginRight: 6 }}
                  onClick={(e) => this.props.handleClose()}
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button className="btn btn-primary" type="submit">
                  {custId ? "Update" : "Save"}
                </button>

                {custId && (
                  <a
                    href={void 0}
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(2)}
                  >
                    Next
                  </a>
                )}
              </Grid> 
            </Grid> */}
            <div className="card-content">
              <div className="row">
                <div className="col-md-10 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        First Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="first_name"
                        value={formData.first_name ? formData.first_name : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder="Enter First Name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        value={formData.last_name ? formData.last_name : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder="Enter Last Name"
                      />
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Email <span className="red">*</span>
                      </label>
                      <input
                        type="email"
                        pattern="^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$"
                        required
                        ref={this.emailRef}
                        name="email"
                        value={formData.email ? formData.email : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        onBlur={() => this.blurChange("email")}
                        placeholder="Enter Email"
                      />
                      {this.state.duplicates.email?.length > 0 && (
                        <ul className="duplicate-dropdown-list">
                          <li>{"Possible Duplicates"}</li>
                          {this.state.duplicates.email?.map(
                            (duplicate, index) => (
                              <li key={index}>
                                {duplicate.default_contact?.email} -{" "}
                                {duplicate.default_contact?.first_name}
                                {duplicate.default_contact?.last_name}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Mobile <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        ref={this.mobilerefRef}
                        required
                        pattern="\+91\d{10}$"
                        name="mobile"
                        minLength={13}
                        maxLength={13}
                        value={formData.mobile ? formData.mobile : ""}
                        onKeyPress={(e) => this.validateNumber(e, /[+0-9]|\./)}
                        class="form-control"
                        onChange={this.handleChange}
                        onBlur={() => this.blurChange("mobile")}
                        placeholder="Enter Mobile"
                      />
                      {this.state.duplicates.mobile?.length > 0 && (
                        <ul className="duplicate-dropdown-list">
                          <li>{"Possible Duplicates"}</li>
                          {this.state.duplicates.mobile?.map(
                            (duplicate, index) => (
                              <li key={index}>
                                {duplicate.default_contact?.mobile} -{" "}
                                {duplicate.default_contact?.first_name}
                                {duplicate.default_contact?.last_name}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">Secondary Email</label>
                      <input
                        type="email"
                        ref={this.email2ref}
                        pattern="^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$"
                        name="email_2"
                        value={formData.email_2 ? formData.email_2 : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        onBlur={() => this.blurChange("email_2")}
                        placeholder="Enter Secondary Email"
                      />
                      {this.state.duplicates.email_2?.length > 0 && (
                        <ul className="duplicate-dropdown-list">
                          <li>{"Possible Duplicates"}</li>
                          {this.state.duplicates.email_2?.map(
                            (duplicate, index) => (
                              <li key={index}>
                                {duplicate.default_contact?.email_2} -{" "}
                                {duplicate.default_contact?.first_name}
                                {duplicate.default_contact?.last_name}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">Secondary Mobile</label>
                      <input
                        type="text"
                        name="mobile_2"
                        value={formData.mobile_2 ? formData.mobile_2 : ""}
                        class="form-control"
                        minLength={13}
                        pattern="\+91\d{10}$"
                        maxLength={13}
                        ref={this.mobile22ref}
                        onKeyPress={(e) => this.validateNumber(e, /[+0-9]|\./)}
                        onChange={this.handleChange}
                        onBlur={() => this.blurChange("mobile_2")}
                        placeholder="Enter Secondary Mobile"
                      />
                      {this.state.duplicates.mobile_2?.length > 0 && (
                        <ul className="duplicate-dropdown-list">
                          <li>{"Possible Duplicates"}</li>
                          {this.state.duplicates.mobile_2?.map(
                            (duplicate, index) => (
                              <li key={index}>
                                {duplicate.default_contact?.mobile_2} -{" "}
                                {duplicate.default_contact?.first_name}
                                {duplicate.default_contact?.last_name}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Date of Birth
                        {/* <span className="red">*</span> */}
                      </label>
                      <CalendarComponent
                        // required
                        onChange={this.handleDatePicker}
                        name="date_of_birth"
                        maxDate={new Date()}
                        selected={formData.date_of_birth}
                        className="form-control"
                      />
                      {/* <input
                        type="text"
                        required
                        name="dob"
                        value={formData.dob}
                        class="form-control"
                        // onChange={this.handleChange}
                        placeholder=""
                      /> */}
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Preferred Language
                      </label>
                      <select
                        className="form-control"
                        name="preferred_language"
                        value={
                          formData.preferred_language
                            ? formData.preferred_language
                            : ""
                        }
                        onChange={this.handleChange}
                      >
                        <option value="">Please Choose</option>
                        {masterData &&
                          masterData.languages &&
                          masterData.languages.map(({ id, language_name }) => (
                            <option key={id} value={language_name}>
                              {language_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-xs-12 upload-block upload-block01 ">
                  <label className="control-label">Profile Image </label>
                  <div className="upload-img">
                    <input
                      type="file"
                      className="file-upload"
                      id="img-upload"
                    />
                    <label for="img-upload">
                      <img src="/app-assets/backend-images/default.png" />
                      <div className="upload-icon">
                        <img src="/app-assets/backend-images/icons/camera.svg" />
                      </div>
                    </label>
                  </div>
                </div>
                {/* <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Country <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="country"
                    value={formData.country ? formData.country : "IN"}
                    onChange={this.handleChange}
                    disabled="disabled"
                  >
                    {country_list &&
                      country_list.map(({ code, name }) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    State <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="state"
                    value={formData.state ? formData.state : ""}
                    onChange={this.handleChange}
                  >
                    {state_list &&
                      state_list.map(({ code, name }) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    City <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="city"
                    value={formData.city}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div> */}

                <div className="col-md-10 col-xs-12">
                  <div className="row">
                    {/* <div className="col-md-4 col-xs-12">
                      <label className="control-label">Specialization</label>
                      <select
                        className="form-control"
                        name="specialization"
                        value={
                          formData.specialization ? formData.specialization : ""
                        }
                        onChange={this.handleChange}
                      >
                        <option value="">Please Choose</option>
                        <option value={"specialization"}>specialization</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        Education Qualification
                      </label>
                      <select
                        className="form-control"
                        name="educational_qualification"
                        value={
                          formData.educational_qualification
                            ? formData.educational_qualification
                            : ""
                        }
                        onChange={this.handleChange}
                      >
                        <option value="">Please Choose</option>
                        <option value={"educational_qualification"}>
                          Education
                        </option>
                      </select>
                    </div> */}
                    {/* <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Number of Accounts <span className="red"></span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        // required
                        name="no_of_accounts"
                        onKeyPress={(e) => this.validateNumber(e, /[0-9]/)}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder="Enter Number of Accounts"
                      />
                    </div> */}
                  </div>
                </div>

                <div className="col-md-12 col-xs-12 text-right">
                  <button
                    style={{ marginRight: 6 }}
                    onClick={(e) => this.props.handleClose()}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    {custId ? "Update" : "Save"}
                  </button>

                  {custId && (
                    <a
                      href={void 0}
                      className="btn btn-secondary"
                      onClick={(e) => this.props.handleTabView(2)}
                    >
                      Next
                    </a>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default BasicInformation;
