import React, { Component } from "react";

import { connect } from "react-redux";
import { SCOPES } from "../../../../../config/permission-maps";
import PermissionsGate, {
  hasPermission,
} from "../../../../../config/permissiongate";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/newDeleteComponent";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  deleteContactAccounts,
  getContactAccounts,
  updateContactPrimaryAccount,
} from "../../../apifunctions";
import ManageAccount from "../manage/addAccount";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import Swal from "sweetalert2";

// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";

class BusinessProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      totalCount: "",
      opendelete: false,
      mainId: "",
      pagesize: "",
      loader: false,
      openModal: false,
      custBus: [],
      page: 1,
      anchorEl: null,
      activeRowId: null,
      activeRow: {},
    };
  }
  componentDidMount = () => {
    if (this.props.custId) {
      this.getData(this.props.custId);
    }
  };
  handleClose = (flag) => {
    this.setState({ openModal: false }, () => {
      if (flag) {
        this.getData(this.props.custId);
        this.props.refetch();
      }
    });
  };
  getData = (serId) => {
    this.setState({ loader: true });

    getContactAccounts(serId, this.state.page)
      .then((res) => res.data)
      .then((res) => {
        console.log(res);
        this.setState({
          custBus: res,
          loader: false,
          // totalCount: res.count,
          // pagesize: res.page_size,
        });
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  // handleChange = (e) => {
  //   this.setState({
  //     formData: {
  //       ...this.state.formData,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (this.state.formData.id) {
  //     updateService_Benefits(serId, this.state.formData.id, this.state.formData)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             snackbarMessage: "Updated Successfully",
  //             snackbarOpen: true,
  //             snackbarVariant: "success",
  //           },
  //           () => {}
  //         );
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err?.response?.data?.error?.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //         });
  //       });
  //   } else {
  //     createService_Benefits(serId, this.state.formData)
  //       .then((res) => res.data)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             formData: res.service_benefits,
  //             snackbarMessage: "Created Successfully",
  //             snackbarOpen: true,
  //             snackbarVariant: "success",
  //           },
  //           () => {}
  //         );
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err?.response?.data?.error?.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //         });
  //       });
  //   }
  // };
  handlepagination(id) {
    this.setState(
      {
        page: id,
      },
      () => {
        this.getData(this.props.custId);
      }
    );
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e) => {
    this.setState({
      opendelete: true,
      mainId: this.state.activeRow.id,
    });
  };
  handleDelete = () => {
    deleteContactAccounts(this.props.custId, this.state.mainId).then(() => {
      this.getData(this.props.custId);
    });
    this.handleDeleteClose();
  };
  makeAsPrimary = (reqData) => {
    console.log("req data", reqData);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to make this as primary?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        updateContactPrimaryAccount(this.props.custId, {
          company_id: this.state.activeRow.company.id,
        }).then(() => {
          this.setState({
            loader: false,
            snackbarMessage: "Primary account updated",
            snackbarOpen: true,
            snackbarVariant: "success",
          });
          this.getData(this.props.custId);
          this.props.refetch();
        });
      }
    });
  };
  openMenu = (e, rowData) => {
    this.setState({ activeRowId: rowData?.id, activeRow: rowData }, () => {
      this.setState({ anchorEl: e.target });
    });
  };
  handleViewClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { formData, loader, totalCount, pagesize, custBus, anchorEl } =
      this.state;
    const { isAdmin, custData } = this.props;
    return (
      <div className="create-content">
        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e) => this.handleViewClose(e)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              // marginRight:100,
              width: 200,
              padding: 0,
              left: 0,
              // right: 80,
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
        >
          {custData?.primary_company?.id !==
          this.state.activeRow?.company?.id ? (
            <MenuItem onClick={(e) => this.makeAsPrimary(e)}>
              Make as Primary Account
            </MenuItem>
          ) : (
            ""
          )}
          <MenuItem onClick={(e) => this.handleDeleteOpen(e)}>Delete</MenuItem>
        </Menu>
        <CommonLoader open={loader} />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        {this.state.openModal && (
          <ManageAccount
            open={this.state.openModal}
            handleClose={this.handleClose}
            custId={this.props.custId}
          />
        )}
        <div className="content-body">
          {/* <form className="edit-form" onSubmit={this.handleSubmit}> */}
          <div className="card-content">
            <div className="row">
              <div className="titlehead col-md-12 col-xs-12 d-flex justify-content-between">
                <div>
                  {" "}
                  <h4>Account Profile</h4>
                </div>
                <div>
                  {" "}
                  <PermissionsGate scopes={[SCOPES.editContact]}>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.setState({ openModal: true })}
                    >
                      + &nbsp; Add New
                    </button>
                  </PermissionsGate>
                </div>
              </div>
              <div className="col-md-12 col-xs-12 table-block">
                <DataTable
                  data={custBus}
                  columns={[
                    {
                      title: "Account ID",
                      type: "text",
                      field: "company.company_code",
                      render: (rowData) =>
                        rowData.company.company_code && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={`/accounts-list-view/${rowData?.company.id}`}
                          >
                            {rowData.company.company_code}
                          </Link>
                        ),
                    },
                    // {
                    //   title: "Date of Incorporation",
                    //   type: "text",
                    //   field: "incorporation_date",
                    //   render: (rowData) =>
                    //     rowData.company.incorporation_date ? (
                    //       <span>{rowData.company.incorporation_date}</span>
                    //     ) : (
                    //       <span>--</span>
                    //     ),
                    // },
                    {
                      title: "Business Name",
                      type: "text",
                      field: "business_name",
                      render: (rowData) =>
                        rowData.company.business_name && (
                          <>
                            <span>{rowData.company.business_name}</span>
                            {rowData?.company.id ===
                              custData.primary_company?.id && (
                              <span
                                span
                                style={{
                                  backgroundColor: "#299956",

                                  padding: "2px 8px",
                                  borderRadius: "6px",
                                  color: "#fff",
                                  marginLeft: 5,
                                  fontSize: "11px",
                                }}
                              >
                                Primary Account
                              </span>
                            )}
                          </>
                        ),
                    },
                    {
                      title: "Category",
                      type: "text",
                      field: "constitution_category_name",
                      render: (rowData) =>
                        rowData.company.constitution && (
                          <span>
                            {
                              rowData.company.constitution
                                .constitution_category_name
                            }
                          </span>
                        ),
                    },

                    {
                      title: "Sector",
                      type: "text",
                      field: "business_sector_name",
                      render: (rowData) =>
                        rowData.company.sector && (
                          <span>
                            {rowData.company.sector.business_sector_name}
                          </span>
                        ),
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "business_name",
                    pageNo: this.state.page,
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                  }}
                  actions={[
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.editContact],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "delete",
                            rowActionCLick: this.openMenu.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/menu-dot.svg",
                            tooltip: "Menu",
                          },
                        ]
                      : []),
                  ]}
                />
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
  };
};
export default connect(mapStateToProps)(BusinessProfile);
