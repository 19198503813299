const home = { name: "Home", path: "/dashboard", isActive: false };
const dataManagement = {
  name: "Data Management",
  path: "/data-management/service-categories",
  isActive: true,
};

const servicecategoriesList = {
  name: "Service Categories",
  path: "/data-management/service-categories",
  isActive: true,
};
const editServiceCategory = {
  name: "Edit Service Category",
  path: "",
  isActive: true,
};
const addServiceCategory = {
  name: "Add New Service Category",
  path: "",
  isActive: true,
};

const constitutioncategoriesList = {
  name: "Consitution Categories",
  path: "",
  isActive: true,
};
const editConstitutionCategory = {
  name: "Edit Consitution Category",
  path: "",
  isActive: true,
};
const addConstitutionCategory = {
  name: "Add New Consitution Category",
  path: "",
  isActive: true,
};

const businesssectorList = {
  name: "Business Sector",
  path: "",
  isActive: true,
};
const editBusinessSector = {
  name: "Edit Business Sector",
  path: "",
  isActive: true,
};
const addBusinessSector = {
  name: "Add New Business Sector",
  path: "",
  isActive: true,
};

const businessindustriesList = {
  name: "Business Industries",
  path: "",
  isActive: true,
};
const editBusinessIndustries = {
  name: "Edit Business Industry",
  path: "",
  isActive: true,
};
const addBusinessIndustries = {
  name: "Add New Business Industry",
  path: "",
  isActive: true,
};

const educationalqualificationsList = {
  name: "Educational Qualifications",
  path: "",
  isActive: true,
};
const editEducationalQualifications = {
  name: "Edit Educational Qualifications",
  path: "",
  isActive: true,
};
const addEducationalQualifications = {
  name: "Add New Educational Qualifications",
  path: "",
  isActive: true,
};

const specializationList = {
  name: "Specializations",
  path: "",
  isActive: true,
};
const editSpecialization = {
  name: "Edit Specialization",
  path: "",
  isActive: true,
};
const addSpecialization = {
  name: "Add New Specialization",
  path: "",
  isActive: true,
};

const currentpracticesList = {
  name: "Current Practices",
  path: "",
  isActive: true,
};
const editCurrentPractices = {
  name: "Edit Current Practices",
  path: "",
  isActive: true,
};
const addCurrentPractices = {
  name: "Add New Current Practices",
  path: "",
  isActive: true,
};

const parameteroptionsList = {
  name: "Parameter Options",
  path: "",
  isActive: true,
};
const editParameterOptions = {
  name: "Edit Parameter Options",
  path: "",
  isActive: true,
};
const addParameterOptions = {
  name: "Add New Parameter Options",
  path: "",
  isActive: true,
};

const filingscompliance = {
  name: "Filings Compliance",
  path: "/filings-compliance/entity-type",
  isActive: true,
};

const entitytypeList = { name: "Entity Type", path: "", isActive: true };
const editEntityType = { name: "Edit Entity Type", path: "", isActive: true };
const addEntityType = { name: "Add New Entity Type", path: "", isActive: true };

const governmentfilingsList = {
  name: "Government Filings",
  path: "",
  isActive: true,
};
const editGovernmentFilings = {
  name: "Edit Government Filings",
  path: "",
  isActive: true,
};
const addGovernmentFilings = {
  name: "Add New Government Filings",
  path: "",
  isActive: true,
};

const compliancemasterList = {
  name: "Compliance Master",
  path: "",
  isActive: true,
};
const editComplianceMaster = {
  name: "Edit Compliance ",
  path: "",
  isActive: true,
};
const addComplianceMaster = {
  name: "Add New Compliance ",
  path: "",
  isActive: true,
};

const compliancelistList = {
  name: "Compliance List",
  path: "",
  isActive: true,
};
const editComplianceList = {
  name: "Edit Compliance ",
  path: "",
  isActive: true,
};

const servicespackages = {
  name: "Services",
  path: "/packages-services/service-management",
  isActive: true,
};

const servicemanagementList = {
  name: "Service Management",
  path: "/packages-services/service-management",
  isActive: true,
};

const serviceinfoList = {
  name: "Service Info",
  path: "/packages-services-management/service-info",
  isActive: true,
};

const descriptionList = {
  name: "Description",
  path: "/packages-services-management/description",
  isActive: true,
};

const benefitsList = {
  name: "Benefits",
  path: "/packages-services-management/benefits",
  isActive: true,
};

const procedureList = {
  name: "Procedure",
  path: "/packages-services-management/procedure",
  isActive: true,
};

const documentrequiredList = {
  name: "Document Required",
  path: "/packages-services-management/document-required",
  isActive: true,
};

const servicecheckList = {
  name: "Service CheckList",
  path: "/packages-services-management/service-checklist",
  isActive: true,
};

const prerequisitsList = {
  name: "Pre Requisits",
  path: "/packages-services-management/pre-requisits",
  isActive: true,
};

const deliverableList = {
  name: "Deliverable",
  path: "/packages-services-management/deliverable",
  isActive: true,
};

const faqList = {
  name: "FAQ",
  path: "/packages-services-management/faq",
  isActive: true,
};

const pricingList = {
  name: "Pricing",
  path: "/packages-services-management/pricing",
  isActive: true,
};

const mediaList = {
  name: "Media",
  path: "/packages-services-management/media",
  isActive: true,
};

const quickvideoList = {
  name: "Quick Video",
  path: "/packages-services-management/quick-video",
  isActive: true,
};

const packagemanagementList = {
  name: "Package Management",
  path: "/packages-services/package-management",
  isActive: true,
};
const pack_serviceinfoList = {
  name: "Service Info",
  path: "/packages-management/service-info",
  isActive: true,
};
const pack_infoList = {
  name: "Package Info",
  path: "/packages-management/package-info",
  isActive: true,
};

const pack_descriptionList = {
  name: "Description",
  path: "/packages-management/description",
  isActive: true,
};

const pack_benefitsList = {
  name: "Benefits",
  path: "/packages-management/benefits",
  isActive: true,
};

const pack_procedureList = {
  name: "Procedure",
  path: "/packages-management/procedure",
  isActive: true,
};

const pack_documentrequiredList = {
  name: "Document Required",
  path: "/packages-management/document-required",
  isActive: true,
};

const pack_prerequisitsList = {
  name: "Pre Requisits",
  path: "/packages-management/pre-requisits",
  isActive: true,
};

const pack_deliverableList = {
  name: "Deliverable",
  path: "/packages-management/deliverable",
  isActive: true,
};

const pack_faqList = {
  name: "FAQ",
  path: "/packages-management/faq",
  isActive: true,
};

const pack_pricingList = {
  name: "Pricing",
  path: "/packages-management/pricing",
  isActive: true,
};

const pack_mediaList = {
  name: "Media",
  path: "/packages-management/media",
  isActive: true,
};

const pack_quickvideoList = {
  name: "Quick Video",
  path: "/packages-management/quick-video",
  isActive: true,
};
const leadsenquiry = {
  name: "Leads & Enquires",
  path: "/leads-enquiry/leads",
  isActive: true,
};
const leadList = {
  name: "Leads",
  path: "/leads-enquiry/leads",
  isActive: true,
};
const supportList = {
  name: "Support",
  path: "/leads-enquiry/support",
  isActive: true,
};
const viewSupport = {
  name: "Support",
  path: "/leads-enquiry/support-view",
  isActive: true,
};

const Coupons = {
  name: "Coupons",
  path: "/coupons/coupons-list",
  isActive: true,
};

const couponsList = {
  name: "Coupons",
  path: "/coupons/coupons-list",
  isActive: true,
};

const couponsCreate = {
  name: "Create Coupon",
  path: "/coupons/coupons-list/new",
  isActive: true,
};

const couponsEdit = {
  name: "Edit Coupon",
  path: "/coupons/coupons-list-edit/:id",
  isActive: true,
};

const couponsView = {
  name: "Coupons View",
  path: "/coupons/coupons-list-view",
  isActive: true,
};

const Employees = {
  name: "Employees",
  path: "/employees/employee-list",
  isActive: true,
};
const Roles = {
  name: "Roles",
  path: "/roles",
  isActive: true,
};
const RoleDetails = {
  name: "Role Details",
  path: "/roles-view/:id",
  isActive: true,
};
const TeamDetails = {
  name: "Team Details",
  path: "/teams-view/:id",
  isActive: true,
};
const EmployeeDetails = {
  name: "Employee Details",
  path: "/employees-list-view/:id",
  isActive: true,
};

const employeesList = {
  name: "Employee List",
  path: "/employees/employee-list",
  isActive: true,
};

const Contacts = {
  name: "Contacts",
  path: "/contacts/contact-list",
  isActive: true,
};

const contactsList = {
  name: "Contact List",
  path: "/contacts/contact-list",
  isActive: true,
};

const contactcomponentList = {
  name: "Add New Contact",
  path: "/contacts-list-create",
  isActive: true,
};

const servicecomponentList = {
  name: "Service Mgnt",
  path: "/packages-services-management",
  isActive: true,
};

const basicInformation = {
  name: "Basic Information",
  path: "/contacts-list-create/basic-information",
  isActive: true,
};
const officialMaping = {
  name: "Official Maping",
  path: "/contacts-list-create/official-maping",
  isActive: true,
};
const addressInformation = {
  name: "Address Information",
  path: "/contacts-list-create/address-information",
  isActive: true,
};
const kycInformation = {
  name: "KYC Information",
  path: "/contacts-list-create/kyc-information",
  isActive: true,
};

const contactviewcomponent = {
  name: "Contacts Details",
  path: "",
  isActive: true,
};

const Account = {
  name: "Accounts",
  path: "/accounts/account-list",
  isActive: true,
};

const accountList = {
  name: "Account List",
  path: "/accounts/account-list",
  isActive: true,
};

const accountcomponentList = {
  name: "Add New Account List",
  path: "/accounts-list-create/basic-information",
  isActive: true,
};

const comapnyadddetailst = {
  name: "Add Account Details",
  path: "",
  isActive: true,
};
const comapnyeditdetailst = {
  name: "Edit Account Details",
  path: "",
  isActive: true,
};
const accountbasicInformation = {
  name: "Basic Information",
  path: "/accounts-list-create/basic-information",
  isActive: true,
};
const accountofficialMaping = {
  name: "Official Maping",
  path: "/accounts-list-create/official-maping",
  isActive: true,
};
const accountcontactInformation = {
  name: "Contact Information",
  path: "/accounts-list-create/contact-information",
  isActive: true,
};

const accountregistrationInformation = {
  name: "Registration Information",
  path: "/accounts-list-create/registration-information",
  isActive: true,
};

const accountaddressInformation = {
  name: "Address Information",
  path: "/accounts-list-create/address-information",
  isActive: true,
};
const accountpromoterInformation = {
  name: "Promoter Information",
  path: "/accounts-list-create/promoter-information",
  isActive: true,
};

const accountcompliancesInformation = {
  name: "Compliances Information",
  path: "/accounts-list-create/compliances-information",
  isActive: true,
};

const accountViewComponent = {
  name: "Account Details",
  path: "",
  isActive: true,
};
const orderViewComponent = {
  name: "Order Details",
  path: "",
  isActive: true,
};
const taskViewComponent = {
  name: "Task Details",
  path: "",
  isActive: true,
};
const approvalViewComponent = {
  name: "Approval Details",
  path: "",
  isActive: true,
};
const paymentApprovalViewComponent = {
  name: "Approval Details",
  path: "",
  isActive: true,
};

const accountprofile = {
  name: "Account Profile",
  path: "/accounts-list-view/account-profile",
  isActive: true,
};

const accountrepresentative = {
  name: "Account Profile",
  path: "/accounts-list-view/representative",
  isActive: true,
};

const accountcompliances = {
  name: "Compliances",
  path: "/accounts-list-view/compliances",
  isActive: true,
};

const CheckList = {
  name: "CheckList",
  path: "/checklist/checklistView",
  isActive: true,
};
const checklistView = {
  name: "Checklists",
  path: "/checklist/checklistView",
  isActive: true,
};
//////////////////////////////////////////

const Orders = {
  name: "Orders & Payments",
  path: "/orders/by-orders-list",
  isActive: true,
};
const ordersList = {
  // name: "Orders & Payments",
  name: "By Orders",
  path: "/orders/by-orders-list",
  isActive: true,
};
const servicesList = {
  name: "By Services",
  path: "/orders/by-services-list",
  isActive: true,
};

const paymentsList = {
  name: "Payments",
  path: "/payment-refund/patments",
  isActive: true,
};

const userfilemanagementList = {
  name: "Folders",
  path: "/user-file-management/folders-list",
  isActive: true,
};

const foldersList = {
  name: "Folders",
  path: "/user-file-management/folders-list",
  isActive: true,
};

const Teams = {
  name: "Teams",
  path: "/teams",
  isActive: true,
};
const MyTasks = {
  name: "My Tasks",
  path: "/my-tasks",
  isActive: true,
};
const Exports = {
  name: "Exports",
  path: "/employee/exports",
  isActive: true,
};
const MyApprovals = {
  name: "My Approvals",
  path: "/my-apprrovals",
  isActive: true,
};
const MyPaymentApprovals = {
  name: "Payment Approvals",
  path: "/payment-apprrovals",
  isActive: true,
};

/////////////////////////////////////

module.exports = {
  servicecategories: [home, dataManagement, servicecategoriesList],
  servicecategoriesedit: [
    home,
    dataManagement,
    servicecategoriesList,
    editServiceCategory,
  ],
  servicecategoriescreate: [
    home,
    dataManagement,
    servicecategoriesList,
    addServiceCategory,
  ],

  constitutioncategories: [home, dataManagement, constitutioncategoriesList],
  constitutioncategoriesedit: [
    home,
    dataManagement,
    constitutioncategoriesList,
    editConstitutionCategory,
  ],
  constitutioncategoriescreate: [
    home,
    dataManagement,
    constitutioncategoriesList,
    addConstitutionCategory,
  ],

  businesssector: [home, dataManagement, businesssectorList],
  businesssectoredit: [home, dataManagement, editBusinessSector],
  businesssectorcreate: [home, dataManagement, addBusinessSector],

  businessindustries: [home, dataManagement, businessindustriesList],
  businessindustriesedit: [home, dataManagement, editBusinessIndustries],
  businessindustriescreate: [home, dataManagement, addBusinessIndustries],

  educationalqualifications: [
    home,
    dataManagement,
    educationalqualificationsList,
  ],
  educationalqualificationsedit: [
    home,
    dataManagement,
    editEducationalQualifications,
  ],
  educationalqualificationscreate: [
    home,
    dataManagement,
    addEducationalQualifications,
  ],

  specialization: [home, dataManagement, specializationList],
  specializationedit: [home, dataManagement, editSpecialization],
  specializationcreate: [home, dataManagement, addSpecialization],

  currentpractices: [home, dataManagement, currentpracticesList],
  currentpracticesedit: [home, dataManagement, editCurrentPractices],
  currentpracticescreate: [home, dataManagement, addCurrentPractices],

  parameteroptions: [home, dataManagement, parameteroptionsList],
  parameteroptionsedit: [home, dataManagement, editParameterOptions],
  parameteroptionscreate: [home, dataManagement, addParameterOptions],

  entitytype: [home, filingscompliance, entitytypeList],
  entitytypeedit: [home, filingscompliance, editEntityType],
  entitytypecreate: [home, filingscompliance, addEntityType],

  governmentfilings: [home, filingscompliance, governmentfilingsList],
  governmentfilingsedit: [home, filingscompliance, editGovernmentFilings],
  governmentfilingscreate: [home, filingscompliance, addGovernmentFilings],

  compliancemaster: [home, filingscompliance, compliancemasterList],
  compliancemasteredit: [home, filingscompliance, editComplianceMaster],
  compliancemastercreate: [home, filingscompliance, addComplianceMaster],

  compliancelist: [home, filingscompliance, compliancelistList],
  compliancelistedit: [home, filingscompliance, editComplianceList],

  servicemanagement: [home, servicespackages, servicemanagementList],
  serviceinfo: [home, servicespackages, servicemanagementList, serviceinfoList],
  description: [home, servicespackages, servicemanagementList, descriptionList],
  benefits: [home, servicespackages, servicemanagementList, benefitsList],
  procedure: [home, servicespackages, servicemanagementList, procedureList],
  documentrequired: [
    home,
    servicespackages,
    servicemanagementList,
    documentrequiredList,
  ],

  servicechecklist: [
    home,
    servicespackages,
    servicemanagementList,
    servicecheckList,
  ],

  prerequisits: [
    home,
    servicespackages,
    servicemanagementList,
    prerequisitsList,
  ],
  deliverable: [home, servicespackages, servicemanagementList, deliverableList],
  faq: [home, servicespackages, servicemanagementList, faqList],
  pricing: [home, servicespackages, servicemanagementList, pricingList],
  media: [home, servicespackages, servicemanagementList, mediaList],
  quickvideo: [home, servicespackages, servicemanagementList, quickvideoList],

  packagemanagement: [home, servicespackages, packagemanagementList],
  package_info: [home, servicespackages, packagemanagementList, pack_infoList],
  pack_serviceinfo: [
    home,
    servicespackages,
    packagemanagementList,
    pack_serviceinfoList,
  ],
  pack_description: [
    home,
    servicespackages,
    packagemanagementList,
    pack_descriptionList,
  ],
  pack_benefits: [
    home,
    servicespackages,
    packagemanagementList,
    pack_benefitsList,
  ],
  pack_procedure: [
    home,
    servicespackages,
    packagemanagementList,
    pack_procedureList,
  ],
  pack_documentrequired: [
    home,
    servicespackages,
    packagemanagementList,
    pack_documentrequiredList,
  ],
  pack_prerequisits: [
    home,
    servicespackages,
    packagemanagementList,
    pack_prerequisitsList,
  ],
  pack_deliverable: [
    home,
    servicespackages,
    packagemanagementList,
    pack_deliverableList,
  ],
  pack_faq: [home, servicespackages, packagemanagementList, pack_faqList],
  pack_pricing: [
    home,
    servicespackages,
    packagemanagementList,
    pack_pricingList,
  ],
  pack_media: [home, servicespackages, packagemanagementList, pack_mediaList],
  pack_quickvideo: [
    home,
    servicespackages,
    packagemanagementList,
    pack_quickvideoList,
  ],

  leads: [home, leadsenquiry, leadList],
  support: [home, leadsenquiry, supportList],
  supportview: [home, leadsenquiry, viewSupport],

  coupons: [home, couponsList],
  couponslist: [home, couponsList],
  couponscreate: [home, Coupons, couponsCreate],
  couponsedit: [home, Coupons, couponsEdit],
  couponsview: [home, Coupons, couponsView],

  employees: [home, Employees],
  employeelist: [home, Employees],

  contacts: [home, Contacts],
  contactlist: [home, Contacts],

  contactlistcomponent: [home, contactsList, contactcomponentList],

  contactviewcomponent: [home, contactsList, contactviewcomponent],

  accounts: [home, Account],
  accountlist: [home, Account],

  accountListComponent: [home, accountList, comapnyeditdetailst],
  accountaddlistcomponent: [home, accountList, comapnyadddetailst],

  accountViewComponent: [home, accountList, accountViewComponent],

  checklist: [home, checklistView],
  checklistview: [home, checklistView],

  ///////////////////////////////////

  orders: [home, ordersList],
  orderslist: [home, ordersList],
  payments: [home, paymentsList],
  paymentslist: [home, paymentsList],
  // orders: [home, ordersList],
  serviceslist: [home, servicesList],

  userfilemanagement: [home, foldersList],
  folderslist: [home, foldersList],
  teams: [home, Teams],
  teamDetails: [home, Teams, TeamDetails],
  employeeeDetail: [home, Employees, EmployeeDetails],
  roles: [home, Roles],
  rolesDetails: [home, Roles, RoleDetails],
  orderDetails: [home, ordersList, orderViewComponent],
  orderDetailsByService: [home, servicesList, orderViewComponent],
  tasks: [home, MyTasks],
  exports: [home, Exports],
  approvals: [home, MyApprovals],
  paymentApprovals: [home, MyPaymentApprovals],
  taskDetails: [home, MyTasks, taskViewComponent],
  approvalDetails: [home, MyApprovals, approvalViewComponent],
  approvalDetailsPayment: [
    home,
    MyPaymentApprovals,
    paymentApprovalViewComponent,
  ],

  /////////////////////////////////
};
