import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  getMapCustomer,
  saveMapCustomer,
  updateMapCustomer,
} from "../../../apifunctions";
// let serId = "";
class OfficialMaping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      formData: {},
      custId: props.custId,
      custData: props.custData,
    };
  }
  componentDidMount = () => {
    // if (serId) {
    //   this.getData(serId);
    // }
    if (this.state.custData.tier || this.state.custData.cre_agent) {
      this.getData(this.state.custId);
    }
  };
  async getData(custId) {
    this.setState({ loader: true });
    // try {
    getMapCustomer(custId)
      .then((res) => res.data)
      .then((res) => {
        console.log(res);
        this.setState({
          formData: res.customer_mapping,
          loader: false,
        });
      })
      .catch((err) => {
        var error_message = this.props.loadError(
          err?.response?.data?.error?.message
        );
        this.setState({
          snackbarMessage: error_message,
          loader: false,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
    // } catch (err) {}
  }
  handleChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ loader: true });
    console.log(this.state.formData);

    if (this.state.formData.id) {
      updateMapCustomer(this.state.custId, this.state.formData)
        // .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: res,
              snackbarMessage: "Mapped Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              loader: false,
            },
            () => {
              this.getData(this.state.custId);
            }
          );
        })
        .catch((err) => {
          var error_message = this.props.loadError(
            err?.response?.data?.error?.message
          );
          this.setState({
            snackbarMessage: error_message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } else {
      saveMapCustomer(this.state.custId, this.state.formData)
        // .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: res,
              snackbarMessage: "Mapped Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              loader: false,
            },
            () => {
              this.getData(this.state.custId);
            }
          );
        })
        .catch((err) => {
          var error_message = this.props.loadError(
            err?.response?.data?.error?.message
          );
          this.setState({
            snackbarMessage: error_message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    }
  };
  render() {
    const { masterData } = this.props;
    const { formData, loader, custId, custData } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                {/* <div className="col-md-12 col-xs-12 customer-list">
                  <span className="card-title">
                    Customer Unique Referal ID: {custData.Customer_Referal_ID}
                  </span>
                </div> */}
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Contact Tier <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="customer_tier"
                    value={formData.customer_tier ? formData.customer_tier : ""}
                    onChange={this.handleChange}
                  >
                    <option value="">Select Tier</option>
                    {masterData.tier &&
                      masterData.tier.map((tier) => (
                        <option key={tier} value={tier}>
                          {tier}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-3 col-xs-12">
                  <label className="control-label">
                    Customer Relation Executive <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="cre_agent"
                    value={formData.cre_agent ? formData.cre_agent : ""}
                    onChange={this.handleChange}
                  >
                    <option value="">Select Executive</option>
                    {masterData.cre_agent &&
                      masterData.cre_agent.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  {/* <Link
                    className="btn btn-secondary"
                    to="/contacts-list-create/basic-information"
                  >
                    Back
                  </Link> */}
                  {custId && (
                    <a
                      href={void 0}
                      className="btn btn-secondary"
                      onClick={(e) => this.props.handleTabView(1)}
                    >
                      Back
                    </a>
                  )}
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onSubmit={this.handleSubmit}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                  {/* <Link
                    className="btn btn-secondary"
                    to="/contacts-list-create/address-information"
                  >
                    Next
                  </Link> */}
                  {custId && (
                    <a
                      href={void 0}
                      className="btn btn-secondary"
                      onClick={(e) => this.props.handleTabView(3)}
                    >
                      Next
                    </a>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default OfficialMaping;
