import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  addGovFile,
  getGovFileData,
  updateGoveFileData,
} from "../apifunctions";
let dayOfMonth = 30;
let editflag = false;
class GovernmentFilingsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      snackbarOpen: false,
      submited: false,
      snackbarMessage: "",
      month: [
        { id: "Apr", value: "April", checked: false, count: 30 },
        { id: "July", value: "July", checked: false, count: 31 },
        { id: "Oct", value: "October", checked: false, count: 31 },
        { id: "Jan", value: "January", checked: false, count: 31 },
        { id: "May", value: "May", checked: false, count: 31 },
        { id: "Aug", value: "August", checked: false, count: 31 },
        { id: "Nov", value: "November", checked: false, count: 30 },
        { id: "Feb", value: "February", checked: false, count: 28 },
        { id: "Jun", value: "June", checked: false, count: 30 },
        { id: "Sep", value: "September", checked: false, count: 30 },
        { id: "Dec", value: "December", checked: false, count: 31 },
        { id: "Mar", value: "March", checked: false, count: 31 },
      ],
      snackbarVariant: "",
    };
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // if (this.props.match.params.id !== "new") {
    //   editflag = true;
    //   this.getData();
    // } else {
    //   editflag = false;
    // }
  }
  componentDidMount = () => {
    if (this.props.match.params.id !== "new") {
      editflag = true;
      this.getData();
    } else {
      editflag = false;
    }
  };
  async getData() {
    const { month } = this.state;
    this.setState({ loader: true });
    try {
      getGovFileData(this.props.match.params.id)
        .then((res) => res)
        .then((res) => {
          this.setState(
            {
              formData: {
                description: res.description,
                filing_day: res.filing_day,
                filing_name: res.filing_name,
                filing_type: res.filing_type,
                compliance_filing_frequency: res.compliance_filing_frequency,
                id: res.id,
                priority: res.priority,
                short_description: res.short_description,
                status: res.status,
              },
            },
            () => {
              if (res.get_month && res.get_month.length !== 0) {
                res.get_month.forEach((item) => {
                  month.forEach((mont) => {
                    if (mont.value === item.month) {
                      mont["checked"] = true;
                    }
                  });
                });
              }
            }
          );
          this.setState({ month, loader: false });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            loader: false,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  componentWillReceiveProps = (props) => {};

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: value },
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let months = [];

    this.state.month.forEach((item) => {
      if (item.checked) {
        months.push(item.value);
      }
    });

    this.setState(
      {
        submited: true,
        formData: { ...this.state.formData, month: months },
      },
      () => {
        if (months.length !== 0) {
          if (editflag) {
            updateGoveFileData(this.props.match.params.id, this.state.formData)
              .then((res) => {
                this.setState(
                  {
                    snackbarMessage: "Updated Successfully",
                    snackbarOpen: true,
                    snackbarVariant: "success",
                    submited: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.history.push(
                        "/filings-compliance/government-filings"
                      );
                    }, 2500);
                  }
                );
              })
              .catch((err) => {
                this.setState({
                  submited: false,
                  snackbarMessage: err?.response?.data?.error?.message,
                  snackbarOpen: true,
                  snackbarVariant: "error",
                });
              });
          } else {
            addGovFile(this.state.formData)
              .then((res) => {
                this.setState(
                  {
                    snackbarMessage: "Created succesfully",
                    snackbarOpen: true,
                    snackbarVariant: "success",
                    submited: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.handleClose();
                    }, 2500);
                  }
                );
              })
              .catch((err) => {
                this.setState({
                  submited: false,
                  snackbarMessage: err?.response?.data?.error?.message,
                  snackbarOpen: true,
                  snackbarVariant: "error",
                });
              });
          }
        } else {
          this.setState({
            submited: false,
            snackbarMessage: "Please select filing months",
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        }
      }
    );
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleCheckChange = (e) => {
    const { month } = this.state;
    month.forEach((item) => {
      if (item.value === e.target.value) {
        item["checked"] = e.target.checked;
        dayOfMonth = this.state.month[7].checked ? 28 : 30;
      }
    });

    this.setState({ month });
  };
  handleClose = () => {
    this.props.history.push("/filings-compliance/government-filings");
  };
  render() {
    const { masterData } = this.props;
    const { formData, month, loader } = this.state;
    let max = true;
    let required = true;
    month.forEach((item) => {
      if (item.checked) {
        required = false;
        if (item.count === 30 || item.count === 28) {
          max = false;
        }
      }
    });
    dayOfMonth = max ? 31 : this.state.month[7].checked ? 28 : 30;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-7 col-xs-12">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Filing Type <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={formData.filing_type}
                        onChange={this.handleChange}
                        name="filing_type"
                        required
                      >
                        <option value="">Select</option>
                        {masterData.filing_types &&
                          masterData.filing_types.map((filt, ind) => (
                            <option key={filt.id} value={filt.id}>
                              {filt.filing_type}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Filing Name <span className="red">*</span>
                      </label>
                      <input
                        required
                        value={formData.filing_name}
                        type="text"
                        name="filing_name"
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Enter Filing name"
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Filing Day of Month <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        name="filing_day"
                        value={formData.filing_day}
                      >
                        <option value="">Select</option>
                        {[...Array(dayOfMonth)].map((filt, index) => (
                          <option key={filt} value={filt}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Priority <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        value={formData.priority}
                        name="priority"
                      >
                        <option value="">Select</option>
                        {masterData.filing_priority &&
                          masterData.filing_priority.map((filt, ind) => (
                            <option key={filt} value={filt}>
                              {filt}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Status <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        name="status"
                        value={formData.status}
                      >
                        <option value="">Select</option>
                        {masterData.statuses &&
                          masterData.statuses.map((filt, ind) => (
                            <option key={filt.id} value={filt.id}>
                              {filt.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Compliance Filing Frequency{" "}
                        <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={this.handleChange}
                        value={formData.compliance_filing_frequency}
                        required
                        name="compliance_filing_frequency"
                        class="form-control"
                        placeholder="Enter Filing frequency"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-xs-12">
                  <div className="quarter-block">
                    <label className="control-label">
                      Filing Months <span className="red">*</span>
                    </label>
                    <div className="checkbox-card">
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={3}
                          style={{ display: "block" }}
                          sm={3}
                          align="center"
                          className="card-title"
                        >
                          <p>Q1</p>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "block" }}
                          xs={3}
                          sm={3}
                          align="center"
                          className="card-title"
                        >
                          <p>Q2</p>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{ display: "block" }}
                          sm={3}
                          align="center"
                          className="card-title"
                        >
                          <p>Q3</p>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{ display: "block" }}
                          sm={3}
                          align="center"
                          className="card-title"
                        >
                          <p>Q4</p>
                        </Grid>
                        {month.map((item) => (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            align="center"
                            className="checkbox-block"
                          >
                            <input
                              checked={item.checked}
                              value={item.value}
                              type="checkbox"
                              required={required}
                              id={item.id}
                              name={item.id}
                              onChange={this.handleCheckChange}
                            />
                            <label for={item.id}>{item.id}</label>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="control-label">
                    Short Description <span className="red">*</span>
                  </label>
                  <textarea
                    type="text"
                    required
                    name="short_description"
                    value={formData.short_description}
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Enter Short Description"
                    rows="5"
                  ></textarea>
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="control-label">
                    Filing Description <span className="red">*</span>
                  </label>
                  <textarea
                    type="text"
                    required
                    name="description"
                    class="form-control"
                    value={formData.description}
                    onChange={this.handleChange}
                    placeholder="Enter Filing Description"
                    rows="5"
                  ></textarea>
                </div>
                <div class="col-md-12 col-xs-12 text-right">
                  <button
                    style={{ marginRight: 6 }}
                    onClick={(e) => this.handleClose()}
                    className="btn btn-secondary"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={this.state.submited}
                    type="submit"
                    class="btn btn-primary"
                  >
                    {editflag ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default GovernmentFilingsCreate;
