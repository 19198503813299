import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { MdCheck, MdDownload } from "react-icons/md";
import { useSelector } from "react-redux";

import { SCOPES } from "../../../../config/permission-maps";
import PermissionsGate, {
  hasPermission,
} from "../../../../config/permissiongate";
import CommonLoaderComponent from "../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../plugins/dataTable/dataTable";
import DeletePopup from "../../../../plugins/delete/newDeleteComponent";
import CancelPopup from "../../../../plugins/cancelPopup/cancelComponent";
import SearchField from "../../../../plugins/searchfield";
import SnackbarMessage from "../../../../plugins/snackbar-component";
import { formatDate } from "../../../../utils/dateUtils";
import {
  assign_team_lead_to_order_item,
  cancel_order_payment_link,
  get_order_payments,
  getTeamLeads,
  remove_order_payment,
  resend_order_payment_link,
  get_order_payments_export,
} from "../../apifunctions";
import OrderItemTasks from "./componennts/orderitemtasks";
import ManagePayment from "./manage";
import { Link } from "react-router-dom";
import ViewPayment from "./manage/view";
import ResendLinkComponent from "./componennts/resendLinkPopup/resendLinkComponent";
import { numberFormat } from "../../../../utils/numberFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Payment = ({ id, orderData, refreshOrderData }) => {
  const classes = useStyles();
  const isAdmin = useSelector((state) => state.permission.isAdmin);
  const [formSearch, setFormSearch] = useState({
    search: "",

    page: 1,
  });

  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.permission.userData);
  const [teamLeads, setTeamLeads] = useState([]);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openResendLink, setOpenResendLink] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});
  const [updateKey, setUpdateKey] = useState(0);
  const [paymentType, setPaymentType] = useState();

  useEffect(() => {
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
      if (refreshOrderData) {
        refreshOrderData();
      }
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      get_order_payments(id)
        .then((res) => {
          let dt = res.data;
          dt.forEach((itm) => {
            itm["employee_name"] = itm?.assigned_to?.name;
            itm["employee_id"] = itm?.assigned_to?.id;
          });
          setTeamsList(dt);
          setTotalCount(res.data.length);
          setLoader(false);
          // setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err?.response?.error?.message
              ? String(err?.response.error.message)
              : "Could not add payment",
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleExport = () => {
    get_order_payments_export(id)
      .then((res) => res.data)
      .then((res) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err?.response?.error?.message
            ? String(err?.response.error.message)
            : "Could not export payment",
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  const handleChange = (e) => {
    setFormSearch((f) => ({ ...f, search: e.target.value }));
  };

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleDeleteOpen = (e, data) => {
    setRowData(data);

    setOpenDelete(true);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };
  const handleCancelOpen = (e, data) => {
    setRowData(data);

    setOpenCancel(true);
  };
  const handleCancelClose = (e, data) => {
    setRowData({});
    setOpenCancel(false);
  };
  const handleViewOpen = (e, data) => {
    setRowData(data);

    setOpenView(true);
  };
  const handleViewClose = (e, data) => {
    setRowData({});
    setOpenView(false);
  };
  const handleResendLinkOpen = (e, data) => {
    setRowData(data);

    setOpenResendLink(true);
  };
  const handleResendLinkClose = (e, data) => {
    setRowData({});
    setOpenResendLink(false);
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleDeleteConfirm = () => {
    setLoader(true);
    console.log(rowData);
    remove_order_payment(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Payment deleted.",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const handleCancelConfirm = () => {
    setLoader(true);
    cancel_order_payment_link(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Payment link cancelled.",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleCancelClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const handleResendLinkConfirm = () => {
    setLoader(true);
    resend_order_payment_link(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Payment link resent successfully.",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleResendLinkClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const fetchEmployeeNySearch = (e, row) => {
    if (e) {
      teamsList.forEach((itm) => {
        if (itm.id === row.id) {
          itm["employee_name"] = e;
        }
      });
      setTeamsList(teamsList);
      setUpdateKey(updateKey + 1);
    }
    getTeamLeads({
      page: 1,
      ...(e
        ? {
            search: e,
          }
        : {}),
    }).then((res) => {
      setTeamLeads(res?.results || []);
    });
  };
  const handleServiceChange = (e, row) => {
    let tm = teamsList;
    tm.forEach((itm) => {
      if (itm.id === row.id) {
        itm["employee_name"] = e["name"];
        itm["employee_id"] = e["id"];
      }
    });
    setTeamsList(tm);
    setUpdateKey(updateKey + 1);

    setAssigneeToItem(e["id"], row);
  };
  const setAssigneeToItem = (id, row) => {
    console.log(row);
    assign_team_lead_to_order_item(row.order?.id, row.id, {
      employee_id: id || null,
    })
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Team Lead added successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  const handleDownload = (r) => {
    if (r) {
      window.open(r, "_blank");
    }
  };
  const handleAddPayment = (paymentType) => {
    setPaymentType(paymentType);
    setOpenManage(true);
  };
  const pernissions = useSelector((state) => state.permission.value);
  return (
    <div className="card-content collapse show">
      <CommonLoaderComponent open={loader} />
      <SnackbarMessage
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      {openManage && (
        <ManagePayment
          open={openManage}
          order_id={id}
          data={rowData}
          paymentType={paymentType}
          handleClose={handleClose}
        />
      )}
      {openView && (
        <ViewPayment
          open={openView}
          order_id={id}
          data={rowData}
          handleClose={handleViewClose}
        />
      )}
      <DeletePopup
        opendelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
        submitted={loader}
      />
      <CancelPopup
        opencancel={openCancel}
        handleCancelClose={handleCancelClose}
        cancel={handleCancelConfirm}
        submitted={loader}
      />
      <ResendLinkComponent
        openResendLink={openResendLink}
        handleResendLinkClose={handleResendLinkClose}
        resendLink={handleResendLinkConfirm}
        submitted={loader}
      />
      <div className="">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={6} sm={6} className="table-search">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[SCOPES.viewPayments]}
            >
              <div
                className="d-flex justify-content-between"
                style={{
                  color: "black",
                }}
              >
                <div>
                  <span className="order-item-value">Total Order Value:</span>
                  <span className="order-item-total">
                    {" " + numberFormat(orderData.grand_total) || " 0.00 "}
                  </span>
                </div>
                <div>
                  <span className="order-item-value">Paid Amount:</span>
                  <span className="order-item-value">
                    {" " + numberFormat(orderData.paid_amount) || " 0.00 "}
                  </span>
                </div>
                <div>
                  <span className="order-item-value">Payment Due:</span>
                  <span className="order-item-value">
                    {" " + numberFormat(orderData.remaining_amount) || " 0.00 "}
                  </span>
                </div>
              </div>
            </PermissionsGate>
          </Grid>

          <Grid item className="table-search">
            {totalCount > 0 && (
              <Button
                to="#"
                className="btn btn-secondary mr-2"
                onClick={(e) => handleExport(e)}
              >
                Export
              </Button>
            )}
            <PermissionsGate scopes={[SCOPES.addOrderPayment]}>
              <Button
                className="btn btn-primary mr-2"
                onClick={() => handleAddPayment("manual")}
              >
                + &nbsp; Record Payment Data
              </Button>
            </PermissionsGate>
            <PermissionsGate scopes={[SCOPES.sendOrderPaymentLink]}>
              <Button
                  className="btn btn-primary"
                  onClick={() => handleAddPayment("link")}
                >
                  Send Payment Link
                </Button>
            </PermissionsGate>
          </Grid>
          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[SCOPES.viewPayments]}
            >
              {/* <div className="orderAccordion">
                {teamsList.map((item, ind) => (
                  <div key={ind}>
                    <div className="customAccordion mb-0">
                      <div
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          className="h-100"
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            items
                            xs={12}
                            className="bg-primary text-white p-1"
                          >
                            <Grid
                              container
                              spacing={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={10}>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <div>Amount</div>
                                    <div className=" text-success ">{item.amount}</div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div>Payment Method</div>
                                    <div className=" text-success ">
                                      {item.payment_method?.display_name}
                                    </div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div>Status</div>

                                    <div className=" text-success ">
                                      {item.status}
                                    </div>

                                  </Grid>
                                  <Grid item xs={3}>
                                    <div>Created by</div>
                                    <div className=" text-success ">
                                      {item.created_by?.first_name}{" "}
                                      {item.created_by?.last_name}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2} className="px-2">
                                <Grid
                                  container
                                  justify="flex-end"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <div
                                    className="btn btn-danger"
                                    onClick={(e) => handleDeleteOpen(e, item)}
                                  >
                                    Delete
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <div>
                        <OrderItemTasks data={item.transactions || []} />
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <DataTable
                data={teamsList}
                columns={[
                  {
                    title: "Transaction Date",
                    type: "text",

                    render: (ro) => (
                      <div className=" ">
                        {formatDate(ro.transactions[0]?.transaction_date)}
                      </div>
                    ),
                  },
                  {
                    title: "Transaction ID",
                    type: "text",

                    render: (ro) => (
                      <div className=" ">
                        {ro.transactions[0]?.transaction_id}
                      </div>
                    ),
                  },
                  {
                    title: "Payment method",
                    type: "text",
                    width: "100px",
                    render: (ro) => (
                      <div className="  ">
                        {ro.payment_method?.display_name}
                      </div>
                    ),
                  },

                  {
                    title: "Amount",
                    type: "amount",
                    field: "amount",
                  },

                  {
                    title: "External reference ID",
                    type: "text",
                    render: (ro) => (
                      <div className=" ">
                        {ro.transactions[0]?.external_reference_id}
                      </div>
                    ),
                  },
                  {
                    title: "Professional Fee",
                    type: "amount",
                    field: "professional_fee",
                  },
                  {
                    title: "Vendor Fee",
                    type: "amount",
                    field: "vendor_fee",
                  },
                  {
                    title: "Govt Fee",
                    type: "amount",
                    field: "govt_fee",
                  },
                  {
                    title: "GST Amount",
                    type: "amount",
                    field: "gst",
                  },
                  {
                    title: "Extra Received",
                    type: "amount",
                    field: "extra_received",
                  },
                  {
                    title: "Receipt Number",
                    type: "text",
                    render: (ro) => (
                      <div className=" ">
                        {ro.transactions[0]?.receipt_number}
                      </div>
                    ),
                  },

                  {
                    title: "Advance",
                    align: "center",
                    render: (ro) => (
                      <>{ro.transactions[0]?.is_advance && <MdCheck />}</>
                    ),
                  },

                  {
                    title: "Created by",
                    type: "text",

                    render: (ro) => (
                      <div className=" ">
                        {ro.created_by?.first_name} {ro.created_by?.last_name}
                      </div>
                    ),
                  },
                  {
                    title: "Document",

                    render: (ro) => (
                      <>
                        {ro.transactions[0]?.payment_document?.name && (
                          <div
                            className="d-flex align-content-center justify-content-between text-link cursor-pointer"
                            onClick={() =>
                              handleDownload(
                                ro?.transactions[0]?.payment_document?.url,
                              )
                            }
                          >
                            <div>
                              {ro.transactions[0]?.payment_document?.name}
                            </div>
                            <div style={{ paddingLeft: "2px" }}>
                              <MdDownload />
                            </div>
                          </div>
                        )}
                      </>
                    ),
                  },

                  {
                    title: "Payment Type",
                    type: "text",
                    field: "payment_type_label",
                  },

                  {
                    title: "Payment Status",
                    type: "text",
                    field: "payment_status_label",
                  },
                ]}
                options={{
                  sort: false,

                  pagination: false,
                }}
                actions={[
                  // {
                  //   className: "edit",
                  //   //rowActionCLick: this.handleEdit.bind(this),
                  //   iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                  //   tooltip: "Edit ",
                  // },
                  {
                    className: "view",
                    rowActionCLick: handleViewOpen.bind(this),
                    iconName: "/app-assets/backend-images/icons/view.png",
                    tooltip: "View",
                  },
                  ...(isAdmin ||
                  hasPermission({
                    scopes: [SCOPES.deleteOrderPayment],
                    permissions: pernissions,
                  })
                    ? [
                        {
                          className: "delete",
                          rowActionCLick: handleDeleteOpen.bind(this),
                          iconName:
                            "/app-assets/backend-images/icons/delete-icon.svg",
                          tooltip: "Delete",
                        },
                      ]
                    : []),
                  ...(isAdmin ||
                  hasPermission({
                    scopes: [SCOPES.cancelOrderPayment],
                    permissions: pernissions,
                  })
                    ? [
                        {
                          className: "view",
                          rowActionCLick: handleCancelOpen.bind(this),
                          iconName:
                            "/app-assets/backend-images/icons/cancel-icon.svg",
                          tooltip: "Cancel",
                          show: (row) =>
                            row.payment_type === "link" &&
                            row.status === "link_created",
                        },
                      ]
                    : []),
                  ...(isAdmin ||
                  hasPermission({
                    scopes: [SCOPES.resendOrderPaymentLink],
                    permissions: pernissions,
                  })
                    ? [
                        {
                          className: "view",
                          rowActionCLick: handleResendLinkOpen.bind(this),
                          iconName:
                            "/app-assets/backend-images/icons/refresh-icon-black.svg",
                          tooltip: "Resend Payment Link",
                          show: (row) =>
                            row.payment_type === "link" &&
                            row.status === "link_created",
                        },
                      ]
                    : []),
                ]}
              />
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Payment;
