import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import styles from "./resendLink.style";
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const ResendLinkPopup = ({ classes, submitted, openResendLink, ...props }) => {
  const handleClose = () => {
    props.handleResendLinkClose();
  };

  const handleResendLink = () => {
    props.resendLink();
  };
  return (
    <div>
      <Dialog
        open={openResendLink}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="customDialog deleteDialog"
      >
        <DialogTitle disableTypography className={classes.title}>
          <h4>Resend Payment Link</h4>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <p>Are you sure you want to resend the payment link?</p>
        </DialogContent>
        <DialogActions className={classes.actionsButton}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            className="btn btn-secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleResendLink()}
            autoFocus
            disabled={submitted}
            className="btn btn-primary"
            style={{
              backgroundColor: "#f00 !important",
              color: "#fff !important",
            }}
          >
            Resend Link
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(ResendLinkPopup);
