import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  isAdmin: false,
  allPermissions: [],
  userData: {},
  isImpersonating: null,
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setPermission: (state, action) => {
      state.value = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setAllPermission: (state, action) => {
      console.log(state, action);
      state.allPermissions = action.payload;
    },
    setIsImpersonating: (state, action) => {
      state.isImpersonating = action.payload;
    },
  },
});

const { reducer } = permissionSlice;

// Action creators are generated for each case reducer function
export const { setPermission, setIsAdmin, setAllPermission, setUserData, setIsImpersonating} =
  permissionSlice.actions;

export default reducer;
