import React, { useState } from "react";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import SnackbarComponent from "../../../../../plugins/snackbar-component";
import { update__employee } from "../../../apifunctions";
import { EMAIL_REGEX } from "../../../../../utils/formValidate";
const BasicInfo = (props) => {
  console.log(props);
  const emailRef = React.useRef(null);
  const mobileRef = React.useRef(null);

  const [formData, setFormData] = useState(props.formData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  const handleCancel = () => {
    props.setEdit(false);
    props.refetch();
  };
  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    if (e.target.name === "email") {
      const { validity } = emailRef.current;
      console.log(validity);
      if (validity.patternMismatch) {
        emailRef.current.setCustomValidity("Enter a valid email");
      } else {
        emailRef.current.setCustomValidity("");
      }
    }
    if (e.target.name === "mobile") {
      const { validity } = mobileRef.current;
      console.log(validity);
      if (validity.patternMismatch) {
        mobileRef.current.setCustomValidity(
          "Enter a valid 10 digit mobile number starts with +91"
        );
      } else {
        mobileRef.current.setCustomValidity("");
      }
    }
    setFormData({ ...formData, [e.target.name]: value });
  };
  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      ...props.mainData,
      avatar_thumbnail: props.mainData.avatar_thumbnail,
      is_admin: props.mainData.is_admin,
      name: props.mainData.name,
      status: props.mainData.status,

      user: formData,
    };
    if (!payload["employee_profile"]) {
      delete payload["employee_profile"];
    }
    if (!payload["employee_address"]) {
      delete payload["employee_address"];
    }
    delete payload["group"];
    update__employee(formData.id, payload)
      .then((res) => {
        setSnackbarVarient("success");
        setSnackbarMessage("Updated Successfully");
        setSnackbarOpen(true);
        setTimeout(() => {
          handleCancel();
        }, 500);
      })
      .catch(() => {
        setSnackbarVarient("error");
        setSnackbarMessage("Something went wrong");
        setSnackbarOpen(true);
        setLoading(false);
      });
  };
  return (
    <div>
      {" "}
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2} className="pb-1 customFields">
          <Grid item xs={12}>
            <div className=" p-0 m-0">
              <div className="fieldBox d-flex align-items-center justify-content-between">
                <div className="details-box">
                  <div className="detail-box border-r">
                    <div className="">
                      <p className="name">Basic Info</p>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  {!props.edit ? (
                    <button
                      className="btn btn-primary"
                      title="Edit Team Info"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setEdit(true);
                      }}
                      disabled={loading}
                    >
                      Change
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCancel();
                        }}
                        disabled={loading}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        // onClick={(e) => onSubmit(e)}
                        disabled={loading}
                      >
                        Update
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={3}>
            <InputLabel>
              First Name<span className="red">*</span>
            </InputLabel>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              disabled={!props.edit}
              required
              name="first_name"
              value={formData.first_name ? formData.first_name : ""}
              onChange={handleChange}
              type="text"
              fullWidth
              placeholder="Enter First Name"
              className="customFieldInput"
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel>
              Last Name<span className="red">*</span>
            </InputLabel>
            <TextField
              variant="outlined"
              margin="dense"
              required
              disabled={!props.edit}
              name="last_name"
              value={formData.last_name ? formData.last_name : ""}
              onChange={handleChange}
              type="text"
              fullWidth
              placeholder="Enter Last Name"
              className="customFieldInput"
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel>
              Email<span className="red">*</span>
            </InputLabel>
            <TextField
              variant="outlined"
              margin="dense"
              required
              disabled={true}
              name="email"
              inputRef={emailRef}
              inputProps={{ pattern: EMAIL_REGEX }}
              value={formData.email ? formData.email : ""}
              onChange={handleChange}
              type="email"
              fullWidth
              placeholder="Enter Email"
              className="customFieldInput"
            />
          </Grid>
          {/* <Grid item xs={3}>
          <InputLabel>Username</InputLabel>
          <TextField
            variant="outlined"
            margin="dense"
            required
            disabled={true}
            name="username"
            value={formData.username ? formData.username : ""}
            onChange={handleChange}
            type="text"
            fullWidth
            className="fieldInput"
          />
        </Grid> */}
          <Grid item xs={3}>
            <InputLabel>
              Mobile<span className="red">*</span>
            </InputLabel>

            <TextField
              variant="outlined"
              margin="dense"
              required
              disabled={!props.edit}
              name="mobile"
              inputRef={mobileRef}
              onKeyPress={(e) => validateNumber(e, /[+0-9]|\./)}
              inputProps={{
                minLength: 13,
                maxLength: 13,
                pattern: "^[+][9][1][0-9]{10}$",
              }}
              value={formData.mobile ? formData.mobile : ""}
              onChange={handleChange}
              type="text"
              fullWidth
              placeholder="Enter Mobile"
              className="customFieldInput"
            />
          </Grid>
        </Grid>
      </form>
      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default BasicInfo;
