import React, { Component } from "react";
import BreadCrumb from "../../../../layout/breadcrumb";
import NavigationTab from "../../../../plugins/customtab/customtab";
import nsmConfig from "../../../../plugins/customtab/customtabmenu";
import Benefits from "./benefits/benefits";
import Deliverable from "./deliverable/deliverable";
import Description from "./description/description";
import DocumentRequired from "./document-required/document-required";
import FAQ from "./faq/faq";
import Media from "./media/media";
import PreRequisits from "./pre-requisits/pre-requisits";
import Pricing from "./pricing/pricingmaster.component";
import Procedure from "./procedure/procedure";
import QuickVideo from "./quick-video/quick-video";
import ServiceCheckList from "./service-checklist/service-checklist";
import "./service-component.css";
import ServiceInfo from "./service-info/service-info";

let tabData = nsmConfig["servicecomponent"].menus;
let tabnew = [
  {
    id: 1,
    name: "Service Info",
    path: "/packages-services-management/service-info",
  },
];
class ServiceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      type: "",
      ser_Id:
        this.props.match.params.id === "new" ? "" : this.props.match.params.id,
    };
  }

  componentDidMount = () => {
    // this.getMasterData();
  };

  handleTabView = (value) => {
    /*if (this.state.ser_Id) {*/
    this.setState({
      currentTab: value,
    });
    /*  }*/
  };
  handleParent = (id) => {
    if (id) {
      this.setState(
        {
          ser_Id: id,
        },
        () => {
          this.props.history.push(`/packages-services-service/details/${id}`);
        }
      );
    }
  };
  handleCancel = () => {
    this.props.history.push("/packages-services/service-management");
  };
  render() {
    const { currentTab, ser_Id } = this.state;

    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb type={"servicemanagement"} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <NavigationTab
                          currentTab={currentTab}
                          tabData={ser_Id ? tabData : tabnew}
                          handleTabView={this.handleTabView}
                        />
                        {currentTab === 1 && (
                          <ServiceInfo
                            key={ser_Id + currentTab}
                            ser_Id={ser_Id}
                            masterData={this.props.masterData}
                            handleParent={this.handleParent}
                            handleTabView={this.handleTabView}
                            handleCancel={this.handleCancel}
                          />
                        )}
                        {currentTab === 2 && ser_Id && (
                          <Description
                            key={ser_Id + currentTab}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 3 && ser_Id && (
                          <Benefits
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 4 && ser_Id && (
                          <Procedure
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 5 && ser_Id && (
                          <DocumentRequired
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 6 && ser_Id && (
                          <ServiceCheckList
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 7 && ser_Id && (
                          <PreRequisits
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 8 && ser_Id && (
                          <Deliverable
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 9 && ser_Id && (
                          <FAQ
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 10 && ser_Id && (
                          <Pricing
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 11 && ser_Id && (
                          <Media
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 12 && ser_Id && (
                          <QuickVideo
                            key={ser_Id}
                            ser_Id={ser_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServiceComponent;
