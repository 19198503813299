import React, { useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import CloseIcon from "@material-ui/icons/Close";
import TeamDialogTheme from "../dialogTheme";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { add_teams, update_teams } from "../apifunctions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";

const ManageTeam = ({ open = false, data = {}, handleClose = () => {} }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = { name: formData?.name };
    if (formData?.id) {
      try {
        update_teams(formData?.id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);

            setTimeout(() => {
              setSubmitting(false);
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(
              err?.response?.data?.errors
                ? err?.response?.data?.errors[0].message[0]
                : "Could not update team"
            );
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      try {
        add_teams(payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);

            setTimeout(() => {
              setSubmitting(false);
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(
              err?.response?.data?.error?.message
            );
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    setFormData((f) => ({ ...f, [e.target.name]: value }));
  };
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {formData?.id ? "Edit Team" : "Add Team"}</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>Team Name<span className="red">*</span></InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="name"
                  value={formData.name ? formData.name : ""}
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter Team Name"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {formData?.id ? "Update" : "Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageTeam;
