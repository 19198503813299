import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apis from "./apiactions/api.actions";
import { refreshToken } from "./config";
import {
  setIsAdmin,
  setPermission,
  setAllPermission,
} from "./config/redux/permissionSlice";
import Layout from "./layout/layout_funk";
import NoLayout from "./layout/Nolayout_funk ";
import { connect } from "react-redux";

function App(props) {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [masterData, setMasteData] = useState([]);
  const [count, setCount] = useState(1);
  let token = localStorage.getItem("token");
  // let masterData = JSON.parse(localStorage.getItem("masterData"));
  if (!token) {
    refreshToken(null);
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("masterData");
    window.history.replaceState(null, null, "/");
    history.push("/");
  }

  if (!isLoggedIn && localStorage.getItem("token")) {
    setIsLoggedIn(true);
  }

  const login = () => {
    setIsLoggedIn(true);
    history.push("/dashboard");
  };
  const logout = () => {
    refreshToken(null);

    // setPermission(permissions);
    props.dispatch(setIsAdmin(false));
    props.dispatch(setPermission([]));
    props.dispatch(setAllPermission([]));
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("masterData");
    setIsLoggedIn(false);
    window.history.replaceState(null, null, "/");
    history.push("/");
  };

  const getmasterData = () => {
    apis
      .getDatas("core/master-data")
      .then((res) => res.data) //checkkkk
      .then((res) => {
        setMasteData(res);
        localStorage.setItem("masterData", JSON.stringify(res));
      });
  };
  const getAllPermissionData = () => {
    apis
      .getDatas("core/permissions")
      //checkkkk
      .then((res) => {
        props.dispatch(setAllPermission(res));
        localStorage.setItem("allPermissions", JSON.stringify(res));
      });
  };
  refreshToken(localStorage.getItem("token"));
  useEffect(() => {
    if (isLoggedIn) getmasterData();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) getAllPermissionData();
  }, [isLoggedIn]);

  return (
    <div>
      {isLoggedIn ? (
        <Layout masterData={masterData} login={login} logout={logout} />
      ) : (
        <NoLayout login={login} logout={logout} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  isAdmin: state.isAdmin,
  allPermissions: state.allPermissions,
});
export default connect(mapStateToProps)(App);
