import { MuiThemeProvider } from '@material-ui/core';
import { debounce } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CommonLoader from '../../../plugins/commonLoader/commonLoader.component';
import SelectBox from '../../../plugins/selectbox/commonSelectBox';
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from '../../../plugins/snackbar-component';
import { generateErrorMessage } from '../../../utils/commonUtils';
import {
  getAccountDuplicate,
  getbusinsector,
  getConstCat,
  getleadsource,
  save_Compnay_Basic,
} from '../../company/apifunctions';
import RoleDialogTheme from './dialogTheme';

const masterData = localStorage.getItem('masterData')
  ? JSON.parse(localStorage.getItem('masterData'))
  : [];
const ManageAccount = ({
  open = false,
  data = {},
  orderExist = false,
  handleClose = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sectData, setSecData] = useState([]);
  const [constData, setConstData] = useState([]);
  const [indusData, setIndusData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [subSectData, setSubSectData] = useState([]);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formData, setFormData] = useState({ ...data, order_date: new Date() });
  const [duplicate, setDuplicate] = useState({});
  const latestValue = useRef();

  const history = useHistory();

  const handleBusDataChange = (value, reason = undefined) => {
    getBustData(value);
  };

  const getBustData = (value) => {
    getbusinsector(value ?? '')
      .then((res) => res.data)
      .then((res) => {
        setSecData(res);
      })
      .catch((err) => {
        setSnackbarVarient('error');
        setSnackbarMessage(err?.response?.data?.error?.message.error);
        setSnackbarOpen(true);
        setSubmitting(false);
      });
  };
  const handleBusinessTierSelectChange = (e, id, name) => {
    setFormData((f) => ({
      ...f,
      [id]: e.id,
      business_tier_name: e[name],
    }));
  };
  const handlebusSelectChange = (e, id, name) => {
    setIndusData(e.business_industries ? e.business_industries : []);
    setFormData((f) => ({
      ...f,
      [id]: e.id,
      [name]: e[name],
      industry: '',
      business_industry_name: '',
      sub_sector: '',
      sub_sector_name: '',
    }));
    if (!id) {
      handleBusDataChange();
    }
  };
  const handlesubSelectChange = (e, id, name) => {
    setFormData((f) => ({
      ...f,
      [id]: e.id,
      sub_sector_name: e.business_industry_name,
    }));
  };
  const handleindusSelectChange = (e, id, name) => {
    setSubSectData(e.children ? e.children : []);

    setFormData((f) => ({
      ...f,
      [id]: e.id,
      [name]: e[name],
      [id]: e.id,
      [name]: e[name],
      sub_sector: '',
      sub_sector_name: '',
    }));
  };

  const handleSelectChange = (e, id, name) => {
    setFormData((f) => ({
      ...f,
      [id]: e.id,
      [name]: e[name],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    save_Compnay_Basic(formData)
      .then((res) => res.data)
      .then((res) => {
        setSnackbarVarient('success');
        setSnackbarMessage('Created Successfully');
        setSnackbarOpen(true);
        setSubmitting(false);
        setTimeout(() => {
          props.onSuccess(res);
        }, 500);
      })
      .catch((err) => {
        setSnackbarVarient('error');
        setSnackbarMessage(generateErrorMessage(err));
        setSnackbarOpen(true);
        setSubmitting(false);
      });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage('');
  };
  const handleDataChange = (value, reason = undefined) => {
    getConstData(value);
  };
  const getConstData = (value) => {
    getConstCat(value ?? '')
      .then((res) => res.data)
      .then((res) => {
        setConstData(res.constitution_categories);
      })
      .catch((err) => {
        setSnackbarVarient('error');
        setSnackbarMessage(err?.response?.data?.error?.message.error);
        setSnackbarOpen(true);
        setSubmitting(false);
      });
  };

  const getAccountDuplicateData = debounce(async (fieldName, value) => {
    latestValue.current = value;
    try {
      const res = await getAccountDuplicate({
        [fieldName]: value,
        company_id: '',
      });
      if (latestValue.current === value) {
        setDuplicate({
          [fieldName]: res.data ?? [],
        });
      }
    } catch (error) {
      setDuplicate({
        [fieldName]: [],
      });
    }
  }, 1000);

  const handleChange = (e) => {
    const fieldName = e.target.name;
    let value = e.target.value;
    if (value.trim() === '') {
      value = '';
    }
    setFormData((f) => ({
      ...f,
      [e.target.name]: value,
    }));
    getAccountDuplicateData(fieldName, value);
  };
  const handleLeadSourceSelectChange = (e, id, name) => {
    setFormData((f) => ({
      ...f,
      lead_source: e.id,
      lead_source_name: e[name],
    }));
  };
  const handleLeadSourceChange = (value, reason = undefined) => {
    getLeadSourceData(value);
  };
  const getLeadSourceData = (value) => {
    getleadsource(value ?? '') // api for listing lead source here
      .then((res) => res.data)
      .then((res) => {
        setLeadSourceData(res);
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message.error,
          snackbarOpen: true,
          snackbarVariant: 'error',
        });
      });
  };
  const blurChange = (fieldName) => {
    setDuplicate({
      duplicate: { [fieldName]: [] },
    });
  };
  useEffect(() => {
    getBustData();
    getLeadSourceData();
    getConstData();
  }, []);
  console.log(duplicate);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          // aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> Add Account</h3>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-6 pb-2" style={{ position: 'relative' }}>
                  <label className="control-label">
                    Business Name <span className="red">*</span>
                  </label>
                  <TextField
                    type="text"
                    required
                    name="business_name"
                    value={formData.business_name}
                    className="customFieldInput "
                    onChange={handleChange}
                    onBlur={() => blurChange('business_name')}
                    placeholder="Enter Business Name"
                  />
                  {duplicate?.business_name?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{'Possible Duplicates'}</li>
                      {duplicate?.business_name?.map((item, index) => (
                        <li key={index}>
                          {item.business_name}
                          {item.customer_name ? ` (${item.customer_name})` : ''}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Sector</label>

                  <SelectBox
                    Data={sectData}
                    id="id"
                    async="true"
                    value={
                      formData.business_sector_name
                        ? formData.business_sector_name
                        : ''
                    }
                    desc="business_sector_name"
                    descId="sector"
                    getData={handleBusDataChange}
                    placeholder="Sector"
                    className="form-control"
                    onChange={handlebusSelectChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Industry</label>
                  <SelectBox
                    Data={indusData}
                    id="id"
                    value={formData.business_industry_name}
                    desc="business_industry_name"
                    disabled={!formData.sector}
                    //getData={handleBusIndDataChange}
                    placeholder="Industry"
                    className="form-control"
                    descId="industry"
                    onChange={handleindusSelectChange}
                  />
                </div>

                {/* <div className="col-6 pb-2">
                  <InputLabel>
                    Total Amount<span className="red">*</span>
                  </InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    margin="dense"
                    required
                    type="number"
                    name="total_amount"
                    value={formData.total_amount ? formData.total_amount : ""}
                    onChange={handleChange}
                    className="customFieldInput "
                    fullWidth
                    placeholder="Total Order Amount"
                  />
                </div> */}
                <div className="col-6 pb-2">
                  <label className="control-label">
                    Sub Sector
                    {/* <span className="red">*</span> */}
                  </label>
                  <SelectBox
                    Data={subSectData}
                    id="id"
                    value={formData.sub_sector_name}
                    desc="business_industry_name"
                    required={false}
                    // required={formData.industry}
                    // getData={handleDataChange}
                    disabled={!formData.industry}
                    descId="sub_sector"
                    placeholder="Sub sector"
                    className="form-control"
                    onChange={handlesubSelectChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Constitution Category</label>
                  <SelectBox
                    Data={constData}
                    id="id"
                    async="true"
                    value={formData.constitution_category_name}
                    desc="constitution_category_name"
                    getData={handleDataChange}
                    placeholder="Category"
                    className="form-control"
                    descId="constitution"
                    onChange={handleSelectChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Business Tier</label>
                  <SelectBox
                    Data={masterData.business_tier}
                    id="id"
                    // async="true"
                    value={formData.business_tier_name}
                    desc="tier"
                    // getData={handleDataChange}
                    placeholder="Business Tier"
                    className="form-control"
                    descId="business_tier"
                    onChange={handleBusinessTierSelectChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Lead Source</label>

                  <SelectBox
                    Data={leadSourceData}
                    id="id"
                    async="true"
                    value={
                      formData.lead_source_name ? formData.lead_source_name : ''
                    }
                    desc="name"
                    descId="id"
                    getData={handleLeadSourceChange}
                    placeholder="Lead source"
                    className="form-control"
                    onChange={handleLeadSourceSelectChange}
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">PAN Number</label>
                  <TextField
                    type="text"
                    name="pan_no"
                    value={formData.pan_no}
                    className="customFieldInput "
                    onChange={handleChange}
                    onBlur={() => blurChange('pan_no')}
                    placeholder="Enter PAN Number"
                  />
                  {duplicate?.pan_no?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{'Possible Duplicates'}</li>
                      {duplicate?.pan_no?.map((item, index) => (
                        <li key={index}>
                          {item.pan_no} - {item.business_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageAccount;
