import moment from "moment";
import React, { Component } from "react";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoaderComponent from "../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  getCompliance_listitem,
  updateCompliance_listitem,
} from "../apifunctions";
class ComplianceListEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      submited: false,
      snackbarOpen: false,

      snackbarVariant: "",
      editflag: false,

      hideAdd: false,
      loader: false,
      snackbarMessage: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.setState({ editflag: true });
      this.getComplianeData();
    }
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  async getComplianeData() {
    this.setState({ loader: true });
    let data = [];
    try {
      getCompliance_listitem(this.props.match.params.id)
        .then((res) => res.data)
        .then((res) => {
          let stringmonth = "";

          this.setState({
            formData: {
              compliance_date: res.compliance_date,
              revision_url: res.revision_url,
              filing_day: res.filing.filing_day,
              filing_name: res.filing.filing_name,
              filing_type: res.filing.filing_type,
              filing_priority: res.filing.priority,
              filing_month: stringmonth,
              constitution_category_name:
                res.constitution_category.constitution_category_name,
              priority: res.filing.priority,
              entity_variation_name: res.entity_type.entity_variation_name,
              filing: res.filing.id,
              status: res.filing.status.name,
            },
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleDatePicker = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.name]: e.value,
      },
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submited: true });
    let forms = {
      remarks: this.state.formData.remarks,
      compliance_date: moment(this.state.formData.compliance_date).format(
        "DD-MM-YYYY"
      ),
      revision_url: this.state.formData.revision_url,
    };

    if (this.state.editflag) {
      updateCompliance_listitem(this.props.match.params.id, forms)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {
              this.getComplianeData();
            }
          );
        })
        .catch((err) => {
          this.setState({
            submited: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    }
  };
  handleClose = () => {
    this.props.history.push("/filings-compliance/compliance-list");
  };
  render() {
    const { submited, formData, loader } = this.state;
    return (
      <div className="create-content">
        <CommonLoaderComponent open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Filing Type <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin-name"
                        value={formData.filing_type}
                        disabled={true}
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Filing Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin-name"
                        disabled={true}
                        value={formData.filing_name}
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Priority <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin-name"
                        disabled={true}
                        value={formData.priority}
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Status <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin-name"
                        class="form-control"
                        disabled={true}
                        value={formData.status}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Constitution Category <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin-name"
                        disabled={true}
                        value={formData.constitution_category_name}
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Entity Variation <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="admin-name"
                        value={formData.entity_variation_name}
                        disabled={true}
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Revised Filing Date <span className="red">*</span>
                      </label>
                      <CalendarComponent
                        required
                        selected={formData.compliance_date}
                        name="compliance_date"
                        onChange={this.handleDatePicker}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Government Order on Revision - URL{" "}
                        <span className="red">*</span>
                      </label>
                      <input
                        type="url"
                        name="revision_url"
                        value={formData.revision_url}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder="Revision URL"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <label className="control-label">Remarks</label>
                  <textarea
                    type="text"
                    name="remarks"
                    value={formData.remarks}
                    class="form-control"
                    placeholder="remarks"
                    onChange={this.handleChange}
                    rows="5"
                  ></textarea>
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => this.handleClose()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                  >
                    Save
                  </button>
                  {/* <button className="btn btn-secondary">SAVE & PUBLISH</button> */}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ComplianceListEdit;
