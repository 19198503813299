import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { getCustomerBusiness } from "../../../apifunctions";
// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";

class CustomerComplainces extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      totalCount: "",
      pagesize: "",
      loader: false,
      custBus: [],
      page: 1,
    };
  }
  componentDidMount = () => {
    if (this.props.custId) {
      this.getData(this.props.custId);
    }
  };
  getData = (serId) => {
    this.setState({ loader: true });

    getCustomerBusiness(serId, this.state.page)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          custBus: res.results,
          loader: false,
          totalCount: res.count,
          pagesize: res.page_size,
        });
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage:
            err?.response?.data?.error?.message ??
            "Could not fetch compliances",
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  // handleChange = (e) => {
  //   this.setState({
  //     formData: {
  //       ...this.state.formData,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };
  // onSnackbarClose = () => {
  //   this.setState({ snackbarOpen: false });
  // };
  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (this.state.formData.id) {
  //     updateService_Benefits(serId, this.state.formData.id, this.state.formData)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             snackbarMessage: "Updated Successfully",
  //             snackbarOpen: true,
  //             snackbarVariant: "success",
  //           },
  //           () => {}
  //         );
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err?.response?.data?.error?.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //         });
  //       });
  //   } else {
  //     createService_Benefits(serId, this.state.formData)
  //       .then((res) => res.data)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             formData: res.service_benefits,
  //             snackbarMessage: "Created Successfully",
  //             snackbarOpen: true,
  //             snackbarVariant: "success",
  //           },
  //           () => {}
  //         );
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err?.response?.data?.error?.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //         });
  //       });
  //   }
  // };
  handlepagination(id) {
    this.setState(
      {
        page: id,
      },
      () => {
        this.getData();
      }
    );
  }
  render() {
    const { formData, loader, totalCount, pagesize, custBus } = this.state;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="titlehead col-md-12 col-xs-12">
                  <h4>Compliances</h4>
                  <a className="btn btn-primary" href="#">
                    + &nbsp; Add New
                  </a>
                </div>
                <div className="col-md-12 col-xs-12 table-block">
                  <DataTable
                    data={custBus}
                    columns={[
                      {
                        title: "SL",
                        type: "text",
                        field: "company_code",
                      },
                      {
                        title: "Created Date/ Time",
                        type: "text",
                        field: "incorporation_date",
                      },
                      {
                        title: "Business name",
                        type: "text",
                        field: "business_name",
                      },
                      {
                        title: "Category",
                        type: "text",
                        field: "constitution_category_name",
                        render: (rowData) =>
                          rowData.constitution && (
                            <span>
                              {rowData.constitution.constitution_category_name}
                            </span>
                          ),
                      },
                      {
                        title: "Contact",
                        type: "text",
                        field: "customer_name",
                      },
                      {
                        title: "Sector",
                        type: "text",
                        field: "constitution_category_name",
                        render: (rowData) =>
                          rowData.sector && (
                            <span>{rowData.sector.business_sector_name}</span>
                          ),
                      },
                    ]}
                    options={{
                      sort: false,
                      sortvalue: "company_code",
                      pageNo: this.state.formSearch.page,
                      pagination: true,
                      totalCount: totalCount,
                      pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                      handlepagination: this.handlepagination.bind(this),
                    }}
                    // actions={[
                    //   {
                    //     className: "view",
                    //     rowActionCLick: this.handleView.bind(this),
                    //     iconName: "/app-assets/backend-images/icons/view.png",
                    //     tooltip: "View Details",
                    //   },
                    // {
                    //   className: "view",
                    //   rowActionCLick: this.handleView.bind(this),
                    //   iconName:
                    //     "/app-assets/backend-images/icons/delete-icon.svg",
                    //   tooltip: "Delete Customer",
                    // },
                    // ]}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CustomerComplainces;
