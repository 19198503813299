import SnackbarMessage from "../../plugins/snackbar-component";
import { addCheckList, updateCheckList } from "./apifunctions";
import CheckListStyle from "./checklist-style";
import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import CommonLoaderComponent from "../../plugins/commonLoader/commonLoader.component";

class CheckListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
      mainId: props.mainId,
      loader: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      formData: props.formData,
      mainId: props.mainId,
    });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  getCheckListView = () => {
    this.props.getCheckListView();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loader: true,
    });
    if (this.props.editMode) {
      updateCheckList(this.state.mainId, this.state.formData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              editMode: false,
              loader: false,
            },
            () => {
              this.handleClose();
              this.getCheckListView();
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } else {
      addCheckList(this.state.formData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              loader: false,
            },
            () => {
              this.handleClose();
              this.getCheckListView();
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    }
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleChange = (e) => {
    let value = e.target.value;

    if (value.trim() === "") {
      value = "";
    }
    if (e.target.name === "days_for_completion") {
      if (Number(value) <= 32767 || NaN) {
        this.setState({
          formData: {
            ...this.state.formData,
            [e.target.name]: Number(value) || 0,
          },
        });
      }
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]: value,
        },
      });
    }
  };
  validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  render() {
    const { openDialog, editMode } = this.props;
    const { formData } = this.state;

    return (
      <div>
        <CommonLoaderComponent open={this.state.loader} />
        <MuiThemeProvider theme={CheckListStyle}>
          <Dialog
            open={openDialog}
            fullWidth
            aria-labelledby="form-dialog-title"
            className="customDialog"
          >
            <form className="customFields" onSubmit={this.handleSubmit}>
              <DialogTitle disableTypography>
                <h3>{editMode ? "Edit Activity" : "Add Activity"}</h3>
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleClose()}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div>
                  <InputLabel>
                    Name <span className="red">*</span>
                  </InputLabel>
                  <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    inputProps={{ maxLength: 35 }}
                    required
                    name="name"
                    value={formData.name ? formData.name : ""}
                    onChange={this.handleChange}
                    type="text"
                    fullWidth
                    placeholder="Enter Name"
                    className="customFieldInput"
                  />
                </div>
                <div>
                  <InputLabel>
                    Display Name <span className="red">*</span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="display_name"
                    required
                    inputProps={{ maxLength: 35 }}
                    value={formData.display_name ? formData.display_name : ""}
                    onChange={this.handleChange}
                    type="text"
                    fullWidth
                    placeholder="Enter Display Name"
                    className="customFieldInput"
                  />
                </div>
                <div>
                  <InputLabel>
                    Expected days for completion <span className="red">*</span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="days_for_completion"
                    required
                    value={
                      formData.days_for_completion
                        ? formData.days_for_completion
                        : ""
                    }
                    onChange={this.handleChange}
                    onKeyPress={(e) => this.validateNumber(e, /[0-9]/)}
                    type="text"
                    fullWidth
                    placeholder="Enter Expected days for completion"
                    className="customFieldInput"
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{ marginRight: 0 }}
                  variant="contained"
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="btn btn-primary"
                >
                  {editMode ? "Update" : "Save"}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </MuiThemeProvider>

        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
export default CheckListDialog;
