import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const payments = "sales/payments/";
const export_payments = "sales/payments/export";
const accounts = "customer/companies/";
const orders = "sales/orders/";
export const searchPayments = (val) =>
  apis.getDatas(payments + `${parseQueryParams(val)}`);
export const exportPayments = (val) =>
  apis.getDatas(export_payments + `${parseQueryParams(val)}`);
export const getPaymentStatusList = (data) =>
  apis.getDatas(`core/payment-statuses`);
export const getPaymentTypesList = (data) =>
  apis.getDatas(`core/payment-types`);
export const getAccountList = (data) =>
  apis.getDatas(`${accounts}${parseQueryParams(data)}`);
export const getAllEmployees = (data) =>
  apis.getDatas(`employee/?view=all${parseQueryParams(data)}`);
export const remove_payment = (id) => apis.deleteData(payments + id);
export const cancel_payment_link = (id) =>
  apis.patchData(payments + id + "/cancel");
export const resend_payment_link = (id, id2) =>
  apis.createData(orders + id + "/payments/" + id2 + "/remainder");
