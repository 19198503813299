import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate from "../../../config/permissiongate";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import NewDeleteComponent from "../../../plugins/delete/newDeleteComponent";
import SearchField from "../../../plugins/searchfield";
import { delete_employee, getEmployeeManageList, searchEmployeeData } from "../apifunctions";
import ManageEmployee from "../manage";

class EmployeeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,

      openManage: false,
      rowData: {},
      formSearch: {
        search: "",
        // status: "",
        // employee_tier__tier: "",
        // cre_agent__id: "",
        // agent_name: "",
        // fromDate: "",
        // toDate: "",
        type: "manage",
        page: 1,
      },
      employee_list: [],
      loader: false,
      pagesize: 0,
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      // await this.getData();

      this.getData();
    } catch (err) {}
  }

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      searchEmployeeData(this.state.formSearch)
        .then((res) => res)
        .then((res) => {
          console.log("res", res);
          this.setState({
            employee_list: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {
      console.log("error", err);
    }
  }
  handleView = (e, row) => {
    this.props.history.push(`/employees-list-view/${row.id}`);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.getData();
        }
      }
    );
  };
  async loadEmployeeList() {
    try {
      getEmployeeManageList()
        .then((res) => {
          console.log(res);
          this.setState({
            employee_list: res,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  handleDatePicker = (date) => {
    //
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          fromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          toDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
      },
      () => {
        if (this.state.formSearch.fromDate && this.state.formSearch.toDate) {
          this.getData();
        }
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (e) => {
    this.getData();
  };
  setOpenManage = () => {
    this.setState({
      openManage: true,
    });
  };
  handleClose = () => {
    this.setState({
      openManage: false,
      rowData: {},
    });
  };
  handleEdit = (e, data) => {
    this.props.history.push({
      pathname: `/employees-list-view/${data.id}`,
      state: { edit: true },
    });
    // this.setState({ rowData: data?.user, openManage: true });
  };
  handleDeleteOpen = (e, data) => {
    this.setState({ rowData: data?.user, opendelete: true });
  };
  handleDeleteClose = (e, data) => {
    this.setState({ rowData: {}, opendelete: false });
  };

  refetch = () => {
    this.getData();
  };

  handleDeleteConfirm = () => {
    delete_employee(this.state?.rowData?.id)
      .then((res) => {
        this.setState({
          snackbarMessage: res.data?.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
        this.handleDeleteClose();
        // setTimeout(() => {
        this.getData();
        // }, 5000);
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          loader: false,
          snackbarVariant: "error",
        });
      });
  };
  render() {
    const { masterData } = this.props;
    const { loader, formSearch, employee_list, totalCount, pagesize } =
      this.state;

    return (
      <div className="card-content collapse show">
        {this.state.openManage && (
          <ManageEmployee
            open={this.state.openManage}
            data={this.state.rowData}
            handleClose={this.handleClose}
            refetch={this.refetch}
          />
        )}
        {this.state.opendelete && (
          <NewDeleteComponent
            opendelete={this.state.opendelete}
            submitted={this.state.loader}
            handleDeleteClose={this.handleDeleteClose}
            delete={this.handleDeleteConfirm}
          />
        )}
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item className="table-search">
              <Button
                className="btn btn-primary"
                onClick={() => this.setOpenManage()}
              >
                + &nbsp; Add New
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                // errorProps={{ disabled: true }}
                RenderError={() => <p>Access Denied !!</p>}
                scopes={[SCOPES.manageEmployee]}
              >
                <DataTable
                  data={employee_list}
                  columns={[
                    {
                      title: "Employee Name",
                      type: "text",
                      field: "name",
                      render: (rowData) =>
                        rowData?.name && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={`/employees-list-view/${rowData.id}`}
                          >
                            {rowData?.name ?? "--"}
                          </Link>
                        ),
                    },
                    {
                      title: "Email",
                      type: "text",
                      field: "user",
                      render: (rowData) =>
                        rowData.user && (
                          <span>{rowData?.user?.email ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Mobile",
                      type: "text",
                      field: "user",
                      render: (rowData) =>
                        rowData.user && <span>{rowData?.user?.mobile}</span>,
                    },
                    {
                      title: "Designation",
                      field: "designation",
                      // align: "center",
                      render: (rowData) =>
                        rowData.employee_profile && (
                          <span>
                            {rowData?.employee_profile?.designation?.name}
                          </span>
                        ),
                    },
                    // {
                    //   title: "Work Zone",
                    //   field: "work_zone",
                    //   // align: "center",
                    //   render: (rowData) =>
                    //     rowData.employee_profile && (
                    //       <span>{rowData?.employee_profile?.work_zone}</span>
                    //     ),
                    // },
                    {
                      title: "Team",
                      field: "team",
                      // align: "center",
                      render: (rowData) =>
                        rowData.employee_profile?.team && (
                          <span>
                            {rowData?.employee_profile?.team
                              ?.map((team) => {
                                return team.name;
                              })
                              .join(", ")}
                          </span>
                        ),
                    },
                    {
                      title: "Status",
                      field: "status",
                      align: "center",
                      render: (rowData) => (
                        <div className="tabletag">
                          {rowData?.status ? (
                            <span className="tag green">Active</span>
                          ) : (
                            <span className="tag red">Inactive</span>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "datetime_created",
                    pagination: true,
                    pageNo: this.state.formSearch.page,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                  }}
                  actions={[
                    {
                      className: "view",
                      rowActionCLick: this.handleView.bind(this),
                      iconName: "/app-assets/backend-images/icons/view.png",
                      tooltip: "View In Detailed",
                    },
                    {
                      className: "edit",
                      rowActionCLick: this.handleEdit.bind(this),
                      iconName:
                        "/app-assets/backend-images/icons/edit-icon.svg",
                      tooltip: "Edit",
                    },
                    {
                      className: "delete",
                      rowActionCLick: this.handleDeleteOpen.bind(this),
                      iconName:
                        "/app-assets/backend-images/icons/delete-icon.svg",
                      tooltip: "Delete",
                    },
                    // {
                    //   className: "view",
                    //   rowActionCLick: this.handleView.bind(this),
                    //   iconName:
                    //     "/app-assets/backend-images/icons/delete-icon.svg",
                    //   tooltip: "Delete Employee",
                    // },
                  ]}
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default EmployeeList;
