import React from "react";
import { SCOPES } from "../../config/permission-maps";
import ContentWrapperComponent from "../../layout/contentWrapper";
import TaskListComponent from "./taskListComponent";

export default function MyTaskComponent(props) {
  console.log(props);
  const currentRoute = props.routes?.find(
    (route) => props.match.path === props.location.pathname
  );
  console.log(currentRoute);
  const state = {
    type: currentRoute ? currentRoute["type"] : null,
  };
  return (
    <ContentWrapperComponent
      scopes={[SCOPES.managePaymentApprovals]}
      type={state.type}
      routes={props.routes}
      {...props}
      component={TaskListComponent}
    />
  );
}
