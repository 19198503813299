import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  createService_Benefits,
  getService_Benefits,
  updateService_Benefits,
} from "../../../apifunctions";

class Benefits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      formData: {},
      serId: this.props.ser_Id,
    };
  }
  componentDidMount = () => {
    if (this.props.ser_Id) {
      this.getData(this.props.ser_Id);
    }
  };
  componentWillReceiveProps = () => {
    this.setState({
      serId: this.props.ser_Id,
    });
  };
  async getData(serId) {
    this.setState({ loader: true });
    try {
      getService_Benefits(serId)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: res.service_benefits[0],
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    if (this.state.formData.id) {
      updateService_Benefits(
        this.props.ser_Id,
        this.state.formData.id,
        this.state.formData
      )
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {}
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            submited: false,
          });
        });
    } else {
      createService_Benefits(this.props.ser_Id, this.state.formData)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: res.service_benefits,
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {}
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            submited: false,
          });
        });
    }
  };
  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <label className="control-label">Description</label>
                  <textarea
                    type="text"
                    required
                    value={formData.description}
                    name="description"
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder=""
                    rows="8"
                    className="form-control"
                  ></textarea>
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <a
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(2)}
                  >
                    Back
                  </a>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onSubmit={this.handleSubmit}
                    disabled={submited}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                  <a
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(4)}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default Benefits;
