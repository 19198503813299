import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";

import DataTable from "../../../../../plugins/dataTable/dataTable";
import { fetch_order_item_task_approvals } from "../../../apifunctions";
import ManageApprovalRequestStatus from "./manageApprovalRequestStatus";

const OrderItemTasksApprovalRequests = ({
  orderId,
  itemId,
  taskId,
  user,
  keyValue,
}) => {
  const [itemData, setItemData] = useState([]);
  const [openManage, setOpenManage] = useState(false);

  const [activeItem, setActiveItem] = useState({});
  const getDatas = () => {
    fetch_order_item_task_approvals(orderId, itemId, taskId).then((res) => {
      setItemData(res.data);
    });
  };
  useEffect(() => {
    if (orderId) {
      getDatas();
    }
  }, [keyValue, taskId]);
  const handleManageStatus = (data) => {
    if (user?.user_data?.id === data?.assignee?.id) {
      setActiveItem({
        id: data?.id,
        status_id: data?.status?.id,
        status_name: data?.status?.name,
      });
      setOpenManage(true);
    }
  };
  const handleClose = (flag) => {
    console.log("close");
    setOpenManage(false);
    setActiveItem({});
    if (flag) {
      getDatas();
    }
  };
  return (
    <div className="w-100">
      {openManage && (
        <ManageApprovalRequestStatus
          data={activeItem}
          open={openManage}
          handleClose={handleClose}
        />
      )}
      <div>
        {" "}
        <DataTable
          data={itemData}
          columns={[
            {
              title: "Title",
              type: "text",
              field: "title",
            },

            {
              title: "Approval Type",
              type: "text",

              render: (rowData) => (
                <span
                // className="text-primary cursor-pointer"
                >
                  {rowData?.approval_type?.name ?? "--"}
                </span>
              ),
            },
            {
              title: "Assignee",
              type: "text",

              render: (rowData) => (
                <span
                // className="text-primary cursor-pointer"
                // onClick={() => {
                //   handleManageAssignee(rowData);
                // }}
                >
                  {rowData?.assignee?.name ?? "--"}
                </span>
              ),
            },
            {
              title: "Amount",
              type: "amount",
              field: "amount",
            },
            {
              title: "Comment",
              type: "text",
              field: "comment",
            },
            {
              title: "Status",
              type: "text",

              render: (rowData) => (
                //   <div
                //   className={`d-flex align-items-center justify-content-between ${
                //     user?.user_data?.id === rowData?.assignee?.id
                //       ? "text-primary cursor-pointer"
                //       : ""
                //   }`}
                //   onClick={() => {
                //     handleManageStatus(rowData);
                //   }}
                // >
                <div>{rowData?.status?.name}</div>
                //   <div>
                //     <AiOutlineCaretDown />
                //   </div>
                // </div>
              ),
            },
          ]}
          options={{
            sort: false,

            pagination: false,
          }}
          actions={
            [
              // {
              //   className: "edit",
              //   //rowActionCLick: this.handleEdit.bind(this),
              //   iconName: "/app-assets/backend-images/icons/edit-icon.svg",
              //   tooltip: "Edit ",
              // },
              // {
              //   className: "delete",
              //   //rowActionCLick: this.handleDeleteOpen.bind(this),
              //   iconName: "/app-assets/backend-images/icons/delete-icon.svg",
              //   tooltip: "Delete",
              // },
            ]
          }
        />
      </div>
    </div>
  );
};

export default OrderItemTasksApprovalRequests;
