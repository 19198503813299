import React, { useState } from "react";
import { useSelector } from "react-redux";

import ChangePassword from "./manage";

function Profile() {
  const user = useSelector((state) => state.permission.userData);
  const [open, setOpen] = useState(false);
  console.log(user);
  return (
    <>
      {open && (
        <ChangePassword
          open={open}
          employeeId={user.user_data?.id}
          data={{}}
          handleClose={() => setOpen(false)}
        />
      )}
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="container rounded bg-white mt-5 mb-5">
            <div className="row">
              <div className="col-md-3 ">
                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                  <img
                    className="rounded-circle mt-5"
                    width="150px"
                    src="/app-assets/images/profile.jpg"
                  />
                  <span className="font-weight-bold">
                    {" "}
                    {user.user_data?.name || "--"}{" "}
                  </span>
                  <span className="text-black-50">
                    {user.user_data?.user?.username || "--"}
                  </span>
                  {user.user_data?.is_admin ? (
                    <span
                      className="tag bg-success text-white "
                      style={{ padding: 5 }}
                    >
                      Admin
                    </span>
                  ) : user.user_data?.is_operations_head ? (
                    <span
                      className="tag bg-success text-white"
                      style={{ padding: 5 }}
                    >
                      Operations Head
                    </span>
                  ) : (
                    ""
                  )}
                  <span> </span>
                </div>
              </div>
              <div className="col-md-9 ">
                <div className="p-3 py-5">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <h4 className="text-right">My Profile</h4>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary profile-button"
                        type="button"
                        onClick={() => setOpen(true)}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label className="labels">First name</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={user.user_data?.user?.first_name || "--"}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="labels">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={user.user_data?.user?.last_name || "--"}
                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-12 mt-2">
                      <label className="labels">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={user.user_data?.user?.email || "--"}
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label className="labels">Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={user.user_data?.user?.mobile || "--"}
                      />
                    </div>

                    <div className="col-md-12 mt-2">
                      <label className="labels">Role</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={user.user_data?.role?.name || "--"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
