import React, { Component } from "react";
import { Link } from "react-router-dom";
import apis from "../../apiactions/api.actions";
import SnackbarMessage from "../../plugins/snackbar-component";
import "./login-style.css";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      loginParams: {},
      snackbarMessage: "",
      snackbarVariant: "",
    };
  }

  sendOTP = (event) => {
    try {
      apis
        .auth("employee/forgot-password-request-otp/", this.state.loginParams)
        .then((res) => {
          if (res.status) {
            localStorage.setItem("user_email", this.state.loginParams.email);
            this.setState(
              {
                snackbarMessage: res.message,
                snackbarOpen: true,
                snackbarVariant: "success",
              },
              () => {
                setTimeout(() => {
                  this.props.history.push("/enter-otp");
                }, 2500);
              }
            );
          }
        })
        .catch((err) => {
          localStorage.removeItem("user_email");
          this.setState({
            snackbarMessage: err.response.data.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}

    event.preventDefault();
  };
  handleFormChange = (e) => {
    this.setState({
      loginParams: {
        ...this.state.loginParams,
        [e.target.name]: e.target.value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  render() {
    return (
      <div className="login-wrapper">
        <div className="left-block">
          <div className="img-block">
            <img src="/app-assets/backend-images/login/login-img.png" />
          </div>
        </div>
        <div className="right-block">
          <div className="card">
            <h4 className="form-title">Forgot Password</h4>
            <form className="login-form" onSubmit={this.sendOTP}>
              <div className="form-group">
                <label className="control-label">
                  Email <span class="red">*</span>
                </label>
                <input
                  type="tel"
                  name="email"
                  onChange={this.handleFormChange}
                  required
                  className="form-control"
                  placeholder="Enter your email"
                />
              </div>

              <div class="form-group">
                <button type="submit" className="btn btn-primary">
                  SEND OTP
                </button>
              </div>
              <div class="form-group">
                <Link to="/" className="btn btn-primary">
                  BACK
                </Link>
              </div>
            </form>
          </div>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ForgotPassword;
