import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useSelector } from "react-redux";

import DataTable from "../../../../../plugins/dataTable/dataTable";
import {
  fetch_order_item_tasks,
  request_order_item_tasks_approval,
} from "../../../apifunctions";
import ManageOrderApproval from "./manage";
import ManageEmployeeTaskStatus from "./mangeEmployeeTasksStatus";
import OrderItemTasksApprovalRequests from "./OrderTasksApprovalRequests";
import { FcAcceptDatabase } from "react-icons/fc";
import Swal from "sweetalert2";
import CommonLoaderComponent from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarComponent from "../../../../../plugins/snackbar-component";
const OrderItemTasks = ({ orderId, itemId }) => {
  const [itemData, setItemData] = useState([]);
  const user = useSelector((state) => state.permission.userData);
  const [openManage, setOpenManage] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [loader, setLoader] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});
  const [activeSelection, setActiveSelection] = useState(null);
  const [keyValue, setKeyValue] = useState(0);
  const [openStatusManage, setOpenStatusManage] = useState(false);
  const getDatas = () => {
    fetch_order_item_tasks(orderId, itemId).then((res) => {
      setItemData(res.data);
    });
  };
  useEffect(() => {
    if (orderId) {
      getDatas();
    }
  }, []);
  console.log("user", user);
  const handleClose = (flag) => {
    setOpenManage(false);
    setOpenStatusManage(false);
    setActiveSelection(null);

    if (flag) {
      getDatas();
      setKeyValue(keyValue + 1);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleCreateApprovalRequest = (dt) => {
    Swal.fire({
      title: "Request for Completion Approval",
      input: "textarea",

      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        request_order_item_tasks_approval(orderId, itemId, dt.id, {
          comment: res.value,
        })
          .then(() => {
            setLoader(false);
            setSnackbarData({
              snackbarMessage: "Service removed from team",
              snackbarOpen: true,
              snackbarVariant: "success",
            });

            // setTimeout(() => {
            getDatas();
            // }, 5000);
          })
          .catch((err) => {
            setLoader(false);
            console.log("err", err);
            setSnackbarData({
              snackbarMessage: err.error.message,
              snackbarOpen: true,
              snackbarVariant: "error",
            });
          });
      }
    });
  };
  const handleManageStatus = (data) => {
    if (
      user?.user_data?.id === data?.assignee?.id ||
      user?.user_data?.is_admin
    ) {
      setActiveSelection({
        id: data?.id,
        status_id: data?.status?.id,
        status_name: data?.status?.name,
      });
      setOpenStatusManage(true);
    }
  };
  console.log(activeItem);
  return (
    <div className="w-100">
      {openManage && (
        <ManageOrderApproval
          open={openManage}
          data={activeItem}
          handleClose={handleClose}
          orderId={orderId}
          itemId={itemId}
          taskId={activeItem?.id}
        />
      )}
      {openStatusManage && (
        <ManageEmployeeTaskStatus
          open={openStatusManage}
          data={activeSelection}
          handleClose={handleClose}
        />
      )}
      <CommonLoaderComponent open={loader} />
      <SnackbarComponent
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      <div>
        {" "}
        <DataTable
          data={itemData}
          columns={[
            {
              title: "Task Name",
              type: "text",
              field: "display_name",
              render: (rowData) =>
                rowData.display_name && (
                  <span
                    className={`${
                      user?.user_data?.id === rowData?.assignee?.id
                        ? "text-primary cursor-pointer"
                        : ""
                    }`}
                    onClick={() => {
                      user?.user_data?.id === rowData?.assignee?.id
                        ? setActiveItem(rowData)
                        : setActiveItem({});
                    }}
                  >
                    {rowData?.display_name ?? "--"}
                  </span>
                ),
            },

            // {
            //   title: "Short Description",
            //   type: "text",
            //   field: "short_description",
            // },
            {
              title: "Assignee",
              type: "text",

              render: (rowData) => (
                <span
                // className="text-primary cursor-pointer"
                // onClick={() => {
                //   handleManageAssignee(rowData);
                // }}
                >
                  {rowData?.assignee?.name ?? "--"}
                </span>
              ),
            },
            {
              title: "Status",
              type: "text",

              render: (rowData) => (
                <>
                  {user?.user_data?.is_admin ||
                  (rowData.status.code !== "completed" &&
                    user?.user_data?.id === rowData?.assignee?.id) ? (
                    <div
                      className={`d-flex align-items-center justify-content-between text-primary cursor-pointer`}
                      onClick={() => {
                        handleManageStatus(rowData);
                      }}
                    >
                      <div>{rowData?.status?.name ?? "Select status"}</div>
                      <div>
                        <AiOutlineCaretDown />
                      </div>
                    </div>
                  ) : (
                    <div className="disabled">{rowData?.status?.name}</div>
                  )}
                </>
              ),
            },
            {
              title: "Actions",
              type: "text",

              render: (rowData) => (
                <>
                  {user?.user_data?.id === rowData?.assignee?.id &&
                  rowData.status.code === "in_progress" ? (
                    <div
                      title={"Request Completion Approval"}
                      className={`d-flex align-items-center text-primary cursor-pointer`}
                      onClick={() => handleCreateApprovalRequest(rowData)}
                    >
                      <span className="cursor-pointer">
                        {" "}
                        <FcAcceptDatabase size={20} />
                      </span>
                      <span className="cursor-pointer ml-1">
                        Request Completion Approval
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
          ]}
          options={{
            sort: false,

            pagination: false,
          }}
          actions={[]}
        />
      </div>

      <div className={`py-2 `}>
        <div
          className={`d-flex py-1 ${
            activeItem && user?.user_data?.id === activeItem?.assignee?.id
              ? "justify-content-between"
              : ""
          }`}
        >
          <div>
            <h3>
              {`Approval Requests ${
                activeItem && activeItem?.id
                  ? " - " + activeItem?.display_name
                  : ""
              }`}{" "}
            </h3>
          </div>
          {activeItem &&
            user?.user_data?.id === activeItem?.assignee?.id &&
            activeItem.status.code !== "completed" && (
              <div>
                {" "}
                <Button
                  className="btn btn-primary"
                  onClick={() => setOpenManage(true)}
                >
                  + &nbsp; Add Approval request
                </Button>
              </div>
            )}
        </div>
        {activeItem && activeItem?.id ? (
          <OrderItemTasksApprovalRequests
            orderId={orderId}
            itemId={itemId}
            user={user}
            keyValue={keyValue}
            taskId={activeItem?.id}
          />
        ) : (
          <div> Select your assigned tasks to view / add Approval requests</div>
        )}
      </div>
    </div>
  );
};

export default OrderItemTasks;
