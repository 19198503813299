import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

import CommonLoader from "../../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../../plugins/snackbar-component";
import { changeTaskStatus, getTaskStatusList } from "../../../../apifunctions";
import RoleDialogTheme from "./dialogTheme";

const ManageEmployeeTaskStatus = ({
  open = false,

  data = {},
  handleClose = () => {},
}) => {
  console.log(data);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data });

  const [serviceData, setServiceData] = useState([]);

  const fetchStatusesSearch = (e) => {
   
    getTaskStatusList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setServiceData(res?.data || []);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitting(true);
    if (formData.status_id && formData.status_id !== "") {
      try {
        changeTaskStatus(formData?.id, {
          status: formData.status_id,
        })
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setTimeout(() => {
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please select a status");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleServiceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      status_name: e["name"],
      status_id: e["id"],
    }));
  };
  const handleChange = (e) => {
    let value=e.target.value;
    if(value.trim()===""){
      value=""
    }
    setFormData((f) => ({ ...f, [e.target.name]: value }));
  };
  useEffect(() => {
    fetchStatusesSearch();
  }, []);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          {/* <form onSubmit={handleSubmit}> */}
          <DialogTitle disableTypography>
            <h3> Change Status</h3>
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
          <DialogContent>
    
            <div>
              <SelectBox
              required
                Data={serviceData}
                id="id"
                async="true"
                value={formData.status_name ? formData.status_name : ""}
                desc="name"
                descId="id"
                getData={fetchStatusesSearch}
                placeholder="Select Status"
                className="form-control"
                onChange={handleServiceChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 0 }}
              variant="contained"
              onClick={(e) => handleClose()}
              className="btn btn-secondary"
            >
              Cancel
            </Button>
            <button
              variant="contained"
      type="submit"
              disabled={submitting}
              className="btn btn-primary"
            >
              {"Save"}
            </button>
          </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageEmployeeTaskStatus;
