import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const employee = "employee/";
const support_note = "note";
const employee_listall = "employee/?view=all";
const employee_list_manage = "employee/?view=all&type=manage";

const roles = "user/groups/";
export const searchEmployeeData = (val) =>
  apis.getDatas(
    employee +
      "?search=" +
      val.search +
      "&type=manage" +
      // + '&view=all'
      // +
      // "&status__id=" +
      // val.status +
      // "&fromDate=" +
      // val.fromDate +
      // "&toDate=" +
      // val.toDate +
      // "&cre_agent__id=" +
      // val.cre_agent__id +
      // "&employee_tier__tier=" +
      // val.employee_tier__tier +
      "&page=" +
      val.page
  );

export const getEmployeeList = () => apis.getDatas(employee_listall);
export const getEmployeeManageList = () => apis.getDatas(employee_list_manage);
export const getEmployeeDetails = (id) => apis.getDatas(`${employee}${id}/`);
export const getEmployeePermissions = (id) => apis.getDatas(`${employee}permissions/${id}/`);
export const getEmployeeWorkProfile = (id) =>
  apis.getDatas(`${employee}${id}/work-profile`);
export const updateEmployeeWorkProfile = (id, data) =>
  apis.createData(`${employee}${id}/work-profile`, data);
export const addTeamToEmployee = (id, data) =>
  apis.createData(`${employee}${id}/add-to-team`, data);
export const getEmployeeTeam = (id) => apis.getDatas(`${employee}teams/`);

export const add_employee = (data) => apis.createData(employee, data);
export const delete_employee = (id) => apis.deleteData(`${employee}${id}/`);
export const delete_team_from_employee = (id, id2) =>
  apis.deleteData(`${employee}${id}/team/${id2}`);
export const setAdminStatus = (id, status) =>
  apis.createData(`${employee}${id}/set-admin-status`, status);
export const setOperationsHeadStatus = (id, status) =>
  apis.createData(`${employee}${id}/set-operations-head`, status);
export const setPassword = (data) =>
  apis.updateData(`${employee}set-password`, data);
export const setEmployeeStatus = (id, status) =>
  apis.createData(`${employee}${id}/set-status`, status);
export const update__employee = (id, data) =>
  apis.updateData(`${employee}${id}/`, data);
export const get_Custom_Business = (id, page) =>
  apis.getDatas(`${employee}${id}/company?page=${page}`);
export const getCustomer = (id) => apis.getDatas(employee + id + "/");
export const getEmployeeLists = (data) =>
  apis.getDatas(`${employee}${parseQueryParams(data)}`);

export const getDesig = (data) =>
  apis.getDatas(`core/designations${parseQueryParams(data)}`);

export const getDept = (data) =>
  apis.getDatas(`core/departments${parseQueryParams(data)}`);
export const getTeamsList = (data) =>
  apis.getDatas(`employee/teams/${parseQueryParams(data)}`);

export const fetchEmployeeTeams = (Id) =>
  apis.getDatas(`employee/${Id}/team`);

export const searchRoles = (val) =>
  apis.getDatas(
    roles +
      "?search=" +
      val.search +
      // + '&view=all'
      // +
      // "&status__id=" +
      // val.status +
      // "&fromDate=" +
      // val.fromDate +
      // "&toDate=" +
      // val.toDate +
      // "&cre_agent__id=" +
      // val.cre_agent__id +
      // "&employee_tier__tier=" +
      // val.employee_tier__tier +
      "&page=" +
      val.page
  );
