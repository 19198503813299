import { Button, Grid } from "@material-ui/core";
// import Managetask from "./manage/addtask";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils.js"

import PermissionsGate from "../../config/permissiongate";
import CommonLoader from "../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../plugins/dataTable/dataTable";
import DeleteComponent from "../../plugins/delete/newDeleteComponent";
import SearchField from "../../plugins/searchfield";
import SnackbarComponent from "../../plugins/snackbar-component";
import { delete_tasks, searchtasks } from "./apifunctions";

export default function TasksListComponent(props) {
  const [formSearch, setFormSearch] = useState({
    search: "",
    // status: "",
    // employee_tier__tier: "",
    // cre_agent__id: "",
    // agent_name: "",
    // fromDate: "",
    // toDate: "",
    page: 1,
  });
  const [tasksList, settasksList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});
  const history = useHistory();
  useEffect(() => {
    console.log("use effect tasks");
    getData();

    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      searchtasks(formSearch)
        .then((res) => {
          let d = res.results;
          d.forEach((item) => {
            item["assignee_name"] = item?.assignee?.name;
            item["assignee_id"] = item?.assignee?.id;
            item["status_name"] = item?.status?.name;
            item["status_id"] = item?.status?.id;
            item["assignee_is_admin"] = item?.assignee?.is_admin;
          });
          settasksList(d);
          setTotalCount(res.count);
          setLoader(false);
          setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleView = (id) => {
    if (id) {
      history.push("/task-details/" + id);
    }
  };

  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
  };

  const handleDeleteConfirm = () => {
    delete_tasks(rowData?.id)
      .then((res) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: res.data?.message,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };

  return (
    <div className="card-content collapse show">
      <CommonLoader open={loader} />
      {/* {openManage && (
        <Managetask
          open={openManage}
          data={rowData}
          handleClose={handleClose}
        />
      )} */}
      <SnackbarComponent
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      <DeleteComponent
        opendelete={openDelete}
        submitted={loader}
        handleDeleteClose={handleDeleteClose}
        delete={handleDeleteConfirm}
      />
      <div className="card-body card-dashboard">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={6} sm={2} className="table-search">
            <SearchField
              onChange={handleChange}
              value={formSearch.search}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </Grid>

          {/* <Grid item className="table-search">
            <Button
              className="btn btn-primary"
              onClick={() => setOpenManage(true)}
            >
              + &nbsp; Add New
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[]}
            >
              <DataTable
                data={tasksList}
                columns={[
                  {
                    title: "Order ID",
                    type: "text",

                    render: (rowData) =>
                      rowData?.item?.order?.id && (
                        <Link
                          className="text-primary cursor-pointer"
                          to={"/task-details/" + rowData?.item?.order?.id}
                        >
                          {rowData?.item?.order?.order_number ?? "--"}
                        </Link>
                      ),
                  },
                  {
                    title: "Task Name",
                    type: "text",
                    field: "display_name",
                  },
                  {
                    title: "Status",
                    type: "text",
                    field: "status_name",
                  },
                  {
                    title: "Remarks",
                    type: "text",
                    field: "remark",
                  },
                  {
                    title: "Expected Completion Date",
                    type: "date",
                    field: "expected_completion_date",
                  },
                  {
                    title: "Actual Completion Date",
                    type: "date",
                    render: (rowData) =>
                      formatDate(rowData.actual_completion_date, 'YYYY-MM-DD')
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "name",
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: handlepagination.bind(this),
                }}
                actions={
                  [
                    //   {
                    //     className: "view",
                    //     rowActionCLick: handleView.bind(this),
                    //     iconName: "/app-assets/backend-images/icons/view.png",
                    //     tooltip: "View In Detailed",
                    //   },
                    //   {
                    //     className: "edit",
                    //     rowActionCLick: handleEdit.bind(this),
                    //     iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    //     tooltip: "Edit",
                    //   },
                    //   {
                    //     className: "delete",
                    //     rowActionCLick: handleDeleteOpen.bind(this),
                    //     iconName:
                    //       "/app-assets/backend-images/icons/delete-icon.svg",
                    //     tooltip: "Delete",
                    //   },
                  ]
                }
              />
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
