import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const company = "customer/companies/";
const company_export = "customer/companies/export/";
export const constitutioncat = "core/constitution-categories";
const support_note = "note";
const customer = "customer/";
export const businsector = "core/business-sectors";
export const leadsource = "core/lead-source";
export const businesCat = "core/business-industries";
const employee_listall = "employee/?view=all";
const companyDetail = "customer/company/";
const eduQualif = "customer/educational-qualifications/";
const eduspecial = "customer/specialization/";

export const updateCompanyPrimaryAgent = (id, data) =>
  apis.updateData(`${companyDetail}${id}/set-primary-agent/`, data);
export const deleteAccountContacts = (id, id2) =>
  apis.deleteData(customer + "account/" + id + "/contacts/" + id2 + "/");
export const getContacts = (id) =>
  apis.getDatas(`${customer}account/${id}/contacts/`);
export const getAccountOrders = (id) =>
  apis.getDatas(`${customer}account/${id}/orders/`);
export const getContactList = (data) =>
  apis.getDatas(`${customer}${parseQueryParams(data)}`);
export const getAgentList = (id, data) =>
  apis.getDatas(`${customer}account/${id}/agents/${parseQueryParams(data)}`);
export const crearteAccountAgent = (id, data) =>
  apis.createData(`${customer}account/${id}/agents/`, data);
export const deleteAccountAgent = (id, id2) =>
  apis.deleteData(`${customer}account/${id}/agents/${id2}/`);
export const getAgentRoleList = (data) =>
  apis.getDatas(`core/account-agent-roles${parseQueryParams(data)}`);
export const seachCompanyData = (data) =>
  apis.getDatas(`${company}${parseQueryParams(data)}`);
export const exportCompany = (data) =>
  apis.getDatas(`${company_export}${parseQueryParams(data)}`);
export const mergeAccounts = (parentAccountId, data) =>
  apis.updateData(`${companyDetail}${parentAccountId}/merge/`, data);

export const getbusIndusData = (val, sector_id = null) => {
  const baseQuery = `${businesCat}?search=${encodeURIComponent(
    val
  )}&view=all&industry_only=true`;
  const query = sector_id
    ? `${baseQuery}&business_sector_id=${sector_id}`
    : baseQuery;

  return apis.getDatas(query);
};

export const getSubIndusData = (val, industry_id = null) => {
  const baseQuery = `${businesCat}?search=${val}&view=all&sub_industry_only=true`;
  const query = industry_id
    ? `${baseQuery}&industry_id=${industry_id}`
    : baseQuery;

  return apis.getDatas(query);
};
export const searhCustomerData = (val) =>
  apis.getDatas(customer + "?view=all&search=" + val);
export const getConstCat = (val) =>
  apis.getDatas(constitutioncat + "?view=all&search=" + val);
export const getbusinsector = (val) =>
  apis.getDatas(businsector + "?view=all&search=" + val);

export const getleadsource = (val) =>
  apis.getDatas(leadsource + "?view=all&search=" + val);

export const getEmployeeList = () => apis.getDatas(employee_listall);
export const get_Custom_Business = (id) =>
  apis.getDatas(`${company}${id}/company`);
export const get_Company_Details = (id) => apis.getDatas(company + id + "/");
export const save_Compnay_Basic = (data) =>
  apis.createData(`${companyDetail}business-basic-info/`, data);
export const get_Compnay_Basic = (data) =>
  apis.getDatas(`${companyDetail}business-basic-info/${data}/`);
export const updateCompnayBasic = (id, data) =>
  apis.updateData(`${companyDetail}business-basic-info/${id}/`, data);
export const updateCompnayBasicImage = (id, data) =>
  apis.createData(`${companyDetail}${id}/basic-info-profile-image/`, data);
export const add_contact_to_account = (id, data) =>
  apis.createData(`customer/account/${id}/contacts/`, data);
export const getAccounts = (data) =>
  apis.getDatas(`${customer}companies/${parseQueryParams(data)}`);
export const save_CompnayMapping = (id, data) =>
  apis.createData(`${companyDetail}${id}/official-mapping/`, data);
export const update_CompnayMapping = (id, id2, data) =>
  apis.updateData(`${companyDetail}${id}/official-mapping/${id2}/`, data);
export const update_CompnayPrimaryContaract = (id, data) =>
  apis.updateData(`${companyDetail}${id}/set-primary-contact/`, data);
export const getCompnayMapping = (id) =>
  apis.getDatas(`${companyDetail}${id}/official-mapping/`);
export const update_Compnaycompliance = (id, data) =>
  apis.updateData(`${companyDetail}${id}/company-compliance-info/`, data);
export const get_Compnaycompliance = (id, data) =>
  apis.getDatas(`${companyDetail}${id}/company-compliance-info/`);
export const update_Compnaycontact = (id, data) =>
  apis.updateData(`${companyDetail}${id}/contact-info/`, data);
export const get_Compnaycontact = (id, data) =>
  apis.getDatas(`${companyDetail}${id}/contact-info/`);

export const save_Compnayaddressinfo = (id, data) =>
  apis.createData(`${companyDetail}${id}/company-address-info/`, data);
export const get_Compnayaddressinfo = (id, data) =>
  apis.getDatas(`${companyDetail}${id}/company-address-info/`, data);
export const update_Compnayaddressinfo = (id, id2, data) =>
  apis.updateData(`${companyDetail}${id}/company-address-info/${id2}/`, data);
export const add_CompanyaddreKyc = (id, id2, data) =>
  apis.createData(`${companyDetail}${id}/address/${id2}/kyc/`, data);
export const get_CompanyaddreKyc = (id, id2) =>
  apis.getDatas(`${companyDetail}${id}/address-kyc/${id2}/`);
export const delete_CompanyaddreKyc = (id, id2, id3) =>
  apis.deleteData(`${companyDetail}${id}/address/${id2}/kyc/${id3}/`);
export const getEmployeeListSearch = (val) =>
  apis.getDatas(`${employee_listall}&search=${val}`);
export const get_CompnayPromotorinfo = (id, data) =>
  apis.getDatas(`${companyDetail}${id}/company-promoter-info/`, data);
export const save_CompnayPromotorinfo = (id, data) =>
  apis.createData(`${companyDetail}${id}/company-promoter-info/`, data);
export const update_CompnayPromotorinfo = (id, id2, data) =>
  apis.updateData(`${companyDetail}${id}/company-promoter-info/${id2}/`, data);
export const add_CompanyPromoKyc = (id, id2, data) =>
  apis.createData(`${companyDetail}${id}/promoter/${id2}/kyc/`, data);
export const get_CompanyPromoKyc = (id, id2) =>
  apis.getDatas(`${companyDetail}${id}/promoter-kyc/${id2}/`);
export const delete_CompanyPromoKyc = (id, id2, id3) =>
  apis.deleteData(`${companyDetail}${id}/promoter/${id2}/kyc/${id3}/`);
export const getCountryState = () => apis.getDatas(`core/country-state-list`);
export const get_CompnayRegistration = (id) =>
  apis.getDatas(`${companyDetail}${id}/registration-information/`);
export const delete_CompnayRegistration = (id, id2) =>
  apis.deleteData(`${companyDetail}${id}/registration-information/${id2}/`);
export const save_CompnayRegistration = (id, data) =>
  apis.createData(`${companyDetail}${id}/registration-information/`, data);

export const getEducationalList = (val) =>
  apis.getDatas(eduQualif + `?search=${val}&view=all`);
export const getSpecializList = (val) =>
  apis.getDatas(eduspecial + `?search=${val}&view=all`);

//////////////////////////
export const getCategoryList = () => apis.getDatas(constitutioncat);

export const getSectorList = () => apis.getDatas(businsector);

export const getCustomerList = () => apis.getDatas(customer);

export const getAccountDuplicate = (data) =>
  apis.getDatas(
    `${customer}possible-account-duplicates/${parseQueryParams(data)}`
  );

export const listAccountDuplicate = (id, data) =>
  apis.getDatas(
    `${customer}${id}/account-duplicates/${parseQueryParams(data)}`
  );
