import { Menu, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import Swal from "sweetalert2";

import { connect } from "react-redux";
import { SCOPES } from "../../../../config/permission-maps";
import { hasPermission } from "../../../../config/permissiongate";
import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../plugins/delete/newDeleteComponent";
import SnackbarMessage from "../../../../plugins/snackbar-component";
import {
  deleteAccountContacts,
  getContacts,
  update_CompnayPrimaryContaract as updateCompanyPrimaryContact,
} from "../../apifunctions";
import ManageAccount from "./manage/addAccount";
import { Link } from "react-router-dom";
// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";

class BusinessProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      totalCount: "",
      anchorEl: null,
      activeRowId: null,
      opendelete: false,
      activeRow: {},
      mainId: "",
      pagesize: "",
      loader: false,
      openModal: false,
      custBus: [],
      page: 1,
    };
  }
  componentDidMount = () => {
    if (this.props.custId) {
      this.getData(this.props.custId);
    }
  };
  handleClose = (flag) => {
    this.setState({ openModal: false }, () => {
      if (flag) {
        this.getData(this.props.custId);
      }
    });
  };
  getData = (serId) => {
    this.setState({ loader: true });

    getContacts(serId, this.state.page)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          custBus: res,
          loader: false,
          // totalCount: res.count,
          // pagesize: res.page_size,
        });
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  // handleChange = (e) => {
  //   this.setState({
  //     formData: {
  //       ...this.state.formData,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (this.state.formData.id) {
  //     updateService_Benefits(serId, this.state.formData.id, this.state.formData)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             snackbarMessage: "Updated Successfully",
  //             snackbarOpen: true,
  //             snackbarVariant: "success",
  //           },
  //           () => {}
  //         );
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err?.response?.data?.error?.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //         });
  //       });
  //   } else {
  //     createService_Benefits(serId, this.state.formData)
  //       .then((res) => res.data)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             formData: res.service_benefits,
  //             snackbarMessage: "Created Successfully",
  //             snackbarOpen: true,
  //             snackbarVariant: "success",
  //           },
  //           () => {}
  //         );
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err?.response?.data?.error?.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //         });
  //       });
  //   }
  // };
  handlepagination(id) {
    this.setState(
      {
        page: id,
      },
      () => {
        this.getData(this.props.custId);
      }
    );
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };

  handleDelete = () => {
    deleteAccountContacts(this.props.custId, this.state.activeRow?.id)
      .then(() => {
        this.getData(this.props.custId);
        this.props.refetch();
        this.handleDeleteClose();
      })
      .catch((err) => {
        this.setState({
          loader: false,
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  handleDeleteOpen = () => {
    this.setState({
      opendelete: true,
    });
  };
  makeAsPrimary = (reqData) => {
    console.log("req data", reqData);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to make this as primary?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        updateCompanyPrimaryContact(this.props.custId, {
          customer_id: this.state.activeRowId,
        }).then(() => {
          this.setState({
            loader: false,
            snackbarMessage: "Primary contact updated",
            snackbarOpen: true,
            snackbarVariant: "success",
          });
          this.getData(this.props.custId);
          this.props.refetch();
        });
      }
    });
  };
  openMenu = (e, rowData) => {
    this.setState(
      { activeRowId: rowData?.customer_id, activeRow: rowData },
      () => {
        this.setState({ anchorEl: e.target });
      }
    );
  };
  handleViewClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl, loader, totalCount, pagesize, custBus } = this.state;
    const { isAdmin } = this.props;

    return (
      <div className="create-content">
        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e) => this.handleViewClose(e)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              // marginRight:100,
              width: 200,
              padding: 0,
              left: 0,
              // right: 80,
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
        >
          {this.props?.userDetails?.default_customer?.id !==
          this.state.activeRowId ? (
            <>
              <MenuItem onClick={(e) => this.makeAsPrimary(e)}>
                Make as Primary contact
              </MenuItem>
              <MenuItem onClick={(e) => this.handleDeleteOpen(e)}>
                Delete
              </MenuItem>
            </>
          ) : (
            <MenuItem disabled onClick={() => {}}>
              No Actions found
            </MenuItem>
          )}
        </Menu>
        <CommonLoader open={loader} />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        {this.state.openModal && (
          <ManageAccount
            open={this.state.openModal}
            handleClose={this.handleClose}
            activeData={custBus}
            custId={this.props.custId}
          />
        )}
        <div className="content-body">
          {/* <form className="edit-form" onSubmit={this.handleSubmit}> */}
          <div className="card-content">
            <div className="row">
              <div className="titlehead col-md-12 col-xs-12 d-flex justify-content-between">
                <div>
                  {" "}
                  <h4>Contacts</h4>
                </div>
                <div>
                  {" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setState({ openModal: true })}
                  >
                    + &nbsp; Add New
                  </button>
                </div>
              </div>
              <div className="col-md-12 col-xs-12 table-block">
                <DataTable
                  data={custBus}
                  columns={[
                    // {
                    //   title: "SL",
                    //   type: "text",
                    //   field: "slNo",
                    // },
                    // this.props?.userDetails?.default_customer?.id
                    {
                      title: "Customer ID",
                      type: "text",
                      field: "customer_code",
                      render: (rowData) =>
                        rowData.customer_code && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={`/contacts-list-view/${rowData?.customer_id}`}
                          >
                            {rowData.customer_code}
                          </Link>
                        ),
                    },
                    // {
                    //   title: "Created Date/ Time",
                    //   type: "text",
                    //   field: "datetime_created",
                    // },
                    {
                      title: "Contact Name",
                      type: "text",
                      field: "name",
                      render: (rowData) => (
                        <>
                          <>
                            {rowData.customer_info && (
                              <span>
                                {rowData.customer_info.first_name}{" "}
                                {rowData.customer_info.last_name}
                              </span>
                            )}
                          </>
                          {rowData?.customer_id ===
                            this.props?.userDetails?.default_customer?.id && (
                            <span
                              span
                              style={{
                                backgroundColor: "#299956",

                                padding: "2px 8px",
                                borderRadius: "6px",
                                color: "#fff",
                                marginLeft: 5,
                                fontSize: "11px",
                              }}
                            >
                              Primary Contact
                            </span>
                          )}
                        </>
                      ),
                    },
                    {
                      title: "Mobile",
                      type: "text",
                      field: "mobile",
                      render: (rowData) =>
                        rowData?.customer_info && (
                          <span>{rowData.customer_info.mobile}</span>
                        ),
                    },
                    // {
                    //   title: "Primary Business",
                    //   field: "primaryBusiness",
                    //   align: "center",
                    // },
                    // {
                    //   title: "City",
                    //   field: "city",
                    //   align: "city",
                    // },
                    // {
                    //   title: "Tier",
                    //   field: "tier",
                    // },
                    // {
                    //   title: "CRE/ Agent",
                    //   field: "creAgent",
                    //   render: (rowData) => (
                    //     <span>
                    //       {rowData?.cre_agent ? rowData.cre_agent.name : ""}
                    //     </span>
                    //   ),
                    // },
                    {
                      title: "Status",
                      field: "customer_status",
                      render: (rowData) =>
                        rowData.customer_status?.code && (
                          <span
                            span
                            style={{
                              backgroundColor:
                                rowData.customer_status.code === "active"
                                  ? "#299956"
                                  : "#DEDEDE",
                              padding: "2px 8px",
                              borderRadius: "6px",
                              color:
                                rowData.customer_status.code === "active"
                                  ? "#fff"
                                  : "#313131",
                              fontSize: "11px",
                            }}
                          >
                            {rowData.customer_status.name}
                          </span>
                        ),
                      type: "status",
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "company_code",
                    pageNo: this.state.page,
                    pagination: false,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                  }}
                  actions={[
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.editAccounts],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "delete",
                            rowActionCLick: this.openMenu.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/menu-dot.svg",
                            tooltip: "Menu",
                          },
                        ]
                      : []),
                  ]}
                />
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
  };
};
export default connect(mapStateToProps)(BusinessProfile);
