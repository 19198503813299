import React, { Component } from "react";
import { Route } from "react-router-dom";
import BreadCrumb from "../../layout/breadcrumb";
import NavigationSubMenu from "../../layout/sub-menu";
import "./file-management.css";

class UserFileManagement extends Component {
  constructor(props) {
    super(props);

    const currentRoute = this.props.routes.find(
      (route) =>
        this.props.match.path + route.path === this.props.location.pathname
    );
    if (this.props.match.path === this.props.location.pathname) {
      this.props.history.push(`${this.props.match.path}/user-file-management`);
    }
    this.state = {
      type: currentRoute ? currentRoute["type"] : null,
    };
  }

  componentDidMount = () => {};

  componentWillMount() {
    this.rerouteToAdminPage();
    // this.loadjsagain(null);
  }
  componentWillReceiveProps = () => {
    if (this.props.location.pathname === "/user-file-management")
      this.props.history.push(`${this.props.match.path}/user-file-management`);
  };
  componentDidUpdate(prevProps) {
    this.rerouteToAdminPage();
    // this.loadjsagain(prevProps);
  }
  RemoveLastDirectoryPartOf(the_url) {
    var the_arr = the_url.split("/");
    the_arr.pop();
    return the_arr.join("/");
  }
  rerouteToAdminPage() {
    let path = this.RemoveLastDirectoryPartOf(this.props.location.pathname);
    let currentRoute = this.props.routes.find(
      (route) =>
        this.props.match.path + route.path === this.props.location.pathname
    );
    if (
      currentRoute == null ||
      currentRoute === undefined ||
      currentRoute === ""
    ) {
      currentRoute = this.props.routes.find(
        (route) => this.props.match.path + route.path === path + "/:id"
      );
    }
    //Setting BreadCrumb type also in Here from currentRoute
    if (currentRoute && currentRoute["type"] !== this.state.type) {
      this.setState({ type: currentRoute["type"] });
    }
    // if (this.props.location.pathname === "/data-management")
    //   this.props.history.push(`${this.props.match.path}/service-categories`);
  }

  render() {
    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb type={this.state.type} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <NavigationSubMenu type={this.props.type} />
                        {this.props.routes.map((route, index) => {
                          return (
                            <Route
                              key={index}
                              path={`${this.props.match.path}${route.path}`}
                              render={(props) => (
                                // pass the sub-routes down to keep nesting
                                <route.component
                                  key={index}
                                  {...props}
                                  masterData={this.props.masterData}
                                  routes={route.routes}
                                />
                              )}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserFileManagement;
