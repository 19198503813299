import { Grid, InputLabel, TextField } from "@material-ui/core";
import React from "react";
import { useRef } from "react";

const RoleDetails = (props, ref) => {
  console.log(props);
  const buttonRef = useRef(null);
  const [formData, setFormData] = React.useState(props.data);

  React.useImperativeHandle(ref, () => ({
    handleSave: () => {
 
      buttonRef.current?.click();
    },
  }));
  React.useEffect(() => {
    setFormData(props.data);
  }, [props.data]);
  const handleChange = (e) => {
    let value=e.target.value;
    if(value.trim()===""){
      value=""
    }
    setFormData({ ...formData, name: value });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    let permionData = [];

    props.rolepermission.forEach((item) => {
      permionData.push(item.acl);
    });
    props.handleSubmit({ ...formData, permissions: permionData });
  };
  return (
    <form onSubmit={(e)=>handleUpdate(e)}>
      <div className="customFields">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputLabel>Role Name<span className="red">*</span></InputLabel>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              required
              disabled={!props.edit}
              name="name"
              value={formData.name ? formData.name : ""}
              onChange={handleChange}
              type="text"
              fullWidth
              className="customFieldInput"
              placeholder="Enter Role Name"
            />
          </Grid>
          <div className="d-none">
            <button type="submit" ref={buttonRef}>
              save
            </button>
          </div>
        </Grid>
      </div>
    </form>
  );
};

export default React.forwardRef(RoleDetails);
