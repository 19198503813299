import React, { Component } from "react";
import BreadCrumb from "../../../../layout/breadcrumb";
import NavigationTab from "../../../../plugins/customtab/customtab";
import nsmConfig from "../../../../plugins/customtab/customtabmenu";
import Benefits from "./benefits/benefits";
import Deliverable from "./deliverable/deliverable";
import Description from "./description/description";
import DocumentRequired from "./document-required/document-required";
import FAQ from "./faq/faq";
import Media from "./media/media";
import PackageCheckList from "./package-checklist/package-checklist";
import PackageInfo from "./package-info/package-info";
import PreRequisits from "./pre-requisits/pre-requisits";
import Pricing from "./pricing/pricingmaster.component";
import Procedure from "./procedure/procedure";
import QuickVideo from "./quick-video/quick-video";
import "./service-component.css";
import ServiceInfo from "./serviceinfo/service-info";
let tabData = nsmConfig["packagecomponent"].menus;
let tabnew = [
  {
    id: 1,
    name: "Package Info",
    path: "/packages-services-management/service-info",
  },
];
class PackageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      type: "",
      pack: "",
      pack_Id:
        this.props.match.params.id === "new" ? "" : this.props.match.params.id,
    };
  }
  handleTabView = (value) => {
    if (this.state.pack_Id) {
      this.setState({
        currentTab: value,
      });
    }
  };
  handleParent = (id) => {
    if (id) {
      this.setState(
        {
          pack_Id: id,
        },
        () => {
          this.props.history.push(`/packages-services-package/details/${id}`);
        }
      );
    }
  };
  handleCancel = () => {
    this.props.history.push("/packages-services/package-management");
  };
  render() {
    const { currentTab, pack_Id } = this.state;

    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb type={"packagemanagement"} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <NavigationTab
                          tabData={pack_Id ? tabData : tabnew}
                          currentTab={currentTab}
                          handleTabView={this.handleTabView}
                        />

                        {currentTab === 0 && (
                          <PackageInfo
                            key={pack_Id + currentTab}
                            pack_Id={pack_Id}
                            masterData={this.props.masterData}
                            handleParent={this.handleParent}
                            handleTabView={this.handleTabView}
                            handleCancel={this.handleCancel}
                          />
                        )}
                        {currentTab === 1 && (
                          <ServiceInfo
                            key={pack_Id + currentTab}
                            pack_Id={pack_Id}
                            masterData={this.props.masterData}
                            handleTabView={this.handleTabView}
                          />
                        )}
                        {currentTab === 2 && pack_Id && (
                          <Description
                            key={pack_Id + currentTab}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 3 && pack_Id && (
                          <Benefits
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 4 && pack_Id && (
                          <Procedure
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 5 && pack_Id && (
                          <DocumentRequired
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 6 && pack_Id && (
                          <PackageCheckList
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 7 && pack_Id && (
                          <PreRequisits
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 8 && pack_Id && (
                          <Deliverable
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 9 && pack_Id && (
                          <FAQ
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 10 && pack_Id && (
                          <Pricing
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 11 && pack_Id && (
                          <Media
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 12 && pack_Id && (
                          <QuickVideo
                            key={pack_Id}
                            pack_Id={pack_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageComponent;
