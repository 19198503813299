import React from "react";
import { SCOPES } from "../../config/permission-maps";
import ContentWrapperComponent from "../../layout/contentWrapper";
import RoleList from "./groupsList";

export default function TeamsComponent(props) {
  const currentRoute = props.routes.find(
    (route) => props.match.path + route.path === props.location.pathname
  );
  const state = {
    type: currentRoute ? currentRoute["type"] : null,
  };
  return (
    <ContentWrapperComponent
      scopes={[SCOPES.manageEmployee]}
      type={state.type}
      routes={props.routes}
      {...props}
      component={RoleList}
    />
  );
}
