import { InputLabel, MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

import CommonLoader from "../../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../../../../../plugins/snackbar-component";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../../../../plugins/snackbar-component";
import { getAgentList, getAgentRoleList, getEmployeeListSearch } from "../../../../apifunctions";
import TeamDialogTheme from "./dialogTheme";

const ManageAgent = ({
  open = false,
  id,
  refetch = () => {},
  handleSubmit = () => {},
  handleClose = () => {},
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const [agentData, setAgentData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const fetchAgentBySearch = (e) => {
  
    getEmployeeListSearch(e ?? "").then((res) => {
      let data = res;

      

      setAgentData(data);
    });
  };
  const handleSubmitData = (e) => {
    e.preventDefault();
    if (formData?.ex_id && formData?.role_id) {
      let payload = {
        customer_relation_executive: formData?.ex_id,
        agent_role: formData?.role_id,
      };
      handleSubmit(payload)
        .then((res) => {
          setSnackbarVarient("success");
          setSnackbarMessage("Updated Successfully");
          setSnackbarOpen(true);

          refetch();
        })
        .catch((err) => {
          setSnackbarVarient("error");
          setSnackbarMessage(err?.response?.data?.error?.message);
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please fill all required fields with valid inputs");
      setSnackbarOpen(true);
    }
  };
  const fetchRoleBySearch = (e) => {
   
    getAgentRoleList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      let data = res?.data;
  

      setRoleData(data);
    });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  const handleAgentChange = (e, a, b) => {
    console.log(e, a, b);
    setFormData((f) => ({
      ...f,
      ex_name: e["name"],
      ex_id: e["id"],
    }));
  };
  const handleRoleChange = (e, a, b) => {
    console.log(e, a, b);
    setFormData((f) => ({
      ...f,
      role_name: e["name"],
      role_id: e["id"],
    }));
  };
  useEffect(() => {
    fetchAgentBySearch();
    fetchRoleBySearch();
  }, []);
  console.log("hi");
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmitData}>
            <DialogTitle disableTypography>
              <h3> {"Add  Agent"}</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>
                  Agent<span className="red">*</span>
                </InputLabel>
                <SelectBox
                  Data={agentData}
                  id="id"
                  async="true"
                  required
                  value={formData.ex_name ? formData.ex_name : ""}
                  desc="name"
                  descId="id"
                  getData={fetchAgentBySearch}
                  placeholder="Agent"
                  className="form-control"
                  onChange={handleAgentChange}
                />
              </div>
              <div>
                <InputLabel>
                  Role<span className="red">*</span>
                </InputLabel>
                <SelectBox
                  Data={roleData}
                  id="id"
                  async="true"
                  required
                  value={formData.role_name ? formData.role_name : ""}
                  desc="name"
                  descId="id"
                  getData={fetchRoleBySearch}
                  placeholder="Role"
                  className="form-control"
                  onChange={handleRoleChange}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageAgent;
