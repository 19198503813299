import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";

import SnackbarMessage from "../../../plugins/snackbar-component";
import { changePassword } from "../apifunctions.js";
import RoleDialogTheme from "./dialogTheme";
import { MuiThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";

const ChangePassword = ({
  open = false,
  data = {},
  handleClose = () => {},
  employeeId,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    const payload = {
      employee: employeeId,
      password: formData?.password,
      confirm_password: formData?.confirm_password,
      old_password: formData?.old_password,
    };
    if (formData.password === formData.confirm_password) {
      try {
        changePassword(payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Password changed Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);

            setTimeout(() => {
              handleClose(true);
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(generateErrorMessage(err));
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Password does not match");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormData((f) => ({ ...f, [name]: value }));
  };
  const generateErrorMessage = (err) => {
    let errMessage = "Something went wrong!";

    const errorArray = [];
    if (err.response.data.errors) {
      if (Array.isArray(err.response.data.errors)) {
        err.response.data.errors.forEach((item) => {
          console.log(item);
          if (item.field === "message") {
            errorArray.push(item.message);
          }
        });

        // });
        errMessage = String(errorArray);
        // } else {
        //   errMessage = "Something went wrong!";
      }
    } else {
      if (err?.response?.data?.error?.message) {
        errMessage = err?.response?.data?.error?.message;
      } else {
        errMessage = "Something went wrong!";
      }
    }
    return errMessage;
  };
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> Change Password</h3>
              <IconButton aria-label="close" onClick={()=>handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <InputLabel>Old Password</InputLabel>
                <TextField
                  name="old_password"
                  id="id"
                  required
                  type="password"
                  value={formData.old_password ? formData.old_password : ""}
                  placeholder="Old password"
                  className="customFieldInput"
                  onChange={handleChange}
                />
              </div>
              <div>
                <InputLabel>New Password</InputLabel>
                <TextField
                  name="password"
                  id="id"
                  required
                  inputProps={{ minLength: 8 }}
                  type="password"
                  value={formData.password ? formData.password : ""}
                  placeholder="New password"
                  className="customFieldInput"
                  onChange={handleChange}
                />
              </div>
              <div>
                <InputLabel>Confirm Password</InputLabel>
                <TextField
                  name="confirm_password"
                  id="id"
                  required
                  type="password"
                  value={
                    formData.confirm_password ? formData.confirm_password : ""
                  }
                  placeholder="Confirm password"
                  className="customFieldInput"
                  onChange={handleChange}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ChangePassword;
