import { cloneElement } from "react";
import { useSelector } from "react-redux";

export const hasPermission = ({ permissions, scopes }) => {
  // console.log(scopes,permissions)
  const scopesMap = {};

  if (scopes.length === 0) {
    return true;
  }
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });
  const has_perm = permissions?.some((permission) => scopesMap[permission]);

  return has_perm;
};

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
  overridePermission = false,
}) {
  const permissions = useSelector((state) => state.permission.value);
  const isAdmin = useSelector((state) => state.permission.isAdmin);
  const permissionGranted =
    isAdmin || overridePermission
      ? isAdmin || overridePermission
      : hasPermission({ permissions, scopes });

  if (!permissionGranted && !errorProps) return <RenderError />;

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps });

  return <>{children}</>;
}
