import { MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useRef, useState } from 'react';

import CommonLoader from '../../../../../plugins/commonLoader/commonLoader.component';
import SelectBox from '../../../../../plugins/selectbox/commonSelectBox';
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from '../../../../../plugins/snackbar-component';
import { addServiceToTeam, getServiceList } from '../../../apifunctions';
import TeamDialogTheme from '../../../dialogTheme';

const ManageTeamServices = ({
  open = false,
  data = {},
  team_id,
  handleClose = () => {},
  refetch = () => {},
}) => {
  const submitRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionType, setActionType] = useState('close');
  const [formData, setFormData] = useState({ ...data });
  const [serviceData, setServiceData] = useState([]);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = { service_id: formData?.service_id };

    try {
      addServiceToTeam(team_id, payload)
        .then((res) => {
          setSnackbarVarient('success');
          setSnackbarMessage('Added Successfully');
          setSnackbarOpen(true);
          setFormData((f) => ({ service_name: null, service_id: null }));
          setSubmitting(false);
          if (actionType === 'close') {
            setTimeout(() => {
              handleClose(true);
            }, 500);
          }
          refetch();
        })
        .catch((err) => {
          setSnackbarVarient('error');
          setSnackbarMessage(err?.response?.data?.error?.message);
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
    }
  };
  const fetServiceBySearch = (e) => {
 
    getServiceList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      console.log(res);
      setServiceData(res?.data?.results || []);
    });
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage('');
  };

  const handleServiceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      service_name: e['service_name'],
      service_id: e['id'],
    }));
  };
  useEffect(() => {
    fetServiceBySearch();
  }, []);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> {'Add  Service'}</h3>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <label className="control-label">
                  Service <span className="red">*</span>
                </label>

                <SelectBox
                  Data={serviceData}
                  id="id"
                  async="true"
                  required
                  value={formData.service_name ? formData.service_name : ''}
                  desc="service_name"
                  descId="id"
                  getData={fetServiceBySearch}
                  placeholder="Service"
                  className="form-control"
                  onChange={handleServiceChange}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                // type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setActionType('save');
                  setTimeout(() => {
                    submitRef.current.click();
                  }, 100);
                }}
                disabled={submitting}
                className="btn btn-primary"
              >
                {'Save'}
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  setActionType('close');
                  setTimeout(() => {
                    submitRef.current.click();
                  }, 100);
                }}
                disabled={submitting}
                className="btn btn-primary"
              >
                {'Save & Close'}
              </Button>
              <div className="d-none">
                <button ref={submitRef} type="submit">
                  submit
                </button>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageTeamServices;
