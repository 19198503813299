import React, { Component } from "react";
import SnackbarMessage from "../../../../plugins/snackbar-component";

class ManageBusinesCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectData: [],
      openSector: false,
      formData: {
        business_industry_code: props.catForm.business_industry_code,
        business_industry_display_name:
          props.catForm.business_industry_display_name,
        business_industry_name: props.catForm.business_industry_name,
        business_sector: props.catForm.business_sector,
        parent: props.catForm.parent,
        description: props.catForm.description,
        business_sector_name: this.props.catForm.business_sector_name,

        parent_name: props.catForm.parent_name,
        id: props.catForm.id,
      },
      snackbarMessage: "",
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formData: {
        ...this.state.formData,
        business_industry_code: props.catForm.business_industry_code,
        business_industry_display_name:
          props.catForm.business_industry_display_name,
        business_sector_name: this.props.catForm.business_sector_name,
        business_industry_name: props.catForm.business_industry_name,
        business_sector: props.catForm.business_sector,
        parent: props.catForm.parent,
        parent_name: props.catForm.parent_name,
        description: props.catForm.description,
        id: props.catForm.id,
      },
    });
  };
  componentDidMount = () => {};

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submited: true });
    this.props
      .submit(this.state.formData)
      .then((res) => {
        this.setState({
          snackbarMessage: this.state.formData.id
            ? "Business category updated successfully"
            : "Business category created successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
          submited: false,
        });
        if (this.state.formData.id) {
          this.props.handleRefresh(e, res, false);
        } else {
          setTimeout(() => {
            this.props.handleRefresh(e, res, true);
          }, 1500);
        }
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  render() {
    const { formData } = this.state;
    const { level } = this.props;

    return (
      <div className="card-content">
        <form className="edit-form" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-10 col-xs-12">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Business Sector Name <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    value={formData.business_sector_name}
                    name="business_industry_name"
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Enter Business Sector Name"
                  />
                </div>
                {level === 3 && (
                  <div className="col-md-4 col-xs-12">
                    <label className="control-label">
                      Business Category Parent Name{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      disabled
                      value={formData.parent_name}
                      name="business_industry_name"
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="Enter Business Category Parent Name"
                    />
                  </div>
                )}
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Business Category Name <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={formData.business_industry_name}
                    name="business_industry_name"
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Enter Business Category Name"
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Business Category Code <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={formData.business_industry_code}
                    name="business_industry_code"
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Enter Business Category Code"
                  />
                </div>

                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Display Name <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={formData.business_industry_display_name}
                    name="business_industry_display_name"
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder="Enter Display Name"
                  />
                </div>
                <div className="col-md-12 col-xs-12 ">
                  <label className="control-label">Description</label>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder="Enter Description"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-xs-12 upload-block upload-block01 ">
              <label className="control-label">Thumbnail Image </label>
              <div className="upload-img">
                <input type="file" className="file-upload" id="img-upload" />
                <label for="img-upload">
                  <img src="/app-assets/backend-images/thumbnail-business-sector.png" />
                  <div className="upload-icon">
                    <img src="/app-assets/backend-images/icons/camera.svg" />
                  </div>
                </label>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <button
                disabled={this.state.submited}
                type="submit"
                className="btn btn-primary"
              >
                {this.props.editCat ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </form>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ManageBusinesCategory;
