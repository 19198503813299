import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";

import { SCOPES } from "../config/permission-maps";
import PermissionsGate from "../config/permissiongate";

import "./sidemenu.css";

const sideMenuItems = [
  {
    parent: null,
    id: 1,
    label: "Dashboard",
    name: "dashboard",
    path: "/dashboard",
    icon: "ic-dashboard",
    expanded: false,
    hasChild: false,
    permission: SCOPES.dashboard,
  },
  // {
  //   id: 2,
  //   parent: null,
  //   label: "My Tasks",
  //   name: "my-tasks",
  //   path: "/my-tasks",
  //   icon: "ic-tasks",
  //   expanded: false,
  //   hasChild: false,
  //   permission: null,
  // },
  // {
  //   id: 13,
  //   parent: null,
  //   label: "My Approvals",
  //   name: "my-apprrovals",
  //   path: "/my-apprrovals",
  //   icon: "ic-approvals",
  //   expanded: false,
  //   hasChild: false,
  //   permission: null,
  // },
  // {
  //   id: 14,
  //   parent: null,
  //   label: "Payment Approvals",
  //   name: "payment-apprrovals",
  //   path: "/payment-apprrovals",
  //   icon: "ic-payment-approvals",
  //   expanded: false,
  //   hasChild: false,
  //   permission: SCOPES.managePaymentApprovals,
  // },

  {
    parent: null,
    id: 3,
    label: "Leads",
    name: "Leads",
    path: "/leads-enquiry",
    icon: "ic-leads",
    expanded: false,
    hasChild: false,
    permission: SCOPES.manageLeads,
  },
  {
    parent: null,
    id: 4,
    label: "Contacts",
    name: "Contacts",
    expanded: false,
    hasChild: false,
    path: "/contacts/contact-list",
    icon: "ic-contacts",
    permission: SCOPES.manageContacts,
  },

  {
    parent: null,
    label: "Accounts",
    id: 5,
    name: "accounts",
    path: "/accounts/account-list",
    hasChild: false,
    expanded: false,
    icon: "ic-accounts",
    permission: SCOPES.manageAccounts,
  },
  {
    parent: null,
    id: 6,
    label: "Deals",
    name: "deals",
    path: "/deals",
    hasChild: false,
    icon: "ic-deals",
    expanded: false,
    permission: SCOPES.manageDeals,
  },
  {
    parent: null,
    id: 7,
    label: "Quote",
    name: "quote",
    path: "/quote",
    icon: "ic-quote",
    expanded: false,
    hasChild: false,
    permission: SCOPES.manageQuotes,
  },
  {
    parent: null,
    id: 8,
    label: "Service Orders",
    name: "orders",
    expanded: false,
    hasChild: false,
    path: "/orders/by-orders-list",
    icon: "ic-order",
    permission: SCOPES.manageOrders,
  },
  {
    parent: null,
    id: 8,
    label: "Export",
    name: "exports",
    expanded: false,
    hasChild: false,
    path: "/employee/exports",
    icon: "ic-governmentfillings",
    permission: SCOPES.manageExport,
  },
  {
    parent: null,
    id: 9,
    label: "Invoices & Receipts",
    name: "invoice-reciepts",
    path: "/invoice-reciepts",
    hasChild: false,
    icon: "ic-invoice",
    expanded: false,
    permission: SCOPES.manageInvoices,
  },
  {
    parent: null,
    id: 10,
    label: "Payments",
    name: "payment-refund",
    path: "/payment-refund/payments",
    hasChild: false,
    icon: "ic-payment",
    expanded: false,
    permission: SCOPES.managePayments,
  },
  {
    parent: null,
    id: 11,
    label: "Filings & Compliance",
    name: "filingscompliance",
    path: "/filings-compliance/entity-type",
    icon: "ic-governmentfillings",
    expanded: false,
    hasChild: false,
    permission: SCOPES.manageFilings,
  },
  {
    parent: null,
    id: 12,
    label: "System Settings",
    name: "syssettings",
    path: "/system-settings",
    hasChild: true,
    icon: "ic-settings",
    expanded: false,
    permission: SCOPES.settings,
  },
  {
    parent: 12,
    label: "Organization",
    name: "organization",
    id: 121,
    hasChild: true,
    path: "/organization",
    icon: "ic-packagemanagement",
    expanded: false,
    permission: SCOPES.manageOrg,
  },
  {
    label: "Employees",
    parent: 121,
    name: "employees",
    id: 1211,
    path: "/employees/employee-list",
    expanded: false,
    hasChild: false,
    icon: "ic-packagemanagement",
    permission: SCOPES.manageEmployee,
  },
  {
    label: "Teams",
    name: "teams",
    id: 1212,
    parent: 121,
    path: "/teams",
    expanded: false,
    hasChild: false,
    icon: "ic-packagemanagement",
    permission: SCOPES.teamSettings,
  },
  {
    label: "Roles",
    name: "roles",
    id: 1213,
    parent: 121,
    expanded: false,
    hasChild: false,
    path: "/roles",
    icon: "ic-packagemanagement",
    permission: SCOPES.manageRole,
  },
  {
    label: "Services",
    name: "services",
    id: 122,
    path: "/services",
    hasChild: true,
    parent: 12,
    icon: "ic-packagemanagement",
    expanded: false,
    permission: SCOPES.manageServices,
  },
  {
    label: "Services",
    name: "servicespackages",
    path: "/packages-services/service-management",
    expanded: false,
    icon: "ic-servicemanagement",
    hasChild: false,
    parent: 122,
    id: 1221,
    permission: SCOPES.viewService,
  },
  {
    label: "Activities",
    id: 1222,
    parent: 122,
    name: "checklist",
    path: "/checklist/checklistView",
    hasChild: false,
    icon: "ic-digitalmanagement",
    expanded: false,
    permission: SCOPES.taskSettings,
  },
  {
    label: "Promotions",
    name: "promotions",
    id: 123,
    parent: 12,
    hasChild: true,
    path: "/promotions",
    icon: "ic-packagemanagement",
    expanded: false,
    permission: SCOPES.promotionSettings,
  },
  {
    label: "Coupons",
    id: 1231,
    parent: 123,
    hasChild: false,
    name: "coupons",
    path: "/coupons/coupons-list",
    icon: "ic-coupons",
    expanded: false,
    permission: SCOPES.manageCoupon,
  },

  {
    label: "Data Management",
    name: "dataManagement",
    path: "/data-management",
    parent: 12,
    id: 124,
    hasChild: true,
    expanded: false,
    icon: "ic-datamanagement",
    permission: SCOPES.manageData,
  },
  {
    label: "Dropdowns",
    name: "dropdowns",
    id: 1241,
    parent: 124,
    path: "/data-management/parameter-options",
    icon: "ic-coupons",
    expanded: false,
    permission: SCOPES.manageData,
  },
  {
    label: "Forms",
    name: "forms",
    path: "/forms",
    parent: 12,
    id: 125,
    hasChild: true,
    expanded: false,
    icon: "ic-coupons",
    permission: SCOPES.manageForms,
  },
  {
    label: "Business Forms",
    name: "business_forms",
    id: 1251,
    parent: 125,
    path: "/business-forms",
    icon: "ic-coupons",
    expanded: false,
    permission: SCOPES.manageForms,
  },
];

function Sidemenu(params) {
  const location = useLocation();
  const history = useHistory();
  const [menuItems, setMenuItems] = React.useState(sideMenuItems);
  const [updateKey, setUpdateKey] = React.useState(1);
  const handlePath = (e, item) => {
    let m = [...menuItems];
    if (!item.hasChild) {
      history.push(item.path);
    } else {
      // m.forEach((item) => {
      //   item["expanded"] = !item.expanded;
      // });
      item.expanded = !item.expanded;
      setMenuItems(m);
      setUpdateKey(updateKey + 1);
    }
  };
  const checkIsActive = (chi) => {
    let isActive = false;
    if (chi) {
      isActive = chi.some((ch) => location.pathname.startsWith(ch.path));
    }
    return isActive;
  };
  const FunMenus = (renProps) => {
    const { item, level } = renProps;

    return (
      <>
        <ListItem
          className={`subLinkItem linkItem-${level} nav-item ${
            location.pathname.startsWith(item.path) ? "active" : ""
          }`}
          key={item.name}
          style={{
            left: `${level * 0}px`,
            marginLeft: `${level * 0}px`,
            position: "relative",
          }}
        >
          <Link
            onClick={(e) => (!item.hasChild ? "" : handlePath(e, item))}
            className="menuLink"
            to={item.hasChild ? location.pathname : item.path}
          >
            {/* <i className={`menu-icon ${item.icon}`}></i> */}
            <span className="menuIcon">
              {item.label.charAt(0).toUpperCase()}
            </span>
            <span className="menu-title" data-i18n="">
              {item.label}
            </span>
            {item.hasChild && (
              <>
                {item.expanded ? (
                  <BiChevronUp style={{ fontSize: 24, marginRight: 0 }} />
                ) : (
                  <BiChevronDown style={{ fontSize: 24, marginRight: 0 }} />
                )}
              </>
            )}
          </Link>
        </ListItem>
        <>{item.expanded && <>{renProps.renderFuncs(item, level + 1)}</>} </>
      </>
    );
  };

  const renderFuncs = (mitem, level) => {
    const childItems = menuItems.filter((item) => item.parent === mitem.id);

    return childItems.map((item, key) => {
      if (mitem.id === item.parent)
        return (
          <React.Fragment>
            <FunMenus
              item={item}
              level={level}
              key={key}
              renderFuncs={renderFuncs}
            />
          </React.Fragment>
        );
    });
  };
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
    >
      <div>
        <List
          //  className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          {menuItems
            .filter((item) => item.parent == null)
            .map((mItem) => (
              <>
                {/* {mItem.permission && ( */}
                <PermissionsGate
                  // errorProps={{ disabled: true }}
                  RenderError={() => <></>}
                  scopes={mItem.permission ? [mItem.permission] : []}
                >
                  {!mItem.parent && (
                    <ListItem
                      className={` nav-item ${
                        location.pathname.startsWith(mItem.path) ? "active" : ""
                      }`}
                      key={mItem.name}
                    >
                      <Link
                        onClick={(e) =>
                          mItem.hasChild ? handlePath(e, mItem) : ""
                        }
                        className="menuLink"
                        to={mItem.hasChild ? location.pathname : mItem.path}
                      >
                        <i className={`menu-icon ${mItem.icon}`}></i>
                        <span className="menu-title" data-i18n="">
                          {mItem.label}
                        </span>
                        {mItem.hasChild && (
                          <>
                            {mItem.expanded ? (
                              <BiChevronUp
                                style={{ fontSize: 24, marginRight: 0 }}
                              />
                            ) : (
                              <BiChevronDown
                                style={{ fontSize: 24, marginRight: 0 }}
                              />
                            )}
                          </>
                        )}
                      </Link>
                    </ListItem>
                  )}
                  <>{mItem.expanded && <>{renderFuncs(mItem, 1)}</>} </>
                </PermissionsGate>
                {/* )} */}
              </>
            ))}
        </List>
      </div>
    </div>
  );
}

export default withRouter(Sidemenu);
