import { MuiThemeProvider } from "@material-ui/core";
import { debounce } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
// import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { EMAIL_REGEX } from "../../../utils/formValidate";
import { getbusinsector, getConstCat, save_Compnay_Basic } from "../../company/apifunctions";
import { saveCustomerBasic } from "../../customers/apifunctions";
import { getContactDuplicate } from "../../orders/apifunctions";
import { add_orders, getAccountList, getContacts, getServiceList } from "../apifunctions";
import RoleDialogTheme from "./dialogTheme";

const masterData = localStorage.getItem("masterData")
  ? JSON.parse(localStorage.getItem("masterData"))
  : [];
const ManageContact = ({
  open = false,
  data = {},
  handleClose = () => {},
  ...props
}) => {
  const emailRef = React.useRef(null);
  const mobilerefRef = React.useRef(null);
  const email2ref = React.useRef(null);
  const mobile22ref = React.useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sectData, setSecData] = useState([]);
  const [constData, setConstData] = useState([]);
  const [indusData, setIndusData] = useState([]);
  const [subSectData, setSubSectData] = useState([]);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({ ...data, order_date: new Date() });
  const [duplicate, setDuplicate] = useState({});
  const latestValue = useRef();

  const handleBusinessTierSelectChange = (e, id, name) => {
    setFormData((f) => ({
      ...f,
      [id]: e.id,
      preferred_language: e[name],
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formData.email_2 === formData.email) {
      setSnackbarVarient("error");
      setSnackbarMessage("Primary and Secondary email should not be same");
      setSnackbarOpen(true);
      setSubmitting(false);
    } else {
      if (formData.mobile_2 === formData.mobile) {
        setSnackbarVarient("error");
        setSnackbarMessage(
          "Primary and Secondary phone number should not be same"
        );
        setSnackbarOpen(true);
        setSubmitting(false);
      } else {
        setSubmitting(true);
        saveCustomerBasic({
          ...formData,
          account_id: props.accountData?.id,
          date_of_birth: moment(formData.date_of_birth).format("YYYY-MM-DD"),
          preferred_language:
            formData.preferred_language &&
            formData.preferred_language.length > 0
              ? formData.preferred_language
              : null,
          last_name:
            formData.last_name && formData.last_name.length > 0
              ? formData.last_name
              : "",
        })
          .then((res) => res.data)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Created Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setTimeout(() => {
              props.onSuccess(res);
            }, 500);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(
              err?.response?.data?.error?.message.error ??
                err?.response?.data?.error?.message
            );
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      }
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const getContactDuplicateData = debounce(async (fieldName, value) => {
    latestValue.current = value;
    try {
      const res = await getContactDuplicate({
        [fieldName]: value,
        contact_id: '',
      });
      if (latestValue.current === value) {
        setDuplicate({
          [fieldName]: res.data ?? [],
        });
      }
    } catch (error) {
      setDuplicate({
        [fieldName]: [],
      });
    }
  }, 1000);

  const handleChange = (e) => {
    const fieldName = e.target.name;
    let value = e.target.value;
    const { name, value1 } = e.target;
    const trimmedValue = value.trimStart();
    if (value.trim() === "") {
      value = undefined;
    }
    if (e.target.name === "email") {
      const { validity } = emailRef.current;
      if (validity.patternMismatch) {
        emailRef.current.setCustomValidity("Enter a valid email");
      } else {
        emailRef.current.setCustomValidity("");
      }
    }
    if (e.target.name === "email_2") {
      const { validity } = email2ref.current;
      if (validity.patternMismatch) {
        email2ref.current.setCustomValidity("Enter a valid email");
      } else {
        email2ref.current.setCustomValidity("");
      }
    }
    if (e.target.name === "mobile") {
      const { validity } = mobilerefRef.current;
      if (validity.patternMismatch) {
        mobilerefRef.current.setCustomValidity(
          "Enter a valid 10 digit mobile number starts with +91"
        );
      } else {
        mobilerefRef.current.setCustomValidity("");
      }
    }
    if (e.target.name === "mobile_2") {
      const { validity } = mobile22ref.current;
      if (validity.patternMismatch) {
        mobile22ref.current.setCustomValidity(
          "Enter a valid 10 digit mobile number starts with +91"
        );
      } else {
        mobile22ref.current.setCustomValidity("");
      }
    }

    setFormData((f) => ({ ...f, [name]: trimmedValue }));
    const valueToPass =
      name === "mobile" || name === "mobile_2"
        ? encodeURIComponent(trimmedValue)
        : trimmedValue;
    getContactDuplicateData(name, valueToPass);

  };

  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  const handleDatePicker = (e) => {
    setFormData((f) => ({
      ...f,
      [e.name]: e.value ?? "",
    }));

    // setTimeout(() => {
    //   console.log(this.state.formData.dob, e.name);
    // }, 500);
  };
  const blurChange = (fieldName) => {
    setDuplicate({
      duplicate: { [fieldName]: [] },
    });
  };
  useEffect(() => {}, []);
  console.log(formData);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          // aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <h3> Add Contact to Account : {props?.accountData?.name}</h3>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-6 pb-2">
                  <label className="control-label">
                    Account <span className="red">*</span>
                  </label>
                  <TextField
                    type="text"
                    // required
                    name="first_name"
                    disabled
                    value={
                      props?.accountData?.name ? props?.accountData?.name : ""
                    }
                    className="customFieldInput"
                    // onChange={handleChange}
                    // placeholder="Enter First Name"
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">
                    First Name <span className="red">*</span>
                  </label>
                  <TextField
                    type="text"
                    required
                    name="first_name"
                    value={formData.first_name ? formData.first_name : ""}
                    className="customFieldInput"
                    onChange={handleChange}
                    placeholder="Enter First Name"
                  />
                </div>
                <div className="col-6 pb-2">
                  <InputLabel>Last Name</InputLabel>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="last_name"
                    value={formData.last_name ? formData.last_name : ""}
                    onChange={handleChange}
                    type="text"
                    fullWidth
                    placeholder="Enter Last Name"
                    className="customFieldInput"
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">
                    Date of Birth
                    {/* <span className="red">*</span> */}
                  </label>
                  <CalendarComponent
                    //required
                    onChange={handleDatePicker}
                    name="date_of_birth"
                    maxDate={new Date()}
                    selected={formData.date_of_birth}
                    className="customFieldInput"
                  />
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Preferred Language</label>
                  <SelectBox
                    Data={masterData.languages}
                    id="id"
                    // async="true"
                    value={formData.preferred_language}
                    desc="language_name"
                    // getData={handleDataChange}
                    placeholder=" Preferred Language"
                    className="form-control"
                    descId="id"
                    onChange={handleBusinessTierSelectChange}
                  />
                  {/* <select
                    required
                    className="form-control"
                    name="preferred_language"
                    value={
                      formData.preferred_language
                        ? formData.preferred_language
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <option value="">Please Choose</option>
                    {masterData &&
                      masterData.languages &&
                      masterData.languages.map(({ id, language_name }) => (
                        <option key={id} value={language_name}>
                          {language_name}
                        </option>
                      ))}
                  </select> */}
                </div>

                <div className="col-12 pb-2">
                  <h4> Contact Details</h4>
                </div>
                <div className="col-6 pb-2" style={{ position: "relative" }}>
                  <label className="control-label">
                    Email <span className="red">*</span>
                  </label>
                  <TextField
                    type="email"
                    inputProps={{ pattern: EMAIL_REGEX }}
                    required
                    inputRef={emailRef}
                    name="email"
                    value={formData.email ? formData.email : ""}
                    className="customFieldInput"
                    onChange={handleChange}
                    onBlur={() => blurChange("email")}
                    placeholder="Enter Email"
                  />
                  {duplicate?.email?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {duplicate?.email?.map((item, index) => (
                        <li key={index}>
                          {item.default_contact.email} -{" "}
                          {item.default_contact.first_name}
                          {item.default_contact.last_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">
                    Mobile <span className="red">*</span>
                  </label>
                  <TextField
                    type="text"
                    inputRef={mobilerefRef}
                    required
                    name="mobile"
                    inputProps={{
                      minLength: 13,
                      maxLength: 13,
                      pattern: "^[+][9][1][0-9]{10}$",
                    }}
                    value={formData.mobile ? formData.mobile : ""}
                    onKeyPress={(e) => validateNumber(e, /[+0-9]|\./)}
                    className="customFieldInput"
                    onChange={handleChange}
                    onBlur={() => blurChange("mobile")}
                    placeholder="Enter Mobile"
                  />
                  {duplicate?.mobile?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {duplicate?.mobile?.map((item, index) => (
                        <li key={index}>
                          {item.default_contact.mobile} -{" "}
                          {item.default_contact.first_name}
                          {item.default_contact.last_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* <div className="col-6 pb-2">
                  <InputLabel>
                    Total Amount<span className="red">*</span>
                  </InputLabel>
                  <TextField
                    // autoFocus
                    variant="outlined"
                    margin="dense"
                    required
                    type="number"
                    name="total_amount"
                    value={formData.total_amount ? formData.total_amount : ""}
                    onChange={handleChange}
                    className="customFieldInput "
                    fullWidth
                    placeholder="Total Order Amount"
                  />
                </div> */}
                <div className="col-6 pb-2">
                  <label className="control-label">Secondary Email</label>
                  <TextField
                    type="email"
                    inputRef={email2ref}
                    inputProps={{ pattern: EMAIL_REGEX }}
                    name="email_2"
                    value={formData.email_2 ? formData.email_2 : ""}
                    className="customFieldInput"
                    onChange={handleChange}
                    onBlur={() => blurChange("email_2")}
                    placeholder="Enter Secondary Email"
                  />
                  {duplicate?.email_2?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {duplicate?.email_2?.map((item, index) => (
                        <li key={index}>
                          {item.default_contact.email_2} -{" "}
                          {item.default_contact.first_name}
                          {item.default_contact.last_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="col-6 pb-2">
                  <label className="control-label">Secondary Mobile</label>
                  <TextField
                    type="text"
                    name="mobile_2"
                    value={formData.mobile_2 ? formData.mobile_2 : ""}
                    className="customFieldInput"
                    inputProps={{
                      minLength: 13,
                      maxLength: 13,
                      pattern: "^[+][9][1][0-9]{10}$",
                    }}
                    inputRef={mobile22ref}
                    onKeyPress={(e) => validateNumber(e, /[+0-9]|\./)}
                    onChange={handleChange}
                    onBlur={() => blurChange("mobile_2")}
                    placeholder="Enter Secondary Mobile"
                  />
                  {duplicate?.mobile_2?.length > 0 && (
                    <ul className="duplicate-dropdown-list">
                      <li>{"Possible Duplicates"}</li>
                      {duplicate?.mobile_2?.map((item, index) => (
                        <li key={index}>
                          {item.default_contact.mobile_2} -{" "}
                          {item.default_contact.first_name}
                          {item.default_contact.last_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {"Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ManageContact;
