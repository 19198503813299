import React from 'react';
// import { client } from 'websocket';
var W3CWebSocket = require('websocket').w3cwebsocket;

class WebSocketComponent extends React.Component {

  constructor(props) {
    super(props);
    // const servInstance = process.env.REACT_APP_BASE_URL;
    let token = localStorage.getItem("token");
    var loc = window.location
    var wsStart = "ws://";
    if (loc.protocol == "https:"){
        wsStart = "wss://";
    }
    var port = "8200";
    var webSocketEndpoint =  wsStart + loc.hostname + ":" + port + `/ws?Token=${token}`  // ws : wss
    this.client = new W3CWebSocket(webSocketEndpoint);
    // this.client = new W3CWebSocket(webSocketEndpoint);

    // 'ws://localhost:8200/ws'
  }

  componentDidMount() {
    this.client.onopen = () => {
        console.log('WebSocket Client Connected');
    };

    this.client.onmessage = (message) => {
        console.log("recevied", message);
    };
    this.client.onerror = function(e) {
        console.log('Connection Error', e);
    };
    // Socket close Functionality
    this.client.onclose = function(e){
      console.log('closed', e)
  }

  }

  componentWillUnmount() {
    this.client.close(); // Abort the connection on unmount
  }

  sendNumber(connection) {
    if (connection.connected) {
      var number = Math.round(Math.random() * 0xffffff);
      connection.sendUTF(number.toString());
      setTimeout(() => this.sendNumber(connection), 1000);
    }
  }

  render() {
    return (
      <div>
        {/* Your WebSocket receiver component */}
      </div>
    );
  }
}

export default WebSocketComponent;
