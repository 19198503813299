import { Grid, Tooltip } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { SCOPES } from "../../../config/permission-maps";
import PermissionsGate, { hasPermission } from "../../../config/permissiongate";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/newDeleteComponent";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  deleteServiceOrderRow,
  exportOrders,
  getAllEmployees,
  getAllServiceList,
  getOrderStatusList,
  getServiceCategoryList,
  searchOrders,
} from "../apifunctions";
import ManageOrder from "../manage";

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      addNew: false,
      opendelete: false,
      formSearch: {
        page: 1,
        search: "",
        fromDate: "",
        toDate: "",
        customer: "",
        order_status__id: "",
        order_items__service_id: "",
        order_items__service__service_category_id: "",
        assigned_to_id: "",
        account__business_mapping_info__customer_relation_executive: "",
      },
      order_status_list: [],
      service_list: [],
      service_category_list: [],
      cre_list: [],
      account_cre_list: [],
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
      filterStatus: false,
      filterAppliedStatus: false,
      emptyStatus: false,
      tabType: "orderDetails",
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
      this.loadOrderStatusList();
      this.loadServiceList();
      this.loadServiceCategoryList();
      this.loadAccountCreList();
      this.loadOrderCreList();
    } catch (err) {}
  }

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      const response = await searchOrders(this.state.formSearch);
      const resData = response.data;

      const isFormEmpty = this.isFormEmpty(this.state.formSearch);
      this.setState({
        datas: resData.results,
        totalCount: resData.count,
        pagesize: resData.page_size,
        loader: false,
        filterAppliedStatus: true,
        emptyStatus: !isFormEmpty,
      });
    } catch (err) {
      this.setState({
        snackbarMessage: err.message,
        snackbarOpen: true,
        snackbarVariant: "error",
        loader: false,
      });
      console.log("error", err);
    }
  }

  isFormEmpty(form) {
    const fieldsToCheck = [
      "order_status__id",
      "order_items__service_id",
      "order_items__service__service_category_id",
      "assigned_to_id",
      "account__business_mapping_info__customer_relation_executive",
      "startDate",
      "endDate",
      "search",
    ];
    return fieldsToCheck.every((field) => !form[field]);
  }

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
        filterStatus: true,
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  handleView = (id) => {
    this.props.history.push("/orders-view/" + id);
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };

  handleDelete = () =>
    deleteServiceOrderRow(this.state.mainId)
      .then(() => {
        this.setState(
          {
            loader: false,
            snackbarMessage: "Order deleted",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.handleDeleteClose();
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,

          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  handleAdd = () => {
    this.setState({ addNew: true });
  };
  handleExport = () => {
    exportOrders(this.state.formSearch)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarVariant: "success",
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  handleCloseAdd = () => {
    this.setState({ addNew: false });
  };
  handleDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          fromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          toDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
        filterStatus: true,
      },
      () => {
        if (this.state.formSearch.fromDate && this.state.formSearch.toDate) {
          this.handleSearch();
        }
      }
    );
  };

  handleOrderStatusSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_status__id: e.id,
          order_status_name: e.order_status_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleOrderStatusDataChange = (value, reason = undefined) => {
    this.loadOrderStatusList(value);
  };

  handleServiceCategorySelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_items__service__service_category_id: e.id,
          service_category_name: e.service_category_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleServiceCategoryDataChange = (value, reason = undefined) => {
    this.loadServiceCategoryList(value);
  };

  handleServiceNameSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          order_items__service_id: e.id,
          service_name: e.service_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleServiceNameDataChange = (value, reason = undefined) => {
    this.loadServiceList({
      type: "manage",
      service_category:
        this.state.formSearch.order_items__service__service_category_id,
    });
  };

  handleAccountCreSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          account__business_mapping_info__customer_relation_executive: e.id,
          account_cre_name: e.account_cre_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleOrderCreDataChange = (value, reason = undefined) => {
    this.loadOrderCreList();
  };

  handleOrderCreSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          assigned_to_id: e.id,
          order_cre_name: e.order_cre_name,
        },
        filterStatus: true,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleAccountCreDataChange = (value, reason = undefined) => {
    this.loadAccountCreList();
  };

  async loadOrderStatusList() {
    try {
      getOrderStatusList()
        .then((res) => {
          const updatedData = res.data.map((item) => ({
            id: item.id,
            order_status_name: item.name,
          }));
          this.setState({
            order_status_list: updatedData,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  async loadServiceList(val, data) {
    try {
      getAllServiceList(val, data)
        .then((res) => {
          this.setState({
            service_list: res.data.services,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  async loadServiceCategoryList() {
    try {
      getServiceCategoryList()
        .then((res) => {
          const updatedData = res.data.service_categories.map((item) => ({
            id: item.id,
            service_category_name: item.service_category_display_name,
          }));
          this.setState({
            service_category_list: updatedData,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  async loadAccountCreList() {
    try {
      getAllEmployees()
        .then((res) => {
          const updatedData = res.map((item) => ({
            id: item.id,
            account_cre_name: item.name,
          }));
          this.setState({
            account_cre_list: updatedData,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }

  async loadOrderCreList() {
    try {
      getAllEmployees()
        .then((res) => {
          const updatedData = res.map((item) => ({
            id: item.id,
            order_cre_name: item.name,
          }));
          this.setState({
            cre_list: updatedData,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  clearFilter = () => {
    this.setState(
      {
        formSearch: {
          page: 1,
          search: "",
          fromDate: "",
          toDate: "",
          customer: "",
          order_status__id: "",
          order_items__service_id: "",
          order_items__service__service_category_id: "",
          assigned_to_id: "",
          account__business_mapping_info__customer_relation_executive: "",
        },
        filterStatus: false,
      },
      () => {
        this.getData();
      }
    );
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleServiceName = (rowData) => {
    return (
      rowData.order_items.map
        .call(rowData.order_items, function (item) {
          return item.name;
        })
        .join(", ") ?? ""
    );
  };
  render() {
    const {
      loader,
      totalCount,
      formSearch,
      pagesize,
      editMode,
      formData,
      order_status_list,
      service_list,
      service_category_list,
      cre_list,
      account_cre_list,
      filterStatus,
      filterAppliedStatus,
      emptyStatus,
    } = this.state;
    const { masterData } = this.props;
    const { isAdmin } = this.props;

    return (
      <div className="card-content collapse show">
        {this.state.addNew && (
          <ManageOrder
            open={this.state.addNew}
            handleClose={this.handleCloseAdd}
            masterData={masterData}
            {...this.props}
          />
        )}
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.startDate}
                endDate={formSearch.endDate}
                name="date"
                onChange={this.handleDatePicker}
                placeholder="Order Date"
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={order_status_list}
                id="id"
                async="true"
                value={formSearch.order_status_name}
                desc="order_status_name"
                getData={this.handleOrderStatusDataChange}
                placeholder="Order Status"
                className="form-control"
                onChange={this.handleOrderStatusSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={service_category_list}
                id="id"
                async="true"
                value={formSearch.service_category_name}
                desc="service_category_name"
                getData={this.handleServiceCategoryDataChange}
                placeholder="Service Category"
                className="form-control"
                onChange={this.handleServiceCategorySelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={service_list}
                id="id"
                async="true"
                value={formSearch.service_name}
                desc="service_name"
                getData={this.handleServiceNameDataChange}
                placeholder="Service Name"
                className="form-control"
                onChange={this.handleServiceNameSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={account_cre_list}
                id="id"
                async="true"
                value={formSearch.account_cre_name}
                desc="account_cre_name"
                getData={this.handleAccountCreDataChange}
                placeholder="Account CRE"
                className="form-control"
                onChange={this.handleAccountCreSelectChange}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={cre_list}
                id="id"
                async="true"
                value={formSearch.order_cre_name}
                desc="order_cre_name"
                getData={this.handleOrderCreDataChange}
                placeholder="Order CRE"
                className="form-control"
                onChange={this.handleOrderCreSelectChange}
              />
            </Grid>
            <Grid item align="right" xs={12} sm={10}>
              {filterStatus && filterAppliedStatus && emptyStatus && (
                <Link
                  className="btn btn-secondary"
                  onClick={(e) => this.clearFilter(e)}
                  to="#"
                >
                  Clear filters
                </Link>
              )}
              <PermissionsGate scopes={[SCOPES.exportOrders]}>
                {totalCount > 0 && (
                  <Link
                    className="btn btn-secondary"
                    onClick={(e) => this.handleExport(e)}
                    to="#"
                  >
                    Export
                  </Link>
                )}
              </PermissionsGate>

              <PermissionsGate scopes={[SCOPES.addOrder]}>
                <Link
                  className="btn btn-primary"
                  onClick={(e) => this.handleAdd(e)}
                  to="#"
                >
                  + &nbsp; Add New
                </Link>
              </PermissionsGate>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <PermissionsGate
                scopes={[
                  SCOPES.viewOrder,
                  SCOPES.viewAllOrder,
                  SCOPES.viewTeamOrder,
                ]}
                RenderError={<p>Access Denied</p>}
              >
                <DataTable
                  data={this.state.datas}
                  columns={[
                    /*{
                              title: "SL",
                              type: "String",
                              field: "slNo",
                            },*/
                    {
                      title: "Order ID",
                      type: "text",
                      field: "order_number",
                      render: (rowData) =>
                        rowData.order_number && (
                          <Link
                            className="text-primary cursor-pointer"
                            to={`/orders-view/${rowData?.id}`}
                          >
                            {rowData?.order_number ?? "--"}
                          </Link>
                        ),
                    },
                    {
                      title: "Order Date",
                      type: "date",
                      field: "order_date",
                    },
                    {
                      title: "Account Name",
                      type: "text",
                      render: (rowData) =>
                        rowData.account && (
                          <span>{rowData?.account?.business_name ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Contact Name",
                      type: "text",
                      render: (rowData) =>
                        rowData.contact && (
                          <span>{rowData?.contact?.name ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Order Status",
                      type: "text",
                      field: "order_status",
                      render: (rowData) =>
                        rowData.order_status && (
                          <span>{rowData?.order_status?.name ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Allocation Status",
                      type: "String",
                      field: "allotted_services_count",
                      render: (rowData) => {
                        let color;
                        let backgroundColor;

                        if (
                          rowData.allotted_services_count ===
                          rowData.total_services_count
                        ) {
                          color = "white";
                          backgroundColor = "green";
                        } else if (rowData.allotted_services_count === 0) {
                          color = "white";
                          backgroundColor = "#f56969";
                        } else {
                          color = "white";
                          backgroundColor = "darkorange";
                        }

                        const spanStyle = {
                          color,
                          fontWeight: "bold",
                          backgroundColor,
                          padding: "5px 10px",
                          borderRadius: "5px",
                          display: "inline-block", // Added to make the rounded background fit the content
                        };

                        return (
                          <div style={{ textAlign: "center" }}>
                            <span style={spanStyle}>
                              {rowData.allotted_services_count} of{" "}
                              {rowData.total_services_count}
                            </span>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Order Value",
                      type: "amount",
                      field: "grand_total",
                    },
                    {
                      title: "Payments Collected",
                      type: "amount",
                      field: "paid_amount",
                    },
                    {
                      title: "Payments Due",
                      type: "amount",
                      field: "remaining_amount",
                    },
                    {
                      title: "Spot Incentive",
                      type: "amount",
                      field: "spot_incentive",
                    },
                    {
                      title: "Service Name",
                      type: "text",
                      width: 250,
                      render: (rowData) => (
                        <Tooltip title={this.handleServiceName(rowData)}>
                          <span>{this.handleServiceName(rowData)}</span>
                        </Tooltip>
                      ),
                    },
                    {
                      title: "Account CRE",
                      type: "text",
                      render: (rowData) =>
                        rowData.account && (
                          <Tooltip
                            title={
                              rowData?.account?.primary_agent?.name ?? "--"
                            }
                          >
                            <span>
                              {rowData?.account?.primary_agent?.name ?? "--"}
                            </span>
                          </Tooltip>
                        ),
                    },
                    {
                      title: "Order CRE",
                      type: "text",
                      render: (rowData) =>
                        rowData && <span>{rowData?.order_cre ?? "--"}</span>,
                    },
                    {
                      title: "Professional Fee",
                      type: "amount",
                      render: (rowData) =>
                        rowData && (
                          <span>{rowData?.professional_fee_total ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Vendor Fee",
                      type: "amount",
                      render: (rowData) =>
                        rowData && (
                          <span>{rowData?.vendor_fee_total ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Government Fee",
                      type: "amount",
                      render: (rowData) =>
                        rowData && (
                          <span>{rowData?.govt_fee_total ?? "--"}</span>
                        ),
                    },
                    {
                      title: "GST",
                      type: "amount",
                      render: (rowData) =>
                        rowData && <span>{rowData?.tax ?? "--"}</span>,
                    },
                    {
                      title: "Discount",
                      type: "amount",
                      render: (rowData) =>
                        rowData && <span>{rowData?.discount ?? "--"}</span>,
                    },
                    {
                      title: "Service Count",
                      type: "amount",
                      render: (rowData) =>
                        rowData && (
                          <span>{rowData?.total_services_count ?? "--"}</span>
                        ),
                    },
                    {
                      title: "Service Allocated",
                      type: "amount",
                      render: (rowData) =>
                        rowData && (
                          <span>
                            {rowData?.allotted_services_count ?? "--"}
                          </span>
                        ),
                    },
                  ]}
                  options={{
                    sort: false,
                    sortvalue: "order_date",
                    pageNo: formSearch.page,
                    pagination: true,
                    totalCount: totalCount,
                    pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                    handlepagination: this.handlepagination.bind(this),
                  }}
                  actions={[
                    ...(isAdmin ||
                    hasPermission({
                      scopes: [SCOPES.deleteOrder],
                      permissions: this.props.permissions,
                    })
                      ? [
                          {
                            className: "delete",
                            rowActionCLick: this.handleDeleteOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/delete-icon.svg",
                            tooltip: "Delete",
                          },
                        ]
                      : []),
                  ]}
                />
              </PermissionsGate>
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}
// export default OrdersList;
const mapStateToProps = (state) => {
  return {
    permissions: state.permission.value,
    isAdmin: state.permission.isAdmin,
  };
};
export default connect(mapStateToProps)(OrdersList);
