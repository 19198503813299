import React, { useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import CloseIcon from "@material-ui/icons/Close";
import RoleDialogTheme from "./dialogTheme";
// import { addCheckList } from "./apifunctions";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { setPassword } from "../apifunctions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";

const ChangePassword = ({
  open = false,
  data = {},
  handleClose = () => {},
  ...props
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [passError, setPassError] = useState(false);
  const [passConError, setPassConError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!passConError && !passError) {
      setSubmitting(true);

      const payload = {
        ...formData,
        employee: props.id,
      };
      try {
        setPassword(payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setTimeout(() => {
              handleClose(true);
            }, 500);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(
              err?.response?.data?.error?.message || "Something went wrong"
            );
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    }
  };
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };

  const handleChange = (e) => {
    setFormData((f) => ({ ...f, [e.target.name]: e.target.value }));
    if (e.target.name === "password") {
      setPassConError(false);
      const passRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-^])[A-Za-z\d#?!@$%^&*-^]{6,15}$/;

      if (passRegex.test(e.target.value)) {
        setPassError(false);

        if (
          !passError &&
          formData.confirm_password &&
          formData.confirm_password?.trim() !== ""
        ) {
          if (formData.confirm_password !== e.target.value) {
            setPassConError(true);
          } else {
            setPassConError(false);
          }
        }
      } else {
        setPassError(true);
      }
    } else {
      if (formData.password && formData.password?.trim() !== "") {
        if (formData.password !== e.target.value) {
          setPassConError(true);
        } else {
          setPassConError(false);
        }
      }
    }
  };
  console.log(formData);
  return (
    <div>
      <CommonLoader open={submitting} />
      <MuiThemeProvider theme={RoleDialogTheme}>
        <Dialog
          open={open}
          fullWidth
          aria-labelledby="form-dialog-title"
          className="customDialog"
        >
          <form className="customFields" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <div>
                <h3> Change Password</h3>
                <p className="m-0">This will reset the employee password</p>
              </div>
              <IconButton aria-label="close" onClick={(e) => handleClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {/* <div>
                <InputLabel>Old Password</InputLabel>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  required
                  name="old_password"
                  value={formData.old_password ? formData.old_password : ""}
                  onChange={handleChange}
                  type="password"
                  fullWidth
                  className="fieldInput"
                />
              </div> */}
              <div>
                <InputLabel>Password</InputLabel>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  name="password"
                  value={formData.password ? formData.password : ""}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <div
                          onClick={() => setShowPassword((f) => !f)}
                          className="d-flex  cursor-pointer"
                        >
                          {showPassword ? (
                            <AiFillEye />
                          ) : (
                            <AiFillEyeInvisible />
                          )}
                        </div>
                      </React.Fragment>
                    ),
                  }}
                  fullWidth
                  className="customFieldInput"
                  placeholder="Enter Password"
                />
                {passError && (
                  <p
                    className="text-danger "
                    style={{ fontSize: "12px", marginT0p: 5 }}
                  >
                    Password must be 6-15 characters long, and contain one
                    uppercase, one lowercase, one special character and a number
                  </p>
                )}
              </div>
              <div>
                <InputLabel>Confirm Password</InputLabel>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  name="confirm_password"
                  value={
                    formData.confirm_password ? formData.confirm_password : ""
                  }
                  onChange={handleChange}
                  type={showConPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <div
                          onClick={() => setShowConPassword((f) => !f)}
                          className="d-flex cursor-pointer"
                        >
                          {showConPassword ? (
                            <AiFillEye />
                          ) : (
                            <AiFillEyeInvisible />
                          )}
                        </div>
                      </React.Fragment>
                    ),
                  }}
                  className="customFieldInput"
                  placeholder="Confirm Password"
                />
                {passConError && (
                  <p
                    className="text-danger "
                    style={{ fontSize: "12px", marginT0p: 5 }}
                  >
                    Password does not match
                  </p>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 0 }}
                variant="contained"
                onClick={(e) => handleClose()}
                className="btn btn-secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                {formData?.id ? "Update" : "Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
      <SnackbarMessage
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default ChangePassword;
