import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { SCOPES } from "../../../../config/permission-maps";
import PermissionsGate, {
  hasPermission,
} from "../../../../config/permissiongate";
import CalendarComponent from "../../../../plugins/calendar.comp";
import CommonLoaderComponent from "../../../../plugins/commonLoader/commonLoader.component";
import ChangeRequestPopup from "../../../../plugins/delete/changeRequestPopup";
import DeletePopup from "../../../../plugins/delete/newDeleteComponent";
import SelectBox from "../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../plugins/snackbar-component";
import { formatDate } from "../../../../utils/dateUtils";
import { numberFormat } from "../../../../utils/numberFormat";
import {
  add_target_close_date,
  assign_team_to_order_item,
  change_service_status,
  fetch_order_items,
  getOrderServiceStatusList,
  getTeams,
  remove_order_items,
  unAssignTeamFromItem,
  update_service_remark,
} from "../../apifunctions";
import ManageOrderItems from "./manage";
import EditOrderItem from "./manage/edit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const OrderItems = ({ id, data, handleRefresh }) => {
  const classes = useStyles();
  const [formSearch, setFormSearch] = useState({
    search: "",
    // status: "",
    // employee_tier__tier: "",
    // cre_agent__id: "",
    // agent_name: "",
    // fromDate: "",
    // toDate: "",
    page: 1,
  });
  const inputsRef = useRef(new Map());
  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.permission.userData);
  const [teams, setTeams] = useState([]);
  const [pagesize, setPageSize] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarData, setSnackbarData] = useState({});
  const [updateKey, setUpdateKey] = useState(0);
  const [anchorEl, setAnchorE1] = useState(null);
  const [OrderServiceStatusData, setOrderServiceStatusData] = useState([]);
  const [inputValue, setInputValue] = useState(false);
  const [removeTeamLeadFlag, setRemoveTeamLeadFlag] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [openChange, setOpenChange] = useState(false);
  const [changeRequest, setChangeRequest] = useState({});
  const [orderItemData, setOrderItemData] = useState({});
  const [openItemEdit, setOpenItemEdit] = useState(false);

  useEffect(() => {
    getData();
    fetchTeamsBySearch();
    fetchServiceStatusBySearch();
    return () => {};
  }, []);
  const handleClose = (flag = false) => {
    setOpenManage(false);
    setRowData({});
    if (flag) {
      getData();
    }
  };
  const getData = () => {
    setLoader(true);

    try {
      fetch_order_items(id)
        .then((res) => {
          let dt = res.data;
          dt.forEach((itm) => {
            itm["employee_name"] = itm?.assigned_to?.name;
            itm["employee_id"] = itm?.assigned_to?.id;
            itm["team_name"] =
              itm?.team?.name && itm?.assigned_to?.name
                ? `${itm.team.name} (${itm.assigned_to.name}) `
                : null;
            itm["team_id"] = itm?.team?.id ?? null;
            itm["order_service_status_name"] =
              itm?.order_service_status?.name ?? null;
            itm["order_service_status_id"] =
              itm?.order_service_status?.id ?? null;
            itm["previous_remarks"] = itm?.service_remarks ?? "";
          });
          setTeamsList([...dt]);
          // setTotalCount(res.count);
          setLoader(false);

          // setPageSize(res.page_size);
        })
        .catch((err) => {
          setLoader(false);
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChange = () => {};

  const handleSearch = () => {};

  const handleClear = () => {};

  const handlepagination = () => {};

  const handleDeleteOpen = (e, data) => {
    setRowData(data);
    setOpenDelete(true);
  };
  const handleOpenMenu = (e, data) => {
    setRowData(data);

    setAnchorE1(e.target);
  };
  const handleDeleteClose = (e, data) => {
    setRowData({});
    setOpenDelete(false);
    getData();
  };
  const onSnackbarClose = () => {
    setSnackbarData({});
  };
  const handleDeleteConfirm = () => {
    setLoader(true);
    console.log(rowData);
    remove_order_items(id, rowData?.id)
      .then(() => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: "Service removed from Order",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        handleDeleteClose();
        // setTimeout(() => {
        getData();
        if (handleRefresh) {
          handleRefresh();
        }
        // }, 5000);
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  // const fetchEmployeeNySearch = (e, row) => {
  //   if (e) {
  //     setTeamsList(teamsList);
  //     setUpdateKey(updateKey + 1);
  //   }
  //   getTeamLeads({
  //     page: 1,
  //     ...(e
  //       ? {
  //           search: e,
  //         }
  //       : {}),
  //   }).then((res) => {
  //     setTeamLeads(res?.results || []);
  //   });
  // };

  const fetchTeamsBySearch = (e, row) => {
    if (e) {
      setTeamsList(teamsList);
      setUpdateKey(updateKey + 1);
    }
    getTeams({
      page: 1,
      type: "dropdown_with_leads_only",
      ...(e
        ? {
            search: e,
          }
        : {}),
    }).then((res) => {
      setTeams(
        res?.results.map((team) => {
          return {
            id: team.id,
            name: team.lead ? team.name + " (" + team.lead + ") " : team.name,
          };
        }) || []
      );
    });
  };

  const handleServiceChange = (e, row) => {
    const { id, name } = e;
    const { assigned_to, employee_id } = row;

    if (assigned_to) {
      setRowDetails(row);
      if (!id) {
        setOpenDelete(true);
        setRemoveTeamLeadFlag(true);
      } else {
        setOpenChange(true);
        setChangeRequest(e);
      }
    }

    if (id) {
      const updatedTeamsList = teamsList.map((team) =>
        team.id === row.id
          ? { ...team, employee_name: name, employee_id: id }
          : team
      );
      setTeamsList(updatedTeamsList);
      if (!assigned_to && id !== employee_id) {
        setAssigneeToItem(id, row);
      }
    }
  };
  const setRemoveTeamLead = () => {
    unAssignTeamFromItem(rowDetails.order?.id, rowDetails.id)
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Team removed successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        setOpenDelete(false);
        getData();
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const setAssigneeToItem = (id, row) => {
    assign_team_to_order_item(row.order?.id, row.id, {
      team_id: id || null,
    })
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Team added successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        getData();
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const fetchServiceStatusBySearch = (e, row) => {
    if (e) {
      setTeamsList(teamsList);
      setUpdateKey(updateKey + 1);
    }
    getOrderServiceStatusList({
      page: 1,
      ...(e
        ? {
            search: e,
          }
        : {}),
    }).then((res) => {
      setOrderServiceStatusData(res?.data || []);
    });
  };

  const handleServiceStatusSelectChange = (e, row) => {
    let tm = teamsList;
    tm.forEach((itm) => {
      if (itm.id === row.id) {
        itm["order_service_status_name"] = e.name;
        itm["order_service_status_id"] = e.id;
      }
    });
    setTeamsList(tm);
    setUpdateKey(updateKey + 1);
    if (e["id"] && e["id"] !== "") {
      setServiceStatusToItem(e["id"], row);
    }
  };

  const setServiceStatusToItem = (id, row) => {
    change_service_status(row.order?.id, row.id, {
      order_service_status_id: id || null,
    })
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Service Status Updated",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        getData();
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const onChangeDate = (date, row) => {
    if (date.value) {
      add_target_close_date(row.order?.id, row.id, {
        target_close_date: moment(date.value).format("YYYY-MM-DD") || null,
      })
        .then(() => {
          setSnackbarData({
            snackbarMessage: "Target Close Date added successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          });
          getData();
        })
        .catch((err) => {
          setSnackbarData({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    }
  };

  const handleRemarkChange = (e, row) => {
    const { value } = e.target;
    const result = value.trimStart() ?? "";
    setTeamsList((prevTeams) =>
      prevTeams.map((team) =>
        team.id === row.id
          ? {
              ...team,
              service_remarks: result,
              remark_updated: team.previous_remarks
                ? true
                : result
                ? true
                : false,
            }
          : team
      )
    );
    setUpdateKey((prevKey) => prevKey + 1);

    setInputValue(true);
  };

  const updateRemark = (e, row) => {
    e.preventDefault();

    const showError = (message) => {
      setSnackbarData({
        snackbarMessage: message,
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    };

    if (!row.service_remarks && !row.remark_updated) {
      showError("Service Remarks Required");

      return;
    }

    if (!row.remark_updated) {
      showError("Service Remarks needs to update");
      return;
    }

    update_service_remark(row.order?.id, row.id, {
      remark: row.service_remarks,
    })
      .then(() => {
        setSnackbarData({
          snackbarMessage: "Service remark updated successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        getData();
      })
      .catch((err) => {
        setSnackbarData({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  const handleRequestClose = () => {
    setRowData({});
    setOpenChange(false);
    getData();
  };
  const handleChangeRequest = () => {
    setAssigneeToItem(changeRequest?.id, rowDetails);
    setOpenChange(false);
    setRowData({});
  };

  function handleServicePriceEdit(e, orderItem) {
    e.preventDefault();
    setOrderItemData(orderItem);
    setOpenItemEdit(true);
  }

  function handleServicePriceEditClose() {
    setOrderItemData({});
    setOpenItemEdit(false);
  }
  const pernissions = useSelector((state) => state.permission.value);

  return (
    <div className="card-content collapse show">
      <CommonLoaderComponent open={loader} />
      <SnackbarMessage
        snackbarOpen={snackbarData?.snackbarOpen}
        snackbarMessage={snackbarData?.snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarData?.snackbarVariant}
      />
      {openManage && (
        <ManageOrderItems
          open={openManage}
          order_id={id}
          data={rowData}
          selectedItems={teamsList}
          handleClose={handleClose}
          refreshOrderData={handleRefresh}
        />
      )}
      {openItemEdit && (
        <EditOrderItem
          open={openItemEdit}
          order_id={id}
          data={orderItemData}
          handleClose={handleServicePriceEditClose}
          refreshOrderData={handleRefresh}
        />
      )}
      <DeletePopup
        opendelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        delete={removeTeamLeadFlag ? setRemoveTeamLead : handleDeleteConfirm}
        submitted={loader}
      />
      <ChangeRequestPopup
        openChange={openChange}
        handleClose={handleRequestClose}
        handleChangeRequest={handleChangeRequest}
        submitted={loader}
      />
      <div className="">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={6} sm={2} className="table-search">
            {/* <SearchField
              onChange={handleChange}
              value={formSearch.search}
              handleSearch={handleSearch}
              handleClear={handleClear}
            /> */}
          </Grid>

          <Grid item className="table-search">
            <PermissionsGate
              overridePermission={
                user?.user_data?.id === data["created_by"] ||
                user?.user_data?.is_operations_head
              }
              scopes={[SCOPES.addOrderItems]}
            >
              <Button
                className="btn btn-primary"
                onClick={() => {
                  setRowData({});
                  setOpenManage(true);
                }}
              >
                + &nbsp; Add New
              </Button>
            </PermissionsGate>
          </Grid>
          <Grid item xs={12} sm={12} className="table-block">
            <PermissionsGate
              // errorProps={{ disabled: true }}
              RenderError={() => <p>Access Denied !!</p>}
              scopes={[
                SCOPES.viewOrder,
                SCOPES.viewAllOrder,
                SCOPES.viewTeamOrder,
              ]}
            >
              {/* <DataTable
                data={teamsList}
                columns={[
                  {
                    title: "Service Name",
                    type: "text",
                    field: "service_name",
                  },
                ]}
                options={{
                  sort:false,
                  sortvalue: "name",
                  pagination: true,
                  totalCount: totalCount,
                      pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "delete",
                    rowActionCLick: handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              /> */}

              <div className="orderAccordion">
                {teamsList.map((item, ind) => (
                  <div key={ind}>
                    <Accordion className="customAccordion mb-0" defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={6}>
                            <div className="block">
                              <Typography className={classes.heading}>
                                <span className="order-item-value">
                                  {item.service_name}
                                </span>
                              </Typography>
                            </div>
                            <div className="flexBox">
                              <p className="itemBox">
                                <span className="label">Service Value: </span>
                                <span className="amount">
                                  {" " + numberFormat(item.total_price) ||
                                    " 0.00 "}
                                </span>
                              </p>
                              <p className="itemBox">
                                <span className="label">Team: </span>
                                <span className="amount">
                                  {item.team_name ? item.team_name : "--"}
                                </span>
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="flexBox_column">
                              <div className="flexBox">
                                <p className="itemBox">
                                  <span className="label">
                                    Service Status:{" "}
                                  </span>
                                  <span className="value">
                                    {item.order_service_status_name
                                      ? item.order_service_status_name
                                      : "--"}
                                  </span>
                                </p>
                              </div>
                              <div className="flexBox">
                                <p className="itemBox">
                                  <span className="label">
                                    Target Close Date:
                                  </span>
                                  <span className="amount">
                                    {item.target_close_date
                                      ? formatDate(item.target_close_date)
                                      : "--"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <OrderItemTasks orderId={id} itemId={item.id} /> */}
                        <div
                          className="col-md-12 col-xs-12"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <form onSubmit={(event) => updateRemark(event, item)}>
                            <div className="row">
                              <div className="col-md-5 col-xs-12">
                                <div className="row customGap">
                                  <div className="col-md-6 col-xs-12">
                                    <InputLabel>Team</InputLabel>
                                    <SelectBox
                                      Data={teams}
                                      disabled={
                                        !user?.user_data?.is_admin &&
                                        !user?.user_data?.is_operations_head &&
                                        !hasPermission({
                                          scopes: [SCOPES.editOrder],
                                          permissions: pernissions,
                                        })
                                      }
                                      id="id"
                                      async="true"
                                      value={
                                        item.team_name ? item.team_name : ""
                                      }
                                      disableClearable={
                                        item.team_name ? false : true
                                      }
                                      desc="name"
                                      descId="id"
                                      selectedValue={{
                                        name: item.team_name
                                          ? item.team_name
                                          : "",
                                        id: item.team_id ? item.team_id : "",
                                      }}
                                      hideSelection
                                      getData={(e) =>
                                        fetchTeamsBySearch(e, item)
                                      }
                                      placeholder="Select Team"
                                      className="form-control w-100"
                                      onChange={(e) =>
                                        handleServiceChange(e, item)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-6 col-xs-12">
                                    <InputLabel>Service Status</InputLabel>
                                    <SelectBox
                                      Data={OrderServiceStatusData}
                                      id="id"
                                      async="true"
                                      getData={(e) =>
                                        fetchServiceStatusBySearch(e, item)
                                      }
                                      value={item.order_service_status_name}
                                      selectedValue={{
                                        name: item.order_service_status_name
                                          ? item.order_service_status_name
                                          : "",
                                        id: item.order_service_status_id
                                          ? item.order_service_status_id
                                          : "",
                                      }}
                                      desc="name"
                                      descId="id"
                                      placeholder="Select Service Status"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleServiceStatusSelectChange(e, item)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-6 col-xs-12">
                                    <div className="position-relative">
                                      <InputLabel>
                                        Team Leader Allocation Date
                                      </InputLabel>
                                      <CalendarComponent
                                        variant="outlined"
                                        margin="dense"
                                        className="customFieldInput "
                                        fullWidth
                                        readOnly={true}
                                        dateTime={false}
                                        selected={item.assigned_date}
                                        key={`Target Allocated Date ${ind}`}
                                        placeholder="Target Allocated Date"
                                        name="target_close_date"
                                        onChange={(e) => onChangeDate(e, item)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-xs-12">
                                    <div className="position-relative">
                                      <InputLabel>Target Close Date</InputLabel>
                                      <CalendarComponent
                                        variant="outlined"
                                        margin="dense"
                                        className="customFieldInput"
                                        fullWidth
                                        selected={item.target_close_date}
                                        key={`Target Close Date ${ind}`}
                                        placeholder="Target Close Date"
                                        name="target_close_date"
                                        onChange={(e) => onChangeDate(e, item)}
                                        minDate={
                                          new Date(
                                            moment(
                                              item?.assigned_date,
                                              "DD-MM-YYYY"
                                            ).format("MM-DD-YYYY")
                                          ) ?? null
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-xs-12">
                                    <InputLabel>Service Remarks</InputLabel>

                                    <TextField
                                      type="text"
                                      inputRef={(el) =>
                                        inputsRef.current.set(item.id, el)
                                      }
                                      name="service_remarks"
                                      multiline
                                      rows={14}
                                      value={item.service_remarks}
                                      className="customFieldInput textareaField textareaGroup"
                                      onChange={(e) =>
                                        handleRemarkChange(e, item)
                                      }
                                      placeholder="Enter Service Remarks"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3 col-xs-12">
                                <div className="service_pricing">
                                  <div className="pricing_container">
                                    <div className="d-flex justify-content-between align-items-center customer-view">
                                      <h4>Service Pricing</h4>
                                      <div className="actions">
                                        <button
                                          className="btn btn-primary"
                                          onClick={(e) =>
                                            handleServicePriceEdit(e, item)
                                          }
                                        >
                                          <img src="/app-assets/backend-images/icons/edit-lg.png" />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">
                                        Professional Fee
                                      </span>
                                      <span className="text">
                                        {" " +
                                          numberFormat(item.professional_fee) ||
                                          " 0.00 "}
                                      </span>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">Vendor Fee</span>
                                      <span className="text">
                                        {" " + numberFormat(item.vendor_fee) ||
                                          " 0.00 "}
                                      </span>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">
                                        Government Fee
                                      </span>
                                      <span className="text">
                                        {" " + numberFormat(item.govt_fee) ||
                                          " 0.00 "}
                                      </span>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">
                                        GST Amount{" "}
                                        {item.gst_percent
                                          ? `(${parseInt(item.gst_percent)}%)`
                                          : ""}
                                      </span>
                                      <span className="text">
                                        {" " + item.gst_amount || " 0.00 "}
                                      </span>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">
                                        Discount Amount{" "}
                                        {item.discount_percent
                                          ? `(${parseInt(
                                              item.discount_percent
                                            )}%)`
                                          : ""}
                                      </span>
                                      <span className="text">
                                        {" " + item.discount_amount || " 0.00 "}
                                      </span>
                                    </div>
                                    {item.price_adjustment !== undefined &&
                                      item.price_adjustment != 0 && (
                                        <div className="itemBox">
                                          <span className="text">Rounding</span>
                                          <span className="text">
                                            {item.price_adjustment > 0
                                              ? " " +
                                                numberFormat(
                                                  item.price_adjustment
                                                )
                                              : " - " +
                                                  numberFormat(
                                                    item.price_adjustment * -1
                                                  ) || " 0.00 "}
                                          </span>
                                        </div>
                                      )}
                                    <div className="itemBox">
                                      <span className="text">
                                        <b>Total Service Amount</b>
                                      </span>
                                      <span className="text">
                                        <b>
                                          {" " +
                                            numberFormat(item.total_price) ||
                                            " 0.00 "}
                                        </b>
                                      </span>
                                    </div>
                                  </div>
                                  <hr />

                                  <div className="pricing_container">
                                    <h4>Order Summary</h4>
                                    {data.price_adjustment !== undefined &&
                                      data.price_adjustment !== 0 && (
                                        <div className="itemBox">
                                          <span className="text">
                                            Order Rounding
                                          </span>
                                          <span className="text">
                                            {data.price_adjustment > 0
                                              ? " " +
                                                numberFormat(
                                                  data.price_adjustment
                                                )
                                              : " - " +
                                                  numberFormat(
                                                    data.price_adjustment * -1
                                                  ) || " 0.00 "}
                                          </span>
                                        </div>
                                      )}
                                    <div className="itemBox">
                                      <span className="text">Order Value</span>
                                      <span className="text">
                                        {" " + numberFormat(data.grand_total) ||
                                          " 0.00 "}
                                      </span>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">
                                        Order Collection
                                      </span>
                                      <span className="text">
                                        {" " + numberFormat(data.paid_amount) ||
                                          " 0.00 "}
                                      </span>
                                    </div>
                                    <div className="itemBox">
                                      <span className="text">
                                        <b className="red">Total Order Due</b>
                                      </span>
                                      <span className="text">
                                        <b className="red">
                                          {" " +
                                            numberFormat(
                                              data.remaining_amount
                                            ) || " 0.00 "}
                                        </b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row pb-2">
                              {/* <div className="col-md-12 col-xs-12">
                              <div className="position-relative display-inline-block"> */}
                              <div className="col-md-12 col-xs-12 d-flex align-items-end">
                                <div className="position-relative">
                                  <div
                                    className="btn btn-danger"
                                    onClick={(e) => handleDeleteOpen(e, item)}
                                  >
                                    <span className="icon">
                                      <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.8334 5.91488L15.1106 16.0336C15.0483 16.9058 14.3226 17.5815 13.4482 17.5815H6.55191C5.67751 17.5815 4.95177 16.9058 4.88947 16.0336L4.16671 5.91488M8.33337 9.24821V14.2482M11.6667 9.24821V14.2482M12.5 5.91488V3.41488C12.5 2.95464 12.1269 2.58154 11.6667 2.58154H8.33337C7.87314 2.58154 7.50004 2.95464 7.50004 3.41488V5.91488M3.33337 5.91488H16.6667"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <span className="text">Delete Service</span>
                                  </div>
                                </div>

                                {/* <div className="position-relative display-inline-block"> */}
                                <div className="position-relative ml-2">
                                  <button
                                    type="submit"
                                    className="btn btn-success success-button-padding"
                                    // onClick={(e) => updateRemark(e, item)}
                                  >
                                    <span className="text">
                                      Update Service Remarks
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        {/* <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {console.log(
                              "disabled",
                              !user?.user_data?.is_admin &&
                                !user?.user_data?.is_operations_head
                            )}
                            <div className=" accordionHeaderRight">
                              <div className=" minWidthSelect"></div>
                              <a
                                className="d-flex align-items-center justify-content-center"
                                title={
                                  item.assigned_date !== null
                                    ? `TL assigned on : ${item.assigned_date}`
                                    : "TL Not Assigned"
                                }
                              >
                                <img
                                  src="/app-assets/backend-images/icons/info-icon.svg"
                                  width={"15px"}
                                  alt=""
                                />
                              </a>

                              <div className=" minWidthSelect"></div>
                              <div className=" minWidthSelect"></div>

                              {teamsList.length > 1 && (
                                <PermissionsGate
                                  RenderError={() => null}
                                  scopes={[SCOPES.deleteOrderItems]}
                                >
                                  <div>
                                    <div
                                      className="d-flex align-items-center justify-content-center "
                                      onClick={(e) => handleDeleteOpen(e, item)}
                                    >
                                      <img
                                        src="/app-assets/backend-images/icons/delete-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </PermissionsGate>
                              )}
                              {user?.user_data?.is_admin ||
                                user?.user_data?.is_operations_head ||
                                (hasPermission({
                                  scopes: [SCOPES.editOrder],
                                  permissions: pernissions,
                                }) && (
                                  <div>
                                    <div
                                      className="d-flex align-items-center justify-content-center "
                                      onClick={(e) => handleOpenMenu(e, item)}
                                    >
                                      <img
                                        src="/app-assets/backend-images/icons/menu-dot.svg"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </Grid>
                          <Grid item xs={12} className="pt-2">
                            <table className=" w-100">
                              <tbody>
                                <tr>
                                  <td className="px-0 py-0 active">
                                    <span className="order-item-value">
                                      Total Amount :
                                    </span>
                                    <span className="order-item-total">
                                      {" " + numberFormat(item.total_price) ||
                                        " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Professional fee :
                                    <span className="order-item-value">
                                      {" " +
                                        numberFormat(item.professional_fee) ||
                                        " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Vendor fee :
                                    <span className="order-item-value">
                                      {" " + numberFormat(item.vendor_fee) ||
                                        " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Govt. fee :
                                    <span className="order-item-value">
                                      {" " + numberFormat(item.govt_fee) ||
                                        " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    GST Percent :
                                    <span className="order-item-value">
                                      {" " + item.gst_percent || " 0.00 "}
                                    </span>
                                  </td>
                                  <td className="px-0 py-0 active">
                                    Discount Percent :
                                    <span className="order-item-value">
                                      {" " + item.discount_percent || " 0.00 "}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Grid>
                        </Grid>*/}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </PermissionsGate>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderItems;
