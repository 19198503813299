import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";

import apis from "../../../../../apiactions/api.actions";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeletePopup from "../../../../../plugins/delete/newDeleteComponent";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { createService, getService, removeTeamFromService, servicecat, updateService } from "../../../apifunctions";
import ManageServiceTeam from "./manage";
import Sectionelements from "./sectionelements";

let key = 1;
let masterData = [];
class ServiceInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      datas: [],
      teamData: [],
      snackbarOpen: false,
      openTeamDialog: false,
      deleteTeam: false,
      activeTeam: null,
      submited: false,
      snackbarVariant: "",
      loader: false,
      sections: [],
      snackbarMessage: "",
      sectionData: {
        name: "",
        show_in_app: false,
        show_in_web: false,
      },
      sectionConut: 0,
      section: [],
      serId: this.props.ser_Id,
      set_selected: false,
      elementList: [],
    };
    masterData = JSON.parse(localStorage.getItem("masterData")).service_tabs;

    this.handleChange = this.handleChange.bind(this);
    this.getservData = this.getservData.bind(this);
  }
  componentWillReceiveProps = () => {
    this.setState({ serId: this.props.ser_Id });
  };
  setElements = () => {
    masterData = JSON.parse(localStorage.getItem("masterData")).service_tabs;
    if (masterData && masterData.length !== 0) {
      masterData.forEach((item) => {
        item["checkadd"] = false;
        item["is_selected"] = false;
      });
    }
  };
  componentDidMount = () => {
    if (this.props.ser_Id) {
      this.getservData(this.props.ser_Id);
    }
  };
  getservData(serId) {
    this.setElements();
    this.setState({ loader: true });
    try {
      getService(serId)
        .then((res) => res.data)
        .then((res) => {
          this.dataFilter(res.sections);
          setTimeout(() => {
            this.setState({
              formData: {
                service_category_name: res.service_category
                  ? res.service_category.service_category_name
                  : "",
                managed_by: res.managed_by,
                service_name: res.service_name,
                status: res.status,
                hsn: res.hsn,
                id: res.id,
                service_category: res.service_category
                  ? res.service_category.id
                  : "",
                description: res.description,
              },
              teamData: res.team,
            });
          }, 500);
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            loader: false,
            snackbarVariant: "error",
          });
        });
    } catch (err) { }
  }
  dataFilter = (res) => {
    let secData = res;
    let equip = [...masterData];
    if (secData && secData.length !== 0) {
      secData.forEach((item) => {
        item["readOnly"] = true;
        if (item.elements && item.elements.length !== 0) {
          item["added"] = true;
          item.elements.forEach((ele) => {
            ele["readOnly"] = true;
            ele["ids"] = ele.id;
            ele["tab"] = ele.key.tab;
            equip.forEach((elemen) => {
              if (ele.key.id === elemen.id) {
                let index = equip.indexOf(elemen);
                equip.splice(index, 1);
              }
            });
          });
        }
      });
      this.setState(
        { elementList: equip, sections: secData, loader: false },
        () => {
          key = key + 1;
        }
      );
    } else {
      this.setState(
        { elementList: masterData, sections: secData, loader: false },
        () => {
          key = key + 1;
        }
      );
    }
  };
  handleDataChange = (value, reason=undefined) => {
  
      let tag = servicecat + "?view=all&&search=" + value;
      this.getData(tag);
    
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSelectChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        service_category: e.id,
        service_category_name: e.service_category_name,
      },
    });
  };
  async getData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.service_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) { }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    if (this.state.formData.id) {
      updateService(this.state.formData, this.state.formData.id)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {
              this.getservData(this.state.formData.id);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "error",
          });
        });
    } else {
      createService(this.state.formData)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              serId: res.service.id,
              formData: res.service,
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              submited: false,
              snackbarVariant: "success",
            },
            () => {
              this.props.handleParent(res.service.id);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "error",
          });
        });
    }
  };

  onSnackbarClose = (e) => {
    this.setState({ snackbarOpen: false });
  };

  handleDeleteClose = () => {
    this.setState({ activeTeam: null, deleteTeam: false });
  };
  handleDeleteOpen = (e, row) => {
    this.setState({ activeTeam: row.id, deleteTeam: true });
  };

  handleDeleteConfirm = () => {
    let teamarray = [...this.state.teamData];
    let tm = teamarray.filter((item) => item.id !== this.state.activeTeam);

    removeTeamFromService(this.state.activeTeam, this.state.formData.id)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            this.handleDeleteClose();
            this.getservData(this.state.formData.id);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          submited: false,
          snackbarVariant: "error",
        });
      });
  };
  handleClose = (flag) => {
    this.setState({ openTeamDialog: false });
    if (flag) {
      this.getservData(this.state.formData.id);
    }
  };
  render() {
    const { formData, datas, loader, sections, serId, submited } = this.state;
    const { masterData } = this.props;
    console.log(submited);
    return (
      <div className="create-content">
        {this.state.openTeamDialog && (
          <ManageServiceTeam
            open={this.state.openTeamDialog}
            handleClose={this.handleClose}
            data={this.state.teamData}
            maindata={this.state.formData}
          />
        )}
        <DeletePopup
          opendelete={this.state.deleteTeam}
          submitted={loader}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDeleteConfirm}
        />
        <CommonLoader open={loader} />
        <div className="content-body customFields">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Service Category <span className="red">*</span>
                      </label>
                      <SelectBox
                        Data={datas}
                        id="id"
                        required
                        async="true"
                        placeholder="Service Category"
                        value={formData.service_category_name}
                        desc="service_category_name"
                        getData={this.handleDataChange}
                        className="form-control"
                        onChange={this.handleSelectChange}
                      />
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Service Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="service_name"
                        onChange={this.handleChange}
                        class="form-control"
                        value={formData.service_name}
                        placeholder="service name"
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Manged By <span className="red">*</span>
                      </label>
                      <select
                        name="managed_by"
                        onChange={this.handleChange}
                        required
                        value={formData.managed_by}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        {masterData.service_managed_by &&
                          masterData.service_managed_by.map((filt, ind) => (
                            <option key={filt} value={filt}>
                              {filt}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Status <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        name="status"
                        value={formData.status}
                      >
                        <option value="">Select</option>
                        {masterData.statuses &&
                          masterData.statuses.map((filt, ind) => (
                            <option key={filt.id} value={filt.id}>
                              {filt.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <InputLabel>
                        HSN <span className="red">*</span>
                      </InputLabel>
                      <TextField
                        // autoFocus
                        variant="outlined"
                        margin="dense"
                        required
                        name="hsn"
                        value={formData.hsn ? formData.hsn : ""}
                        onChange={this.handleChange}
                        type="text"
                        fullWidth
                        className="customFieldInput"
                        placeholder="HSN"
                      />
                    </div>
                  </div>
                </div>
                {serId && (
                  <div className="col-md-12 col-xs-12">
                    <Grid container spacing={2} className="customFields">
                      <Grid item xs={12}>
                        <div className=" p-0 m-0">
                          <div className="fieldBox d-flex align-items-center justify-content-between">
                            <div className="details-box">
                              <div className="detail-box border-r">
                                <div className="">
                                  <p className="name">Team Info</p>
                                </div>
                              </div>
                            </div>
                            <div className="actions">
                              {!this.state.openTeamDialog && (
                                <button
                                  className="btn btn-primary"
                                  title="Add Team "
                                  onClick={(e) =>
                                    this.setState({ openTeamDialog: true })
                                  }
                                >
                                  Add
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {/* <Grid item xs={4}>
            <label className="control-label">
            Team Lead <span className="red">*</span>
          </label>
          </Grid> */}
                      <Grid item xs={12}>
                        <div className=" w-100">
                          <DataTable
                            data={this.state.teamData}
                            columns={[
                              {
                                title: "Team Name",
                                type: "text",
                                field: "name",
                              },
                            ]}
                            options={{
                              sort: false,
                              sortvalue: "datetime_created",
                              pagination: false,
                            }}
                            actions={[
                              {
                                className: "delete",
                                rowActionCLick:
                                  this.handleDeleteOpen.bind(this),
                                iconName:
                                  "/app-assets/backend-images/icons/delete-icon.svg",
                                tooltip: "Delete",
                              },
                            ]}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    {serId && (
                      <div className="col-md-12 col-xs-12 sub-title">
                        <h4>Control Data Visibility in App & Web</h4>
                        <p>All Elements</p>
                      </div>
                    )}
                    <div className="col-md-12 col-xs-12">
                      <div className="">
                        <Sectionelements
                          key={serId ? serId + key : key}
                          serId={serId}
                          elementList={this.state.elementList}
                          sections={sections}
                          handleRefresh={this.getservData}
                        />
                        <div className="col-md-12 col-xs-12 text-right">
                          <button
                            style={{ marginRight: 6 }}
                            onClick={(e) => this.props.handleCancel()}
                            className="btn btn-secondary"
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            disabled={submited}
                            type="submit"
                          >
                            {serId ? "Update" : "Save"}
                          </button>
                          {serId && (
                            <a
                              className="btn btn-secondary"
                              onClick={(e) => this.props.handleTabView(2)}
                            >
                              Next
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ServiceInfo;
