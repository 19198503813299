import { Grid, InputLabel, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SelectBox from "../../../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../../../plugins/snackbar-component";
import { getEmployeeList, setTeamLead, update_teams } from "../../apifunctions";

const TeamInfo = (props) => {
  const [formData, setFormData] = useState(props.data);
  const [employeeData, setEmployeeData] = useState([]);

  const [leadEdit, setLeadEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const getEmployeesList = (e) => {
  
    getEmployeeList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setEmployeeData(res?.results || []);
    });
  };
  const getEmployeChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      lead_name: e["name"],
      lead_id: e["id"],
    }));
  };
  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    setFormData((f) => ({ ...f, [e.target.name]: value }));
  };
  const handleManageLeadUpdate = (e) => {
    e.preventDefault();
    if (formData.lead_id) {
      setLoading(true);
      setSubmitting(true);
      setTeamLead(props.id, formData.lead_id, {
        employee_id: formData.lead_id,
      })
        .then(() => {
          setSnackbarVarient("success");
          setSnackbarMessage("Updated Successfully");
          setSnackbarOpen(true);
          setSubmitting(false);
          setLeadEdit(false);
          setLoading(false);
          setTimeout(() => {
            props.handleRefresh();
          }, 1000);
        })
        .catch((err) => {
          setSnackbarVarient("error");
          setSnackbarMessage(err?.response?.data?.error?.message);
          setSnackbarOpen(true);
          setSubmitting(false);
        });
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please select a team lead");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const manageTeamDetails = (e) => {
    e.preventDefault();
    if (formData?.name && formData?.name !== "") {
      try {
        const payload = { name: formData?.name };
        update_teams(formData?.id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setTimeout(() => {
              props.handleRefresh();
              handleCancel();
            }, 1000);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please enter team name");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };
  const handleCancel = () => {
    setLeadEdit(false);
    props.setEdit(false);
    props.handleRefresh();
  };
  useEffect(() => {
    getEmployeesList();
  }, []);
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  return (
    <div>
      <form onSubmit={manageTeamDetails}>
      <Grid container spacing={2} className="pb-1 customFields">
        <Grid item xs={12}>
          <div className=" p-0 m-0">
            <div className="fieldBox p-0 d-flex align-items-center justify-content-between">
              <div className="details-box">
                <div className="detail-box border-r">
                  <div className="">
                    <p className="name">Basic Info</p>
                  </div>
                </div>
              </div>
              <div className="actions">
                {!props.edit ? (
                  <button
                    className="btn btn-primary"
                    title="Edit Team Info"
                    type="button"
                    onClick={(e) => props.setEdit(true)}
                  >
                    Change
                  </button>
                ) : (
                  <>
                    <button
                      className="btn  btn-secondary"
                      type="button"
                      onClick={(e) => handleCancel()}
                    >
                      Cancel
                    </button>
                    <button
                    type="submit"
                      className="btn  btn-primary"
                      // onClick={(e) => manageTeamDetails(e)}
                    >
                      Update
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <div>
          <InputLabel>Team Name<span className="red">*</span></InputLabel>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            required
            disabled={!props.edit}
            name="name"
            value={formData.name ? formData.name : ""}
            onChange={handleChange}
            type="text"
            fullWidth
            placeholder="Enter Team Name"
            className="customFieldInput"
          />
          </div>
        </Grid>
      </Grid>
      </form>
      <form onSubmit={handleManageLeadUpdate}>
      <Grid container spacing={2} className="customFields">
        <Grid item xs={12}>
          <div className="p-0 m-0">
            <div className="fieldBox p-0 d-flex align-items-center justify-content-between">
              <div className="details-box">
                <div className="detail-box border-r">
                  <div className="">
                    <p className="name">Team Lead</p>
                  </div>
                </div>
              </div>
              <div className="actions">
                {!leadEdit ? (
                  <button
                    className="btn btn-primary"
                    title="Edit Team Lead"
                    type="button"
                    onClick={(e) => setLeadEdit(true)}
                  >
                    Change
                  </button>
                ) : (
                  <>
                    <button
                      className="btn  btn-secondary"
                      onClick={(e) => handleCancel()}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      // onClick={(e) => handleManageLeadUpdate(e)}
                    >
                      Update
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <label className="control-label">
            Select Team Lead <span className="red">*</span>
          </label>
          <SelectBox
            Data={employeeData}
            id="id"
            async="true"
            required
            disabled={!leadEdit}
            value={formData.lead_name ? formData.lead_name : ""}
            desc="name"
            descId="id"
            getData={getEmployeesList}
            placeholder="Select Team Lead"
            className="form-control"
            onChange={getEmployeChange}
          />
        </Grid>
      </Grid>
      </form>
      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default TeamInfo;
