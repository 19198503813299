import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { getCustomerKYC, listContactDuplicate } from "../../../apifunctions";

class CustomerInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      customer_kyc_lists: [],
      snackbarMessage: "",
      duplicates: []
      // formData: {},
    };
  }

  componentDidMount = () => {
    this.loadKycData(this.props.custId);
  };
  componentDidUpdate = (prevProps) => {
    console.log("test")
    if (this.props.custData && this.props.custData.id && (this.props.custData.id !== prevProps.custData?.id)) {
      this.duplicateData(this.props.custData.id);
    }
  }
  async loadKycData(custId) {
    getCustomerKYC(custId)
      .then((res) => res.data)
      .then((res) => {
        console.log(res.kyc_attachments);
        this.setState({
          customer_kyc_lists: res.kyc_attachments,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  }
  async duplicateData(dupId) {
    listContactDuplicate(dupId)
      .then((res) => {
        this.setState({
          duplicates: res.data,
        });
      })
  }
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  getAddressString = (address) => {
    const addString = Object.keys(address)
      .map((key, index) => {
        return ["address_1", "address_2"].includes(key) ? address[key] : null;
      })
      .filter((value) => value !== null)
      .join("\n");
    return addString;
  };

  render() {
    const { loader, duplicates } = this.state;
    const { custData } = this.props;
    console.log("cust data", custData);
    let addresses =
      custData && custData.addresses && custData.addresses.length > 0
        ? custData?.addresses
        : [];

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-3 col-xs-12 customer-profile-list">
                  <ul>
                    <li>
                      <span className="sub">Contact Referral ID :</span>
                      <span className="text">
                        {custData.customer_referral_id}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Contact No :</span>
                      <span className="text">{custData.customer_number}</span>
                    </li>
                    <li>
                      <span className="sub">Contact Tier :</span>
                      <span className="text">
                        {custData.tier ? custData.tier : "--"}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Preferred Language :</span>
                      <span className="text">
                        {custData.preferred_language &&
                          custData.preferred_language.language_name}
                      </span>
                    </li>

                    {addresses &&
                      addresses.map((address) => {
                        return (
                          <>
                            <li>
                              <span className="sub">Country :</span>
                              <span className="text">
                                {address.country ? address.country : "India"}
                              </span>
                            </li>
                            <li>
                              <span className="sub">State :</span>
                              <span className="text">
                                {address.state ? address.state : "Andaman and Nicobar"}
                              </span>
                            </li>
                            <li>
                              <span className="sub">City :</span>
                              <span className="text">
                                {address.city ? address.city : "--"}
                              </span>
                            </li>
                            <li>
                              <span className="sub">Address :</span>
                              <span className="text">
                                {/* {this.getAddressString(address)} */}
                                {address.address_1}
                                {", "} {address.address_2}
                              </span>
                            </li>
                            <li>
                              <span className="sub">Pincode :</span>
                              <span className="text">
                                {" "}
                                {address.pin_code ? address.pin_code : "--"}
                              </span>
                            </li>
                          </>
                        );
                      })}

                    <li>
                      <span className="sub">Specialization :</span>
                      <span className="text">NA</span>
                    </li>
                    <li>
                      <span className="sub">Education Qualification :</span>
                      <span className="text">NA </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9 col-xs-12 customer-profile-table">
                  <h4 className="title">Attached KYC Documents</h4>
                  <div className="table-block">
                    <DataTable
                      data={this.state.customer_kyc_lists}
                      columns={[
                        {
                          title: "Document Type",
                          type: "text",
                          field: "document_type",
                          render: (rowData) =>
                            rowData.document_type && (
                              <span>{rowData.document_type.type_name}</span>
                            ),
                        },
                        {
                          title: "Document Number",
                          type: "text",
                          field: "number",
                        },
                        {
                          title: "Attachment 1",
                          type: "text",
                          field: "attachment1_name",
                        },
                        {
                          title: "Attachment 2",
                          type: "text",
                          field: "attachment2_name",
                        },
                        {
                          title: "Document Name",
                          type: "text",
                          field: "document_name",
                          render: (rowData) => (
                            <span>
                              {rowData.document_name === undefined
                                ? "NA"
                                : rowData.document_name}
                            </span>
                          ),
                        },
                        {
                          title: "Updated On",
                          type: "text",
                          field: "datetime_updated",
                        },
                      ]}
                      options={{
                        sort: false,
                        sortvalue: "number",
                      }}
                    />
                  </div>

                  <h4 className="title">Possible Duplicates</h4>
                  <div className="table-block">
                    <DataTable
                      data={this.state.duplicates}
                      columns={[
                        {
                          title: "Account ID",
                          type: "text",
                          field: "customer_number",
                        },
                        {
                          title: "Contact Account",
                          type: "text",
                          field: "name",
                           render: (rowData) =>
                            rowData.default_contact && (
                              <span>{`${rowData.default_contact.first_name} ${rowData.default_contact.last_name}`}</span>
                            ),
                        },
                        {
                          title: "Email",
                          type: "text",
                          field: "email",
                           render: (rowData) =>
                            rowData.default_contact && (
                              <span>{rowData.default_contact.email || ''}</span>
                            ),
                        },
                        {
                          title: "Mobile",
                          type: "text",
                          field: "mobile",
                           render: (rowData) =>
                            rowData.default_contact && (
                              <span>{rowData.default_contact.mobile || ''}</span>
                            ),
                        },
                        {
                          title: "Secondary Email",
                          type: "text",
                          field: "email_2",
                           render: (rowData) =>
                            rowData.default_contact && (
                              <span>{rowData.default_contact.email_2 || ''}</span>
                            ),
                        },
                        {
                          title: "Secondary Mobile",
                          type: "text",
                          field: "mobile_2",
                           render: (rowData) =>
                            rowData.default_contact && (
                              <span>{rowData.default_contact.mobile_2 || ''}</span>
                            ),
                        },
                      ]}
                      options={{
                      }}
                    />
                  </div>                
                </div>

                 
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CustomerInformation;
