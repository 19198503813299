import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { getPackage, updatePackage } from "../../../apifunctions";

class Description extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      formData: {},
      loader: false,
      snackbarMessage: "",
      pack_Id: this.props.pack_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({ pack_Id: this.props.pack_Id });
  };
  componentDidMount = () => {
    if (this.props.pack_Id) {
      this.getData(this.props.pack_Id);
    }
  };
  async getData(pack_Id) {
    this.setState({ loader: true });
    try {
      getPackage(pack_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: {
              service_category_name: res.service_category
                ? res.service_category.service_category_name
                : "",
              managed_by: res.managed_by,
              service_name: res.service_name,
              status: res.status,
              id: res.id,
              service_category: res.service_category
                ? res.service_category.id
                : "",
              description: res.description,
            },
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    updatePackage(this.state.formData, this.props.pack_Id)
      .then((res) => res)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {}
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          submited: false,
        });
      });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <label className="control-label">Description</label>
                  <textarea
                    type="text"
                    name="description"
                    required
                    class="form-control"
                    value={formData.description}
                    onChange={this.handleChange}
                    placeholder=""
                    rows="8"
                  ></textarea>
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <a
                    className="btn btn-secondary"
                    onClick={(w) => this.props.handleTabView(1)}
                  >
                    Back
                  </a>
                  <button
                    className="btn btn-primary"
                    disabled={submited}
                    type="submit"
                  >
                    Update
                  </button>
                  <a
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(3)}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default Description;
