import { debounce } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';

import SelectStyle from './selectoveride.syle';

const SelectBox = ({
  value: propValue,
  getData,
  async: isAsync,
  desc,
  label,
  margin,
  selectedValue,
  name,
  defaultValue,
  required,
  InputLabelProps,
  readOnly,
  error,
  disabled,
  id,
  placeholder,
  disableClearable = false,
  onChange,
  descId,
  Data: data = [],
  hideSelection,
}) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleInputChange = useCallback(
    (event, newValu, reason) => {
      if (reason === 'input') {
        if (getData) {
          handleGetData(newValu, newValu.trim() === '' ? 'opened' : undefined);
        }
        setValue(newValu.trimStart());
        if (!newValu.trim()) {
          onChange({ [id]: '', [desc]: '', Param1: false }, descId, desc);
        }
      } else if (reason === 'clear' && newValu === '') {
        setValue('');
        if (getData) {
          handleGetData('', 'opened');
        }
      }
    },
    [getData, onChange, id, desc, descId]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    setLoading(true);
    if (isAsync && getData) {
      handleGetData('', 'opened');
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [getData, isAsync]);

  const handleGetData = debounce((res, key) => {
    getData(res, key);
  }, 1000);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = useCallback(
    (e, newValue) => {
      setValue('');

      if (newValue) {
        onChange(newValue, descId, desc);
      } else {
        setOpen(false);
        onChange({ [id]: '', [desc]: '' }, descId, desc);
      }
    },
    [onChange, id, desc, descId]
  );
  const handleBlur = () => {
    const res = data.find((item) => item[desc] === value) ? true : false;
    if (!res) {
      setValue('');
    }
  };
  const propsValues = propValue ?? '';

  return (
    <div>
      <MuiThemeProvider theme={SelectStyle}>
        {readOnly ? (
          <TextField
            id="select-pagesize"
            type="text"
            label={label}
            fullWidth
            name={name}
            value={value || ''}
            margin={margin}
            error={error}
            InputLabelProps={InputLabelProps}
            required={required}
            placeholder={placeholder}
            InputProps={{
              readOnly: true,
            }}
            className="customFieldInput"
          />
        ) : (
          <Autocomplete
            options={
              hideSelection
                ? data?.filter(
                    (option) =>
                      option[desc] !==
                      (selectedValue ? selectedValue[desc] : '')
                  )
                : data
            }
            getOptionLabel={(option) => option[desc]}
            onChange={handleChange}
            inputValue={value ? value : propsValues}
            value={selectedValue ?? { [desc]: propsValues }}
            open={open}
            onOpen={handleOpen}
            ListboxProps={{ style: { maxHeight: '15rem' } }}
            onClose={handleClose}
            disableClearable={disableClearable}
            defaultValue={defaultValue}
            name={desc}
            id={id}
            onBlur={handleBlur}
            error={error}
            disabled={disabled}
            loading={loading}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                value={value ? value : propsValues}
                name={name}
                variant="standard"
                disabled={disabled}
                label={label}
                className="customFieldInput autocomplete"
                defaultValue={defaultValue}
                placeholder={placeholder || 'Type to search'}
                margin="dense"
                InputLabelProps={InputLabelProps}
                error={error}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  readOnly: readOnly,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      </MuiThemeProvider>
    </div>
  );
};

export default SelectBox;
