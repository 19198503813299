import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./datatable.css";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="table-responsive">
        <table id="dataTable" className="dataTable table  ">
          {this.headerComponent()}
          <tbody>
            {this.props.dataList.map((data, dIdx) =>
              this.dataComponent(data, dIdx)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  headerComponent() {
    return (
      <thead>
        <tr>
          {this.props.tableConfig.columns.map((column, cIdx) => {
            return (
              <th
                key={column.key || cIdx}
                className={column.hStyle ? column.hStyle.class || "" : ""}
              >
                {column.type === String
                  ? column.title
                  : column.type === "Action"
                  ? "Action"
                  : null}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  dataComponent(data, dIdx) {
    return (
      <tr key={data.id || dIdx}>
        {this.props.tableConfig.columns.map((column, cIdx) => {
          return (
            <td
              key={column.key || cIdx}
              className={column.dStyle ? column.dStyle.class || "" : ""}
            >
              {column.type === String
                ? column.dStyle && column.dStyle.activeStyle
                  ? this.dataActiveStyleComponent(data[column.value])
                  : data[column.value]
                : column.type === "Action"
                ? this.dataActionComponent(data)
                : null}
            </td>
          );
        })}
      </tr>
    );
  }

  dataActionComponent(data) {
    return (
      <>
        {this.props.tableConfig.action.isEdit ? (
          <Link to={this.props.tableConfig.action.editPath} className=" edit">
            <img src="/app-assets/backend-images/icons/edit-icon.svg" />
          </Link>
        ) : null}
        {this.props.tableConfig.action.isDelete ? (
          <a className=" delete" onClick={() => this.props.deleteData(data)}>
            <img src="/app-assets/backend-images/icons/delete-icon.svg" />
          </a>
        ) : null}
      </>
    );
  }

  dataSortComponent() {
    return (
      <span className="text-center">
        <img src="/app-assets/backend-images/icons/drag-icon.svg" />
      </span>
    );
  }

  dataActiveStyleComponent(value) {
    return <span className="active">{value}</span>;
  }
}

export default DataTable;
