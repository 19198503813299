import moment from "moment";

export const formatDate = (
  date,
  fromFormat = "MM-DD-YYYY",
  toFormat = "DD-MMM-YYYY",
  emptySymbol = "--",
) => {
  if (!date) return emptySymbol;
  var dateObj = moment(date, fromFormat);
  return dateObj.format(toFormat);
};
