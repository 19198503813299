import { Grid } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import Swal from "sweetalert2";

import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/newDeleteComponent";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { crearteAccountAgent, deleteAccountAgent, getAgentList, updateCompanyPrimaryAgent } from "../../../apifunctions";
import ManageAgent from "./manage/addAgent";

// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";
let serId = "";
class CompanyOfficialMaping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      loader: false,
      opendelete: false,
      open: false,
      employeeData: [],
      snackbarMessage: "",
      formData: {},
      anchorEl: null,
      activeRowId: null,
      activeRow: {},

    };
  }
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };

  getData = () => {
    this.setState({ loader: true });
    try {
      getAgentList(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          let data = res;
          data.forEach((item) => {
            item["ex_name"] = item?.customer_relation_executive?.name;
            item["ex_id"] = item?.customer_relation_executive?.id;
            item["role_id"] = item?.agent_role?.id;
            item["role_name"] = item?.agent_role?.name;
          });
          this.setState({
            open: false,
            employeeData: data,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            employeeData: [],
            loader: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = (data) =>
    crearteAccountAgent(this.props.com_Id, data).then((res) => res);
  handleDeleteOpen = (e, reqData) => {
    console.log(reqData);
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDelete = () =>
    deleteAccountAgent(this.props.com_Id, this.state.mainId)
      .then((res) => {
        this.setState(
          {
            // employeeData: [],
            loader: false,
            snackbarMessage: res?.data,
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.handleDeleteClose();
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });

      makeAsPrimary = (reqData) => {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to make this as primary?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            updateCompanyPrimaryAgent(this.props.com_Id, {
              customer_relation_executive_id: this.state.activeRowId,
            }).then(() => {
              this.setState({
                loader: false,
                snackbarMessage: "Primary Agent updated",
                snackbarOpen: true,
                snackbarVariant: "success",
              });
              this.getData();
              this.props.handleRefresh();
            });
          }
        });
      };
      openMenu = (e, rowData) => {
        this.setState(
          { activeRowId: rowData?.ex_id, activeRow: rowData },
          () => {
            this.setState({ anchorEl: e.target });
          }
        );
      };
      handleViewClose = () => {
        this.setState({ anchorEl: null });
      };
  render() {
    const { formData, loader, employeeData, submited, open, anchorEl } = this.state;
    const { masterData, companyDetail } = this.props;
    
    return (
      <div className="create-content">
         <Menu
          id="simple-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e) => this.handleViewClose(e)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              // marginRight:100,
              width: 200,
              padding: 0,
              left: 0,
              // right: 80,
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
        >
          {this.props?.companyDetail?.primary_agent !==
          this.state.activeRowId ? (
            <>
              <MenuItem onClick={(e) => this.makeAsPrimary(e)}>
                Make as Primary Agent
              </MenuItem>
            </> 
           ) : (
            <MenuItem disabled onClick={() => {}}>
              No Actions found
            </MenuItem>
          ) 
          } 
        </Menu>
        <CommonLoader open={loader} />
        {open && (
          <ManageAgent
            open={open}
            id={this.props.com_Id}
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            refetch={this.getData}
          />
        )}
        <div className="content-body">
          {/* <form className="edit-form" onSubmit={this.handleSubmit}> */}

          <Grid container spacing={2} className="customFields ">
            <Grid item xs={12}>
              <div className=" p-0 m-0">
                <div className="fieldBox d-flex align-items-center justify-content-between">
                  <div className="details-box">
                    <div className="detail-box border-r">
                      <div className="">
                        <p className="name">
                          {" "}
                          Customer Unique Referal ID:{" "}
                          {companyDetail.company_code}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="actions">
                    {/* {!edit && ( */}
                    <button
                      className="btn btn-primary"
                      title="Edit Team Lead"
                      onClick={(e) => this.handleOpen()}
                    >
                      Add Agent
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={4}>
            <label className="control-label">
            Team Lead <span className="red">*</span>
          </label>
          </Grid> */}
            <Grid item xs={12}>
              <div className=" w-100">
                <DataTable
                  data={employeeData}
                  columns={[
                    {
                      title: "Name",
                      type: "text",
                      field: "ex_name",
                      render: (rowData) => (
                        <>
                          <>
                            {rowData.customer_relation_executive && (
                              <span>
                                {rowData.customer_relation_executive.name}{" "}
                              </span>
                            )}
                          </>
                          {rowData?.ex_id ===
                            this.props?.companyDetail?.primary_agent && (
                            <span
                              span
                              style={{
                                backgroundColor: "#299956",

                                padding: "2px 8px",
                                borderRadius: "6px",
                                color: "#fff",
                                marginLeft: 5,
                                fontSize: "11px",
                              }}
                            >
                              Primary Agent
                            </span>
                          )}
                        </>
                      ),
                    },
                    {
                      title: "Role",
                      type: "text",
                      field: "role_name",
                    },
                  ]}
                  options={{
                    sort: false,
                    // sortvalue: "datetime_created",
                    pagination: false,
                  }}
                  actions={[
                      {
                        className: "delete",
                        rowActionCLick: this.openMenu.bind(this),
                        iconName:
                          "/app-assets/backend-images/icons/menu-dot.svg",
                        tooltip: "Menu",
                      },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
          <div className="card-content py-1">
            <div className="row">
              <div className="col-md-12 col-xs-12 text-right">
                <a
                  href="#/"
                  className="btn btn-secondary"
                  onClick={(e) => this.props.handleTabView(1)}
                >
                  Back
                </a>
                {/* <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button> */}
                <a
                  href="#/"
                  className="btn btn-secondary"
                  onClick={(e) => this.props.handleTabView(3)}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default CompanyOfficialMaping;
