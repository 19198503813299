// import { Grid, MuiThemeProvider } from "../../apifunctions";
import { Grid, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SelectBox from "../../../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../../../plugins/snackbar-component";
import { getDept, getDesig, getEmployeeLists, getEmployeeWorkProfile, getTeamsList, updateEmployeeWorkProfile } from "../../apifunctions";
import TeamDialogTheme from "../employeedetailtheme";

const WorkProfile = (props) => {
  const [departMent, setDepartment] = useState({});
  const [team, setTeam] = useState({});
  const [designation, setDesignation] = useState({});
  const [reportTo, setReportTo] = useState({});
  const [employeeData, setEmployeeData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [desigData, setDesigData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState({});

  const getDepartmentChange = (e, a, b) => {
    setDepartment((f) => ({
      ...f,
      name: e["name"],
      id: e["id"],
    }));
  };
  const getDesignationChange = (e, a, b) => {
    setDesignation((f) => ({
      ...f,
      name: e["name"],
      id: e["id"],
    }));
  };
  const getTeamChange = (e, a, b) => {
    setTeam((f) => ({
      ...f,
      name: e["name"],
      id: e["id"],
    }));
  };
  const getEmployeChange = (e, a, b) => {
    setReportTo((f) => ({
      ...f,
      name: e["name"],
      id: e["id"],
    }));
  };

  const getEmployeesList = (e) => {

    getEmployeeLists({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      let data = res.results;

      setEmployeeData(data.filter((fi) => fi.id !== props.id));
    });
  };
  const getDepartmentList = (e) => {


    getDept({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      let data = res?.data || [];

      setDeptData(data);
    });
  };
  const getDesignationList = (e) => {

    getDesig({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      let data = res?.data || [];

      setDesigData(data);
    });
  };
  const getTeamList = (e) => {
    if (e) {
      setTeam((f) => ({
        ...f,
        name: e,
      }));
    }
    getTeamsList({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      let data = res.results;

      setTeamData(data);
    });
  };
  const getDetails = () => {
    getEmployeeWorkProfile(props.id).then((res) => {
      let data = res.data;
      setDepartment(data?.department);
      setDesignation(data?.designation);
      setTeam(data?.team);
      setReportTo(data?.reports_to);
    })
    .catch((err) => {
      setSnackbarVarient("error");
      setSnackbarMessage(err?.response?.data?.error?.message);
      setSnackbarOpen(true);
      setSubmitting(false);
      setLoading(false);
    });;
  };
  useEffect(() => {
    getDetails();
    getEmployeesList();
    getDepartmentList();
    getDesignationList();
    getTeamList();
  }, []);

  const onSubmit = (e) => {
    setLoading(true);

    e.preventDefault();

    if (designation?.id && departMent?.id && reportTo?.id) {
      try {
        const payload = {
          designation_id: designation?.id,
          department_id: departMent?.id,
          team_id: team?.id,
          reports_to_id: reportTo?.id,
        };
        updateEmployeeWorkProfile(props.id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setSubmitting(false);
            setLoading(false);
            handleCancel();
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
            setLoading(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please fill all required fields");
      setSnackbarOpen(true);
      setSubmitting(false);
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setEdit(false);

  };

  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  console.log(error);
  return (
    <div>
      <MuiThemeProvider theme={TeamDialogTheme}>
        <Grid container spacing={2} className="customFields">
          <Grid item xs={12}>
            <div className=" p-0 m-0">
              <div className="fieldBox d-flex align-items-center justify-content-between">
                <div className="details-box">
                  <div className="detail-box border-r">
                    <div className=""></div>
                  </div>
                </div>
                <div className="actions">
                  {!edit ? (
                    <button
                      className="btn btn-primary"
                      title="Edit Team Lead"
                      disabled={loading}
                      onClick={(e) => setEdit(true)}
                    >
                      Change
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn  btn-secondary"
                        disabled={loading}
                        onClick={(e) => handleCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn  btn-primary"
                        onClick={(e) => onSubmit(e)}
                        disabled={loading}
                      >
                        Update
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <label className="control-label">
              Department
              <span className="red">*</span>
            </label>
            <SelectBox
              Data={deptData}
              id="id"
              async="true"
              required
              disabled={!edit}
              value={departMent?.name ? departMent?.name : ""}
              desc="name"
              descId="id"
              getData={getDepartmentList}
              placeholder="Department"
              className="form-control"
              onChange={getDepartmentChange}
              error={error?.departMent ? true : false}
            />
          </Grid>
          {/* <Grid item xs={3}>
            <label className="control-label">
              Team
              <span className="red">*</span>
            </label>
            <SelectBox
              Data={teamData}
              id="id"
              async="true"
              required
              disabled={!edit}
              value={team?.name ? team?.name : ""}
              desc="name"
              descId="id"
              getData={getTeamList}
              placeholder="Team "
              className="form-control"
              onChange={getTeamChange}
              error={error?.team ? true : false}
            />
          </Grid> */}
          <Grid item xs={3}>
            <label className="control-label">
              Designation
              <span className="red">*</span>
            </label>
            <SelectBox
              Data={desigData}
              id="id"
              async="true"
              required
              disabled={!edit}
              value={designation?.name ? designation?.name : ""}
              desc="name"
              descId="id"
              getData={getDesignationList}
              placeholder="Designation"
              className="form-control"
              onChange={getDesignationChange}
              error={error?.designation ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            <label className="control-label">
              Report to
              <span className="red">*</span>
            </label>
            <SelectBox
              Data={employeeData}
              id="id"
              async="true"
              required
              disabled={!edit}
              value={reportTo?.name ? reportTo?.name : ""}
              desc="name"
              descId="id"
              getData={getEmployeesList}
              placeholder="Team Lead"
              className="form-control"
              onChange={getEmployeChange}
              error={error?.reportTo ? true : false}
            />
          </Grid>
        </Grid>
      </MuiThemeProvider>
      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default WorkProfile;
