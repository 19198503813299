import apis from "../../apiactions/api.actions";
import { parseQueryParams } from "../../utils/formValidate";

const customer = "customer/";
const export_customer = "customer/export/";
const customer_basic = "customer/basic-info/";
const customer_mapping = "/customer-mapping/";
const customer_address = "/customer-address/";
const customer_kyc = "/customerKYCattachments/";
const customer_orders = "/customer-orders/";
const support_note = "note";
const employee_listall = "employee/?view=all";
const location_list = "core/country-state-list";
const master_data = "core/master-data";

export const searhCustomerData = (data) =>
  apis.getDatas(`${customer}${parseQueryParams(data)}`);
export const exportCustomerData = (data) =>
  apis.getDatas(`${export_customer}${parseQueryParams(data)}`);
export const getEmployeeList = () => apis.getDatas(employee_listall);
export const getMasterDataList = () => apis.getDatas(master_data);
export const getAccounts = (data) =>
  apis.getDatas(`${customer}companies/${parseQueryParams(data)}`);
export const getCustomerBusiness = (id, page) =>
  apis.getDatas(customer + id + "/business-info/?page=" + page);
export const getContactAccounts = (id, page) =>
  apis.getDatas(customer + id + "/accounts/?page=" + page);
export const deleteContactAccounts = (id, id2) =>
  apis.deleteData(customer + id + "/accounts/" + id2 + "/");
export const getCustomerOrder = (id, page) =>
  apis.getDatas(customer + id + "/order-info/?page=" + page);
export const getCustomerTransactions = (id, page) =>
  apis.getDatas(customer + id + "/transactions-info/?page=" + page);
export const getCustomerService = (id, page) =>
  apis.getDatas(customer + id + "/service-info/?page=" + page);
export const getCustomerReferrals = (id, page) =>
  apis.getDatas(customer + id + "/referral/?page=" + page);
export const getCustomerCompliances = (id, page) =>
  apis.getDatas(customer + id + "/company-compliance-info/?page=" + page);

export const getCustomer = (id) => apis.getDatas(customer + id + "/");
//apis.getDatas(`${company}${id}/company`);

export const saveCustomerBasic = (data) =>
  apis.createData(`${customer_basic}`, data);
export const updateCustomerBasic = (id, data) =>
  apis.updateData(`${customer_basic}${id}/`, data);
export const getCustomerBasic = (id) =>
  apis.getDatas(customer_basic + id + "/");

export const getLocationList = () => apis.getDatas(location_list);

export const getCustomerInfo = (id) =>
  apis.getDatas(customer + id + "/customer-info/");

export const getMapCustomer = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_mapping);
export const saveMapCustomer = (customer_id, data) =>
  apis.createData(customer + customer_id + "/new-customer-mapping", data);
export const add_account_to_customer = (customer_id, data) =>
  apis.createData(customer + customer_id + "/accounts/", data);
export const updateMapCustomer = (customer_id, data) =>
  apis.updateData(
    customer + customer_id + customer_mapping + data.id + "/",
    data
  );

export const getCustomerAddress = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_address);
export const newCustomerAddress = (customer_id, data) =>
  apis.createData(customer + customer_id + customer_address, data);
export const updateCustomerAddress = (customer_id, data) =>
  apis.updateData(
    customer + customer_id + customer_address + data.id + "/",
    data
  );

export const getCustomerKYC = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_kyc);
export const saveCustomerKYC = (customer_id, data) =>
  apis.createData(customer + customer_id + customer_kyc, data);
export const deleteCustomerKYC = (customer_id, row_id) =>
  apis.deleteData(customer + customer_id + customer_kyc + row_id + "/");

export const getCustomerOrders = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_orders);

export const getContactDuplicate = (data) =>
  apis.getDatas(
    `${customer}possible-contact-duplicates/${parseQueryParams(data)}`
  );

export const listContactDuplicate = (id, data) =>
  apis.getDatas(
    `${customer}${id}/contact-duplicates/${parseQueryParams(data)}`
  );
export const mergeContacts = (parentContactId, data) =>
  apis.updateData(`${customer}${parentContactId}/merge/`, data);
export const updateContactPrimaryAccount = (id, data) =>
  apis.updateData(`${customer}${id}/set-primary-account/`, data);
