import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  deleteCheckListRow,
  getCheckList,
  getCheckListRow,
  updateCheckList,
} from "../apifunctions";
import CheckListDialog from "../checklistdialog";

class CheckListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      formSearch: {
        page: 1,
        search: "",
      },
      pagesize: 0,
      totalCount: 0,
      loader: false,
      editMode: false,
      opendelete: false,
      openDialog: false,
      mainId: "",
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }

  async getData() {
    this.setState({ loader: true });
    try {
      getCheckList(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }

  handleAdd = (e) => {
    this.setState({
      openDialog: true,
      editMode: false,
    });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
      formData: {},
    });
  };

  getCheckListView = () => {
    this.getData();
  };

  handleEdit = (e, row) => {
    this.editCheckList(row.id);
  };

  editCheckList = (id) => {
    console.log(id);
    try {
      getCheckListRow(id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: {
              name: res.name,
              display_name: res.display_name,
              days_for_completion: res.days_for_completion,
            },
            mainId: id,
            editMode: true,

            openDialog: true,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };

  handleDelete = () =>
    deleteCheckListRow(this.state.mainId).then(() => {
      this.getData();
    });

  render() {
    const { loader, totalCount, formSearch, pagesize, editMode, formData } =
      this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item align="right" xs={6} sm={10}>
              <Link
                className="btn btn-primary"
                onClick={(e) => this.handleAdd(e)}
                to='#'
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  // {
                  //   title: "SL",
                  //   type: "String",
                  //   field: "slNo",
                  // },
                  {
                    title: "Name",
                    type: "text",
                    field: "name",
                    render: (rowData) =>
                      rowData?.name && (
                        <span
                          className="text-primary cursor-pointer"
                          onClick={(e) => {
                            this.handleEdit(e, rowData);
                          }}
                        >
                          {rowData?.name ?? "--"}
                        </span>
                      ),
                  },
                  {
                    title: "Display Name",
                    type: "text",
                    field: "display_name",
                  },
                  {
                    title: "Expected days for completion",
                    type: "text",
                    field: "days_for_completion",
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "name",
                  pageNo: formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit ",
                  },
                  {
                    className: "delete",
                    rowActionCLick: this.handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <CheckListDialog
          openDialog={this.state.openDialog}
          handleClose={this.handleClose}
          getCheckListView={this.getCheckListView}
          mainId={this.state.mainId}
          editMode={editMode}
          formData={formData ? formData : ""}
        />
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
export default CheckListView;
