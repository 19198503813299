import { IconButton, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React, { Component } from "react";
import SelectStyle from "./selectbox/selectoveride.syle";

export default class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleSearch();
  };
  render() {
    const { value, onChange, handleSearch, handleClear } = this.props;
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <div className="position-relative">
          <MuiThemeProvider theme={SelectStyle}>
            <TextField
              type="text"
              onChange={onChange}
              name="search"
              value={value ? value : ""}
              className="customFieldInput"
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {value && (
                      <IconButton
                        style={{ padding: 0, outline: "none" }}
                        disableRipple
                        type="button"
                        onClick={(e) => handleClear("search")}
                        className="btn-transclose"
                      >
                        <img src="/app-assets/backend-images/icons/close.png" />
                      </IconButton>
                    )}
                    <IconButton
                      style={{ padding: 0, outline: "none" }}
                      disableRipple
                      type="submit"
                      className="btn-transparent"
                    >
                      <img src="/app-assets/backend-images/icons/search-icon.svg" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MuiThemeProvider>
        </div>
      </form>
    );
  }
}
