import React, { Component } from "react";
import { Route } from "react-router-dom";
import BreadCrumb from "../../layout/breadcrumb";
import NavigationSubMenu from "../../layout/sub-menu";
import "./customers.css";
import { SCOPES } from "../../config/permission-maps";
import ContentWrapperComponent from "../../layout/contentWrapper";
class Customers extends Component {
  constructor(props) {
    super(props);
    const currentRoute = this.props.routes.find((route) => this.props.match.path + route.path === this.props.location.pathname);
    if (this.props.match.path === this.props.location.pathname) {
      this.props.history.push(`${this.props.match.path}/contact-list`);
    }
    this.state = {
      type: currentRoute ? currentRoute["type"] : null,
    };
  }
  componentDidMount = () => {};

  componentWillMount() {
    this.rerouteToAdminPage();
  }
  componentDidUpdate(prevProps) {
    this.rerouteToAdminPage();
  }
  componentWillReceiveProps = () => {
    if (this.props.location.pathname === "/contacts") this.props.history.push(`${this.props.match.path}/contact-list`);
  };
  rerouteToAdminPage() {
    const currentRoute = this.props.routes.find((route) => this.props.match.path + route.path === this.props.location.pathname);
    if (currentRoute && currentRoute["type"] !== this.state.type) {
      this.setState({ type: currentRoute["type"] });
    }
  }

  render() {
    return (
      <>
        <ContentWrapperComponent scopes={[SCOPES.manageContacts]} type={this.state.type} routes={this.props.routes} {...this.props} masterData={this.props.masterData} />
      </>
    );
  }
}

export default Customers;
