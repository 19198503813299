import { Grid, InputLabel, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { SCOPES } from "../../../../config/permission-maps";
import PermissionsGate from "../../../../config/permissiongate";
import CalendarComponent from "../../../../plugins/calendar.comp";
import SelectBox from "../../../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../../../plugins/snackbar-component";
import { numberFormat } from "../../../../utils/numberFormat";
import { getAccountList, getContacts, getEmployees, getLeadSourceList, getOrderStatusList, update_orders } from "../../apifunctions";

const OrderInfo = ({ data, id, handleRefresh }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({ ...data });
  const [accountData, setAccountData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [CREData, setCREData] = useState([]);

  const fetchAccountBySearch = (e) => {
  
    getAccountList({ page: 1, type: "dropdown", ...(e ? { search: e } : {}) }).then((res) => {
      setAccountData(res?.data?.results || []);
    });
  };
  const fetchContacteBySearch = (e, cont) => {
 
    getContacts(cont || formData?.account_id, {
      page: 1,
      type: "dropdown",
      ...(e ? { search: e } : {}),
    }).then((res) => {
      let d = res.data;
      d.forEach((item) => {
        item["name"] =
          item.customer_info.first_name + " " + item.customer_info.last_name;
      });
      setContactData(d || []);
    });
  };
  const fetchLeadSourceBySearch = (searchText) => {
    getLeadSourceList({ page: 1, search: searchText ?? "" }).then((res) => {
      setLeadSourceData(res?.data || []);
    });
  };
  const fetchCREBySearch = (e) => {

    getEmployees({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setCREData(res?.results || []);
    });
  };
  const filteredCREData = () => {
    const filteredEmployeeData = CREData;
    return filteredEmployeeData;
  };
  const fetchStatusBySearch = (e) => {

    getOrderStatusList({
      page: 1,
      type: "order",
      ...(e ? { search: e } : {}),
    }).then((res) => {
      setStatusData(res?.data || []);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = {
      account_id: formData?.account_id ?? "",
      contact_id: formData?.contact_id ?? "",
      order_comment: formData?.order_comment ?? "",
      external_id: formData?.external_id ?? "",
      order_status_id: formData?.status_id ?? "",
      total_amount: formData?.grand_total ?? 0,
      internal_campaign_id: formData?.internal_campaign_id ?? "",
      assigned_to: formData?.employee_id || null,
      spot_incentive: formData?.spot_incentive
        ? formData?.spot_incentive !== ""
          ? formData?.spot_incentive
          : "0.00"
        : "0.00",
      lead_source_id: formData?.lead_source_id || null,
      order_date: formData.order_date
        ? moment(formData.order_date).format("YYYY-MM-DD")
        : "",
    };
    let error = false;
    Object.keys(payload).forEach((item) => {
      if (
        item !== "internal_campaign_id" &&
        item !== "external_id" &&
        item !== "order_comment" &&
        item !== "spot_incentive" &&
        item !== "lead_source_id" &&
        item !== "assigned_to"
      )
        if (!payload[item] || payload[item] === "") {
          error = true;
        }
    });
    setTimeout(() => {
      handleRefresh();
    }, 500);

    if (!error) {
      try {
        update_orders(id, payload)
          .then((res) => {
            setSnackbarVarient("success");
            setSnackbarMessage("Updated Successfully");
            setSnackbarOpen(true);
            setEdit(false);
            setTimeout(() => {
              setSubmitting(false);
            }, 500);
          })
          .catch((err) => {
            setSnackbarVarient("error");
            setSnackbarMessage(err?.response?.data?.error?.message);
            setSnackbarOpen(true);
            setSubmitting(false);
          });
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSnackbarVarient("error");
      setSnackbarMessage("Please fill all required fields");
      setSnackbarOpen(true);
      setSubmitting(false);
    }
  };

  const fetchExistingLeadSource = (order_exist) => {
    if (order_exist) {
      getLeadSourceList({ page: 1, search: "Existing" }).then((response) => {
        const leadSource = (response?.data || []).pop();
        setFormData((f) => ({
          ...f,
          lead_source_id: leadSource?.id,
          lead_source_name: leadSource?.name,
        }));
      });
    }
  };

  const handleAccountChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      account_name: e[b],
      account_id: e[a],
      contact_name: "",
      contact_id: null,
    }));
    if (e[a]) {
      fetchContacteBySearch(undefined, e[a]);
      fetchExistingLeadSource(e["order_count"]);
    } else {
      fetchAccountBySearch();
    }
  };
  const handleContactChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      contact_name: e["name"],
      contact_id: e["customer_id"],
    }));
  };
  const handleSourceChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      lead_source_name: e[b],
      lead_source_id: e[a],
    }));
    if (!e["id"]) {
      fetchLeadSourceBySearch();
    }
  };
  const handleStatusChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      status_name: e[b],
      status_id: e[a],
    }));
  };
  const handleDatePicker = (e) => {
    setFormData((f) => ({ ...f, [e.name]: e.value }));
  };

  useEffect(() => {
    fetchAccountBySearch();
    fetchLeadSourceBySearch();
    fetchCREBySearch();
  }, []);

  const handleChange = (e) => {
    setFormData((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  const handleCancel = () => {
    setEdit(false);
    handleRefresh();
  };

  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  const validateNumber = (evt, regex) => {
    var theEvent = evt || window.event;
    if (!regex.test(evt.key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  const handleCREChange = (selected_data, id, name) => {
    setFormData((f) => ({
      ...f,
      assigned_to_name: selected_data[name],
      employee_id: selected_data[id],
    }));
    if (!selected_data[id]) {
      fetchCREBySearch();
    }
  };
  return (
    <div>
      {/* <form onSubmit={manageTeamDetails}> */}
      <Grid container spacing={2} className="pb-3 customFields">
        <Grid item xs={12}>
          <div className=" p-0 m-0">
            <div className="fieldBox d-flex align-items-center justify-content-between">
              <div className="details-box">
                <div className="detail-box border-r">
                  <div className="">
                    <p className="name">Basic Info</p>
                  </div>
                </div>
              </div>
              <div className="actions">
                <PermissionsGate scopes={[SCOPES.editOrder]}>
                  <>
                    {!edit ? (
                      <button
                        className="btn btn-primary"
                        title="Edit Team Info"
                        onClick={(e) => setEdit(true)}
                      >
                        Change
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn btn-secondary"
                          onClick={(e) => handleCancel()}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Update
                        </button>
                      </>
                    )}
                  </>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <InputLabel>
            Account<span className="red">*</span>
          </InputLabel>
          <SelectBox
            Data={accountData}
            id="id"
            async="true"
            required
            disabled={!edit}
            value={formData.account_name ? formData.account_name : ""}
            desc="business_name"
            descId="id"
            getData={fetchAccountBySearch}
            placeholder="Account"
            className="form-control"
            onChange={handleAccountChange}
          />
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <InputLabel>
            Contact<span className="red">*</span>
          </InputLabel>
          <SelectBox
            Data={contactData}
            id="id"
            async="true"
            disabled={edit && formData?.account_id ? false : true}
            required
            value={formData.contact_name ? formData.contact_name : ""}
            desc="name"
            descId="customer_id"
            getData={(e) =>
              fetchContacteBySearch(e, formData.account_id)
            }
            placeholder="Contact"
            className="form-control"
            onChange={handleContactChange}
          />
        </Grid>
        <Grid item xs={3}>
          {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
          <InputLabel>
            Status<span className="red">*</span>
          </InputLabel>
          <SelectBox
            Data={statusData}
            id="id"
            async="true"
            disabled={!edit}
            required
            value={formData.status_name ? formData.status_name : ""}
            desc="name"
            descId="id"
            getData={fetchStatusBySearch}
            placeholder="Status"
            className="form-control"
            onChange={handleStatusChange}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Grand Total</InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            required
            disabled
            name="grand_total"
            className="customFieldInput"
            // value={formData.grand_total ? formData.grand_total : ""}
            value={
              formData.grand_total ? numberFormat(formData.grand_total) : ""
            }
            onChange={handleChange}
            fullWidth
            placeholder="Enter Grand Total"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>
            Order Date<span className="red">*</span>
          </InputLabel>
          <CalendarComponent
            variant="outlined"
            margin="dense"
            className="customFieldInput "
            required
            readOnly={!edit}
            fullWidth
            selected={formData.order_date}
            name="order_date"
            onChange={handleDatePicker}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Created Date</InputLabel>
          <CalendarComponent
            variant="outlined"
            margin="dense"
            className="customFieldInput "
            required
            readOnly={true}
            fullWidth
            selected={formData.datetime_created}
            name="order_date"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Created By</InputLabel>
          <TextField
            variant="outlined"
            margin="dense"
            name="datetime_created"
            disabled
            value={formData?.created_by?.name ? formData.created_by.name : ""}
            className="customFieldInput "
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>
            External ID {/* <span className="red">*</span> */}
          </InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            // required
            name="external_id"
            disabled={!edit}
            value={formData.external_id ? formData.external_id : ""}
            onChange={handleChange}
            className="customFieldInput "
            fullWidth
            placeholder="Enter External ID"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>
            Internal Campaign {/* <span className="red">*</span> */}
          </InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            // required
            name="internal_campaign_id"
            disabled={!edit}
            value={
              formData.internal_campaign_id ? formData.internal_campaign_id : ""
            }
            onChange={handleChange}
            className="customFieldInput "
            fullWidth
            placeholder="Enter Internal Campaign"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Spot Incentive </InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            // required
            type="number"
            name="spot_incentive"
            disabled={true}
            onKeyPress={(e) => validateNumber(e, /[0-9.]/)}
            value={formData.spot_incentive ? formData.spot_incentive : ""}
            onChange={handleChange}
            className="customFieldInput "
            fullWidth
            placeholder="Enter Spot Incentive"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Source Of Sale</InputLabel>
          <SelectBox
            Data={leadSourceData}
            id="id"
            async="true"
            disabled={!edit}
            value={formData.lead_source_name ? formData.lead_source_name : ""}
            desc="name"
            descId="id"
            getData={fetchLeadSourceBySearch}
            placeholder="Source Of Sale"
            className="form-control"
            onChange={handleSourceChange}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Assigned CRE</InputLabel>
          <SelectBox
            Data={filteredCREData()}
            id="id"
            async="true"
            disabled={!edit}
            value={formData?.assigned_to_name ? formData?.assigned_to_name : ""}
            desc="name"
            descId="id"
            getData={fetchCREBySearch}
            placeholder="Select Agent"
            className="form-control"
            onChange={handleCREChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Remarks</InputLabel>
          <TextField
            // autoFocus
            variant="outlined"
            margin="dense"
            // required
            rowsMax={5}
            rows={5}
            disabled={!edit}
            name="order_comment"
            value={formData.order_comment ? formData.order_comment : ""}
            onChange={handleChange}
            className="customFieldInput textareaField"
            multiline
            fullWidth
            placeholder="Enter Remarks"
          />
        </Grid>
      </Grid>

      {/* </form> */}
      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default OrderInfo;
