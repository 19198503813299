import React, { Component } from "react";

import apis from "../../../apiactions/api.actions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import Validation from "../../../plugins/validation.comp";
import { addEntity, addParams, constitutioncat, deleteParams, getEntity, updateEntity } from "../apifunctions";

class EntityTypeCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      formData: {},
      formParams: {},
      paramCount: 0,
      editflag: false,
      hideAdd: false,
      snackbarOpen: false,
      errors: {},
      submited: false,
      snackbarVariant: "",
      snackbarMessage: "",
      variations: [],
      addparam: true,
      constCatParams: [],
    };
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount = () => {
    if (this.props.match.params.id !== "new") {
      this.setState({ editflag: true });
      this.getEntityData();
    } else {
      this.setState({ editflag: false });
    }
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  async getEntityData() {
    this.setState({ loader: true });
    let data = [];
    try {
      getEntity(this.props.match.params.id)
        .then((res) => res)
        .then((res) => {
          data = res[0].variations;
          if (data) {
            data.forEach((item) => {
              item.parameter_option["enable"] = true;
            });
          }

          this.setState(
            {
              formData: {
                code: res[0].code,
                description: res[0].description,
                entity_variation_name: res[0].entity_variation_name,
                constitution_category:
                  res[0].constitution_category.constitution_category,
                constitution_category_name:
                  res[0].constitution_category.constitution_category_name,
                entity_type: res[0].id,
              },
              variations: data,

              constCatParams: res[0].constitution_category.category_parameters,
              loader: false,
            },
            () => {}
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  async getData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.constitution_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err?.response?.data?.error?.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleDataChange = (value, reason=undefined) => {
   
      let tag = constitutioncat + "?view=all&&search=" + value;
      this.getData(tag);
    
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSelectChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        constitution_category: e.id,
        constitution_category_name: e.constitution_category_name,
      },
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let required = ["constitution_category", "entity_variation_name", "code"];
    const errors = Validation(this.state.formData, "", required);

    this.setState(errors);
    if (Object.keys(errors).length === 0) {
      let variation = [];
      this.setState({ submited: true });
      if (this.state.editflag) {
        updateEntity(this.props.match.params.id, this.state.formData)
          .then((res) => res.data)
          .then((res) => {
            variation = res.variations;
            if (variation) {
              variation.forEach((item) => {
                item.parameter_option["enable"] = true;
              });
            }
            this.setState(
              {
                formData: {
                  code: res.code,
                  description: res.description,
                  entity_variation_name: res.entity_variation_name,
                  constitution_category:
                    res.constitution_category.constitution_category,
                  constitution_category_name:
                    res.constitution_category.constitution_category_name,
                  entity_type: res.id,
                },
                constCatParams: res.constitution_category.category_parameters,
                variations: variation,
                snackbarMessage: "Updated Successfully",
                snackbarOpen: true,
                snackbarVariant: "success",
                submited: false,
              },
              () => {}
            );
          })
          .catch((err) => {
            this.setState({
              submited: false,
              snackbarMessage: err?.response?.data?.error?.message,
              snackbarOpen: true,
              snackbarVariant: "error",
            });
          });
      } else {
        addEntity(this.state.formData)
          .then((res) => res.data)
          .then((res) => {
            variation = res.variations;
            if (variation) {
              variation.forEach((item) => {
                item.parameter_option["enable"] = true;
              });
            }
            this.setState(
              {
                hideAdd: true,
                formData: {
                  code: res.code,
                  description: res.description,
                  entity_variation_name: res.entity_variation_name,
                  constitution_category:
                    res.constitution_category.constitution_category,
                  constitution_category_name:
                    res.constitution_category.constitution_category_name,
                  entity_type: res.id,
                },
                variations: variation,
                constCatParams: res.constitution_category.category_parameters,
                snackbarMessage: "Created succesfully",
                snackbarOpen: true,
                snackbarVariant: "success",
                submited: false,
              },
              () => {}
            );
          })
          .catch((err) => {
            this.setState({
              submited: false,
              snackbarMessage: err?.response?.data?.error?.message,
              snackbarOpen: true,
              snackbarVariant: "error",
            });
          });
      }
    } else {
      let str = Object.keys(errors)
        .map(function (k) {
          return errors[k];
        })
        .join(",");
      this.setState({
        submited: false,
        snackbarMessage: (
          <div>
            <p>Please fill all required fields</p>
            <p>{str}</p>
          </div>
        ),
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };
  handlePramsSubmit = (e, index) => {
    const { variations } = this.state;
    let data = [];
    if (
      this.state.formParams.parameter_option &&
      this.state.formParams.parameter_option !== undefined &&
      this.state.formParams.parameter_option !== ""
    ) {
      addParams(this.state.formParams)
        .then((res) => res.data)
        .then((res) => {
          data = {
            id: res.entity_parameter_varaition_data.id,
            parameter_option: {
              enable: true,

              id: res.entity_parameter_varaition_data.parameter_option,
            },
          };
          variations[index] = data;
          this.setState(
            {
              formParams: {},
              addparam: true,
              variations,
              snackbarMessage: "Created succesfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {}
          );
        })
        .catch((err) => {
          this.setState({
            submited: false,
            snackbarMessage: err?.response?.data?.error?.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } else {
      this.setState({
        submited: false,
        snackbarMessage: "Please select parameter name",
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };
  handleClose = () => {
    this.props.history.push("/filings-compliance/entity-type");
  };

  handleParamChange = (e) => {
    const { variations, constCatParams } = this.state;
    this.setState(
      {
        formParams: {
          ...this.state.formParams,
          [e.target.name]: e.target.value,
          entity_type: this.state.formData.entity_type,
        },
      },
      () => {
        constCatParams.forEach((item) => {
          if (item.parameter_option.id === e.target.value) {
            item["select"] = true;
          } else {
            if (variations && variations.length !== 0) {
              variations.forEach((vare) => {
                if (vare.parameter_option.id === item.parameter_option.id) {
                  item["select"] = true;
                }
              });
            } else {
              item["select"] = false;
            }
          }
        });
      }
    );
  };
  handleParameter = () => {
    const { variations, constCatParams } = this.state;
    if (variations.length < constCatParams.length) {
      let data = {
        parameter_option: { id: null, parameter_option: null, enable: false },
      };
      variations.push(data);
      this.setState({
        variations,
        addparam: false,
      });
    } else {
      this.setState({
        addparam: false,
      });
    }
  };
  handleDelete = (id, index) => {
    const { variations } = this.state;

    deleteParams(id)
      .then((res) => {
        this.setState(
          {
            addparam: true,
            snackbarMessage: "Deleted succesfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            variations.splice(index, 1);
            this.setState({ variations });
          }
        );
      })
      .catch((err) => {
        this.setState({
          submited: false,
          snackbarMessage: err?.response?.data?.error?.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  render() {
    const {
      datas,
      editflag,
      formData,
      constCatParams,
      loader,
      addparam,
      hideAdd,
      variations,
    } = this.state;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-top">
              <h4 className="card-title">
                {" "}
                {editflag ? "Edit Variations" : "Add New Variations"}
              </h4>
              <div class="col-md-6 col-xs-6 text-right">
                <button
                  style={{ marginRight: 6 }}
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                  type="button"
                >
                  Cancel
                </button>
                {!hideAdd && (
                  <button
                    type="submit"
                    disabled={this.state.submited}
                    className="btn btn-primary"
                  >
                    {editflag ? "Update" : "Save"}
                  </button>
                )}
              </div>
            </div>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        Constitution Category <span className="red">*</span>
                      </label>
                      <SelectBox
                        Data={datas}
                        id="id"
                        required
                        async="true"
                        placeholder="Constitution Category"
                        value={formData.constitution_category_name}
                        desc="constitution_category_name"
                        getData={this.handleDataChange}
                        className="form-control"
                        onChange={this.handleSelectChange}
                      />
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Entity Variation Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="entity_variation_name"
                        value={formData.entity_variation_name}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Enter Variation Name"
                      />
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Entity Variation Code <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        name="code"
                        required
                        value={formData.code}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Enter Variation Code"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        Description <span className="red">*</span>
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        required
                        value={formData.description}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Enter Description"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="linedivider"></div>
                <div className="col-md-12 col-xs-12">
                  <button
                    disabled={
                      constCatParams.length === 0
                        ? true
                        : addparam
                        ? false
                        : true
                    }
                    className="btn btn-secondary"
                    type="button"
                    onClick={this.handleParameter}
                  >
                    + ADD NEW PARAMETER
                  </button>
                </div>
                <div className="col-md-12 col-xs-12 row">
                  {variations.map((filt, index) => (
                    <div className="col-md-5 col-xs-12 new-parameter">
                      {filt.parameter_option && (
                        <div className="position-relative">
                          <label className="control-label">
                            Parameter Name
                          </label>
                          <select
                            disabled={filt.parameter_option.enable}
                            onChange={this.handleParamChange}
                            className="form-control"
                            name="parameter_option"
                            value={filt.parameter_option.id}
                          >
                            <option value="">Select Parameter</option>
                            {constCatParams.map((item) => (
                              <React.Fragment>
                                {filt.parameter_option.enable ? (
                                  <option value={item.parameter_option.id}>
                                    {item.parameter_option.display_name}
                                  </option>
                                ) : (
                                  <React.Fragment>
                                    {!item.select && (
                                      <option value={item.parameter_option.id}>
                                        {item.parameter_option.display_name}
                                      </option>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ))}
                          </select>
                          {filt.parameter_option.enable ? (
                            <a
                              className="del-icon"
                              onClick={(e) => this.handleDelete(filt.id, index)}
                            >
                              <img src="/app-assets/backend-images/icons/delete.png" />
                            </a>
                          ) : (
                            <a
                              onClick={(e) => this.handlePramsSubmit(e, index)}
                              href="#/"
                              className="save-icon"
                            >
                              <img
                                src="/app-assets/backend-images/icons/save.png"
                                alt=""
                              />
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default EntityTypeCreate;
