import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import { getEmployeeList, searchSupportData } from "../apifunctions";

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        status: "",
        priority: "",
        client_type: "",
        agent_name: "",
        page: 1,
      },
      employee_list: [],
      pagesize: 0,
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
      this.loadEmployeeList();
    } catch (err) {}
  }

  handlepagination(page) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: page,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      searchSupportData(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleView = (e, row) => {
    this.props.history.push(`${this.props.match.path}-view/` + row.id);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  async loadEmployeeList() {
    try {
      getEmployeeList()
        .then((res) => {
          this.setState({
            employee_list: res,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }

  render() {
    const { masterData } = this.props;
    const { loader, formSearch, employee_list, totalCount, pagesize } =
      this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            {/*<div className="position-relative">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={this.handleChange}
                />
                <button
                  className="btn-transparent"
                  onClick={(e) => this.getData()}
                >
                  <img src="/app-assets/backend-images/icons/search-icon.svg" />
                </button>
              </div>*/}

            <Grid item xs={6} sm={2} className="">
              <select
                className="form-control"
                name="status"
                onChange={this.handleChange}
                value={formSearch.status ? formSearch.status : ""}
              >
                <option value="" selected>
                  Status
                </option>
                {masterData &&
                  masterData.enquiry_statuses &&
                  masterData.enquiry_statuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="">
              <select
                className="form-control"
                name="priority"
                onChange={this.handleChange}
                value={formSearch.priority ? formSearch.priority : ""}
              >
                <option value="" selected>
                  Priority
                </option>
                {masterData &&
                  masterData.enquiry_priorities &&
                  masterData.enquiry_priorities.map((priority) => (
                    <option key={priority} value={priority}>
                      {priority}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="">
              <select
                className="form-control"
                name="client_type"
                onChange={this.handleChange}
                value={formSearch.client_type ? formSearch.client_type : ""}
              >
                <option value="" selected>
                  Client Type
                </option>
                {masterData &&
                  masterData.client_type &&
                  masterData.client_type.map((client_type) => (
                    <option key={client_type} value={client_type}>
                      {client_type}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="">
              <select
                className="form-control"
                name="agent_name"
                onChange={this.handleChange}
                value={formSearch.agent_name ? formSearch.agent_name : ""}
              >
                <option value="" selected>
                  Agent Name
                </option>
                {employee_list &&
                  employee_list.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </Grid>

            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Created Date/ Time",
                    type: "date",
                    field: "enquiry_date"
                  },
                  {
                    title: "Ticket ID",
                    type: "text",
                    field: "ticket_id",
                  },
                  {
                    title: "Client Name",
                    type: "text",
                    field: "full_name",
                  },
                  {
                    title: "Client Type",
                    field: "client_type",
                    align: "center",
                  },
                  {
                    title: "Status",
                    type: "status",
                    field: "status",
                  },
                  {
                    title: "Priority",
                    field: "priority",
                  },
                  {
                    title: "Agent Name",
                    field: "agent_name",
                    render: (rowData) => (
                      <span>
                        {rowData.agent_name ? rowData.agent_name.name : ""}
                      </span>
                    ),
                  },
                ]}
                options={{
                  sort: false,
                  sortvalue: "enquiry_date",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "view",
                    rowActionCLick: this.handleView.bind(this),
                    iconName: "/app-assets/backend-images/icons/view.png",
                    tooltip: "View Details",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Support;
