import { Grid, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarComponent from "../../../../../plugins/snackbar-component";
import { searchRoles, update__employee } from "../../../apifunctions";
import TeamDialogTheme from "../../employeedetailtheme";

const TeamInfo = (props) => {
  console.log(props);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(props.formData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [snackbarVarient, setSnackbarVarient] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const onSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarVarient(null);
    setSnackbarMessage("");
  };
  const handleCancel = () => {
    setEdit(false);
    props.refetch();
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const getEmployeChange = (e, a, b) => {
    setFormData((f) => ({
      ...f,
      name: e["name"],
      id: e["id"],
    }));
  };
  const getEmployeesList = (e) => {
  
    searchRoles({ page: 1, ...(e ? { search: e } : {}) }).then((res) => {
      setEmployeeData(res?.results || []);
    });
  };
  useEffect(() => {
    getEmployeesList();
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      avatar_thumbnail: props.mainData.avatar_thumbnail,
      is_admin: props.mainData.is_admin,
      name: props.mainData.name,
      status: props.mainData.status,
      user: props.mainData.user,
      group: formData.id,
    };
    update__employee(props.mainData.id, payload)
      .then((res) => {
        setSnackbarVarient("success");
        setSnackbarMessage("Updated Successfully");
        setSnackbarOpen(true);

        setTimeout(() => {
          handleCancel();
        }, 500);
      })
      .catch(() => {
        setSnackbarVarient("error");
        setSnackbarMessage("Something went wrong");
        setSnackbarOpen(true);
        setLoading(false);
      });
  };
  return (
    <div>
      <MuiThemeProvider theme={TeamDialogTheme}>
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={2} className="customFields">
            <Grid item xs={12}>
              <div className=" pt-8 m-0">
                <div className="fieldBox d-flex align-items-center justify-content-between">
                  <div className="details-box">
                    <div className="detail-box border-r">
                      <div className="">
                        <p className="name">Role</p>
                      </div>
                    </div>
                  </div>
                  <div className="actions">
                    {!edit ? (
                      <button
                        className="btn btn-primary"
                        title="Edit Team Lead"
                        onClick={(e) => {
                          e.preventDefault();
                          setEdit(true);
                        }}
                      >
                        Change
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn btn-sm btn-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCancel();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-sm btn-primary"
                          // onClick={(e) => onSubmit(e)}
                          type="submit"
                        >
                          Update
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              {/* <label className="control-label">
            Team Lead <span className="red">*</span>
          </label> */}
              <SelectBox
                Data={employeeData}
                id="id"
                async="true"
                required
                disabled={!edit}
                value={formData.name ? formData.name : ""}
                desc="name"
                descId="id"
                getData={getEmployeesList}
                placeholder="Select Role"
                className="form-control"
                onChange={getEmployeChange}
              />
            </Grid>
          </Grid>
        </form>
      </MuiThemeProvider>
      <SnackbarComponent
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        onSnackbarClose={onSnackbarClose}
        variant={snackbarVarient}
      />
    </div>
  );
};

export default TeamInfo;
