import React from "react";
import { Route } from "react-router-dom";

function ComponentRouter(props) {
  return (
    <>
      {props.routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={`${props.match.path}${route.path}`}
            render={(arrayProps) => {
              return (
                // pass the sub-routes down to keep nesting
                <route.component key={index} masterData={arrayProps.masterData} {...props} routes={route.routes} />
              );
            }}
          />
        );
      })}
    </>
  );
}

export default ComponentRouter;
